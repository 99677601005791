import { useWindowSize } from 'react-use';
import { setScreenOrientation, setUserInteracted, setViewType, setWindowSize } from '@/store/uiSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import { getURLParam } from '@vpmedia/simplify';

const UiManager = () => {
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const dispatch = useDispatch();

  const handleInteraction = useCallback(() => {
    dispatch(setUserInteracted(true));
    document.removeEventListener('click', handleInteraction);
    document.removeEventListener('touchstart', handleInteraction);
  }, [dispatch]);

  const handleVisibilityChanged = useCallback(() => {
    if (document.visibilityState !== 'visible' && isTouchDevice) {
      dispatch(setUserInteracted(false));
      document.addEventListener('click', handleInteraction);
      document.addEventListener('touchstart', handleInteraction);
    }
  }, [dispatch, handleInteraction, isTouchDevice]);

  const onScreenOrientationChange = useCallback(() => {
    const currentOrientation = screen.orientation.type;

    const newOrientation = currentOrientation.includes('landscape')
      ? SCREEN_ORIENTATION.LANDSCAPE
      : SCREEN_ORIENTATION.PORTRAIT;

    dispatch(setScreenOrientation(newOrientation));
  }, [dispatch]);

  const isMacWithTouchScreen = useCallback(() => {
    const userAgent = navigator.userAgent;
    return /Macintosh/.test(userAgent);
  }, []);

  const checkDeviceType = useCallback(() => {
    const isDesktopParam = getURLParam('desktop');
    const result = isDesktopParam ? Number.parseInt(isDesktopParam) === 0 : matchMedia('(pointer:coarse)').matches;

    if (result && isMacWithTouchScreen()) {
      dispatch(setViewType(VIEW_TYPE.DESKTOP));
    } else {
      dispatch(setViewType(result ? VIEW_TYPE.TOUCH : VIEW_TYPE.DESKTOP));
    }
  }, [dispatch, isMacWithTouchScreen]);

  useEffect(() => {
    dispatch(setWindowSize({ height: windowHeight, width: windowWidth }));
  }, [dispatch, windowHeight, windowWidth]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChanged);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChanged);
    };
  }, [handleVisibilityChanged]);

  useEffect(() => {
    document.addEventListener('click', handleInteraction);
    document.addEventListener('touchstart', handleInteraction);
    screen.orientation.addEventListener('change', onScreenOrientationChange);

    const mediaQuery = matchMedia('(pointer:coarse)');
    const updateDeviceType = () => checkDeviceType();
    mediaQuery.addEventListener('change', updateDeviceType);

    onScreenOrientationChange();
    updateDeviceType();

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
      screen.orientation.removeEventListener('change', onScreenOrientationChange);
      mediaQuery.removeEventListener('change', updateDeviceType);
    };
  }, [checkDeviceType, handleInteraction, onScreenOrientationChange]);

  return null;
};

export default UiManager;
