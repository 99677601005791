import ChipSelector from '@/components/ChipSelector';
import IconButton from '@/components/IconButton';
import Timer from '@/components/Timer';
import Wallet from '@/components/Wallet';
import WinningNumber from '@/components/WinningNumber';
import ROUND_STATE from '@/enums/roundState';
import StatIcon from '@/assets/svg/statistics.svg?react';
import { closeFavoritesPopup, closeStatisticsPopup, openFavoritesPopup, openStatisticsPopup } from '@/store/uiSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../common/Tooltip';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import GameMessage from '@/components/GameMessage';
import DesktopDrawer from '@/components/DesktopDrawer';
import styles from './ThreeDimensionView.module.scss';
import Racetrack3D from '../Racetrack3D';
import NeighborNumberPicker3D from '../NeighborNumberPicker3D';
import { playPressSound } from '@/lib/soundService';
import QuickChat from '../common/QuickChat';
import { QUICK_CHAT_TYPE, ROULETTE_TABLE_TYPE } from '@/enums/ui';
import RouletteTable from '../RouletteTable';

const ThreeDimensionView = () => {
  const { t } = useTranslation(['common', 'tooltip']);
  const rightSectionRef = useRef();
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const showStatisticsPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showStatisticsPopup
  );
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const showFavoritesPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showFavoritesPopup
  );
  const dispatch = useDispatch();
  const isBettingTime = useSelector(selectIsBettingTime);

  const toggleStatistics = useCallback(() => {
    playPressSound();
    showStatisticsPopup ? dispatch(closeStatisticsPopup()) : dispatch(openStatisticsPopup());
  }, [dispatch, showStatisticsPopup]);

  const toggleFavorites = useCallback(() => {
    playPressSound();
    showFavoritesPopup ? dispatch(closeFavoritesPopup()) : dispatch(openFavoritesPopup());
  }, [dispatch, showFavoritesPopup]);

  const chipSelectorGradientAnimations = {
    opened: {
      opacity: '100%',
      transition: {
        duration: 0.8,
      },
    },
    closed: {
      opacity: '0%',
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <>
      <div className={styles.neighborNumberPicker}>
        <NeighborNumberPicker3D />
      </div>
      <div className={styles.racetrackContainer}>
        <Racetrack3D />
      </div>
      <div className={styles.tableContainer}>
        <RouletteTable type={ROULETTE_TABLE_TYPE.THREE_DIMENSION} />
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.wallet}>
            <Wallet className={styles.wallet} />
          </div>
        </div>
        <div className={styles.center}>
          {roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null && (
            <div className={styles.winningNumber}>
              <WinningNumber showWin={true} number={winningNumber} />
            </div>
          )}
          <div key="main-timer" className={styles.timer}>
            <Timer size={100} />
          </div>
          <div className={styles.gameMessage}>
            <GameMessage />
          </div>
          <div className={clsx(styles.chipSelectorContainer, !isBettingTime && styles.closed)}>
            <motion.div
              animate={isBettingTime ? 'opened' : 'closed'}
              variants={chipSelectorGradientAnimations}
              className={styles.chipSelectorGradient}
            ></motion.div>
            <ChipSelector mode="list" />
          </div>
        </div>
        <div ref={rightSectionRef} className={styles.right}>
          <div className={styles.buttonContainer}>
            <Tooltip content={t('favoriteAndSpecialBets')} distance={6}>
              <IconButton
                icon={<FaStar size="1em" />}
                className={clsx(styles.iconButton, showFavoritesPopup && styles.activeMenuItem)}
                onClick={toggleFavorites}
              />
            </Tooltip>
            <Tooltip content={t('statistics')} distance={6}>
              <IconButton
                icon={<StatIcon height="1em" />}
                className={clsx(styles.iconButton, showStatisticsPopup && styles.activeMenuItem)}
                onClick={toggleStatistics}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.quickChat}>
        <QuickChat type={QUICK_CHAT_TYPE.REVERSE} />
      </div>

      <DesktopDrawer />
    </>
  );
};

export default ThreeDimensionView;
