import { VOICE_TYPE } from '@/enums/sound';
import { QUALITY } from '@/enums/stream';
import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} SettingsState - TBD.
 * @property {{main: {level: number, isMuted: boolean}, studio: {level: number, isMuted: boolean}, effects: {level: number, isMuted: boolean}}} soundVolume - TBD.
 * @property {{automaticAdjustment: boolean, quality: string}} stream - TBD.
 * @property {{hideChatFromOtherPlayers: boolean, autoZoom: boolean, leaveWinningBets: boolean}} general - TBD.
 * @property {string} voiceType - TBD.
 * @property {string} screenName - TBD.
 */

/** @type {SettingsState} */
const initialState = {
  soundVolume: {
    main: { level: 100, isMuted: true },
    studio: { level: 100, isMuted: false },
    effects: { level: 100, isMuted: false },
  },
  stream: {
    automaticAdjustment: true,
    quality: QUALITY.HIGH,
  },
  general: {
    hideChatFromOtherPlayers: false,
    autoZoom: false,
    leaveWinningBets: false,
  },
  voiceType: VOICE_TYPE.MALE,
  screenName: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setHideChatFromOtherPlayers: (state, action) => {
      state.general.hideChatFromOtherPlayers = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setAutoZoom: (state, action) => {
      state.general.autoZoom = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setLeaveWinningBets: (state, action) => {
      state.general.leaveWinningBets = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setStreamAutomaticAdjustment: (state, action) => {
      state.stream.automaticAdjustment = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setStreamQuality: (state, action) => {
      state.stream.quality = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{type: string, level: number}>} action - Store action.
     */
    setSoundVolume: (state, action) => {
      const { type, level } = action.payload;
      const isMuted = state.soundVolume[type].isMuted;
      if (isMuted && level === 0) return;

      state.soundVolume[type].level = level;
      state.soundVolume[type].isMuted = level === 0 ? true : false;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setVoiceType: (state, action) => {
      state.voiceType = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{type: string}>} action - Store action.
     */
    muteSound: (state, action) => {
      const { type } = action.payload;
      state.soundVolume[type].isMuted = true;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{type: string}>} action - Store action.
     */
    unmuteSound: (state, action) => {
      const { type } = action.payload;
      const soundLevel = state.soundVolume[type].level;

      state.soundVolume[type].isMuted = false;
      state.soundVolume[type].level = soundLevel === 0 ? 50 : soundLevel;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setScreenName: (state, action) => {
      state.screenName = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - Store action.
     */
    setPlayerSettings: (state, action) => {
      const { general, stream, soundVolume, screenName } = action.payload;
      state.screenName = screenName;
      state.general = general;
      state.stream = stream;
      state.soundVolume = soundVolume;
    },
  },
});

export const {
  setHideChatFromOtherPlayers,
  setAutoZoom,
  setLeaveWinningBets,
  setStreamAutomaticAdjustment,
  setStreamQuality,
  setSoundVolume,
  setVoiceType,
  muteSound,
  unmuteSound,
  setScreenName,
  setPlayerSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
