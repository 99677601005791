import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import { MODAL_TYPE } from '@/enums/modalType';
import useModal from '@/hooks/useModal';
import { setAutoZoom, setHideChatFromOtherPlayers, setLeaveWinningBets } from '@/store/settingsSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './GeneralSettings.module.scss';

const GeneralSettingsView = () => {
  const { t } = useTranslation('common');
  const general = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings?.general);
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const changeScreenName = useCallback(() => {
    openModal('Screen name', MODAL_TYPE.SCREEN_NAME, null, false);
  }, [openModal]);

  return (
    <div className={styles.container}>
      <Checkbox
        label={t('settings.hideChat')}
        checked={general?.hideChatFromOtherPlayers}
        onChange={(value) => dispatch(setHideChatFromOtherPlayers(value))}
      />
      <Checkbox
        label={t('settings.autoZoom')}
        checked={general?.autoZoom}
        onChange={(value) => dispatch(setAutoZoom(value))}
      />
      <Checkbox
        label={t('settings.winningBets')}
        checked={general?.leaveWinningBets}
        onChange={(value) => dispatch(setLeaveWinningBets(value))}
      />
      <Button onClick={changeScreenName}>{t('screenName.change')}</Button>
    </div>
  );
};

export default GeneralSettingsView;
