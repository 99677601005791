import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import socketApi from '@/lib/socketApi';
import { setScreenName } from '@/store/settingsSlice';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '../../../../hooks/useModal';
import styles from './ScreenNameModalView.module.scss';

const ScreenNameModalView = () => {
  const { t } = useTranslation('common');
  const settings = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings);
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const maxLength = 20;
  const { screenName } = settings || {};
  const [newScreenName, setNewScreenName] = useState(screenName);

  const handleScreenNameChanged = useCallback((event) => {
    const name = event.target.value;
    if (maxLength < name.length) return;
    setNewScreenName(name);
  }, []);

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(setScreenName(newScreenName));
      if (!screenName) {
        socketApi.savePlayerSettings({ ...settings, screenName: newScreenName });
      }
      closeModal();
    },
    [dispatch, newScreenName, closeModal, settings, screenName]
  );

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleFormSubmit}>
        <div>{t('screenName.title')}</div>
        <TextInput
          value={newScreenName ?? ''}
          onChange={handleScreenNameChanged}
          maxLength={maxLength}
          autoFocus={true}
        />
        <Button disabled={!newScreenName || newScreenName === ''} text="OK" className={styles.submitButton} />
      </form>
    </div>
  );
};

export default ScreenNameModalView;
