export const MENU_VIEW = Object.freeze({
  CHAT: 'chat',
  SETTINGS: 'settings',
  HISTORY: 'history',
  HELP: 'help',
});

export const VIEW_TYPE = Object.freeze({
  DESKTOP: 'desktop',
  TOUCH: 'touch',
});

export const DESKTOP_VIEW_TYPE = Object.freeze({
  THREE_DIMENSION: 'threeDimension',
  CLASSIC: 'classic',
  IMMERSIVE: 'immersive',
});

export const MODAL_VIEW = Object.freeze({
  TABLE_LIMIT_REACHED: 'tableLimitReached',
  SCREEN_NAME: 'screenName',
  ERROR: 'error',
  TABLE_CLOSED: 'tableClosed',
});

export const SIDENAV_VIEW = Object.freeze({
  FAVORITE_BETS: 'favoriteBets',
  STATISTICS: 'statistics',
  CHAT: 'chat',
  HELP: 'help',
  SETTINGS: 'settings',
  LIMITS_AND_PAYOUTS: 'limitsAndPayouts',
  HISTORY: 'history',
});

export const ORIENTATION = Object.freeze({
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
});

export const SCREEN_ORIENTATION = Object.freeze({
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
});

export const QUICK_CHAT_TYPE = Object.freeze({
  NORMAL: 'normal',
  REVERSE: 'reverse',
});

export const ROULETTE_TABLE_TYPE = Object.freeze({
  RESPONSIVE: 'responsive',
  THREE_DIMENSION: 'threeDimension',
});
