export const CHAT_MESSAGE_TYPE = Object.freeze({
  PLAYER: 'player',
  PLAYER_ENTERED_TABLE: 'playerEnteredTable',
  PLAYER_LEFT_TABLE: 'playerLeftTable',
  DEALER: 'dealer',
  PIT_BOSS: 'pitBoss',
});

export const CHAT_VIEW_TYPE = Object.freeze({
  DEFAULT: 'default',
  COMPACT: 'compact',
  TOUCH_MENU: 'touchMenu',
});
