import clsx from 'clsx';
import styles from './MessageWall.module.scss';
import WinnerList from '@/components/WinnerList';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { clearWinners } from '@/store/messageWallSlice';

const MessageWall = ({ height, duration = 7, className }) => {
  const winners = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.messageWall.winners);
  const dispatch = useDispatch();

  const handleAnimationCompleted = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        dispatch(clearWinners());
      }
    },
    [dispatch]
  );

  return (
    <div className={clsx(styles.container, className)} style={{ height }}>
      <div
        className={styles.messageWall}
        style={{
          transition: winners ? `all ${duration}s linear` : null,
          transform: winners ? `translateY(-${height})` : null,
        }}
        onTransitionEnd={handleAnimationCompleted}
      >
        {winners && <WinnerList winners={winners} />}
      </div>
    </div>
  );
};

export default MessageWall;
