import cl from 'clsx';
import styles from './Table3D.module.scss';
import { useSelector } from 'react-redux';
import { betSpots } from './betSpotItems';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import useHighlighted from '@/hooks/useHighlighted';
import { useEffect, useState } from 'react';
import useClickAndDrag from '@/hooks/useClickAndDrag';
import { versionedUrl } from '@/lib/urlService';

const Table3D = ({ onBetSpotClicked, onBetSpotEntered, onBetSpotLeaved, onChipPositionCalculated }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { isHighlighted } = useHighlighted();
  const isBettingTime = useSelector(selectIsBettingTime);
  const dragAreaRef = useClickAndDrag(onBetSpotClicked, onBetSpotEntered, onBetSpotLeaved);

  useEffect(() => {
    if (isInitialized) return;
    const calculateChipPositions = (path) => {
      const bbox = path.getBBox();
      const parentBBox = path.parentNode.getBBox();
      const parentWidth = parentBBox.width;
      const parentHeight = parentBBox.height;
      const centerX = ((bbox.x + bbox.width / 2) / Number.parseFloat(parentWidth)) * 100;
      const centerY = ((bbox.y + bbox.height / 2) / Number.parseFloat(parentHeight)) * 100;
      return { x: centerX, y: centerY, betKey: path.dataset.betKey };
    };

    const paths = document.querySelectorAll('#rouletteTable3d path');
    const centers = [...paths].map((path) => calculateChipPositions(path));
    const positions = Object.fromEntries(centers.map(({ x, y, betKey }) => [betKey, { x, y }]));
    onChipPositionCalculated(positions);
    setIsInitialized(true);
  }, [isInitialized, onChipPositionCalculated]);

  return (
    <svg id="rouletteTable3d" ref={dragAreaRef} viewBox="0 0 1132.73 805.95" textRendering="geometricPrecision">
      <defs>
        <style>
          {Object.entries(styles)
            .map(
              ([selector, rules]) =>
                `${selector} { ${Object.entries(rules)
                  .map(([prop, value]) => `${prop}: ${value};`)
                  .join(' ')} }`
            )
            .join(' ')}
        </style>
      </defs>
      <g className={cl(styles.rouletteTable, isBettingTime && styles.enabled)}>
        {betSpots.map((betSpot) => (
          <path
            id={betSpot.id}
            key={betSpot.id}
            className={cl(
              'tableBetSpot',
              styles.betSpot,
              styles[betSpot.color],
              isBettingTime && isHighlighted(betSpot.betKey) && styles.highlighted
            )}
            d={betSpot.d}
            data-bet-key={betSpot.betKey}
            onMouseEnter={() => onBetSpotEntered(betSpot.betKey)}
            onMouseLeave={() => onBetSpotLeaved()}
          ></path>
        ))}
        <image
          xlinkHref={versionedUrl('/images/3d-roulette-table.svg')}
          x="0"
          y="0"
          height="100%"
          width="100%"
          style={{ pointerEvents: 'none' }}
        ></image>
      </g>
    </svg>
  );
};

export default Table3D;
