import DoubleIcon from '@/assets/svg/double.svg?react';
import IconButton from '@/components/IconButton';
import useShortcut from '@/hooks/useShortcut';
import { aggregateBetsByType, getTotalBetValue, isBetEmpty } from '@/lib/rouletteService';
import { playNotAllowedSound } from '@/lib/soundService';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { addBetThunk } from '@/store/thunks/gameThunks';
import cl from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DoubleButton.module.scss';

const DoubleButton = ({ labelDirection = 'right', withLabel = true, className }) => {
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const { t } = useTranslation(['roulette']);
  const isBettingTime = useSelector(selectIsBettingTime);
  const betHistory = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory);
  const dispatch = useDispatch();

  const isDoubleDisabled = useCallback(() => {
    return !isBettingTime || isBetEmpty(bets) || getTotalBetValue(bets) > balance;
  }, [balance, bets, isBettingTime]);

  const handleDoubleBet = useCallback(
    async (event) => {
      if (isDoubleDisabled()) {
        playNotAllowedSound();
        return;
      }

      const mappedBets = aggregateBetsByType(betHistory);
      await dispatch(addBetThunk(mappedBets, event));
    },
    [betHistory, dispatch, isDoubleDisabled]
  );

  const spaceShortcut = [{ key: ' ' }];
  const handleSpaceShortcut = useCallback(
    (event) => {
      handleDoubleBet();
    },
    [handleDoubleBet]
  );

  useShortcut(spaceShortcut, handleSpaceShortcut);

  return (
    <span>
      <IconButton
        onClick={(event) => handleDoubleBet(event)}
        icon={<DoubleIcon height="1.25em" />}
        className={cl(styles.button, className)}
        disabled={isDoubleDisabled()}
        label={withLabel && t('chipSelector.double')}
        labelDirection={labelDirection}
      />
      {/* <Tooltip id="double-tooltip" isOpen={false} /> */}
    </span>
  );
};

export default DoubleButton;
