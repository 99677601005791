import { createContext, useCallback, useState } from 'react';

/**
 * Context for managing modal state and functionality.
 * @typedef {object} ModalContextType
 * @property {boolean} isOpen - Flag indicating whether the modal is open or not.
 * @property {?string} modalTitle - Title of the modal.
 * @property {?string} modalType - Type of the modal.
 * @property {?object} modalProps - Additional properties for the modal.
 * @property {Function} openModal - Function to open a modal.
 * @property {Function} closeModal - Function to close the modal.
 * @property {boolean} displayCloseButton - Flag indicating whether to display the close button.
 * @property {boolean} displayConfirmButton - Flag indicating whether to display the confirm button.
 * @property {?Function} onCloseCallback - Callback function to execute on modal close.
 * @property {?Function} onConfirmCallback - Callback function to execute on confirm button click.
 * @property {string} confirmButtonText - Text for the confirm button.
 */

export const ModalContext = createContext(/** @type {ModalContextType} */ ({}));

/**
 * Provider component for managing modal state and functionality.
 * @param {object} props - React component props.
 * @param {import('react').ReactNode} props.children - Child components.
 * @returns {import('react').JSX.Element} ModalProvider component.
 */
export function ModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [onCloseCallback, setOnCloseCallback] = useState(null);
  const [onConfirmCallback, setOnConfirmCallback] = useState(null);
  const [displayCloseButton, setDisplayCloseButton] = useState(true);
  const [displayConfirmButton, setDisplayConfirmButton] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState('Ok');

  /**
   * Function to open a modal.
   * @param {string} title - Title of the modal.
   * @param {string} type - Type of the modal.
   * @param {object} props - Additional properties for the modal.
   * @param {boolean} [hasCloseButton=true] - Flag indicating whether to display the close button.
   * @param {boolean} [hasConfirmButton=false] - Flag indicating whether to display the confirm button.
   * @param {Function} closeCallback - Callback function to execute on modal close.
   * @param {Function} confirmCallback - Callback function to execute on confirm button click.
   * @param {string} [confirmButtonText='Ok'] - Text for the confirm button.
   */
  const openModal = useCallback(
    (
      title,
      type,
      props,
      hasCloseButton = true,
      hasConfirmButton = false,
      closeCallback,
      confirmCallback,
      confirmButtonText = 'Ok'
    ) => {
      setModalTitle(title);
      setModalType(type);
      setModalProps(props);
      setDisplayCloseButton(hasCloseButton);
      setDisplayConfirmButton(hasConfirmButton);
      setOnCloseCallback(() => closeCallback);
      setOnConfirmCallback(() => confirmCallback);
      setConfirmButtonText(confirmButtonText);
      setIsOpen(true);
    },
    []
  );

  /**
   * Function to close the modal.
   */
  const closeModal = useCallback(() => {
    if (!isOpen) return;

    setIsOpen(false);
    if (onCloseCallback) {
      onCloseCallback();
      setOnCloseCallback(null);
    }
  }, [isOpen, onCloseCallback]);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        modalTitle,
        openModal,
        modalType,
        modalProps,
        closeModal,
        displayCloseButton,
        displayConfirmButton,
        onCloseCallback,
        onConfirmCallback,
        confirmButtonText,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
