import styles from './Checkbox.module.scss';

const Checkbox = ({ label, checked, onChange, ...props }) => {
  return (
    <label className={styles.container} {...props}>
      <input type="checkbox" checked={checked} onChange={(event) => onChange(event.target.checked)} />
      {label}
    </label>
  );
};

export default Checkbox;
