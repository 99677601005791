import Figure from '@/assets/svg/dolly.svg?react';
import styles from './Dolly.module.scss';

const Dolly = (props) => {
  return (
    <svg className={styles.pulseSvg} viewBox="0 0 500 500" {...props}>
      <g>
        <g transform="translate(0 -100)">
          <Figure />
        </g>

        <path
          className={styles.firstCircle}
          d="M250 29c122.1 0 221 99 221 221s-99 221-221 221S29 372 29 250 127.9 29 250 29m0-29C112.1 0 0 112.1 0 250s112.1 250 250 250 250-112.1 250-250S387.9 0 250 0z"
        />
        <path
          className={styles.secondCircle}
          d="M250 29c122.1 0 221 99 221 221s-99 221-221 221S29 372 29 250 127.9 29 250 29m0-29C112.1 0 0 112.1 0 250s112.1 250 250 250 250-112.1 250-250S387.9 0 250 0z"
        />
      </g>
    </svg>
  );
};

export default Dolly;
