const ColdIcon = (props) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" width={29} height={29} {...props}>
    <defs>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 23"
        id="cold"
      >
        <defs>
          <radialGradient id="cold_a" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="#609CFF" />
            <stop offset="100%" stopColor="#609CFF" stopOpacity={0} />
          </radialGradient>
          <linearGradient id="cold_b" x1="100%" x2="0%" y1="50%" y2="50%">
            <stop offset="0%" stopColor="#D3FFFB" stopOpacity={0.1} />
            <stop offset="100%" stopColor="#35E1FF" stopOpacity={0.6} />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" transform="rotate(-90 11.9 11.9)">
          <circle cx={1.895} cy={12} r={12} fill='url("#cold_a")' />
          <path fill='url("#cold_b")' d="M22.737 11.205 2.739 18.403l3.925 3.638L0 24V0l13.757 2.738-4.364 2.455z" />
        </g>
      </symbol>
    </defs>
    <use xlinkHref="#cold" />
  </svg>
);

export default ColdIcon;
