import { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import clsx from 'clsx';
import styles from './CountdownCloseButton.module.scss';

const FULL_DASH_ARRAY = 283;
const TIME_LIMIT = 10;

const CountdownCloseButton = ({ size, className, onClose = () => {}, duration = TIME_LIMIT }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const [circleDashArray, setCircleDashArray] = useState(`${FULL_DASH_ARRAY} ${FULL_DASH_ARRAY}`);
  const timerInterval = useRef(null);

  const calculateTimeFraction = useCallback(() => {
    return timeLeft / duration;
  }, [timeLeft, duration]);

  const calculateCircleDashArray = useCallback(() => {
    const fraction = calculateTimeFraction();
    const dashArray = `${(fraction * FULL_DASH_ARRAY).toFixed(0)} ${FULL_DASH_ARRAY}`;
    setCircleDashArray(dashArray);
  }, [calculateTimeFraction]);

  const stopTimer = useCallback(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }
    onClose();
  }, [onClose]);

  const startTimer = useCallback(() => {
    if (timerInterval.current !== null) return;

    timerInterval.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  }, []);

  useEffect(() => {
    calculateCircleDashArray();
  }, [calculateCircleDashArray, timeLeft]);

  useEffect(() => {
    startTimer();
    return () => {
      if (timerInterval.current !== null) {
        clearInterval(timerInterval.current);
        timerInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      stopTimer();
    }
  }, [stopTimer, timeLeft]);

  return (
    <div className={clsx(styles.timer, className)} style={{ width: size, height: size }} onClick={stopTimer}>
      <svg className={styles.svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g className={styles.circle}>
          <circle className={styles.pathElapsed} cx="50" cy="50" r="45"></circle>
          <path
            strokeDasharray={circleDashArray}
            className={styles.pathRemaining}
            d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
          ></path>
        </g>
      </svg>
      <AiOutlineClose size="1em" className={styles.closeIcon} />
    </div>
  );
};

export default CountdownCloseButton;
