import GameLabel from '@/components/common/GameLabel';
import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useBalance from '@/hooks/useBalance';
import useMoney from '@/hooks/useMoney';
import useOrientation from '@/hooks/useOrientation';
import { format as dateFormat } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SingleLineMessageWall from '../SingleLineMessageWall';
import cl from 'clsx';
import styles from './Footer.module.scss';

const Footer = () => {
  const orientation = useOrientation();
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const { t } = useTranslation('wallet');
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const { totalBet } = useBalance();
  const { format } = useMoney();
  const time = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.time);
  const roundStartedAt = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.roundStartedAt
  );
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;

  return (
    <>
      {isTouchDevice && orientation === SCREEN_ORIENTATION.PORTRAIT && (
        <SingleLineMessageWall className={styles.messageWall} />
      )}
      <div className={cl(styles.container, styles[orientation])}>
        <div>
          <div className={styles.bet}>
            <div className={styles.walletLabel}>{t('bet')}</div>
            <div className={styles.walletValue}>{format(totalBet)}</div>
          </div>
          <div className={styles.total}>
            <div className={styles.walletLabel}>{t('balance')}</div>
            <div className={styles.walletValue}>{format(balance)}</div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.info}>
            {time && <div className="time">{dateFormat(new Date(time), 'HH:mm')}</div>}
            <div className="round">
              {roundStartedAt ? dateFormat(new Date(roundStartedAt), '#HH:mm:ss') : '#--:--:--'}
            </div>
          </div>
          {isTouchDevice && <GameLabel className={styles.gameLabel} />}
        </div>
      </div>
    </>
  );
};

export default Footer;
