import { filterObjectFields } from './arrayService';

/**
 * Maps input data to match the API request schema for adding bets.
 * @param {object[]} data - The array of objects to filter.
 * @returns {object[]} The filtered data.
 */
export const mapAddBetsPayload = (data) => {
  const allowedFields = ['bet', 'betType', 'transactionId'];
  return filterObjectFields(data, allowedFields);
};
