import { motion, AnimatePresence } from 'framer-motion';
import { HiOutlineChevronDown } from 'react-icons/hi';
import clsx from 'clsx';
import styles from './Accordion.module.scss';

const Accordion = ({ headerTitle, isOpen = false, setExpanded, children }) => {
  return (
    <div className={styles.container}>
      <motion.div className={styles.header} initial={false} onClick={setExpanded}>
        <div className={styles.title}>{headerTitle}</div>
        <div className={clsx(styles.icon, isOpen && styles.open)}>
          <HiOutlineChevronDown />
        </div>
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className={styles.content}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}
            transition={{ duration: 0.4 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
