import StateObserver from '@/components/StateObserver';
import CustomTooltip from '@/components/common/CustomTooltip';
import { LowBalanceContextProvider } from '@/context/LowBalanceContext';
import { ModalProvider } from '@/context/ModalContext';
import { TooltipContextProvider } from '@/context/TooltipContext';
import { initI18n } from '@/i18n/index';
import store from '@/store/index';
import '@/styles/globals.scss';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { ErrorBoundary } from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import AppError from './AppError';
import { initOpenTelemetry } from './lib/opentelemetryService';
import { initLog } from './lib/paramService';
import { initSentry } from './lib/sentryService';

initLog();
initSentry();
initOpenTelemetry();
initI18n();

createRoot(document.querySelector('#root')).render(
  <ErrorBoundary fallback={({ error }) => <AppError error={error} />}>
    <Provider store={store}>
      <ModalProvider>
        <TooltipContextProvider>
          <LowBalanceContextProvider>
            <App />
          </LowBalanceContextProvider>
          <CustomTooltip />
        </TooltipContextProvider>
        <StateObserver />
      </ModalProvider>
    </Provider>
  </ErrorBoundary>
);
