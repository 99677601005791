import { SERVER_ERROR_TYPE } from '@/enums/errorType';

/**
 * TBD.
 * @param {string} error - TBD.
 * @returns {boolean} TBD.
 */
export const isErrorTerminating = (error) => {
  const nonTerminatingErrors = [SERVER_ERROR_TYPE.TIME_EXPIRED, SERVER_ERROR_TYPE.DEVICE_MANAGER_ERROR];
  return !nonTerminatingErrors.includes(error);
};

/**
 * TBD.
 * @param {string} error - TBD.
 * @returns {boolean} TBD.
 */
export const isErrorSuppressed = (error) => {
  const suppressedErrors = [
    SERVER_ERROR_TYPE.INVALID_BET_ADD,
    SERVER_ERROR_TYPE.INVALID_BET_REMOVE,
    SERVER_ERROR_TYPE.TABLE_LIMIT_REACHED,
  ];
  return suppressedErrors.includes(error);
};
