import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMoney from './useMoney';
import { v4 as uuidv4 } from 'uuid';

const useWinners = () => {
  const [messages, setMessages] = useState(null);
  const { format } = useMoney();
  const { winners } = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.messageWall);

  useEffect(() => {
    if (winners) {
      setMessages(
        winners.map(({ screenName, win }) => ({
          id: uuidv4(),
          message: `${format(win)} ${screenName}`,
        }))
      );
    }
  }, [format, winners]);

  return { messages };
};

export default useWinners;
