/**
 * TBD.
 * @param {number} number - TBD.
 * @param {number} decimal - TBD.
 * @returns {number} TBD.
 */
export const roundNumber = (number, decimal) => +number.toFixed(decimal);

/**
 * Checks if a character is a numeric digit (0-9).
 * @param {string} char - The character to check.
 * @returns {boolean} True if the character is a numeric digit, false otherwise.
 */
export const checkNumeric = (char) => /^\d$/.test(char);
