import cl from 'clsx';
import { BET_TYPE, COLOR } from '@/enums/layoutTable';
import { ORIENTATION } from '@/enums/ui';
import { roundNumber } from '@/lib/numberService';
import { createBetKey } from '@/lib/rouletteService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './ResponsiveBetSpots.module.scss';
import BetSpot from './BetSpot';
import {
  createCornerSensor,
  createSixLineSensor,
  createSplitSensor,
  createStreetSensor,
  getRectanglePath,
  getZeroPath,
} from '@/lib/rouletteTableService';

const ResponsiveBetSpots = ({
  orientation = ORIENTATION.HORIZONTAL,
  tableOptions,
  onChipPositionCalculated,
  onBetSpotEntered,
  onBetSpotLeaved,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const { tableWidth } = tableOptions;

  const getNumberBetSpot = useCallback(
    ({ number, color }) => {
      const { numberItem } = tableOptions;
      const remainder = number % 3;
      let baseX;
      let baseY;
      let itemWidth = numberItem.width;
      let itemHeight = numberItem.height;
      const isHorizontal = orientation === ORIENTATION.HORIZONTAL;

      if (number === 0) {
        if (isHorizontal) {
          itemWidth = numberItem.width;
          itemHeight = numberItem.height * 3;
          baseX = 0;
          baseY = 0;
        } else {
          itemWidth = 3 * numberItem.width;
          itemHeight = numberItem.height;
          baseX = tableWidth - 3 * numberItem.width;
          baseY = 0;
        }
      } else {
        if (isHorizontal) {
          const itemRow = remainder === 1 ? 3 : remainder === 2 ? 2 : 1;
          const itemColumn =
            itemRow === 1 ? roundNumber(Math.trunc(number / 3), 2) - 1 : roundNumber(Math.trunc(number / 3), 2);
          baseX = number === 0 ? 0 : itemColumn * itemWidth + itemWidth;
          baseY = number === 0 ? 0 : (itemRow - 1) * itemHeight;
        } else {
          const itemColumn = remainder === 1 ? 3 : remainder === 2 ? 2 : 1;
          const itemRow = remainder === 0 ? number / 3 - 1 : Math.trunc(number / 3);
          baseX = number === 0 ? tableWidth - 3 * itemWidth : tableWidth - itemColumn * itemWidth;
          baseY = number === 0 ? 0 : itemHeight + itemRow * itemHeight;
        }
      }

      return {
        content: number.toString(),
        path: number === 0 ? getZeroPath(orientation, itemWidth, itemHeight) : getRectanglePath(itemWidth, itemHeight),
        textOrientation: ORIENTATION.HORIZONTAL,
        x: roundNumber(baseX),
        y: roundNumber(baseY),
        itemWidth,
        itemHeight,
        color,
        betType: BET_TYPE.STRAIGHT,
        betKey: createBetKey(BET_TYPE.STRAIGHT, [number]),
      };
    },
    [tableOptions, orientation, tableWidth]
  );

  const getColumnText = useCallback(
    () => (
      <>
        <tspan dominantBaseline="central" textAnchor="middle">
          2
        </tspan>
        <tspan dominantBaseline="central" textAnchor="middle" dy="-0.2rem" style={{ fontSize: '.6em' }}>
          TO
        </tspan>
        <tspan dominantBaseline="central" textAnchor="middle" dy="0.2rem">
          1
        </tspan>
      </>
    ),
    []
  );

  const getDozenText = useCallback(
    (value) => (
      <>
        <tspan dominantBaseline="central" textAnchor="middle">
          {value}
        </tspan>
        <tspan
          dominantBaseline="central"
          textAnchor="middle"
          dy={orientation === ORIENTATION.HORIZONTAL ? '-0.2rem' : '-3%'}
          style={{ fontSize: '.6em' }}
        >
          {value === 1 ? 'ST' : value === 2 ? 'ND' : 'RD'}
        </tspan>
        <tspan
          dominantBaseline="central"
          textAnchor="middle"
          dy={orientation === ORIENTATION.HORIZONTAL ? '0.2rem' : '3%'}
        >
          12
        </tspan>
      </>
    ),
    [orientation]
  );

  const getDiamondSize = useCallback(
    (itemWidth, itemHeight) => {
      return orientation === ORIENTATION.HORIZONTAL ? itemWidth * 0.4 : itemHeight * 0.6;
    },
    [orientation]
  );

  const getDiamond = useCallback(
    (itemWidth, itemHeight, betType) => {
      return (
        <svg
          viewBox="0 0 99 44"
          {...(orientation === ORIENTATION.HORIZONTAL
            ? {
                width: getDiamondSize(itemWidth, itemHeight),
                x: roundNumber(itemWidth / 2, 2) - roundNumber(getDiamondSize(itemWidth, itemHeight) / 2, 2),
              }
            : {
                height: getDiamondSize(itemWidth, itemHeight),
                y: roundNumber(itemHeight / 2, 2) - roundNumber(getDiamondSize(itemWidth, itemHeight) / 2, 2),
              })}
          style={{ overflow: 'visible' }}
        >
          <g className={cl(styles.diamond, orientation === ORIENTATION.VERTICAL && styles.vertical)}>
            <path
              className={styles.diamond}
              stroke="#828181"
              strokeWidth="2"
              d="m1.12801,22.02819l48.33871,-21.46375l48.33871,21.46375l-48.33871,21.46375l-48.33871,-21.46375z"
              fill={betType === BET_TYPE.RED ? '#d20404' : '#1A1A1A'}
            ></path>
          </g>
        </svg>
      );
    },
    [getDiamondSize, orientation]
  );

  const memoizedNumberBetSpots = useMemo(() => {
    return numbers.map((number) => getNumberBetSpot(number));
  }, [numbers, getNumberBetSpot]);

  const memoizedColumnBetSpots = useMemo(() => {
    const { numberItem } = tableOptions;
    const itemWidth = numberItem.width;
    const itemHeight = numberItem.height;
    const isHorizontal = orientation === ORIENTATION.HORIZONTAL;

    const baseY = isHorizontal ? 0 : 13 * numberItem.height;

    const items = [
      {
        betType: BET_TYPE.RIGHT_COLUMN,
      },
      {
        betType: BET_TYPE.MIDDLE_COLUMN,
      },
      {
        betType: BET_TYPE.LEFT_COLUMN,
      },
    ];

    return items.map((item, index) => ({
      ...item,
      path: getRectanglePath(itemWidth, itemHeight),
      betKey: createBetKey(item.betType),
      content: getColumnText(),
      isOutsideBet: true,
      color: COLOR.NONE,
      itemWidth,
      itemHeight,
      x: isHorizontal ? tableWidth - itemWidth : tableWidth - itemWidth * (index + 1),
      y: isHorizontal ? baseY + index * itemHeight : baseY,
    }));
  }, [getColumnText, orientation, tableOptions, tableWidth]);

  const memoziedSensorBetSpots = useMemo(() => {
    const { numberItem } = tableOptions;
    const itemHeight = numberItem.height;
    const isHorizontal = orientation === ORIENTATION.HORIZONTAL;
    let betSpots = [];

    if (isHorizontal) {
      const itemWidth = roundNumber(tableWidth / 14, 2);
      for (let i = 1; i <= 12; i++) {
        for (let row = 1; row <= 3; row++) {
          if (row < 3) {
            const horizontalSplitSensor = createSplitSensor(
              orientation,
              tableOptions.sensorThickness,
              tableOptions.tableWidth,
              i,
              row,
              itemWidth,
              itemHeight
            );
            betSpots.push(horizontalSplitSensor);
          }

          const verticalSplitSensor = createSplitSensor(
            orientation,
            tableOptions.sensorThickness,
            tableOptions.tableWidth,
            i,
            row,
            itemWidth,
            itemHeight,
            'vertical'
          );
          betSpots.push(verticalSplitSensor);
        }

        const bottomStreetSensor = createStreetSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          i,
          3,
          itemWidth,
          itemHeight
        );
        betSpots.push(bottomStreetSensor);

        const bottomSixLineSensor = createSixLineSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          i,
          3,
          itemWidth,
          itemHeight
        );
        betSpots.push(bottomSixLineSensor);

        const firstLineCornerSensor = createCornerSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          i,
          1,
          itemWidth,
          itemHeight
        );
        betSpots.push(firstLineCornerSensor);

        const secondLineCornerSensor = createCornerSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          i,
          2,
          itemWidth,
          itemHeight
        );
        betSpots.push(secondLineCornerSensor);
      }
    } else {
      const itemWidth = numberItem.width;

      for (let row = 1; row <= 12; row++) {
        for (let column = 1; column <= 3; column++) {
          if (column < 3) {
            const verticalSplitSensor = createSplitSensor(
              orientation,
              tableOptions.sensorThickness,
              tableOptions.tableWidth,
              column,
              row,
              itemWidth,
              itemHeight,
              'vertical'
            );
            betSpots.push(verticalSplitSensor);
          }

          const horizontalSplitSensor = createSplitSensor(
            orientation,
            tableOptions.sensorThickness,
            tableOptions.tableWidth,
            column,
            row,
            itemWidth,
            itemHeight,
            'horizontal'
          );
          betSpots.push(horizontalSplitSensor);
        }

        const bottomStreetSensor = createStreetSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          row,
          3,
          itemWidth,
          itemHeight
        );
        betSpots.push(bottomStreetSensor);

        const bottomSixLineSensor = createSixLineSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          3,
          row,
          itemWidth,
          itemHeight
        );
        betSpots.push(bottomSixLineSensor);

        const firstLineCornerSensor = createCornerSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          1,
          row,
          itemWidth,
          itemHeight
        );
        betSpots.push(firstLineCornerSensor);

        const secondLineCornerSensor = createCornerSensor(
          orientation,
          tableOptions.sensorThickness,
          tableOptions.tableWidth,
          2,
          row,
          itemWidth,
          itemHeight
        );
        betSpots.push(secondLineCornerSensor);
      }
    }

    return betSpots;
  }, [orientation, tableOptions, tableWidth]);

  const memoizedDozenBetSpots = useMemo(() => {
    const { numberItem, outerItem } = tableOptions;
    //for vertical view
    const innerTableHeight = 12 * numberItem.height;
    const isHorizontal = orientation === ORIENTATION.HORIZONTAL;
    const itemWidth = isHorizontal ? roundNumber((tableWidth - 2 * numberItem.width) / 3, 2) : outerItem.width;
    const itemHeight = isHorizontal ? outerItem.height : roundNumber(innerTableHeight / 3, 2);
    const baseY = isHorizontal ? numberItem.height * 3 : 0;

    const items = [
      {
        content: getDozenText(1),
        betType: BET_TYPE.FIRST_TWELVE,
      },
      {
        content: getDozenText(2),
        betType: BET_TYPE.SECOND_TWELVE,
      },
      {
        content: getDozenText(3),
        betType: BET_TYPE.THIRD_TWELVE,
      },
    ];

    return items.map((item, index) => ({
      ...item,
      path: getRectanglePath(itemWidth, itemHeight),
      betKey: createBetKey(item.betType),
      color: COLOR.NONE,
      textOrientation: orientation,
      isOutsideBet: true,
      itemWidth,
      itemHeight,
      x: isHorizontal ? index * itemWidth + numberItem.width : tableWidth - itemWidth * 3 - itemWidth,
      y: isHorizontal ? baseY : numberItem.height + itemHeight * index,
    }));
  }, [getDozenText, orientation, tableOptions, tableWidth]);

  const memoizedEvenMoneyBetSpots = useMemo(() => {
    const { numberItem, outerItem } = tableOptions;
    //for vertical view
    const innerTableHeight = 12 * numberItem.height;
    const isHorizontal = orientation === ORIENTATION.HORIZONTAL;
    const itemWidth = isHorizontal ? roundNumber((tableWidth - 2 * numberItem.width) / 6, 2) : outerItem.width;
    const itemHeight = isHorizontal ? outerItem.height : roundNumber(innerTableHeight / 6, 2);
    const baseY = isHorizontal ? numberItem.height * 3 + outerItem.height : 0;

    const items = [
      {
        content: '1-18',
        betType: BET_TYPE.ONE_TO_EIGHTEEN,
      },
      {
        content: 'EVEN',
        betType: BET_TYPE.EVEN,
      },
      {
        betType: BET_TYPE.RED,
        content: getDiamond(itemWidth, itemHeight, BET_TYPE.RED),
      },
      {
        betType: BET_TYPE.BLACK,
        content: getDiamond(itemWidth, itemHeight, BET_TYPE.BLACK),
      },
      {
        content: 'ODD',
        betType: BET_TYPE.ODD,
      },
      {
        content: '19-36',
        betType: BET_TYPE.NINETEEN_TO_THIRTY_SIX,
      },
    ];

    return items.map((item, index) => ({
      ...item,
      path: getRectanglePath(itemWidth, itemHeight),
      betKey: createBetKey(item.betType),
      color: COLOR.NONE,
      textOrientation: orientation,
      isOutsideBet: true,
      itemWidth,
      itemHeight,
      x: isHorizontal ? index * itemWidth + numberItem.width : tableWidth - itemWidth * 3 - 2 * itemWidth,
      y: isHorizontal ? baseY : numberItem.height + itemHeight * index,
    }));
  }, [getDiamond, orientation, tableOptions, tableWidth]);

  const memoizedBetSpots = useMemo(() => {
    return [
      ...memoizedColumnBetSpots,
      ...memoizedDozenBetSpots,
      ...memoizedEvenMoneyBetSpots,
      ...memoizedNumberBetSpots,
      ...memoziedSensorBetSpots,
    ].map(({ content, path, color, x, y, itemWidth, itemHeight, betType, betKey, textOrientation }) => {
      return (
        <BetSpot
          key={betKey}
          {...{ content, path, color, x, y, itemWidth, itemHeight, betType, betKey, textOrientation }}
          opacity={tableOptions.fieldOpacity}
          onBetSpotEntered={onBetSpotEntered}
          onBetSpotLeaved={onBetSpotLeaved}
        />
      );
    });
  }, [
    memoizedColumnBetSpots,
    memoizedDozenBetSpots,
    memoizedEvenMoneyBetSpots,
    memoizedNumberBetSpots,
    memoziedSensorBetSpots,
    onBetSpotEntered,
    onBetSpotLeaved,
    tableOptions.fieldOpacity,
  ]);

  useEffect(() => {
    if (isInitialized) return;
    const calculateChipPositions = (betSpot) => {
      const x = Number.parseFloat(betSpot.getAttribute('x'));
      const y = Number.parseFloat(betSpot.getAttribute('y'));
      const width = Number.parseFloat(betSpot.getAttribute('width'));
      const height = Number.parseFloat(betSpot.getAttribute('height'));
      const betKey = betSpot.dataset.betKey;
      const centerX = x + width / 2;
      const centerY = y + height / 2;
      const { tableWidth, tableHeight } = tableOptions;
      const relativeCenterX = (centerX / tableWidth) * 100;
      const relativeCenterY = (centerY / tableHeight) * 100;

      return { x: roundNumber(relativeCenterX, 2), y: roundNumber(relativeCenterY, 2), betKey };
    };

    const betSpots = document.querySelectorAll('#rouletteTable svg.betSpot');
    const centers = [...betSpots].map((betSpot) => calculateChipPositions(betSpot));
    onChipPositionCalculated(Object.fromEntries(centers.map(({ x, y, betKey }) => [betKey, { x, y }])));
    setIsInitialized(true);
  }, [isInitialized, onChipPositionCalculated, tableOptions]);

  return <>{memoizedBetSpots}</>;
};

export default ResponsiveBetSpots;
