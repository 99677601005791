import CloseButton from '@/components/FavouriteAndSpecialBets/FavouriteView/CloseButton';
import BetEditModeButton from '../figures/BetEditModeButton';
import BetSaveButton from '../figures/BetSaveButton';
import FavoriteButton from '../figures/FavoriteButton';
import Racetrack from '../figures/Racetrack';
import RacetrackButton from '../figures/RacetrackButton';
import clsx from 'clsx';
import styles from './../../HelpView.module.scss';
import StatisticsButton from '../figures/StatisticsButton';
import BetLimits from '../figures/BetLimits';
import GameNumber from '../figures/GameNumber';
import ChatButton from '../figures/ChatButton';
import SoundButton from '../figures/SoundButton';
import HistoryButton from '../figures/HistoryButton';
import SettingsButton from '../figures/SettingsButton';
import ResponsibleGamingButton from '../figures/ResponsibleGamingButton';
import WinningNumberImg from '/images/winning_numbers.png';

const content = [
  {
    title: 'gameRules',
    content: (
      <>
        <p>
          Roulette is about predicting which number the ball will land on after a spin of the roulette wheel. If you bet
          on that number or on a combination of numbers to which that one number belongs, you win.
        </p>
        <p>
          In European roulette, the wheel consists the numbers 0 to 36. When the time in which you can bet is over, the
          roulette wheel is spinning, and the ball is thrown against the direction of rotation of the wheel. As the
          wheel comes to a stop, the roulette ball will eventually roll into one of the numbered pockets. You win if the
          number you bet on falls.
        </p>
      </>
    ),
  },
  {
    title: 'betTypes',
    content: (
      <>
        <p>
          The roulette table has many ways to bet, from one specific number to various combinations of numbers. Each bet
          type has its payout rate, betting on one specific number gives the highest payout. Below you can read more
          about all the methods of betting.
        </p>
        <div>INSIDE BETS:</div>
        <p>
          Bets on the numbered spaces or the lines between these spaces are called &quot;inside bets.&quot; You can
          choose from the following inside bets:
        </p>
        <ul>
          <li>
            <strong>Straight up</strong>: you place a chip on one number of your choice (including zero).
          </li>
          <li>
            <strong>Split bet</strong>: you place a chip on the split between two numbers, vertically or horizontally. A
            split bet involves two numbers.
          </li>
          <li>
            <strong>Street bet</strong>: you place a chip at the end of a row of numbers. A street bet involves three
            numbers.
            <div>A Street bet consists of three numbers.</div>
          </li>
          <li>
            <strong>Corner bet</strong>: you place a chip on the intersection of four numbers. A corner bet includes
            four numbers.
          </li>
          <li>
            <strong>Line bet</strong>: you place a chip on the intersection at the end of two rows. A line bet consists
            of six numbers.
          </li>
        </ul>
        <div>OUTSIDE BETS:</div>
        <p>
          Betting on the boxes to the left of the playing field is called &quot;outside bets.&quot; You can choose from
          the following outside bets:
        </p>
        <ul>
          <li>
            <strong>Column bet</strong>: you place a chip in a box with &quot;2 to 1&quot; at the end of the column.
            This box includes all twelve numbers in that column. The zero is not covered with a column bet.
          </li>
          <li>
            <strong>Dozen bet</strong>: you place a chip in one of the boxes marked &quot;1st 12&quot;, &quot;2nd
            12&quot;, or &quot;3rd 12&quot;. Each box includes the twelve numbers next to it.
          </li>
          <li>
            <strong>Red/Black</strong>: you place a chip in the red or black box. The red box contains the eighteen red
            numbers, and the black box the eighteen black numbers. The zero is not covered with the red/black bet.
          </li>
          <li>
            <strong>Even/Odd</strong>: you place a chip in one of the boxes containing the eighteen even or eighteen odd
            numbers. The zero is not covered with the odd/even bet.
          </li>
          <li>
            <strong>1-18/19-36</strong>: you place a chip in one of these boxes, which contains either the first or the
            last eighteen numbers. The zero is not covered with this bet.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'neighbourBets',
    content: (
      <>
        <p>
          The &quot;Neighbour Bets&quot; button opens a special betting zone where you can place neighbour bets and
          other special bets. By clicking this button again, you can close or reopen this zone.
        </p>
        <div className={styles.itemContainer}>
          <RacetrackButton />
        </div>
        <p>
          A neighbour bet involves one specific number and a number and a number count that are close to this number on
          the roulette wheel. You place a neighbour bet by clicking on a number. You then place a chip on that specific
          number and the numbers to the left and right of it. You can increase or decrease the number of neighbour
          numbers to the left or right of the specific number. To do this, click on the button with &quot;-&quot; or
          &quot;+&quot;.
        </p>
        <div className={styles.itemContainer}>
          <Racetrack width="20rem" />
        </div>
        <p>
          Each neighbour bet includes a set of numbers with different payout rates. You can read more about it below.
          Chosen bets are displayed.
        </p>
        <h4>Tier</h4>
        <p>
          This bet consists of twelve numbers, consisting of 27, 33 and the ten numbers in between on the roulette
          wheel. You place six chips:
        </p>
        <ul>
          <li>1 chip on the split 5/8</li>
          <li>1 chip on the split 10/11</li>
          <li>1 chip on the split 13/16</li>
          <li>1 chip on the split 23/24</li>
          <li>1 chip on the 27/30 split</li>
          <li>1 chip on the split 33/36</li>
        </ul>

        <h4>Orphelins</h4>
        <p>
          This bet includes the eight numbers on the roulette wheel that are not covered by the Serie 0/2/3 and the
          Serie 5/8 bets. You place five chips:
        </p>
        <ul>
          <li>1 chip on 1 (straight up)</li>
          <li>1 chip on the split 6/9</li>
          <li>1 chip on the split 14/17</li>
          <li>1 chip on the split 17/20</li>
          <li>1 chip on the split 31/34</li>
        </ul>

        <h4>Voisins</h4>
        <p>
          This bet includes seventeen numbers consisting of 22, 25 and the fifteen numbers in between on the roulette
          wheel, including zero. You place nine chips:
        </p>
        <ul>
          <li>2 chips on the street 0/2/3</li>
          <li>1 chip on the 4/7 split</li>
          <li>1 chip on the 12/15 split</li>
          <li>1 chip on the split 18/21</li>
          <li>1 chip on the 19/22 split</li>
          <li>2 chips at the corner 25/26/28/29</li>
          <li>1 chip on the split 32/35</li>
        </ul>

        <h4>Zero</h4>
        <p>
          This bet covers zero and the six numbers closest to zero on the roulette wheel: 12, 35, 3,26, 0, 32 and 15.
          You place four chips:
        </p>
        <ul>
          <li>1 chip on the split 0/3</li>
          <li>1 chip on the split 12/15</li>
          <li>1 chip at 26 (straight up)</li>
          <li>1 chip on the split 32/35</li>
        </ul>
      </>
    ),
  },
  {
    title: 'favAndSpecialBets',
    content: (
      <>
        <h4>FAVORITE BETS</h4>
        <p>
          Handy and easy: the &quot;Favorite bets&quot; menu. This allows you to save a preferred bet or a combination
          of bet types to use in later rounds. You can create a list of thirty favorite bets. You can give each bet a
          different name and save and edit it separately.
        </p>
        <h4>Open and close the &quot;Favorite bets&quot; menu.</h4>
        <p>You open and close the &quot;Favorite Bets&quot; menu by clicking the &quot;Favorite Bets&quot; button.</p>
        <div className={styles.itemContainer}>
          <FavoriteButton />
        </div>
        <p>
          Have you ever placed a favorite bet or combination of bets on the roulette table? Then click on the &quot;Save
          last bet&quot; link. A default name will appear, but you can also come up with a better name. You then save
          this bet and add it to your list of favorite bets. To do this, click on &quot;Save&quot; with your mouse or
          press &quot;Enter&quot; on your keyboard.
        </p>
        <div className={styles.itemContainer}>
          <BetSaveButton />
        </div>
        <h4>PLACE A FAVORITE BET</h4>
        <p>
          Do you want to place one of your favorite bets during the betting time of a roulette round? Then open the
          &quot;Favorite Bets&quot; menu and view the list of all the bets you have saved. The bet you saved first is at
          the top. Hovering over the name of a bet will show you how the chips will be placed on the roulette table. To
          place a favorite bet, simply click on the name of the desired bet. You can multiply the amount of your
          favorite bet by tapping the name twice, three times, four times, and so on.
        </p>
        <h4>REMOVE OR RENAME A FAVORITE BET</h4>
        <p>
          Once you have entered the &quot;Favorite Bets&quot; menu, you can remove or rename a bet from the list by
          clicking the &quot;Edit&quot; button.
        </p>
        <div className={styles.itemContainer}>
          <BetEditModeButton />
        </div>
        <p>
          To change the name, first, click in the grey text box around the current name. Then enter a new name and save
          it by clicking &quot;Save&quot; with your mouse or pressing &quot;Enter&quot; on your keyboard.
        </p>
        <p>You remove a bet from the list of favorite bets by clicking the &quot;Remove&quot; button for that bet.</p>
        <div className={styles.itemContainer}>
          <CloseButton />
        </div>
        <p>
          Are you done editing your list of favorite bets? Then click on &quot;Save&quot; at the top right or on the
          &quot;Favorite bets&quot; button.
        </p>
        <h4>SPECIAL BETS</h4>
        <p>
          In the &quot;Favorite Bets&quot; menu, you can also easily place &quot;Finale en plein&quot; and &quot;Finale
          à cheval&quot; bets.
        </p>
        <p>Finale en plein</p>
        <ul>
          <li>
            <strong>Finale en plein 0</strong> - 4 chips covered 0+10+20+30, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 1</strong> - 4 chips covered 1+11+21+31, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 2</strong> - 4 chips covered 2+12+22+32, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 3</strong> - 4 chips covered 3+13+23+33, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 4</strong> - 4 chips covered 4+14+24+34, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 5</strong> - 4 chips covered 5+15+25+35, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 6</strong> - 4 chips covered 6+16+26+36, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 7</strong> - 3 chips covered 7+17+27, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 8</strong> - 3 chips covered 8+18+28, each with 1 chip
          </li>
          <li>
            <strong>Finale en plein 9</strong> - 3 chips covered 9+19+29, each with 1 chip
          </li>
        </ul>
        <p>Finale à cheval</p>
        <ul>
          <li>
            <strong>Finale à cheval 0/3</strong> - 4-chip bet covers 0/3+10/13+20/23+30/33, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 1/4</strong> - 4-chip bet covers 1/4+11/14+21/24+31/34, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 2/5</strong> - 4-chip bet covers 2/5+12/15+22/25+32/35, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 3/6</strong> - 4-chip bet covers 3/6+13/16+23/26+33/36, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 4/7</strong> - 4-chip bet covers 4/7+14/17+24/27+34, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 5/8</strong> - 4 chips bet covered 5/8+15/18+25/28+35, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 6/9</strong> - 4-chip bet covers 6/9+16/19+26/29+36, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 7/10</strong> - 3-chip bet covers 7/10+17/20+27/30, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 8/11</strong> - 3-chip bet covers 8/11+18/21+28/31, each with 1 chip
          </li>
          <li>
            <strong>Finale à cheval 9/12</strong> - 3 chip bet covers 9/12+19/22+29/32, each with 1 chip
          </li>
        </ul>
        <p>Complete bets</p>
        <p>With a full bet, you place all inside bets on a specific number.</p>
        <p>For example, with a full bet on 36, to fully cover number 36, place eighteen chips as follows:</p>
        <ul>
          <li>one chip at 36</li>
          <li>two chips on each of the split bets 33/36 and 35/36</li>
          <li>three chips on the street bet 34/35/36</li>
          <li>four chips on the corner bet (corner bet) 32/33/35/36</li>
          <li>six chips on the line bet (line bet) 31/32/33/34/35/36</li>
        </ul>
      </>
    ),
  },
  {
    title: 'winningNumbers',
    content: (
      <>
        <p>The &quot;Winning Numbers&quot; screen shows the most recent winning numbers.</p>
        <div className={clsx(styles.itemContainer, styles.winningNumbers)}>
          <img src={WinningNumberImg} alt="" />
        </div>
        <p>On the left, you see the result of the most recently completed round.</p>
      </>
    ),
  },
  {
    title: 'recentResult',
    content: (
      <>
        <p>
          When you click on the &quot;Statistics&quot; button, you will see a table with the winning numbers from the
          500 most recent game rounds. You can adjust the number of laps by sliding the slider.
        </p>
        <div className={styles.itemContainer}>
          <StatisticsButton />
        </div>
        <p>
          If you move your mouse over any part of the stats chart, you will see the spot on the betting table where a
          chip would be placed. Click on the bet to place your chip.
        </p>
      </>
    ),
  },
  {
    title: 'payouts',
    content: (
      <>
        <p>How much you get paid out depends on the type of bet you have placed.</p>
        <div>INSIDE BETS</div>
        <table>
          <thead>
            <tr>
              <th>BET TYPE</th>
              <th>PAYOUT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Straight up</td>
              <td>35:1</td>
            </tr>
            <tr>
              <td>Split</td>
              <td>17:1</td>
            </tr>
            <tr>
              <td>Street</td>
              <td>11:1</td>
            </tr>
            <tr>
              <td>Corner</td>
              <td>8:1</td>
            </tr>
            <tr>
              <td>Line</td>
              <td>5:1</td>
            </tr>
          </tbody>
        </table>
        <div>OUTSIDE BETS</div>
        <table>
          <thead>
            <tr>
              <th>BET TYPE</th>
              <th>PAYOUT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Column</td>
              <td>2:1</td>
            </tr>
            <tr>
              <td>Dozen</td>
              <td>2:1</td>
            </tr>
            <tr>
              <td>Red/Black</td>
              <td>1:1</td>
            </tr>
            <tr>
              <td>Even/Odd</td>
              <td>1:1</td>
            </tr>
            <tr>
              <td>1-18/19-36</td>
              <td>1:1</td>
            </tr>
          </tbody>
        </table>
        <p>In the event of a malfunction, all payouts and games will be cancelled.</p>
      </>
    ),
  },
  {
    title: 'payoutPercentage',
    content: (
      <>
        <p>The theoretical payout percentage for the player is 97.3 percent.</p>
      </>
    ),
  },
  {
    title: 'betLimits',
    content: (
      <>
        <p>
          Below you can read an explanation about different buttons that are or will become available during gameplay.
        </p>
        <p>
          Under the &quot;Betting Limits&quot; button, you can see the table&apos;s minimum and maximum betting limits.
          These can be changed. When you open the betting limits, you can view your current limits.
        </p>
        <div className={styles.itemContainer}>
          <BetLimits />
        </div>
        <p>
          To participate in the game, your balance must be sufficient to cover your bet. You can see your current
          balance under &quot;Balance.&quot;
        </p>
        <div className={clsx(styles.itemContainer, styles.walletButton)}>
          <img src="/images/balance.png" width={286} height={79} alt="" />
        </div>
        <p>
          In the traditional game view, the timer keeps you informed of the betting time. When the betting time is over,
          you can no longer bet.
        </p>
        <div className={clsx(styles.itemContainer, styles.timer)}>
          <img src="/images/timer.png" width={89} height={88} alt="" />
        </div>
        <p>
          When you change the game view The &quot;Traffic Lights&quot; show you the current betting status in the game
          round. You can bet with green light. With yellow light, the betting period is almost over. At a red light, the
          betting time is over.
        </p>
        <div className={clsx(styles.itemContainer, styles.statusBar)}>
          <img src="/images/place_your_bet.png" width={413} height={41} alt="" />
        </div>
        <p>
          Chip Display. You can choose the value of each chip you want to bet. Only the chips that are covered by your
          current balance are activated.
        </p>
        <div className={clsx(styles.itemContainer, styles.chips)}>
          <img src="/images/chips.png" width={617} height={92} alt="" />
        </div>
        <p>
          Have you chosen a chip once? Then click on the relevant betting location on the gaming table to place your
          bet. Do you want to increase your bet? Each click on the bet location increases the amount of your bet by the
          value of the chip or up to the maximum limit for the type of bet you have chosen. Once you have reached this
          limit, you can no longer increase your bet. You will automatically see a notification above your bet.
        </p>
        <p>
          Please note during the betting time and when you have placed bets at the table, do not minimize your browser
          or open another tab. If you do so, this will be considered as leaving the game. Your bets for that game round
          will then be declined.
        </p>
        <p>
          When you have placed your bet, the button &quot;2x Double&quot; becomes visible. Each click on it doubles your
          bet up to the maximum limit. Note: you must have sufficient credit in your account to be able to double all
          your bets.
        </p>
        <div className={clsx(styles.itemContainer, styles.double)}>
          <img src="/images/help/double.svg" width={199} height={77} alt="" />
        </div>
        <p>
          With the &quot;Repeat&quot; button, you can place the same bet(s) from the previous game round again. You will
          only see this button before you bet your first chip.
        </p>
        <div className={clsx(styles.itemContainer, styles.repeatEn)}>
          <img src="/images/help/repeat.svg" width={196} height={77} alt="" />
        </div>
        <p>
          With the &quot;Undo&quot; button, you can remove your last bet. Each time you click on this, one of your bets
          will be removed in the reverse order you placed those bets. When you hold down the &quot;Back&quot; button,
          you undo all your bets at once.
        </p>
        <div className={clsx(styles.itemContainer, styles.undoEn)}>
          <img src="/images/help/undo.svg" width={173} height={77} alt="" />
        </div>
        <p>The &quot;Total Bet&quot; button shows the total of all your bets in the current game round.</p>
        <div className={clsx(styles.itemContainer, styles.walletButton)}>
          <img src="/images/total_bet.png" width={286} height={79} alt="" />
        </div>
      </>
    ),
  },
  {
    title: 'disconectionPolicy',
    content: (
      <>
        <p>
          Will the connection be lost during a game round after a bet is closed? In that case, your placed bets remain
          valid. Once you are reconnected, you will see the result of each valid bet in the &quot;History&quot; window.
        </p>
      </>
    ),
  },
  {
    title: 'chat',
    content: (
      <>
        <p>
          At roulette, it is possible to chat with the game presenter and other players. You do this as follows: simply
          write your message in the chat window and then click on the arrow key or press &quot;Enter.&quot;
        </p>
        <p>
          If you abuse the chat feature, for example, by using inappropriate/offensive language or insulting the game
          host/other players, you will be banned from the chat.
        </p>
        <p>You can resize the chat window and place it anywhere on the screen.</p>
        <p>Use the chat button to resize or close the window.</p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <ChatButton />
        </div>
      </>
    ),
  },
  {
    title: 'gameNumber',
    content: (
      <>
        <p>Each round has a unique game number.</p>
        <div className={styles.itemContainer}>
          <GameNumber />
        </div>
        <p>
          This number indicates when the round started in GMT in hours, minutes and seconds. Use this number for
          reference (or take a screenshot of the game number) if you want to contact customer service regarding a
          particular round.
        </p>
      </>
    ),
  },
  {
    title: 'sound',
    content: (
      <>
        <p>The sound button toggles the game sounds and voices on and off.</p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <SoundButton />
        </div>
        <p>
          The sound is automatically turned on when you change tables. You can change the sound by clicking the
          &quot;Settings&quot; button and then choosing the sound tab.
        </p>
      </>
    ),
  },
  {
    title: 'gameHistory',
    content: (
      <>
        <p>
          With the &quot;History&quot; button, you open a window with all the game rounds you have played and the
          results you have achieved in these rounds.
        </p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <HistoryButton />
        </div>
        <p>There are two ways to view your past game activities:</p>
        <ul>
          <li>
            Game History - Here, you can see your history with a specific game. To do this, click on the game in the
            &quot;Game&quot; column.
          </li>
          <li>
            Account History - Here, you can see your complete account history in the form of a list of dates, games, bet
            amounts, and payouts. The most recently completed lap is at the top.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'settings',
    content: (
      <>
        <p>The &quot;Settings&quot; button</p>
        <p>The &quot;Settings&quot; button launches a menu so that you as a user can adjust the settings yourself.</p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <SettingsButton />
        </div>
        <p>
          The settings you select take effect immediately and are saved immediately. The saved settings will start
          automatically when you log in to play.
        </p>
        <h4>GENERAL</h4>
        <p>You can show or hide the chat messages of other players.</p>
        <h4>VIDEO</h4>
        <p>
          The quality of your video will be adjusted automatically, but you can also change it manually by selecting a
          stream.
        </p>
        <h4>SOUND</h4>
        <p>
          You can turn on or mute the voice of the game host and the sound of the game, as well as adjust the volume.
        </p>
      </>
    ),
  },
  {
    title: 'responsibleGaming',
    content: (
      <>
        <p>
          When choosing the button &quot;Play responsibly,&quot; you get access to information about the policy that
          Casino uses for this.
        </p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <ResponsibleGamingButton />
        </div>
        <p>
          Under &quot;Playing responsibly,&quot; you can read useful information and links about responsible gaming
          behavior in online games of chance. You can also set limits here.
        </p>
      </>
    ),
  },
  {
    title: 'errorHandling',
    content: (
      <>
        <p>
          It may happen that an error occurs in the system, in the game, or the game procedure. In that case, the game
          round is temporarily paused so that the dealer can report the error to the service manager. As a player, you
          will read via the chat or a pop-up message on your screen that the problem is being investigated and solved as
          soon as possible. If the manager can immediately correct the error, play will resume immediately afterwards.
          If no immediate solution is possible, the game round is cancelled. All participants in the game round will be
          refunded their bets.
        </p>
      </>
    ),
  },
  {
    title: 'rulesForValidSpins',
    content: (
      <>
        <p>
          The winning roulette number only counts if the roulette spin is valid. A spin is valid if the dealer throws
          the ball against the direction of rotation of the roulette wheel; the ball goes all the way around at least
          three times and only then comes to rest in a box with a number.
        </p>
        <p>This means that a spin is invalid in the following cases:</p>
        <ul>
          <li>When the ball has been thrown in the direction of rotation of the roulette wheel</li>
          <li>When the ball has made less than three full revolutions.</li>
          <li>When the ball shoots out of the roulette cylinder during the spin.</li>
          <li>When the roulette wheel stops spinning during the spin.</li>
          <li>When something gets into the cylinder during the spin.</li>
        </ul>
        <p>If a spin is not valid, the dealer will spin the ball again.</p>
        <p>
          In the event of disturbances, you can pass the game number of the relevant game round to the live support.
        </p>
      </>
    ),
  },
  {
    title: 'moreGames',
    content: (
      <>
        <p>
          The &quot;Lobby&quot; button can be used to view other games while still playing the current game. That is
          possible at any time.
        </p>
        <p>
          Through the &quot;Lobby&quot; you can simply choose another table or another live game. You will not be
          removed from your current roulette game until you have chosen a new game or table.
        </p>
        <div className={clsx(styles.itemContainer, styles.lobby)}>
          <img src="/images/lobby.png" width={236} height={79} alt="" />
        </div>
      </>
    ),
  },
  {
    title: 'shortcuts',
    content: (
      <>
        <p>Shortcuts can be used to quickly execute useful game functions.</p>
        <table className={styles.leftAlignment}>
          <thead>
            <tr>
              <th>KEY</th>
              <th>FUNCTION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Numeric keys starting from 1</td>
              <td>
                Select the desired chip in the chip view. Key &quot;1&quot; corresponds to the chip with the lowest
                value on the far left. Key &quot;2&quot; selects the chip with the next value, etc.
              </td>
            </tr>
            <tr>
              <td>SPACEBAR</td>
              <td>Repeat your last bet. Press SPACEBAR again to double your bet.</td>
            </tr>
            <tr>
              <td>DELETE, BACKSPACE</td>
              <td>Undo your last bet. Hold for 3 seconds to remove all your bets.</td>
            </tr>
            <tr>
              <td>ESC</td>
              <td>
                If applicable, ESC can be used to: <br />- Exit full-screen mode <br />- Close an open pop-up window
                (History, Rules, Settings, etc.)
              </td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
];

export default content;
