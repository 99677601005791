const StarPositionIcon = ({ className, number, isSet = false }) => {
  return isSet ? (
    <svg height="1.5em" viewBox="0 0 30 30" className={className} fill="currentColor">
      <path d="M15 23.559L6.348 28 8 18.593l-7-6.662 9.674-1.372L15 2l4.326 8.559L29 11.93l-7 6.662L23.652 28z"></path>
      <text x="50%" y="50%" dy="5%" fill="black" fontSize="0.8em" dominantBaseline="central" textAnchor="middle">
        {number}
      </text>
    </svg>
  ) : (
    <svg height="1.5em" viewBox="0 0 1024 1024" fill="currentColor">
      <path d="M514.353 93.873l129.471 254.233 7.062 16.479 18.832 2.353 282.484 44.726-211.863 214.216 2.353 18.832 44.726 282.484-254.235-129.471-16.479-7.062-16.477 7.062-254.233 129.471 44.726-282.484 2.353-18.832-14.125-11.77-202.446-202.444 282.484-44.726 18.832-2.353 7.062-16.479 129.471-254.235zM514.353 16.19l-162.428 315.44-348.395 56.498 249.525 251.88-54.141 350.75 315.438-160.073 317.793 160.073-54.143-350.75 249.527-251.88-350.75-54.143-162.428-317.793z"></path>
      <text x="50%" y="50%" dy="5%" fontSize="25em" dominantBaseline="central" textAnchor="middle">
        {number}
      </text>
    </svg>
  );
};

export default StarPositionIcon;
