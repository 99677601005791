import ROUND_STATE from '@/enums/roundState';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './StatusBar.module.scss';

const StatusBar = () => {
  const { t } = useTranslation('common');
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const timer = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.timer);

  const hasNoBet = useCallback(() => {
    return Object.keys(bets).length === 0;
  }, [bets]);

  const getRoundStateBackground = useCallback(() => {
    const stateColors = {
      [ROUND_STATE.PLACE_BETS]: 'green',
      [ROUND_STATE.FINAL_BETS]: 'yellow',
      [ROUND_STATE.NO_MORE_BETS]: 'red',
      [ROUND_STATE.SPINNING]: 'grey',
      [ROUND_STATE.WINNING_NUMBER]: 'grey',
    };

    return stateColors[roundState] || 'grey';
  }, [roundState]);

  const getRoundStateLabel = useCallback(() => {
    const roundLabels = {
      [ROUND_STATE.PLACE_BETS]: () => {
        const { remainingTime } = timer || {};
        return `${t('status.placeYourBet')} ${remainingTime ?? ''}`;
      },
      [ROUND_STATE.FINAL_BETS]: () => {
        const { remainingTime } = timer || {};
        return `${t('status.finalBets')} ${remainingTime ?? ''}`;
      },
      [ROUND_STATE.NO_MORE_BETS]: () => t('status.noMoreBets'),
      [ROUND_STATE.WINNING_NUMBER]: () => {
        const { color } = numbers[winningNumber];
        return `${winningNumber} ${t(color)}`;
      },
    };

    const labelFunction = roundLabels[roundState];

    return labelFunction ? labelFunction() : '';
  }, [roundState, timer, t, numbers, winningNumber]);

  return (
    <div className={clsx(styles.container, styles[getRoundStateBackground()])}>
      {roundState && (
        <div className={clsx(styles.label, roundState === ROUND_STATE.WINNING_NUMBER && styles.winningNumber)}>
          {getRoundStateLabel()}
        </div>
      )}
    </div>
  );
};

export default StatusBar;
