import { USER_TYPE } from '@/enums/userType';
import clsx from 'clsx';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './MessageList.module.scss';

const MessageListItem = ({ chatId, userType, senderUserId, screenName, message, onDelete, userId }) => {
  const chatAnimations = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: 20,
      opacity: 0,
    },
    exit: {
      opacity: 0,
    },
  };

  const getScreenName = () => {
    return userType === USER_TYPE.DEALER ? 'DEALER' : screenName;
  };

  useEffect(() => {
    setTimeout(() => {
      onDelete(chatId);
    }, 5000);
  }, [chatId, onDelete]);

  return (
    <motion.div
      layout
      className={clsx(styles.message, userType === USER_TYPE.DEALER && styles.dealer)}
      animate="visible"
      initial="hidden"
      exit="exit"
      variants={chatAnimations}
    >
      <div className={styles.userName}>{getScreenName()}:</div>
      <div className={styles.messageContent}>{message}</div>
    </motion.div>
  );
};

const MessageList = ({ onItemAdded, onItemDeleted }) => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const messages = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.chat.messages);
  const displayedMessages = useRef(new Set());
  const [visibleMessages, setVisibleMessages] = useState([]);

  const handleDeleteItem = useCallback(
    (chatId) => {
      setVisibleMessages((prev) => prev.filter((item) => item.chatId !== chatId));
      onItemDeleted?.(chatId);
    },
    [onItemDeleted]
  );

  useEffect(() => {
    if (displayedMessages.current) {
      for (const { chatId } of messages) {
        displayedMessages.current.add(chatId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (const message of messages) {
      const { chatId } = message;
      if (!displayedMessages.current.has(chatId)) {
        displayedMessages.current.add(chatId);
        onItemAdded?.(chatId);
        setVisibleMessages((prev) => [...prev, message]);
      }
    }
  }, [messages, onItemAdded]);

  return (
    <div className={styles.container}>
      <LayoutGroup>
        <AnimatePresence initial={false}>
          {visibleMessages.map(({ chatId, userId, screenName, message, userType }) => (
            <MessageListItem
              key={chatId}
              chatId={chatId}
              senderUserId={userId}
              screenName={screenName}
              message={message}
              onDelete={handleDeleteItem}
              userId={publicPlayerId}
              userType={userType}
            />
          ))}
        </AnimatePresence>
      </LayoutGroup>
    </div>
  );
};

export default MessageList;
