import CameraIcon from '@/assets/svg/camera.svg?react';
import MessagesIcon from '@/assets/svg/chat.svg?react';
import CloseIcon from '@/assets/svg/close.svg?react';
import HistoryIcon from '@/assets/svg/history.svg?react';
import UserIcon from '@/assets/svg/rg.svg?react';
import SettingsIcon from '@/assets/svg/settings.svg?react';
import ChatView from '@/components/ChatView';
import HelpView from '@/components/HelpView';
import HistoryLine from '@/components/HistoryLine';
import HistoryView from '@/components/HistoryView';
import IconButton from '@/components/IconButton';
import SettingsView from '@/components/SettingsView';
import SlidePopup from '@/components/SlidePopup';
import SoundView from '@/components/SoundView';
import TableLimitsView from '@/components/TableLimitsView';
import ViewSelectorPopoverView from '@/components/ViewSelectorPopoverView';
import { DESKTOP_VIEW_TYPE, MENU_VIEW } from '@/enums/ui';
import { playPressSound } from '@/lib/soundService';
import { openResponsibleGamingPage, redirectLiveCasinoHomePage } from '@/lib/urlService';
import {
  closeSlidePopup,
  closeTableLimits,
  openSlidePopup,
  openTableLimits,
  pinTableLimits,
  setSlidePopupView,
  unpinTableLimits,
} from '@/store/uiSlice';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import GameLabel from '../../GameLabel';
import Popover from '../../Popover';
import InfoPanel from '../InfoPanel';
import PinButton from '../PinButton/PinButton';
import styles from './DesktopHeaderView.module.scss';
import DesktopSoundToggleButton from '../DesktopSoundToggleButton';

const menuIconSize = '0.75em';
const SlidePopupView = ({ slidePopupView, slidePopupOptions, closeSlidePopup }) => {
  const { t } = useTranslation('common');
  if (!slidePopupView) return;
  let title;
  let icon;

  switch (slidePopupView) {
    case MENU_VIEW.CHAT:
      title = t('chat');
      icon = <MessagesIcon height="1.25em" />;
      break;
    case MENU_VIEW.SETTINGS:
      title = t('settings');
      icon = <SettingsIcon height="1.25em" />;
      break;
    case MENU_VIEW.HISTORY:
      title = t('history');
      icon = <HistoryIcon height="1.25em" />;
      break;
    case MENU_VIEW.HELP:
      title = t('howToPlay');
      icon = <BsFillQuestionCircleFill size="1.25em" />;
      break;

    default:
      throw new Error(`Unsupported slide popup view: ${slidePopupView}`);
  }

  return (
    <SlidePopup onClose={closeSlidePopup} className={styles.slidePopup} title={title} icon={icon}>
      {slidePopupView === MENU_VIEW.CHAT && (
        <div className={styles.chatWrapper}>
          <ChatView {...slidePopupOptions} />
        </div>
      )}
      {slidePopupView === MENU_VIEW.SETTINGS && (
        <div className={styles.settingsWrapper}>
          <SettingsView {...slidePopupOptions} />
        </div>
      )}
      {slidePopupView === MENU_VIEW.HISTORY && (
        <div className={styles.historyWrapper}>
          <HistoryView {...slidePopupOptions} />
        </div>
      )}
      {slidePopupView === MENU_VIEW.HELP && <HelpView {...slidePopupOptions} />}
    </SlidePopup>
  );
};

const SlidePopupUI = () => {
  const slidePopupOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOpened
  );
  const slidePopupView = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupView
  );
  const slidePopupOptions = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOptions
  );
  const dispatch = useDispatch();

  const handleCloseSlidePopup = useCallback(() => dispatch(closeSlidePopup()), [dispatch]);

  return (
    <>
      {slidePopupOpened && (
        <SlidePopupView
          slidePopupView={slidePopupView}
          slidePopupOptions={slidePopupOptions}
          closeMenu={handleCloseSlidePopup}
        />
      )}
    </>
  );
};

const DesktopHeaderView = () => {
  const { t } = useTranslation('common');
  const { main: mainSound } = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume
  );
  const tableLimitsOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.tableLimitsOpened
  );
  const tableLimitsPinned = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.tableLimitsPinned
  );
  const slidePopupView = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupView
  );
  const slidePopupOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOpened
  );
  const desktopView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.desktopView);
  const dispatch = useDispatch();
  const userInteracted = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.userInteracted
  );

  const toggleView = useCallback(
    (view) => {
      if (slidePopupOpened && view === slidePopupView) {
        dispatch(closeSlidePopup());
      } else {
        dispatch(setSlidePopupView({ view }));
        dispatch(openSlidePopup());
      }
    },
    [dispatch, slidePopupOpened, slidePopupView]
  );

  const handlePinClicked = useCallback(() => {
    playPressSound();
    tableLimitsPinned ? dispatch(unpinTableLimits()) : dispatch(pinTableLimits());
  }, [dispatch, tableLimitsPinned]);

  const handleToggleView = useCallback(
    (view) => {
      playPressSound();
      toggleView(view);
    },
    [toggleView]
  );

  const handleRGClicked = useCallback(() => {
    playPressSound();
    openResponsibleGamingPage();
  }, []);

  const handleExit = useCallback(() => {
    redirectLiveCasinoHomePage();
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.left}
        onMouseEnter={() => dispatch(openTableLimits())}
        onMouseLeave={() => dispatch(closeTableLimits())}
      >
        <div
          className={clsx(
            styles.tableLimitsContainer,
            (tableLimitsOpened || tableLimitsPinned) && styles.tableLimitsContainerOpened
          )}
        >
          <TableLimitsView />
        </div>

        <IconButton
          className={styles.closeButton}
          icon={<CloseIcon width="0.8em" height="0.8em" />}
          onClick={handleExit}
        />
        <GameLabel className={styles.gameLabel} />
        <PinButton onClick={handlePinClicked} />
      </div>
      {desktopView === DESKTOP_VIEW_TYPE.THREE_DIMENSION && (
        <div className={styles.center}>
          <div className={styles.history}>
            <HistoryLine />
          </div>
        </div>
      )}
      <div className={styles.right}>
        {desktopView !== DESKTOP_VIEW_TYPE.CLASSIC && (
          <IconButton
            icon={<MessagesIcon height={menuIconSize} />}
            tooltip={{ id: 'global-tooltip', content: t('chat'), place: 'bottom' }}
            className={clsx(
              styles.menuItem,
              slidePopupOpened && slidePopupView === MENU_VIEW.CHAT && styles.activeMenuItem
            )}
            onClick={() => handleToggleView(MENU_VIEW.CHAT)}
          />
        )}

        <IconButton
          icon={<UserIcon height={menuIconSize} />}
          className={styles.menuItem}
          tooltip={{ id: 'global-tooltip', content: t('responsibleGaming'), place: 'bottom' }}
          onClick={handleRGClicked}
        />

        <Popover content={<ViewSelectorPopoverView />} distance={16} onClick={() => dispatch(closeSlidePopup())}>
          <IconButton className={styles.menuItem} icon={<CameraIcon height={menuIconSize} />} />
        </Popover>

        <Popover content={<SoundView />} distance={6} onClick={() => dispatch(closeSlidePopup())}>
          <DesktopSoundToggleButton className={styles.menuItem} />
        </Popover>

        <IconButton
          icon={<SettingsIcon height={menuIconSize} />}
          tooltip={{ id: 'global-tooltip', content: t('settings'), place: 'bottom' }}
          className={clsx(
            styles.menuItem,
            slidePopupOpened && slidePopupView === MENU_VIEW.SETTINGS && styles.activeMenuItem
          )}
          onClick={() => handleToggleView(MENU_VIEW.SETTINGS)}
        />

        <IconButton
          icon={<HistoryIcon height={menuIconSize} />}
          tooltip={{ id: 'global-tooltip', content: t('history'), place: 'bottom' }}
          className={clsx(
            styles.menuItem,
            slidePopupOpened && slidePopupView === MENU_VIEW.HISTORY && styles.activeMenuItem
          )}
          onClick={() => handleToggleView(MENU_VIEW.HISTORY)}
        />

        <IconButton
          icon={<BsFillQuestionCircleFill size={menuIconSize} />}
          tooltip={{ id: 'global-tooltip', content: t('howToPlay'), place: 'bottom' }}
          className={clsx(
            styles.menuItem,
            slidePopupOpened && slidePopupView === MENU_VIEW.HELP && styles.activeMenuItem
          )}
          onClick={() => handleToggleView(MENU_VIEW.HELP)}
        />

        <InfoPanel />
      </div>
      <SlidePopupUI />
    </div>
  );
};

export default DesktopHeaderView;
