import { useContext } from 'react';
import { ModalContext } from './../context/ModalContext';

/**
 * Hook for accessing modal context.
 * @returns {import('../context/ModalContext').ModalContextType} Modal context.
 */
const useModal = () => useContext(ModalContext);

export default useModal;
