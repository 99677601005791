import clsx from 'clsx';
import { motion } from 'framer-motion';
import Checkbox from '@/components/common/Checkbox';
import { QUALITY } from '@/enums/stream';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { setStreamAutomaticAdjustment, setStreamQuality } from '@/store/settingsSlice';
import styles from './VideoSettingsView.module.scss';

const VideoSettingsView = () => {
  const { t } = useTranslation('common');
  const stream = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings.stream);
  const dispatch = useDispatch();

  const streamQualityIndex = useMemo(() => {
    let index = 0;
    for (const key in QUALITY) {
      if (QUALITY[key] === stream.quality) {
        break;
      }
      index++;
    }

    return index;
  }, [stream.quality]);

  const handleStreamQualityChanged = useCallback(
    (value) => {
      dispatch(setStreamQuality(value));
    },
    [dispatch]
  );

  return (
    <div className={styles.container}>
      <div className={styles.videoQualityLabel}>{t('settings.videoQuality')}</div>
      <Checkbox
        label={t('settings.autoAdjust')}
        checked={stream.automaticAdjustment}
        onChange={(value) => dispatch(setStreamAutomaticAdjustment(value))}
      />
      <div className={styles.videoQualityContainer}>
        {Object.keys(QUALITY).map((key, index) => (
          <div key={key} className={styles.item}>
            {index === streamQualityIndex ? (
              <motion.div layoutId="anchorContainer" className={styles.anchorContainer}>
                <motion.div layout className={clsx(styles.anchor, styles.down)}></motion.div>
              </motion.div>
            ) : null}
            <div
              onClick={() => handleStreamQualityChanged(QUALITY[key])}
              className={clsx(styles.label, QUALITY[key] === stream.quality && styles.selected)}
            >
              {t(`settings.${QUALITY[key]}`)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoSettingsView;
