import { forwardRef } from 'react';
import clsx from 'clsx';
import styles from './NumberItem.module.scss';

const NumberItemComponent = ({ index, item, isHighlighted, onMouseEnter, onMouseLeave, onClick, isEnabled }, ref) => {
  const { number, color } = item;

  return (
    <div
      ref={ref}
      className={clsx(styles.item, styles[color], isHighlighted && styles.hightlighted, isEnabled && styles.enabled)}
      onMouseEnter={() => onMouseEnter(index, number)}
      onMouseLeave={() => onMouseLeave(number)}
      onClick={(event) => onClick(event, number)}
    >
      {number}
    </div>
  );
};

const NumberItem = forwardRef(NumberItemComponent);

export default NumberItem;
