import Footer from '@/components/common/Footer';
import Header from '@/components/common/Header';
import MobileHorizontalView from '@/components/common/MainContent/MobileHorizontalView';
import MobileVerticalView from '@/components/common/MainContent/MobileVerticalView';
import MobileStreamView from '@/components/common/MobileStreamView';
import NoConnection from '@/components/common/NoConnection';
import Sidenav from '@/components/common/Sidenav';
import TouchMenu from '@/components/common/TouchMenu';
import { SCREEN_ORIENTATION } from '@/enums/ui';
import cl from 'clsx';
import { useSelector } from 'react-redux';
import styles from './TouchView.module.scss';

/**
 * TBD.
 * @returns {import('react').ReactElement} Output to render.
 */
const TouchView = () => {
  const orientation = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.screenOrientation
  );

  if (!orientation) return null;

  return (
    <>
      <Header />
      <div className={styles.mainContent}>
        <div className={cl(styles.mobileStreamView, orientation === SCREEN_ORIENTATION.LANDSCAPE && styles.landscape)}>
          <MobileStreamView />
        </div>
        {orientation === SCREEN_ORIENTATION.PORTRAIT ? <MobileVerticalView /> : <MobileHorizontalView />}
      </div>
      <NoConnection />
      <Footer />
      <Sidenav />
      <TouchMenu />
    </>
  );
};

export default TouchView;
