import { BET_TYPE, NAMED_BET_VALUES, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import { ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import { TooltipContext } from '@/context/TooltipContext';
import {
  createBetKey,
  getNeighbors,
  getRacetrackNamedBetDisplayName,
  getRacetrackNamedBets,
  hasEnoughCredit,
} from '@/lib/rouletteService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Racetrack.module.scss';
import RacetrackHorizontalBorder from './RacetrackHorizontalBorder';
import RacetrackHorizontalItems from './RacetrackHorizontalItems';
import RacetrackVerticalBorder from './RacetrackVerticalBorder';
import RacetrackVerticalItems from './RacetrackVerticalItems';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';

const Racetrack = ({
  orientation = ORIENTATION.HORIZONTAL,
  withFieldOpacity = false,
  backgroundFilled = false,
  displayChipsAndWinningNumber = false,
  parentWidth = 880,
  parentHeight = 250,
}) => {
  const height = parentHeight;
  const width = parentWidth;
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const numberOrder = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numberOrder);
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const { t } = useTranslation('tooltip');
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const racetrackNeighborCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.racetrackNeighborCount
  );
  const dispatch = useDispatch();
  const [racetrackHighlightedNumbers, setRacetrackHighlightedNumbers] = useState([]);
  const isBettingTime = useSelector(selectIsBettingTime);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;

  const checkBalance = useCallback(
    (bets) => {
      return selectedChip && hasEnoughCredit(balance, bets, selectedChip);
    },
    [balance, selectedChip]
  );

  const getBets = useCallback(
    (betType, number) => {
      let bets;
      if (betType === RACE_TRACK_BET_TYPE.NUMBER) {
        const { left, right } = getNeighbors(numberOrder, Number(number), racetrackNeighborCount);
        const selectedItems = [number, ...left, ...right];
        bets = selectedItems.map((item) => ({
          betType: BET_TYPE.STRAIGHT,
          numbers: [item],
          multiplier: 1,
        }));
      } else {
        bets = getRacetrackNamedBets(betType);
      }

      return bets;
    },
    [numberOrder, racetrackNeighborCount]
  );

  const handleOnClick = useCallback(
    (event, betType, number) => {
      const bets = getBets(betType, number);
      const mappedBets = bets.map((betItem) => ({
        betType: createBetKey(betItem.betType, betItem.numbers),
        bet: (betItem?.multiplier ?? 1) * selectedChip,
      }));
      dispatch(addBetThunk(mappedBets, event));
    },
    [dispatch, getBets, selectedChip]
  );

  const handleOnMouseEnter = useCallback(
    (betType, item, itemRef) => {
      if (!isBettingTime || isTouchDevice) return;

      let selectedItems;
      let tooltip;
      if (betType === RACE_TRACK_BET_TYPE.NUMBER) {
        const { left, right } = getNeighbors(numberOrder, Number(item), racetrackNeighborCount);
        selectedItems = [item, ...left, ...right];
        tooltip = t('racetrackNumber.alone', { number: selectedItems[0] });
        if (racetrackNeighborCount > 0) {
          tooltip = t('racetrackNumber.withNeighbours', {
            number: selectedItems[0],
            neighborCount: racetrackNeighborCount,
          });
        }
      } else {
        selectedItems = NAMED_BET_VALUES[betType];
        const requiredChips = getRacetrackNamedBets(betType).reduce((total, bet) => {
          return total + (bet.multiplier ?? 1);
        }, 0);
        tooltip = t('racetrackNamedBet', {
          betType: getRacetrackNamedBetDisplayName(betType),
          requiredChips,
          numberCount: selectedItems.length,
        });
      }

      let betsPreview;
      if (betType === RACE_TRACK_BET_TYPE.NUMBER) {
        betsPreview = selectedItems.map((number) => ({
          betType: BET_TYPE.STRAIGHT,
          numbers: [number],
          value: selectedChip,
        }));
      } else {
        betsPreview = getRacetrackNamedBets(betType).map((bet) => ({
          ...bet,
          value: selectedChip * (bet.multiplier ?? 1),
        }));
      }
      showTooltip(itemRef, tooltip);
      setRacetrackHighlightedNumbers(selectedItems);
      if (!checkBalance(betsPreview)) return;
      dispatch(setHighlightedNumbers(selectedItems));
      dispatch(setBetsPreview(betsPreview));
    },
    [
      isBettingTime,
      isTouchDevice,
      showTooltip,
      checkBalance,
      dispatch,
      numberOrder,
      racetrackNeighborCount,
      t,
      selectedChip,
    ]
  );

  const handleOnMouseLeave = useCallback(() => {
    setRacetrackHighlightedNumbers([]);
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      className={clsx(styles.racetrack, !isBettingTime && styles.disabled)}
      style={{ width, height }}
    >
      {orientation === ORIENTATION.HORIZONTAL ? (
        <RacetrackHorizontalItems
          width={width}
          height={height}
          backgroundFilled={backgroundFilled}
          withFieldOpacity={withFieldOpacity}
          orientation={orientation}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          racetrackHighlightedNumbers={racetrackHighlightedNumbers}
          onClick={handleOnClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      ) : (
        <RacetrackVerticalItems
          width={width}
          height={height}
          backgroundFilled={backgroundFilled}
          withFieldOpacity={withFieldOpacity}
          orientation={orientation}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          racetrackHighlightedNumbers={racetrackHighlightedNumbers}
          onClick={handleOnClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {orientation === ORIENTATION.HORIZONTAL ? (
        <RacetrackHorizontalBorder width={width} height={height} />
      ) : (
        <RacetrackVerticalBorder width={width} height={height} />
      )}
    </svg>
  );
};

export default Racetrack;
