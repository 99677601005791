import HeatMapCircle from '@/components/HeatMapCircle';
import ColdIcon from '@/components/common/ColdIcon';
import HotIcon from '@/components/common/HotIcon';
import { BET_TYPE } from '@/enums/layoutTable';
import { TooltipContext } from '@/context/TooltipContext';
import socketApi from '@/lib/socketApi';
import { createBetKey, hasEnoughCredit } from '@/lib/rouletteService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './HotCold.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { VIEW_TYPE } from '@/enums/ui';

const NumberItem = ({ item }) => {
  const { number, color } = item;
  return <div className={`${styles.numberItem} ${styles[color]}`}>{number}</div>;
};

const HotCold = ({ view = 'standard' }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const [hotNumbers, setHotNumbers] = useState([]);
  const [coldNumbers, setColdNumbers] = useState([]);
  const rounds = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.statRoundCount);
  const [heatMap, setHeatMap] = useState();
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const { t } = useTranslation('tooltip');
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const dispatch = useDispatch();
  const hotRef = useRef();
  const coldRef = useRef();
  const isBettingTime = useSelector(selectIsBettingTime);

  const checkBalance = useCallback(
    (bets) => {
      return selectedChip && hasEnoughCredit(balance, bets, selectedChip);
    },
    [balance, selectedChip]
  );

  const isDisabled = useCallback(
    (numbers) => {
      const bets = numbers.map((number) => ({
        betType: BET_TYPE.STRAIGHT,
        numbers: [number],
        value: selectedChip,
      }));

      return !isBettingTime || !checkBalance(bets);
    },
    [checkBalance, isBettingTime, selectedChip]
  );

  const highlightColdNumbers = useCallback(() => {
    if (isDisabled(coldNumbers) || isTouchDevice) return;

    dispatch(setHighlightedNumbers(coldNumbers));
    const betsPreview = coldNumbers.map((number) => ({
      betType: BET_TYPE.STRAIGHT,
      numbers: [number],
      value: selectedChip,
    }));
    dispatch(setBetsPreview(betsPreview));
    showTooltip(coldRef.current, t('coldNumbers', { numbers: coldNumbers.join(', ') }));
  }, [coldNumbers, dispatch, isDisabled, isTouchDevice, selectedChip, showTooltip, t]);

  const highlightHotNumbers = useCallback(() => {
    if (isDisabled(hotNumbers) || isTouchDevice) return;

    dispatch(setHighlightedNumbers(hotNumbers));
    const betsPreview = hotNumbers.map((number) => ({
      betType: BET_TYPE.STRAIGHT,
      numbers: [number],
      value: selectedChip,
    }));
    dispatch(setBetsPreview(betsPreview));
    showTooltip(hotRef.current, t('hotNumbers', { numbers: hotNumbers.join(', ') }));
  }, [dispatch, hotNumbers, isDisabled, isTouchDevice, selectedChip, showTooltip, t]);

  const resetHighlightedNumbers = useCallback(() => {
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  const handleOnClick = useCallback(
    (event, numbers) => {
      const mappedBets = numbers.map((number) => ({
        betType: createBetKey(BET_TYPE.STRAIGHT, number),
        bet: selectedChip,
      }));
      dispatch(addBetThunk(mappedBets, event));
    },
    [dispatch, selectedChip]
  );

  const initData = useCallback(async () => {
    const result = await socketApi.getHotAndColdNumbers(rounds);
    setColdNumbers(result.coldNumbers);
    setHotNumbers(result.hotNumbers);
    setHeatMap(result.heatMap);
  }, [rounds]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <div
      className={clsx(
        styles.container,
        view === 'compact' && styles.compact,
        view === 'vertical' && styles.vertical,
        view === 'tablet' && styles.tablet
      )}
    >
      <div
        ref={hotRef}
        className={clsx(styles.numberItemsContainer, isDisabled(hotNumbers) && styles.disabled)}
        onMouseEnter={highlightHotNumbers}
        onMouseLeave={resetHighlightedNumbers}
        onClick={(event) => handleOnClick(event, hotNumbers)}
      >
        {hotNumbers.map((number, index) => (
          <NumberItem key={`hot-number-${index}`} item={numbers[number]} />
        ))}
        <div className={clsx(styles.numberItem, styles.icon)}>
          <HotIcon height="1em" width="1em" />
        </div>
      </div>
      <div className={styles.chartContainer}>
        {heatMap && (
          <HeatMapCircle
            className={styles.chart}
            hotNumbers={hotNumbers}
            coldNumbers={coldNumbers}
            heatMapValues={heatMap}
          />
        )}
      </div>
      <div
        ref={coldRef}
        className={clsx(styles.numberItemsContainer, styles.left, isDisabled(coldNumbers) && styles.disabled)}
        onMouseEnter={highlightColdNumbers}
        onMouseLeave={resetHighlightedNumbers}
        onClick={(event) => handleOnClick(event, coldNumbers)}
      >
        {coldNumbers.map((number, index) => (
          <NumberItem key={`cold-number-${index}`} item={numbers[number]} />
        ))}
        <div className={clsx(styles.numberItem, styles.icon)}>
          <ColdIcon height="1em" width="1em" />
        </div>
      </div>
    </div>
  );
};

export default HotCold;
