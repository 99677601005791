import { SOCKET_ACTION } from '@/enums/socketEvent';
import { setError } from '@/store/errorSlice';
import store from '@/store/index';
import { Logger } from '@vpmedia/simplify';
import { isErrorSuppressed } from './errorService';
import { socket } from '../socket/index';

/**
 * TBD.
 * @returns {Promise<import('socket.io-client').Socket>} TBD.
 */

const logger = new Logger('SocketAPI');

/**
 * Emits a socket event with callback.
 * @param {string} eventName - Event name.
 * @param {object} data - Event payload.
 * @returns {Promise<object>} Promise with response data.
 */
const emitWithAck = async (eventName, data) => {
  return new Promise((resolve, reject) => {
    socket.emit(eventName, data, (response) => {
      if (response.error) {
        logger.warn('emitWithAck', { eventName, data, response });
      } else {
        logger.info('emitWithAck', { eventName, data, response });
      }
      if (response.success) {
        resolve(response.data ?? null);
      } else {
        if (!isErrorSuppressed(response.error)) {
          store.dispatch(setError({ operation: eventName, errorCode: response.error }));
        }
        const error = new Error(response.error || JSON.stringify(response));
        reject(error);
      }
    });
  });
};

/**
 * Emits a socket event.
 * @param {string} eventName - Event name.
 * @param {object} data - Event payload.
 */
const emit = async (eventName, data) => {
  logger.info('emit', { eventName, data });
  socket.emit(eventName, data);
};

const socketApi = {
  getWinningNumbers: async (roundCount) => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_WINNING_NUMBERS, roundCount);
  },
  getHotAndColdNumbers: async (roundCount) => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_HOT_AND_COLD_DATA, roundCount);
  },
  getAdvancedStatistics: async (roundCount) => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_ADVANCED_STATISTICS, roundCount);
  },
  getFavoriteBets: async () => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_FAVORITE_BETS, null);
  },
  saveFavoriteBet: async (name, order, items) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_FAVORITE_ADDED, { name, order, items });
  },
  removeFavoriteBet: async (betId) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_FAVORITE_REMOVED, betId);
  },
  addBet: async (betData) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_ADDED, betData);
  },
  removeBet: async (transactionId) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_REMOVED, transactionId);
  },
  removeAllBets: async () => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_ALL_REMOVED, null);
  },
  addBetMovement: async (betData) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_MOVEMENT_ADDED, betData);
  },
  removeBetMovement: async (transactionId) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_MOVEMENT_REMOVED, transactionId);
  },
  sendMessage: async (chatMessage) => {
    await emit(SOCKET_ACTION.ON_CHAT_MESSAGE_SENT, chatMessage);
  },
  getHistory: async () => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_HISTORY, null);
  },
  getHistoryItem: async (roundId) => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_HISTORY_ITEM, roundId);
  },
  savePlayerSettings: async (settings) => {
    return await emitWithAck(SOCKET_ACTION.ON_PLAYER_SETTINGS_CHANGED, settings);
  },
  getSubscriberToken: async (streamName) => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_SUBSCRIBER_TOKEN, streamName);
  },
};

export default socketApi;
