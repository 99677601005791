import useMoney from '@/hooks/useMoney';
import { findMinAndMaxBetLimits } from '@/lib/betService';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import styles from './GameLabel.module.scss';

const GameLabel = ({ className }) => {
  const { format } = useMoney();
  const gameName = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameName);
  const betLimits = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betLimits);
  const minMaxBets = findMinAndMaxBetLimits(betLimits);

  if (!minMaxBets) return null;

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.name}>{gameName}</div>
      <div className={styles.range}>
        {format(minMaxBets.minBet)} - {format(minMaxBets.maxBet, false)}
      </div>
    </div>
  );
};

export default GameLabel;
