import socketApi from '@/lib/socketApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} FavoriteBetsState - TBD.
 * @property {number} defaultFavBetsCount - TBD.
 * @property {{order: number, name?: string, betId?: string, isNew?: boolean, items?: {[key: string]: number}}[]} bets - TBD.
 */

export const loadFavoriteBets = createAsyncThunk('favoriteBets/fetch', async (payload, { dispatch }) => {
  return await socketApi.getFavoriteBets();
});

/**
 * TBD.
 * @param {number} order - TBD.
 * @returns {{order: number}} TBD.
 */
const generateEmptyFavBet = (order) => {
  return {
    order,
  };
};

/** @type {FavoriteBetsState} */
const initialState = {
  defaultFavBetsCount: 6,
  bets: [],
};

export const favoriteBetsSlice = createSlice({
  name: 'favoriteBets',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {FavoriteBetsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{order: number, bets: {}[]}>} action - Store action.
     */
    changeBet: (state, action) => {
      const newItem = action.payload;
      const currentIndex = state.bets.findIndex((item) => item.order === newItem.order);
      state.bets[currentIndex] = newItem;

      const lastItem = state.bets.at(-1);
      if (lastItem?.bets) {
        state.bets.push(generateEmptyFavBet(state.bets.length + 1));
      }
    },
    /**
     * TBD.
     * @param {FavoriteBetsState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - Store action.
     */
    removeBet: (state, action) => {
      const deleteOrder = action.payload;
      const currentIndex = state.bets.findIndex((item) => item.order === deleteOrder);
      state.bets[currentIndex] = generateEmptyFavBet(deleteOrder);

      const lastSavedBetOrder = Math.max(...state.bets.filter((bet) => bet.bets).map((bet) => bet.order));

      if (
        lastSavedBetOrder > 0 &&
        state.bets.length > state.defaultFavBetsCount &&
        lastSavedBetOrder < state.bets.length - 1
      ) {
        state.bets.splice(lastSavedBetOrder + 1, state.bets.length);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFavoriteBets.fulfilled, (state, action) => {
      const favBets = action.payload ?? [];

      const defaultFavBetsCount = 6;
      let itemCount = defaultFavBetsCount;
      const maxOrderNumber = Math.max(...favBets.map((item) => item.order));
      if (maxOrderNumber >= itemCount) {
        itemCount = maxOrderNumber + 1;
      }
      let placeholders = Array.from({ length: itemCount })
        .fill()
        .map((_, index) => generateEmptyFavBet(index + 1));

      for (const bet of favBets) {
        placeholders[bet.order - 1] = bet;
      }

      state.defaultFavBetsCount = defaultFavBetsCount;
      state.bets = placeholders;
    });
  },
});

export const { removeBet, changeBet } = favoriteBetsSlice.actions;

export default favoriteBetsSlice.reducer;
