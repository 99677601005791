import chatReducer from '@/store/chatSlice';
import errorReducer from '@/store/errorSlice';
import favoriteBetsReducer from '@/store/favoriteBetsSlice';
import gameReducer from '@/store/gameSlice';
import messageWallReducer from '@/store/messageWallSlice';
import settingsReducer from '@/store/settingsSlice';
import tableReducer from '@/store/tableSlice';
import uiReducer from '@/store/uiSlice';
import userReducer from '@/store/userSlice';
import { configureStore } from '@reduxjs/toolkit';
import errorMiddleware from './middlewares/errorMiddleware';

/**
 * @typedef {object} RootState - TBD.
 * @property {import('./gameSlice').GameState} game - TBD.
 * @property {import('./tableSlice').TableState} table - TBD.
 * @property {import('./settingsSlice').SettingsState} settings - TBD.
 * @property {import('./uiSlice').UIState} ui - TBD.
 * @property {import('./messageWallSlice').MessageWallState} messageWall - TBD.
 * @property {import('./chatSlice').ChatState} chat - TBD.
 * @property {import('./userSlice').UserState} user - TBD.
 * @property {import('./favoriteBetsSlice').FavoriteBetsState} favoriteBets - TBD.
 * @property {import('./errorSlice').ErrorState} error - TBD.
 */

export default configureStore({
  reducer: {
    game: gameReducer,
    table: tableReducer,
    settings: settingsReducer,
    ui: uiReducer,
    messageWall: messageWallReducer,
    chat: chatReducer,
    user: userReducer,
    favoriteBets: favoriteBetsReducer,
    error: errorReducer,
  },
  // eslint-disable-next-line unicorn/prefer-spread
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorMiddleware),
});
