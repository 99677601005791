const HotIcon = (props) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" width={29} height={29} {...props}>
    <defs>
      <symbol xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 25" id="hot">
        <defs>
          <radialGradient id="hot_a" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="#D88818" />
            <stop offset="100%" stopColor="#D88818" stopOpacity={0} />
          </radialGradient>
          <linearGradient id="hot_b" x1="100%" x2="0%" y1="50%" y2="50%">
            <stop offset="0%" stopColor="#FFD000" stopOpacity={0.1} />
            <stop offset="100%" stopColor="#FF9000" stopOpacity={0.6} />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" transform="rotate(-90 12.9 12.9)">
          <circle cx={3.158} cy={12} r={12} fill='url("#hot_a")' />
          <path
            fill='url("#hot_b")'
            d="M24 12.939c-3.529 2.633-7.17 4.014-10.926 4.142-3.755.127-6.622-.028-8.602-.467l.58.169c2.06.61 3.412 1.12 4.057 1.527.704.445 1.488 1.087 2.354 1.926-1.696-.526-3.506-.828-5.43-.906-1.924-.079-3.49.291-4.697 1.11 1.197.723 2.549 1.085 4.054 1.085 1.506 0 2.605-.094 3.299-.283-1.097 1.063-2.962 1.893-5.594 2.489-.779.176-1.81.266-3.095.269V0c3.646.135 6.682.953 9.11 2.456-1.272-.291-2.442-.383-3.51-.275-1.067.109-2.019.377-2.854.805 2.545.674 4.417 1.315 5.618 1.922 1.8.91 2.154 2.616 5.474 3.255 2.213.426 4.3.426 6.262 0-2.08 1.147-4.053 1.78-5.919 1.9-1.865.12-3.805 0-5.817-.36 3.158 1.956 6.112 3.035 8.862 3.236 2.749.201 5.007.201 6.774 0z"
          />
        </g>
      </symbol>
    </defs>
    <use xlinkHref="#hot" />
  </svg>
);

export default HotIcon;
