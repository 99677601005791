const ROUND_STATE = Object.freeze({
  START_GAME: 'startGame',
  PLACE_BETS: 'placeBets',
  FINAL_BETS: 'finalBets',
  NO_MORE_BETS: 'noMoreBets',
  WINNING_NUMBER: 'winningNumber',
  TABLE_CLOSED: 'tableClosed',
  DEALER_LOCK: 'dealerLock',
});

export default ROUND_STATE;
