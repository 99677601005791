import IconButton from '@/components/IconButton';
import ROUND_STATE from '@/enums/roundState';
import StatIcon from '@/assets/svg/statistics.svg?react';
import { closeFavoritesPopup, closeStatisticsPopup, openFavoritesPopup, openStatisticsPopup } from '@/store/uiSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import ChatView from '../ChatView';
import ChipSelector from '../ChipSelector';
import MessageWall from '../MessageWall';
import Wallet from '../Wallet';
import WinningNumber from '../WinningNumber';
import RacetrackWithSelector from '../common/RacetrackWithSelector';
import StatusBar from '../common/StatusBar';
import Tooltip from '../common/Tooltip';
import styles from './DesktopCompactView.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import HotCold from '../StatisticsView/HotCold';
import HistoryLine from '../HistoryLine';
import DesktopDrawer from '../DesktopDrawer';
import { CHAT_VIEW_TYPE } from '@/enums/chat';
import RouletteTable from '../RouletteTable';

const DesktopCompactView = () => {
  const [ref, { width, height }] = useMeasure();
  const { t } = useTranslation('common');
  const showStatisticsPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showStatisticsPopup
  );
  const [messageWallHeight, setMessageWallHeight] = useState();
  const showFavoritesPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showFavoritesPopup
  );
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const dispatch = useDispatch();
  const racetrackRef = useRef();
  const buttonContainerRef = useRef();
  const isBettingTime = useSelector(selectIsBettingTime);

  const toggleStatistics = useCallback(() => {
    showStatisticsPopup ? dispatch(closeStatisticsPopup()) : dispatch(openStatisticsPopup());
  }, [dispatch, showStatisticsPopup]);

  const toggleFavorites = useCallback(() => {
    showFavoritesPopup ? dispatch(closeFavoritesPopup()) : dispatch(openFavoritesPopup());
  }, [dispatch, showFavoritesPopup]);

  const calculateMessageWallHeight = useCallback(() => {
    const racetrackElement = racetrackRef.current;
    const buttonContainerElement = buttonContainerRef.current;
    if (racetrackElement && buttonContainerElement) {
      const raceTrackY = racetrackElement.getBoundingClientRect().top;
      const buttonContainerY = buttonContainerElement.getBoundingClientRect().top;
      setMessageWallHeight(buttonContainerY - raceTrackY - racetrackElement.offsetHeight);
    }
  }, []);

  const handleWindowResize = useCallback(() => {
    let ticking = false;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        calculateMessageWallHeight();
        ticking = false;
      });
      ticking = true;
    }
  }, [calculateMessageWallHeight]);

  useEffect(() => {
    calculateMessageWallHeight();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [calculateMessageWallHeight, handleWindowResize]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.topSection}>
          <ChatView className={styles.chat} type={CHAT_VIEW_TYPE.COMPACT} />
          <div className={styles.streamContainer}>
            {roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null && (
              <div className={styles.winningNumber}>
                <WinningNumber number={winningNumber} />
              </div>
            )}
          </div>
          <div className={styles.hotColdContainer}>
            <HistoryLine />
            <HotCold />
          </div>
        </div>

        <div className={styles.bottomSection}>
          <div className={styles.statusBarContainer}>
            <StatusBar />
          </div>
          <div className={styles.charts}>
            <div ref={ref} className={styles.layoutTableContainer}>
              <RouletteTable />
            </div>
            <div ref={racetrackRef} className={styles.racetrackContainer}>
              <RacetrackWithSelector backgroundFilled={true} />
            </div>
          </div>
          <div className={styles.footerContainer}>
            <Wallet className={styles.wallet} />
            <div className={clsx(styles.chipSelector, !isBettingTime && styles.closed)}>
              <ChipSelector mode="list" />
            </div>
            <div ref={buttonContainerRef} className={styles.buttonContainer}>
              <Tooltip content={t('favoriteAndSpecialBets')} distance={6}>
                <IconButton
                  icon={<FaStar size="1em" />}
                  className={clsx(showFavoritesPopup && styles.activeMenuItem)}
                  onClick={toggleFavorites}
                />
              </Tooltip>
              <Tooltip content={t('statistics')} distance={6}>
                <IconButton
                  icon={<StatIcon width="1em" />}
                  className={clsx(showStatisticsPopup && styles.activeMenuItem)}
                  onClick={toggleStatistics}
                />
              </Tooltip>
            </div>
          </div>
          {messageWallHeight && (
            <div className={styles.messageWallContainer}>
              <MessageWall height={'6rem'} />
            </div>
          )}
          <DesktopDrawer className={styles.desktopDrawer} />
        </div>
      </div>
    </>
  );
};

export default DesktopCompactView;
