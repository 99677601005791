import styles from './../../HelpView.module.scss';
import CloseButton from '@/components/FavouriteAndSpecialBets/FavouriteView/CloseButton';
import RacetrackButton from '../figures/RacetrackButton';
import Racetrack from '../figures/Racetrack';
import FavoriteButton from '../figures/FavoriteButton';
import BetSaveButton from '../figures/BetSaveButton';
import BetEditModeButton from '../figures/BetEditModeButton';
import clsx from 'clsx';
import StatisticsButton from '../figures/StatisticsButton';
import BetLimits from '../figures/BetLimits';
import GameNumber from '../figures/GameNumber';
import ChatButton from '../figures/ChatButton';
import SoundButton from '../figures/SoundButton';
import HistoryButton from '../figures/HistoryButton';
import SettingsButton from '../figures/SettingsButton';
import ResponsibleGamingButton from '../figures/ResponsibleGamingButton';
import WinningNumberImg from '/images/winning_numbers.png';

const content = [
  {
    title: 'gameRules',
    content: (
      <>
        <p>
          Bij roulette voorspel je op welk nummer het kogeltje na het draaien van het grote wiel terecht zal komen. Als
          je hebt ingezet op dat nummer, of op een combinatie van getallen waar dat nummer deel van uitmaakt, win je.
        </p>
        <p>
          Bij Europese roulette bestaat het wiel uit de nummers 0 tot en met 36. Zodra de inzetperiode voorbij is, wordt
          het roulettewiel gedraaid en het kogeltje in de tegengestelde richting geworpen. Naarmate het wiel tot
          stilstand komt, valt het kogeltje uiteindelijk in een van de genummerde vakjes. Je wint als het kogeltje in
          het vakje met het nummer waarop jij hebt ingezet terechtkomt.
        </p>
      </>
    ),
  },
  {
    title: 'betTypes',
    content: (
      <>
        <p>
          De roulettetafel kent veel verschillende manieren om in te zetten: van één specifiek nummer tot uiteenlopende
          combinaties van nummers. Elk inzetsoort heeft een eigen uitbetaling, waarbij de inzet op één specifiek nummer
          de hoogste uitbetaling oplevert. Hieronder lees je meer over alle manieren van inzetten.
        </p>
        <div>Binnen inzetten:</div>
        <p>
          Inzetten op de genummerde vakken of de lijnen tussen deze vakken noemen we &quot;binnen inzetten&quot;. Je
          kunt kiezen uit de volgende binnen inzetten:
        </p>
        <ul>
          <li>
            <strong>Enkel nummer</strong>: je plaatst een fiche op één nummer naar keuze (inclusief de 0).
          </li>
          <li>
            <strong>Gesplitste inzet</strong>: je plaatst een fiche op de splitsing tussen twee nummers, verticaal of
            horizontaal. Een gesplitste inzet omvat twee nummers.
          </li>
          <li>
            <strong>Straatinzet</strong>: je plaats een fiche aan het eind van een rij met nummers. Een straatinzet
            omvat drie nummers.
          </li>
          <li>
            <strong>Hoekinzet</strong>: je plaatst een fiche op de kruising tussen vier nummers. Een hoekinzet omvat
            vier nummers.
          </li>
          <li>
            <strong>Lijninzet</strong>: je plaatst een fiche op de kruising aan het eind van twee rijen. Een lijninzet
            omvat zes nummers.
          </li>
        </ul>
        <div>Buiten inzetten:</div>
        <p>
          Inzetten op de vakken links van het speelveld noemen we &quot;buiten inzetten&quot;. Je kunt kiezen uit de
          volgende buiten inzetten:
        </p>
        <ul>
          <li>
            <strong>Kolominzet</strong>: je plaatst een fiche in een vak met &quot;2 to 1&quot; aan het eind van de
            kolom. Dit vak omvat alle twaalf getallen in die kolom. De nul wordt niet gedekt met een kolominzet.
          </li>
          <li>
            <strong>Dozijninzet</strong>: je plaatst een fiche in een van de vakken met &quot;1st 12&quot;, &quot;2nd
            12&quot; of &quot;3rd 12&quot;. Elk vak omvat de twaalf nummers ernaast.
          </li>
          <li>
            <strong>Rood/Zwart inzet</strong>: je plaatst een fiche in het rode of het zwarte vak. Het rode vak omvat de
            achttien rode, het zwarte vak de achttien zwarte nummers. De nul wordt niet gedekt met de rood/zwart inzet.
          </li>
          <li>
            <strong>Even/Oneven inzet</strong>: je plaatst een fiche in een van de vakken die de achttien even of de
            achttien oneven (odd) nummers omvatten. De nul wordt niet gedekt met de even/oneven inzet.
          </li>
          <li>
            <strong>1-18/19-36 inzet</strong>: je plaatst een fiche in een van deze vakken, die de eerste of de laatste
            achttien nummers omvatten. De nul wordt niet gedekt met deze inzet.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'neighbourBets',
    content: (
      <>
        <p>
          Met de knop &quot;Buurinzetten&quot; open je een speciale inzetzone waar je buurinzetten en andere speciale
          inzetten kunt plaatsen. Door nogmaals op deze knop te klikken, kun je deze zone sluiten of opnieuw openen.
        </p>
        <p>
          Een buurinzet omvat één specifiek nummer en een aantal nummers die op het roulettewiel dicht bij dit nummer
          liggen. Je plaatst een buurinzet door op een nummer te klikken. Je plaatst dan een fiche op dat specifieke
          nummer en de nummers links en rechts ervan. Je kunt het aantal buurnummers verhogen of verlagen naar links of
          rechts van het specifieke nummer. Hiervoor klik je op de knop met &quot;-&quot; of &quot;+&quot;.
        </p>
        <div className={styles.itemContainer}>
          <RacetrackButton />
        </div>
        <p>
          Elke buurinzet omvat een aantal nummers met verschillende uitbetalingstarieven. Hieronder lees je er meer
          over.
        </p>
        <div className={styles.itemContainer}>
          <Racetrack width="20rem" />
        </div>
        <h4>Tier</h4>
        <p>
          Deze inzet omvat twaalf nummers, bestaande uit 27, 33 en de tien nummers die op het roulettewiel hiertussenin
          liggen. Je plaatst zes fiches:
        </p>
        <ul>
          <li>een fiche op de splitsing van 5/8</li>
          <li>een fiche op de splitsing van 10/11</li>
          <li>een fiche op de splitsing van 13/16</li>
          <li>een fiche op de splitsing van 23/24</li>
          <li>een fiche op de splitsing van 27/30</li>
          <li>een fiche op de splitsing van 33/36</li>
        </ul>

        <h4>Orphelins</h4>
        <p>
          Deze inzet omvat de acht nummers op het roulettewiel die niet gedekt zijn door de inzetten van zero en tiers.
          Je plaatst vijf fiches:
        </p>
        <ul>
          <li>een fiche op 1</li>
          <li>een fiche op de splitsing van 6/9</li>
          <li>een fiche op de splitsing van 14/17</li>
          <li>een fiche op de splitsing van 17/20</li>
          <li>een fiche op de splitsing van 31/34</li>
        </ul>

        <h4>Voisins</h4>
        <p>
          Deze inzet omvat zeventien nummers bestaande uit 22, 25 en de vijftien nummers die op het roulettewiel
          hiertussenin liggen, inclusief nul. Je plaatst negen fiches:
        </p>
        <ul>
          <li>twee fiches op de kruising van 0/2/3</li>
          <li>een fiche op de splitsing van 4/7</li>
          <li>een fiche op de splitsing van 12/15</li>
          <li>een fiche op de splitsing van 18/21</li>
          <li>een fiche op de splitsing van 19/22</li>
          <li>twee fiches op de kruising van 25/26/28/29</li>
          <li>een fiche op de splitsing van 32/35</li>
        </ul>

        <h4>Zero</h4>
        <p>
          Deze inzet dekt de nul en de zes nummers het dichtst bij de nul op het roulettewiel: 12, 35, 3 ,26, 0, 32 en
          15. Je plaatst vier fiches:
        </p>
        <ul>
          <li>een fiche op de splitsing van 0/3</li>
          <li>een fiche op de splitsing van 12/15</li>
          <li>een fiche op 26</li>
          <li>een fiche op de splitsing van 32/35</li>
        </ul>
      </>
    ),
  },
  {
    title: 'favAndSpecialBets',
    content: (
      <>
        <h4>FAVORIETE INZETTEN</h4>
        <p>
          Handig en makkelijk: het menu &quot;Favoriete inzetten&quot;. Daarmee kun je een voorkeursinzet of een
          combinatie van soorten inzetten opslaan om in latere ronden weer te gebruiken. Je kunt een lijst met dertig
          favoriete inzetten maken. Elke inzet kun je een andere naam geven en apart opslaan en bewerken.
        </p>
        <h4>Het menu &quot;Favoriete inzetten&quot; openen en sluiten</h4>
        <p>
          Je opent en sluit het menu van &quot;Favoriete inzetten&quot; door op de knop &quot;Favoriete inzetten&quot;
          te klikken.
        </p>
        <div className={styles.itemContainer}>
          <FavoriteButton />
        </div>
        <p>
          Heb je eenmaal een favoriete inzet of combinatie van inzetten op de roulettetafel geplaatst? Klik dan op de
          koppeling &quot;Laatste inzet opslaan&quot;. Er verschijnt een standaardnaam, maar je kunt ook een betere naam
          verzinnen. Vervolgens sla je deze inzet op en voeg je hem toe aan je lijst met favoriete inzetten. Klik
          hiervoor met je muis op &quot;Opslaan&quot; of druk op &quot;Enter&quot; op je toetsenbord.
        </p>
        <div className={styles.itemContainer}>
          <BetSaveButton />
        </div>
        <h4>Favoriete inzetten plaatsen</h4>
        <p>
          Wil je tijdens de inzettijd van een rouletteronde een van jouw favoriete inzetten plaatsen? Open dan het menu
          &quot;Favoriete inzetten&quot; en bekijk de lijst met alle inzetten die je hebt opgeslagen. De inzet die je
          als eerste hebt opgeslagen, staat bovenaan. Als je met je muis over de naam van een inzet gaat, zie je hoe de
          fiches op de roulettetafel zullen worden geplaatst. Om een favoriete inzet te plaatsen, klik je simpelweg op
          de naam van de gewenste inzet. Je kunt het bedrag van je favoriete inzet vermenigvuldigen door twee keer, drie
          keer, vier keer enzovoort op de naam te tikken.
        </p>
        <h4>Favoriete inzetten verwijderen of een andere naam geven</h4>
        <p>
          Wanneer je het menu &quot;Favoriete inzetten&quot; hebt geopend, kun je een inzet uit de lijst verwijderen of
          een andere naam geven door op de knop &quot;Bewerken&quot; te klikken.
        </p>
        <div className={styles.itemContainer}>
          <BetEditModeButton />
        </div>
        <p>
          Om de naam te wijzigen, klik je eerst in het grijze tekstvak rondom de huidige naam. Voer vervolgens een
          nieuwe naam in en sla deze op door met je muis op &quot;Opslaan&quot; te klikken of door op &quot;Enter&quot;
          op je toetsenbord te drukken.
        </p>
        <p>
          Je verwijdert een inzet uit de lijst met favoriete inzetten door bij die inzet op de knop
          &quot;Verwijderen&quot; te klikken.
        </p>
        <div className={styles.itemContainer}>
          <CloseButton />
        </div>
        <p>
          Ben je klaar met het bewerken van je lijst met favoriete inzetten? Klik dan rechtsboven op &quot;Opslaan&quot;
          of op de knop &quot;Favoriete inzetten&quot;.
        </p>
        <h4>SPECIALE INZETTEN</h4>
        <p>
          In het menu &quot;Favoriete inzetten&quot; kun je ook eenvoudig &quot;Finale en plein&quot;- en &quot;Finale à
          cheval&quot;-inzetten plaatsen.
        </p>
        <p>
          <strong>Finale en plein-inzetten</strong>
        </p>
        <ul>
          <li>
            <strong>Finale en plein 0</strong> - bestaat uit vier fiches en omvat 0, 10, 20 en 30.
          </li>
          <li>
            <strong>Finale en plein 1</strong> - bestaat uit vier fiches en omvat 1, 11, 21en 31.
          </li>
          <li>
            <strong>Finale en plein 2</strong> - bestaat uit vier fiches en omvat 2, 12, 22 en 32.
          </li>
          <li>
            <strong>Finale en plein 3</strong> - bestaat uit vier fiches en omvat 3, 13, 23 en 33.
          </li>
          <li>
            <strong>Finale en plein 4</strong> - bestaat uit vier fiches en omvat 4, 14, 24 en 34.
          </li>
          <li>
            <strong>Finale en plein 5</strong> - bestaat uit vier fiches en omvat 5, 15, 25 en 35.
          </li>
          <li>
            <strong>Finale en plein 6</strong> - bestaat uit vier fiches en omvat 6, 16, 26 en 36.
          </li>
          <li>
            <strong>Finale en plein 7</strong> - bestaat uit drie fiches en omvat 7, 17 en 27.
          </li>
          <li>
            <strong>Finale en plein 8</strong> -bestaat uit drie fiches en omvat 8, 18 en 28.
          </li>
          <li>
            <strong>Finale en plein 9</strong> - bestaat uit drie fiches en omvat 9, 19 en 29.
          </li>
        </ul>
        <p>
          <strong>Finale à cheval-inzetten</strong>
        </p>
        <ul>
          <li>
            <strong>Finale à cheval 0/3</strong> - bestaat uit vier fiches en omvat 0/3, 10/13, 20/23 en 30/33.
          </li>
          <li>
            <strong>Finale à cheval 1/4</strong> - bestaat uit vier fiches en omvat 1/4, 11/14, 21/24 en 31/34.
          </li>
          <li>
            <strong>Finale à cheval 2/5</strong> - bestaat uit vier fiches en omvat 2/5, 12/15, 22/25 en 32/35.
          </li>
          <li>
            <strong>Finale à cheval 3/6</strong> - bestaat uit vier fiches en omvat 3/6, 13/16, 23/26 en 33/36.
          </li>
          <li>
            <strong>Finale à cheval 4/7</strong> - bestaat uit vier fiches en omvat 4/7, 14/17, 24/27 en 34.
          </li>
          <li>
            <strong>Finale à cheval 5/8</strong> - bestaat uit vier fiches en omvat 5/8, 15/18, 25/28 en 35.
          </li>
          <li>
            <strong>Finale à cheval 6/9</strong> - bestaat uit vier fiches en omvat 6/9, 16/19, 26/29 en 36.
          </li>
          <li>
            <strong>Finale à cheval 7/10</strong> - bestaat uit drie fiches en omvat 7/10, 17/20 en 27/30.
          </li>
          <li>
            <strong>Finale à cheval 8/11</strong> - bestaat uit drie fiches en omvat 8/11, 18/21 en 28/31.
          </li>
          <li>
            <strong>Finale à cheval 9/12 </strong>- bestaat uit drie fiches en omvat 9/12, 19/22 en 29/32.
          </li>
        </ul>
        <p>
          <strong>Volledige inzetten</strong>
        </p>
        <p>Met een volledige inzet plaats je alle binnen inzetten op een specifiek getal.</p>
        <p>
          Met bijvoorbeeld een volledige inzet op 36 plaats je, om nummer 36 volledig te dekken, achttien fiches als
          volgt:
        </p>
        <ul>
          <li>één fiche op 36</li>
          <li>twee fiches op elk van de gesplitste inzetten 33/36 en 35/36</li>
          <li>drie fiches op de straatinzet 34/35/36</li>
          <li>vier fiches op de hoekinzet 32/33/35/36</li>
          <li>zes fiches op de lijninzet 31/32/33/34/35/36</li>
        </ul>
      </>
    ),
  },
  {
    title: 'winningNumbers',
    content: (
      <>
        <p>Op het scherm &quot;Winnende nummers&quot; zie je de meest recente winnende nummers.</p>
        <div className={clsx(styles.itemContainer, styles.winningNumbers)}>
          <img src={WinningNumberImg} alt="" />
        </div>
        <p>Links zie je het resultaat van de meest recente voltooide ronde.</p>
      </>
    ),
  },
  {
    title: 'recentResult',
    content: (
      <>
        <p>
          Wanneer je op de knop &quot;Statistieken&quot; klikt, zie je een tabel met de winnende nummers van de 500
          meest recente spelronden. Door met de regelaar te schuiven kun je het aantal ronden aanpassen.
        </p>
        <div className={styles.itemContainer}>
          <StatisticsButton />
        </div>
        <p>
          Als je je muis over een deel van het statistiekendiagram verplaatst, zie je de plek op de inzettafel waar een
          fiche zou worden geplaatst. Klik op de inzet om jouw fiche te plaatsen.
        </p>
      </>
    ),
  },
  {
    title: 'payouts',
    content: (
      <>
        <p>Hoeveel je krijgt uitbetaald, hangt af van het soort inzet dat je hebt geplaatst.</p>
        <div>
          <strong>BINNEN INZETTEN</strong>
        </div>
        <table>
          <thead>
            <tr>
              <th>Soort inzet</th>
              <th>Uitbetaling</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Enkel nummer</td>
              <td>35:1</td>
            </tr>
            <tr>
              <td>Gesplitst</td>
              <td>17:1</td>
            </tr>
            <tr>
              <td>Straat</td>
              <td>11:1</td>
            </tr>
            <tr>
              <td>Hoek</td>
              <td>8:1</td>
            </tr>
            <tr>
              <td>Lijn</td>
              <td>5:1</td>
            </tr>
          </tbody>
        </table>
        <div>
          <strong>BUITEN INZETTEN</strong>
        </div>
        <table>
          <thead>
            <tr>
              <th>Soort inzet</th>
              <th>Uitbetaling</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Kolom</td>
              <td>2:1</td>
            </tr>
            <tr>
              <td>Dozijn</td>
              <td>2:1</td>
            </tr>
            <tr>
              <td>Rood/Zwart</td>
              <td>1:1</td>
            </tr>
            <tr>
              <td>Even/Oneven</td>
              <td>1:1</td>
            </tr>
            <tr>
              <td>1-18/19-36</td>
              <td>1:1</td>
            </tr>
          </tbody>
        </table>
        <p>Bij storingen worden alle uitbetalingen en spellen geannuleerd.</p>
      </>
    ),
  },
  {
    title: 'payoutPercentage',
    content: (
      <>
        <p>Het optimale theoretische uitkeringspercentage voor de speler bedraagt 97,3 procent.</p>
      </>
    ),
  },
  {
    title: 'betLimits',
    content: (
      <>
        <p>Hieronder lees je een uitleg over verschillende knoppen die tijdens het spelen beschikbaar zijn of komen.</p>
        <p>
          Onder de knop &quot;Inzetlimieten&quot; zie je de inzetlimieten van de tafel die minimaal en maximaal zijn
          toegestaan. Deze kunnen worden gewijzigd. Als je de inzetlimieten opent, kun je jouw huidige limieten
          bekijken.
        </p>
        <div className={styles.itemContainer}>
          <BetLimits />
        </div>

        <p>
          Om deel te nemen aan het spel moet je saldo toereikend zijn om je inzet te dekken. Jouw huidige saldo zie je
          onder &quot;Saldo&quot;.
        </p>
        <div className={clsx(styles.itemContainer, styles.walletButton)}>
          <img src="/images/saldo.png" width={286} height={79} alt="" />
        </div>
        <p>
          In de zogeheten &quot;meeslepende&quot; spelweergave houdt de timer jou op de hoogte van de inzettijd. Als de
          inzettijd voorbij is, kun je niet meer inzetten.
        </p>
        <div className={clsx(styles.itemContainer, styles.timer)}>
          <img src="/images/timer.png" width={89} height={88} alt="" />
        </div>
        <p>
          De &quot;Verkeerslichten&quot; laten je de huidige status van het inzetten in de spelronde zien. Bij groen
          licht kun je inzetten. Bij geel licht is de inzetperiode bijna afgelopen. Bij rood licht is de inzettijd
          voorbij.
        </p>
        <div className={clsx(styles.itemContainer, styles.statusBar)}>
          <img src="/images/plaats_uw_inzet.png" width={413} height={41} alt="" />
        </div>
        <p>
          Bij &quot;Ficheweergave&quot; kun je de waarde kiezen van elk fiche dat je wilt inzetten. Geactiveerd zijn
          alleen de fiches die worden gedekt door je huidige saldo.
        </p>
        <div className={clsx(styles.itemContainer, styles.chips)}>
          <img src="/images/chips.png" width={617} height={92} alt="" />
        </div>
        <p>
          Heb je eenmaal een fiche gekozen? Klik dan op de betreffende inzetlocatie op de speeltafel om je inzet te
          plaatsen. Wil je jouw inzet verhogen? Met elke klik op de inzetlocatie verhoog je het bedrag van je inzet met
          de waarde van de chip of tot aan de maximale limiet voor het soort inzet dat je hebt gekozen. Als je deze
          limiet hebt bereikt, kun je jouw inzet niet meer verhogen. Je krijgt hiervan automatisch een melding te zien
          boven je inzet.
        </p>
        <p>
          Let op: tijdens de inzettijd en wanneer je inzetten hebt geplaatst aan de tafel mag je jouw browser niet
          minimaliseren of een andere tab openen. Doe je dat toch, dan wordt dit gezien als het verlaten van het spel.
          Jouw inzetten voor die spelronde worden dan geweigerd.
        </p>
        <p>
          Wanneer je hebt ingezet, wordt de knop &quot;2x Dubbel&quot; zichtbaar. Elke klik daarop verdubbelt jouw inzet
          tot aan de maximale limiet. Let op: je moet wel voldoende tegoed op je account hebben staan om al je inzetten
          te kunnen verdubbelen.
        </p>
        <div className={clsx(styles.itemContainer, styles.double)}>
          <img src="/images/help/dubble.svg" width={300} height={116} alt="" />
        </div>
        <p>
          Met de knop &quot;Herhalen&quot; kun je dezelfde inzet(ten) van de vorige spelronde nogmaals plaatsen. Je ziet
          deze knop alleen voordat je jouw eerste fiche inzet.
        </p>
        <div className={clsx(styles.itemContainer, styles.repeat)}>
          <img src="/images/help/herhalen.svg" width={300} height={118} alt="" />
        </div>
        <p>
          Met de knop &quot;Terug&quot; kun je jouw laatste inzet verwijderen. Elke keer als je hierop klikt, wordt een
          van je inzetten verwijderd, in de omgekeerde volgorde waarin je die inzetten hebt geplaatst. Wanneer je de
          knop &quot;Terug&quot; ingedrukt houdt, maak je al jouw inzetten in één keer ongedaan.
        </p>
        <div className={clsx(styles.itemContainer, styles.undo)}>
          <img src="/images/help/terug.svg" width={300} height={141} alt="" />
        </div>
        <p>Bij de knop &quot;Totale inzet&quot; zie je het totaal van al jouw inzetten in de huidige spelronde.</p>
        <div className={clsx(styles.itemContainer, styles.walletButton)}>
          <img src="/images/totale_inzet.png" width={286} height={79} alt="" />
        </div>
      </>
    ),
  },
  {
    title: 'disconectionPolicy',
    content: (
      <>
        <p>
          Wordt de verbinding tijdens een spelronde verbroken nadat een inzet gesloten is? In dat geval blijven jouw
          geplaatste inzetten geldig. Zodra je weer verbonden bent, zie je in het venster &quot;Geschiedenis&quot; het
          resultaat van elke geldige inzet.
        </p>
      </>
    ),
  },
  {
    title: 'chat',
    content: (
      <>
        <p>
          Bij roulette is het mogelijk om te chatten met de spelpresentator en andere spelers. Dat doe je als volgt:
          schrijf simpelweg je boodschap in het chatvenster en klik hier vervolgens op de pijltoets of druk op
          &quot;Enter&quot;.
        </p>
        <p>
          Als je de chatfunctie misbruikt, bijvoorbeeld omdat je ongepaste/aanstootgevende taal gebruikt of de
          spelpresentator/andere spelers beledigt, word je van de chat uitgesloten.
        </p>
        <p>Je kunt het chatvenster vergroten of verkleinen en ook overal op het scherm plaatsen.</p>
        <p>Gebruik de chatknop om het formaat de wijzigingen of het venster te sluiten.</p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <ChatButton />
        </div>
      </>
    ),
  },
  {
    title: 'gameNumber',
    content: (
      <>
        <p>
          Iedere ronde heeft een uniek spelnummer, dat tot op de seconde aangeeft op welk tijdstip de ronde is gestart.
        </p>
        <div className={styles.itemContainer}>
          <GameNumber />
        </div>
        <p>
          Je kunt dit nummer als referentie gebruiken wanneer je over een bepaalde ronde contact opneemt met onze
          klantenservice. Je kunt in dat geval ook een screenshot van het spelnummer maken.
        </p>
      </>
    ),
  },
  {
    title: 'sound',
    content: (
      <>
        <p>De geluidsknop schakelt de spelgeluiden en stemmen in en uit.</p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <SoundButton />
        </div>
        <p>
          Het geluid wordt automatisch ingeschakeld als je van tafel verandert. Je wijzigt het geluid door op de knop
          &quot;Instellingen&quot; te klikken en vervolgens de tab voor het geluid te kiezen.
        </p>
      </>
    ),
  },
  {
    title: 'gameHistory',
    content: (
      <>
        <p>
          Via de knop &quot;Geschiedenis&quot; open je een venster met alle spelronden die jij heeft gespeeld en de
          resultaten die je per ronde hebt behaald.
        </p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <HistoryButton />
        </div>
        <p>Er zijn twee manieren om je voorbije spelactiviteiten weer te geven:</p>
        <ul>
          <li>
            Spelgeschiedenis - Hier zie je jouw geschiedenis met een specifiek spel. Klik hiervoor het spel aan in de
            kolom &quot;Spel&quot;.
          </li>
          <li>
            Accountgeschiedenis - Hier zie je jouw volledige accountgeschiedenis in de vorm van een lijst met data,
            spellen, inzetbedragen en uitbetalingen. De meest recent voltooide ronde staat bovenaan.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'settings',
    content: (
      <>
        <h4>De knop &quot;Instellingen&quot;</h4>
        <p>Met de knop &quot;Instellingen&quot; kun je de instellingen in het algemeen of per spel wijzigen.</p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <SettingsButton />
        </div>
        <p>
          De instellingen die je selecteert, worden onmiddellijk van kracht en meteen opgeslagen. De opgeslagen
          instellingen worden automatisch gestart wanneer jij je aanmeldt om te spelen.
        </p>
        <h4>ALGEMEEN</h4>
        <p>Je kunt de chatberichten van andere spelers weergeven of verbergen.</p>
        <h4>VIDEO</h4>
        <p>
          De kwaliteit van je video wordt automatisch aangepast, maar kun je ook handmatig wijzigen door een stream te
          selecteren.
        </p>
        <h4>GELUID</h4>
        <p>
          Je kunt de stem van de spelpresentator en het geluid van het spel aanzetten of dempen, maar ook het volume
          aanpassen.
        </p>
      </>
    ),
  },
  {
    title: 'responsibleGaming',
    content: (
      <>
        <p>
          Via de knop &quot;Verantwoord spelen&quot; krijg je toegang tot informatie over het beleid dat Casino hiervoor
          hanteert.
        </p>
        <div className={clsx(styles.itemContainer, styles.iconButton)}>
          <ResponsibleGamingButton />
        </div>
        <p>
          Bij &quot;Verantwoord spelen&quot; lees je nuttige informatie en links over verantwoord spelgedrag bij online
          kansspelen. Ook kun je hier limieten instellen.
        </p>
      </>
    ),
  },
  {
    title: 'errorHandling',
    content: (
      <>
        <p>
          Het kan gebeuren dat er een fout optreedt in het systeem, in het spel of in de spelprocedure. In dat geval
          wordt de spelronde tijdelijk gepauzeerd, zodat de croupier de fout kan doorgeven aan de servicemanager. Als
          speler lees je via de chat of een pop-upbericht op je scherm dat het probleem wordt onderzocht en zo snel
          mogelijk opgelost. Kan de manager de fout onmiddellijk oplossen, dan wordt het spel meteen daarna hervat. Is
          er geen directe oplossing mogelijk, dan wordt de spelronde geannuleerd. Alle deelnemers aan de spelronde
          krijgen hun inzet terugbetaald.
        </p>
      </>
    ),
  },
  {
    title: 'rulesForValidSpins',
    content: (
      <>
        <p>
          Het winnende roulettenummer telt alleen als de roulettespin geldig is. Een spin is geldig als de dealer de
          kogel tegen de draairichting van het roulettewiel in werpt, de kogel ten minste driemaal helemaal rondgaat en
          pas dan tot stilstand komt in een vak met een nummer.
        </p>
        <p>Dit betekent dat een spin ongeldig is in de volgende gevallen:</p>
        <ul>
          <li>Wanneer de kogel in de draairichting van het roulettewiel is geworpen</li>
          <li>Wanneer de kogel minder dan drie hele omwentelingen heeft gemaakt.</li>
          <li>Wanneer de kogel tijdens de spin uit de roulettecylinder schiet.</li>
          <li>Wanneer het roulettewiel tijdens de spin stopt met draaien.</li>
          <li>Wanneer er tijdens de spin iets in de cylinder terechtkomt.</li>
        </ul>
        <p>Is een spin niet geldig, dan zal de dealer de kogel opnieuw laten ronddraaien.</p>
        <p>
          Mochten zich ongeregeldheden voordoen, dan kun je het spelnummer van de betreffende spelronde doorgeven aan de
          liveondersteuning.
        </p>
      </>
    ),
  },
  {
    title: 'moreGames',
    content: (
      <>
        <p>
          De knop &quot;Lobby&quot; kun je gebruiken om andere spellen te bekijken terwijl je het huidige spel nog
          speelt. Dat kan op ieder moment.
        </p>
        <p>
          Via de &quot;Lobby&quot; kun je simpelweg een andere tafel of een ander livespel liezen. Je wordt pas
          verwijderd uit je huidige roulettespel als je ook echt een nieuw spel of een nieuwe tafel hebt gekozen.
        </p>
        <div className={clsx(styles.itemContainer, styles.lobby)}>
          <img src="/images/lobby.png" width={236} height={79} alt="" />
        </div>
      </>
    ),
  },
  {
    title: 'shortcuts',
    content: (
      <>
        <p>Sneltoetsen kunnen worden gebruikt om snel toegang te krijgen tot handige spelfuncties.</p>
        <table className={styles.leftAlignment}>
          <thead>
            <tr>
              <th>TOETS</th>
              <th>FUNCTIE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Numerieke toetsen beginnend vanaf 1</td>
              <td>
                Selecteer de gewenste fiche in het fiche-overzicht. Toets &quot;1&quot; komt overeen met de meest linkse
                fiche met de laagste waarde. Toets &quot;2&quot; selecteert de fiche met de volgende waarde, enzovoort.
              </td>
            </tr>
            <tr>
              <td>SPATIEBALK</td>
              <td>Herhaal uw laatste inzet. Druk nogmaals op de SPATIEBALK om uw inzet te verdubbelen.</td>
            </tr>
            <tr>
              <td>DELETE, BACKSPACE</td>
              <td>Maak uw laatste inzet ongedaan. Houd 3 seconden ingedrukt om al uw inzetten te verwijderen.</td>
            </tr>
            <tr>
              <td>ESC</td>
              <td>
                Indien van toepassing, kan ESC worden gebruikt om: <br />- De volledige schermweergave te sluiten <br />
                - Een open pop-upvenster te sluiten (Geschiedenis, Speluitleg, Instellingen, enzovoort)
              </td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
];

export default content;
