import PerspectiveChip from '@/components/PerspectiveChip';
import { BET_TYPE } from '@/enums/layoutTable';
import ROUND_STATE from '@/enums/roundState';
import { ROULETTE_TABLE_TYPE, SCREEN_ORIENTATION } from '@/enums/ui';
import useOrientation from '@/hooks/useOrientation';
import usePageVisiblity from '@/hooks/usePageVisiblity';
import { createBetKey, getLimitForBetType, parseBetKey } from '@/lib/rouletteService';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Chip from './Chip';

const chipAlignments = {
  threeDimension: {
    [createBetKey(BET_TYPE.STRAIGHT, 0)]: {
      x: -1,
      y: -1,
    },
  },
};

const Chips = ({ tableType = ROULETTE_TABLE_TYPE.RESPONSIVE, chipPositions, chipSize = '1.25rem' }) => {
  const orientation = useOrientation();
  const isPageVisible = usePageVisiblity();
  const leaveWinningBets = useSelector((state) => state.settings.general.leaveWinningBets);
  const draggedBetType = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.table.dragAndDrop.draggedBetType
  );
  const isBettingTime = useSelector(selectIsBettingTime);
  const betsPreview = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betsPreview);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const betLimits = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betLimits);
  const collectedChipsRef = useRef({});

  const chipAnimations = useMemo(
    () => ({
      initial: {
        visibility: 'hidden',
      },
      exit: (betKey) => {
        const element = collectedChipsRef.current[betKey];
        if (!element || roundState !== ROUND_STATE.WINNING_NUMBER || !isPageVisible) return null;
        const rect = element.getBoundingClientRect();

        let x, y;
        if (orientation === SCREEN_ORIENTATION.LANDSCAPE) {
          x = window.innerWidth / 2 - rect.left;
          y = window.innerHeight - rect.top;
        } else {
          x = window.innerWidth - rect.left;
          y = window.innerHeight / 2 - rect.top;
        }

        return {
          visibility: 'visible',
          position: 'absolute',
          opacity: 0,
          x: `${x}px`,
          y: `${y}px`,
          transition: { duration: 2, ease: [0.43, 0.13, 0.23, 0.96] },
        };
      },
    }),
    [isPageVisible, orientation, roundState]
  );

  const chipStyle = {
    pointerEvents: 'none',
    position: 'absolute',
    width: chipSize,
    height: chipSize,
    x: '-50%',
    y: '-50%',
  };

  const isChipVisible = useCallback(
    (betKey) =>
      (roundState !== ROUND_STATE.WINNING_NUMBER && bets[betKey]?.value > 0) ||
      (isBettingTime && betsPreview?.[betKey]) ||
      (roundState === ROUND_STATE.WINNING_NUMBER && bets[betKey]?.win > 0),
    [bets, betsPreview, isBettingTime, roundState]
  );

  const betValue = useCallback(
    (betKey) => {
      if (roundState === ROUND_STATE.WINNING_NUMBER) return bets[betKey]?.value + bets[betKey]?.win;
      const betValue = bets[betKey]?.value;
      const betPreview = betsPreview?.[betKey];
      const totalBetValue = (betValue ?? 0) + (betPreview ?? 0);
      const { betType } = parseBetKey(betKey);
      const currentLimit = getLimitForBetType(betLimits, betType);
      const betLimitError = totalBetValue > currentLimit.max;
      return betLimitError ? currentLimit.max : totalBetValue;
    },
    [betLimits, bets, betsPreview, roundState]
  );

  const chipsToRender = useMemo(
    () =>
      Object.entries(chipPositions)
        .filter(([betKey]) => isChipVisible(betKey))
        .map(([betKey, chipPosition]) => ({
          betKey,
          chipPosition: {
            x: chipPosition.x + (chipAlignments?.[tableType]?.[betKey]?.x ?? 0),
            y: chipPosition.y + (chipAlignments?.[tableType]?.[betKey]?.y ?? 0),
          },
          date: bets[betKey]?.date,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date)),
    [bets, chipPositions, isChipVisible, tableType]
  );

  const collectedChips = useMemo(() => {
    if (roundState !== ROUND_STATE.WINNING_NUMBER) return null;
    return chipsToRender
      .filter((bet) => bets[bet.betKey]?.win)
      .map((bet) => ({ ...bet, value: (leaveWinningBets ? 0 : bets[bet.betKey]?.value) + bets[bet.betKey]?.win }));
  }, [bets, chipsToRender, leaveWinningBets, roundState]);

  return (
    <>
      {chipsToRender.map(({ betKey, chipPosition }) => {
        return (
          <div
            key={`table-chip-${betKey}`}
            data-bet-key={betKey}
            style={{
              ...chipStyle,
              left: `${chipPosition.x}%`,
              top: `${chipPosition.y}%`,
              transform: 'translate(-50%, -50%)',
              visibility: draggedBetType === betKey ? 'hidden' : 'visible',
            }}
          >
            {tableType === ROULETTE_TABLE_TYPE.THREE_DIMENSION ? (
              <PerspectiveChip
                value={betValue(betKey, bets[betKey]?.value, betsPreview[betKey])}
                isPreview={isBettingTime && betsPreview[betKey]}
                isInactive={roundState === ROUND_STATE.NO_MORE_BETS}
                isGlowing={roundState === ROUND_STATE.WINNING_NUMBER}
              />
            ) : (
              <Chip
                value={betValue(betKey, bets[betKey]?.value, betsPreview[betKey])}
                isPreview={isBettingTime && betsPreview[betKey]}
                isInactive={roundState === ROUND_STATE.NO_MORE_BETS}
                isGlowing={roundState === ROUND_STATE.WINNING_NUMBER}
              />
            )}
          </div>
        );
      })}

      <AnimatePresence>
        {collectedChips &&
          collectedChips.map(({ betKey, value, chipPosition }) => (
            <motion.div
              ref={(el) => {
                collectedChipsRef.current[betKey] = el;
              }}
              key={`collected-chip-${betKey}`}
              custom={betKey}
              variants={chipAnimations}
              initial="initial"
              exit="exit"
              style={{
                ...chipStyle,
                left: `${chipPosition.x}%`,
                top: `${chipPosition.y}%`,
              }}
            >
              {tableType === ROULETTE_TABLE_TYPE.THREE_DIMENSION ? (
                <PerspectiveChip value={value} isGlowing />
              ) : (
                <Chip value={value} size={chipStyle.width} isGlowing />
              )}
            </motion.div>
          ))}
      </AnimatePresence>
    </>
  );
};

export default Chips;
