import { useSelector, useDispatch } from 'react-redux';
import cl from 'clsx';
import { useCallback, useEffect, useRef } from 'react';
import { clearPointerTooltip } from '@/store/uiSlice';
import { POINTER_TOOLTIP_TYPE } from '@/enums/pointerTooltipType';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import styles from './PointerTooltip.module.scss';
import { useMeasure } from 'react-use';

const tooltipTimeout = 3000;

const PointerTooltip = () => {
  const pointerTooltipData = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.pointerTooltip
  );
  const isBettingTime = useSelector(selectIsBettingTime);
  const timeoutId = useRef(null);
  const previousTooltipId = useRef(null);
  const [tooltipRef, { width: tooltipWidth, height: tooltipHeight }] = useMeasure();
  const dispatch = useDispatch();
  const overflowMargin = 16;

  const hideTooltip = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
      if (previousTooltipId.current === pointerTooltipData.id) dispatch(clearPointerTooltip());
    }
  }, [dispatch, pointerTooltipData?.id]);

  useEffect(() => {
    if (!pointerTooltipData?.type) return;
    const isNewGameSoon = pointerTooltipData.type === POINTER_TOOLTIP_TYPE.NEW_GAME_SOON;
    const isBetLimitOrLowBalance = [
      POINTER_TOOLTIP_TYPE.BET_LIMIT_REACHED,
      POINTER_TOOLTIP_TYPE.LOW_BALANCE,
      POINTER_TOOLTIP_TYPE.TABLE_LIMIT_REACHED,
      POINTER_TOOLTIP_TYPE.OPPOSITE_BET,
    ].includes(pointerTooltipData.type);

    if (isNewGameSoon && isBettingTime) {
      hideTooltip();
    }

    if (isBetLimitOrLowBalance && !isBettingTime) {
      hideTooltip();
    }
  }, [hideTooltip, isBettingTime, pointerTooltipData?.type]);

  useEffect(() => {
    if (pointerTooltipData) {
      if (previousTooltipId.current !== pointerTooltipData.id) {
        hideTooltip();
      }

      timeoutId.current = setTimeout(() => {
        hideTooltip();
      }, tooltipTimeout);

      previousTooltipId.current = pointerTooltipData.id;
    } else {
      hideTooltip();
      previousTooltipId.current = null;
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [pointerTooltipData, hideTooltip]);

  useEffect(() => {
    const handleClickOnUI = () => {
      if (pointerTooltipData) {
        hideTooltip();
      }
    };

    document.addEventListener('mousedown', handleClickOnUI);
    return () => {
      document.removeEventListener('mousedown', handleClickOnUI);
    };
  }, [hideTooltip, pointerTooltipData]);

  const { x, y, message } = pointerTooltipData || { x: 0, y: 0, message: '' };

  const adjustTooltipPosition = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    let adjustedX = x;
    let adjustedY = y;

    if (x - tooltipWidth / 2 < 0) {
      adjustedX = tooltipWidth / 2 + overflowMargin;
    }

    if (x + tooltipWidth / 2 > viewportWidth) {
      adjustedX = viewportWidth - tooltipWidth / 2 - overflowMargin;
    }

    return { top: adjustedY, left: adjustedX };
  };

  const { top, left } = tooltipHeight > 0 && tooltipWidth > 0 ? adjustTooltipPosition() : { top: -9999, left: -9999 };

  return (
    <div
      ref={tooltipRef}
      className={cl(styles.pointerTooltip, pointerTooltipData && styles.open)}
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      {message}
    </div>
  );
};

export default PointerTooltip;
