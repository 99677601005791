import styles from './RoundSelector.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import Slider from '../Slider';

const RoundSelector = ({ rounds: defaultRounds, onChanged, min, max, step }) => {
  const { t } = useTranslation('roulette');
  const [rounds, setRounds] = useState(defaultRounds);
  const [debouncedRounds] = useDebounce(rounds, 500);

  useEffect(() => {
    onChanged(debouncedRounds);
  }, [debouncedRounds, onChanged]);

  return (
    <div className={styles.container}>
      <div className={styles.lastRoundLabel}>{t('statistics.lastRounds', { count: rounds })}</div>
      <div className={styles.sliderContainer}>
        <Slider
          min={min}
          max={max}
          step={step}
          defaultValue={rounds}
          value={rounds}
          onChange={(value) => setRounds(value)}
        />
        <div className={styles.sliderValue}>{rounds}</div>
      </div>
    </div>
  );
};

export default RoundSelector;
