import { clearTable } from '@/store/gameSlice';
import { setIsPageFrozen, setIsPageVisible } from '@/store/uiSlice';
import { Logger } from '@vpmedia/simplify';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const logger = new Logger('PageLifeCycleManager');

const PageLifeCycleManager = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleVisibilityChange = () => {
      const visibilityState = document.visibilityState;
      logger.info('handleVisibilityChange', { visibilityState });

      if (document.hidden) {
        dispatch(clearTable());
      }
      dispatch(setIsPageVisible(document.visibilityState === 'visible'));
    };

    const handleFreeze = () => {
      logger.info('handleFreeze');
      dispatch(setIsPageFrozen(true));
    };

    const handleResume = () => {
      logger.info('handleResume');
      dispatch(setIsPageFrozen(true));
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    document.addEventListener('freeze', handleFreeze);
    document.addEventListener('resume', handleResume);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      document.removeEventListener('freeze', handleFreeze);
      document.removeEventListener('resume', handleResume);
    };
  }, [dispatch]);

  return null;
};

export default PageLifeCycleManager;
