import styles from './BetSaveButton.module.scss';
import { BiCheck } from 'react-icons/bi';

const BetSaveButton = () => {
  return (
    <div className={styles.checkIcon}>
      <BiCheck />
    </div>
  );
};

export default BetSaveButton;
