import { useSelector } from 'react-redux';
import styles from './BetLimits.module.scss';

const BetLimits = () => {
  const gameName = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameName);
  return (
    <div className={styles.container}>
      <div className={styles.name}>{gameName}</div>
      <div className={styles.limits}>€ 0,50 - 4.000</div>
    </div>
  );
};

export default BetLimits;
