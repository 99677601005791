import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useBalance = () => {
  const [totalBet, setTotalBet] = useState(0);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);

  const hasEnoughBalance = useCallback(
    (bet) => {
      if (balance === 0) return false;

      let sumBetValue;
      if (Array.isArray(bet)) {
        sumBetValue = bet.reduce(
          (previousValue, { multiplier }) => previousValue + selectedChip * (multiplier ?? 1),
          0
        );
      } else {
        const { multiplier } = bet;
        sumBetValue = selectedChip * (multiplier ?? 1);
      }

      return balance - sumBetValue >= 0;
    },
    [balance, selectedChip]
  );

  useEffect(() => {
    let sumValue = 0;
    for (const key of Object.keys(bets)) {
      sumValue += bets[key].value;
    }
    setTotalBet(sumValue);
  }, [bets]);

  return { totalBet, hasEnoughBalance };
};

export default useBalance;
