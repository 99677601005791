import { BET_TYPE, SPECIAL_BET_TYPE } from '@/enums/layoutTable';
import { getColumnByNumber } from '@/lib/rouletteService';

/**
 * TBD.
 * @param {string} betType - TBD.
 * @param {number} number - TBD.
 * @returns {{betType: string, numbers: number[], multiplier?: number}[]} TBD.
 */
export const getSpecialBets = (betType, number) => {
  switch (betType) {
    case SPECIAL_BET_TYPE.FINALE_EN_PLEIN: {
      return Array.from({ length: 4 })
        .fill(number)
        .map((item, index) => ({
          betType: BET_TYPE.STRAIGHT,
          numbers: [item + index * 10],
        }))
        .filter((item) => {
          return item.numbers.filter((number) => number > 36)?.length === 0;
        });
    }
    case SPECIAL_BET_TYPE.FINALES_A_CHEVAL: {
      return Array.from({ length: 4 })
        .fill(number)
        .map((bet, index) => {
          const baseNumber = bet + index * 10;
          return {
            betType: BET_TYPE.SPLIT,
            numbers: [baseNumber, baseNumber + 3],
          };
        })
        .filter((bet) => {
          return bet.numbers.filter((number) => number > 36)?.length < 2;
        })
        .map((bet) => {
          const filteredNumbers = bet.numbers.filter((number) => number < 37);
          return {
            betType: filteredNumbers.length === 1 ? BET_TYPE.STRAIGHT : bet.betType,
            numbers: filteredNumbers.length === 1 ? filteredNumbers : bet.numbers,
          };
        });
    }
    case SPECIAL_BET_TYPE.COMPLETE_BET: {
      let bets = [
        {
          betType: BET_TYPE.STRAIGHT,
          numbers: [number],
        },
      ];
      if (number > 0) {
        const row = number % 3 === 0 ? 1 : number % 3 === 2 ? 2 : 3;

        bets.push({
          betType: BET_TYPE.SPLIT,
          numbers: [number, number - 3 < 0 ? 0 : number - 3],
          multiplier: 2,
        });

        if (number < 34) {
          bets.push({
            betType: BET_TYPE.SPLIT,
            numbers: [number, number + 3],
            multiplier: 2,
          });
        }

        const column = getColumnByNumber(number);
        bets.push({
          betType: BET_TYPE.STREET,
          numbers: Array.from({ length: 3 })
            .fill()
            .map((_, index) => column * 3 - index),
          multiplier: 3,
        });
        if (number > 3) {
          bets.push({
            betType: BET_TYPE.SIX_LINE,
            numbers: [
              ...Array.from({ length: 3 })
                .fill()
                .map((_, index) => (column - 1) * 3 - index),
              ...Array.from({ length: 3 })
                .fill()
                .map((_, index) => column * 3 - index),
            ],
            multiplier: 6,
          });
        } else {
          if (row === 1 || row === 2) {
            bets.push({
              betType: BET_TYPE.STREET,
              numbers: [number, number - 1, 0],
              multiplier: 3,
            });
          }

          if (row === 2 || row === 3) {
            bets.push({
              betType: BET_TYPE.STREET,
              numbers: [number, number + 1, 0],
              multiplier: 3,
            });
          }

          bets.push({
            betType: BET_TYPE.CORNER,
            numbers: [3, 2, 1, 0],
            multiplier: 4,
          });
        }

        if (number < 34) {
          bets.push({
            betType: BET_TYPE.SIX_LINE,
            numbers: [
              ...Array.from({ length: 3 })
                .fill()
                .map((_, index) => column * 3 - index),
              ...Array.from({ length: 3 })
                .fill()
                .map((_, index) => (column + 1) * 3 - index),
            ],
            multiplier: 6,
          });
        }

        if (row === 2 || row === 1) {
          bets.push({
            betType: BET_TYPE.SPLIT,
            numbers: [number, number - 1],
            multiplier: 2,
          });

          if (number < 34) {
            bets.push({
              betType: BET_TYPE.CORNER,
              numbers: [number - 1, number, number + 2, number + 3],
              multiplier: 4,
            });
          }

          if (number > 3) {
            bets.push({
              betType: BET_TYPE.CORNER,
              numbers: [number - 3, number - 4, number, number - 1],
              multiplier: 4,
            });
          }
        }
        if (row === 2 || row === 3) {
          bets.push({
            betType: BET_TYPE.SPLIT,
            numbers: [number, number + 1],
            multiplier: 2,
          });

          if (number < 34) {
            bets.push({
              betType: BET_TYPE.CORNER,
              numbers: [number, number + 1, number + 3, number + 4],
              multiplier: 4,
            });
          }
          if (number > 2)
            bets.push({
              betType: BET_TYPE.CORNER,
              numbers: [number - 3, number - 2, number, number + 1],
              multiplier: 4,
            });
        }
      } else {
        for (let index = 1; index <= 3; index++) {
          bets.push({
            betType: BET_TYPE.SPLIT,
            numbers: [0, index],
            multiplier: 2,
          });
        }

        for (let index = 1; index <= 2; index++) {
          bets.push({
            betType: BET_TYPE.STREET,
            numbers: [0, index, index + 1],
            multiplier: 3,
          });
        }

        bets.push({
          betType: BET_TYPE.CORNER,
          numbers: [3, 2, 1, 0],
          multiplier: 4,
        });
      }

      return bets;
    }
  }
};
