import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useOrientation from '@/hooks/useOrientation';
import cl from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoaderDot from '../LoaderDot';
import styles from './NoConnection.module.scss';

const NoConnection = () => {
  const { t } = useTranslation('common');
  const isSocketConnected = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isSocketConnected
  );
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const orientation = useOrientation();

  if (isSocketConnected) return null;

  return (
    <div className={styles.container}>
      <div
        className={cl(
          styles.content,
          isTouchDevice && styles.isTouchDevice,
          orientation === SCREEN_ORIENTATION.LANDSCAPE && styles.landscape
        )}
      >
        <LoaderDot />
        <div className="message">{t('waitingForConnection')}</div>
      </div>
    </div>
  );
};

export default NoConnection;
