import { useDispatch, useSelector } from 'react-redux';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { setRacetrackNeighborCount } from '@/store/gameSlice';
import styles from './NeighborCounter.module.scss';
import { useCallback, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipContext } from '@/context/TooltipContext';
import clsx from 'clsx';
import { playPressSound } from '@/lib/soundService';
import { VIEW_TYPE } from '@/enums/ui';

const NeighborCounter = ({ orientation = 'vertical' }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const { t } = useTranslation('tooltip');
  const racetrackNeighborCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.racetrackNeighborCount
  );
  const dispatch = useDispatch();
  const increaseRef = useRef();
  const decreaseRef = useRef();

  const increaseNeighborCount = useCallback(() => {
    playPressSound();
    dispatch(setRacetrackNeighborCount(racetrackNeighborCount + 1));
    hideTooltip();
  }, [dispatch, hideTooltip, racetrackNeighborCount]);

  const decreaseNeighborCount = useCallback(() => {
    playPressSound();
    dispatch(setRacetrackNeighborCount(racetrackNeighborCount - 1));
    hideTooltip();
  }, [dispatch, hideTooltip, racetrackNeighborCount]);

  const isDecreaseDisabled = useCallback(() => racetrackNeighborCount === 0, [racetrackNeighborCount]);

  const isIncreaseDisabled = useCallback(() => racetrackNeighborCount === 9, [racetrackNeighborCount]);

  const handleMouseEnter = useCallback(
    (element) => {
      showTooltip(element, t('neighborCounter'));
    },
    [showTooltip, t]
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  return (
    <div className={clsx(styles.container, styles[orientation], isTouchDevice && styles.mobile)}>
      <button
        className={styles.button}
        ref={increaseRef}
        onMouseEnter={() => handleMouseEnter(increaseRef.current)}
        onMouseLeave={handleMouseLeave}
        onClick={increaseNeighborCount}
        style={{ visibility: isIncreaseDisabled() ? 'hidden' : 'visible' }}
      >
        <FaPlus className={styles.icon} size="0.6em" />
      </button>
      <div className={styles.value}>{racetrackNeighborCount}</div>
      <button
        className={styles.button}
        ref={decreaseRef}
        onMouseEnter={() => handleMouseEnter(decreaseRef.current)}
        onMouseLeave={handleMouseLeave}
        onClick={decreaseNeighborCount}
        style={{ visibility: isDecreaseDisabled() ? 'hidden' : 'visible' }}
      >
        <FaMinus className={styles.icon} size="0.6em" />
      </button>
    </div>
  );
};

export default NeighborCounter;
