import { BET_TYPE } from '@/enums/layoutTable';
import { createBetKey, getNumbersByOutsideBet } from './rouletteService';
import { ORIENTATION } from '@/enums/ui';

/**
 * TBD.
 * @param {string} orientation - TBD.
 * @param {number} width - TBD.
 * @param {number} height - TBD.
 * @returns {string} TBD.
 */
export const getZeroPath = (orientation, width, height) => {
  const halfHeight = height / 2;
  return orientation === ORIENTATION.HORIZONTAL
    ? `M 0 ${halfHeight} L ${width * 0.3} 0 H ${width} V ${height} H ${width * 0.3} L 0 ${halfHeight}`
    : `M 0 0 V ${height * 0.3} L ${width / 2} 0 L ${width} ${height * 0.3} V ${height} H 0`;
};

/**
 * TBD.
 * @param {number} width - TBD.
 * @param {number} height - TBD.
 * @returns {string} TBD.
 */
export const getRectanglePath = (width, height) => {
  return `M 0 0 H ${width} V ${height} H 0 Z`;
};

export const createSplitSensor = (
  tableOrientation,
  sensorThickness,
  tableWidth,
  column,
  row,
  itemWidth,
  itemHeight,
  orientation = 'horizontal'
) => {
  const isHorizontalTable = tableOrientation === 'horizontal';
  const isHorizontalOrientation = orientation === 'horizontal';

  const x = isHorizontalTable
    ? isHorizontalOrientation
      ? column * itemWidth
      : column * itemWidth - sensorThickness / 2
    : isHorizontalOrientation
      ? tableWidth - column * itemWidth
      : tableWidth - (itemWidth + sensorThickness / 2) - (column - 1) * itemWidth;

  const y = isHorizontalTable
    ? isHorizontalOrientation
      ? row * itemHeight - sensorThickness / 2
      : (row - 1) * itemHeight
    : isHorizontalOrientation
      ? row * itemHeight - sensorThickness / 2
      : (row - 1) * itemHeight + itemHeight;

  const columnBase = isHorizontalTable ? row : column;
  const columnSet =
    columnBase === 1 ? BET_TYPE.RIGHT_COLUMN : columnBase === 2 ? BET_TYPE.MIDDLE_COLUMN : BET_TYPE.LEFT_COLUMN;

  const columnNumbers = getNumbersByOutsideBet(columnSet);

  let numbers;
  if (tableOrientation === 'vertical') {
    const baseNumber = columnNumbers[row - 1];
    numbers = isHorizontalOrientation ? [baseNumber, row === 1 ? 0 : baseNumber - 3] : [baseNumber, baseNumber - 1];
  } else {
    if (column !== 1 || isHorizontalOrientation) {
      const baseNumber = columnNumbers[column - (isHorizontalOrientation ? 1 : 2)];
      numbers = [baseNumber, baseNumber + (isHorizontalOrientation ? -1 : 3)];
    } else {
      numbers = [0, 4 - row];
    }
  }

  const betKey = createBetKey(BET_TYPE.SPLIT, numbers);
  const sensorWidth = isHorizontalOrientation ? itemWidth : sensorThickness;
  const sensorHeight = isHorizontalOrientation ? sensorThickness : itemHeight;

  return {
    path: getRectanglePath(sensorWidth, sensorHeight),
    betType: BET_TYPE.SPLIT,
    betKey,
    itemWidth: sensorWidth,
    itemHeight: sensorHeight,
    x,
    y,
    color: 'sensor',
  };
};

export const createStreetSensor = (
  tableOrientation,
  sensorThickness,
  tableWidth,
  column,
  row,
  itemWidth,
  itemHeight
) => {
  const isHorizontalTable = tableOrientation === 'horizontal';

  const x = isHorizontalTable
    ? column * itemWidth
    : tableWidth - (itemWidth + sensorThickness / 2) - (row - 1) * itemWidth;

  const y = isHorizontalTable ? row * itemHeight - sensorThickness / 2 : (column - 1) * itemHeight + itemHeight;

  const baseNumber = getNumbersByOutsideBet(BET_TYPE.RIGHT_COLUMN)[column - 1];
  const numbers = [baseNumber, baseNumber - 1, baseNumber - 2];
  const betKey = createBetKey(BET_TYPE.STREET, numbers);

  const sensorWidth = isHorizontalTable ? itemWidth : sensorThickness;
  const sensorHeight = isHorizontalTable ? sensorThickness : itemHeight;

  const sensor = {
    path: getRectanglePath(sensorWidth, sensorHeight),
    betType: BET_TYPE.STREET,
    betKey,
    itemWidth: sensorWidth,
    itemHeight: sensorHeight,
    x,
    y,
    color: 'sensor',
  };

  return sensor;
};

export const createSixLineSensor = (
  tableOrientation,
  sensorThickness,
  tableWidth,
  column,
  row,
  itemWidth,
  itemHeight
) => {
  const isHorizontalTable = tableOrientation === 'horizontal';

  const x = isHorizontalTable
    ? column * itemWidth - sensorThickness
    : tableWidth - column * itemWidth - sensorThickness;

  const y = row * itemHeight - sensorThickness;

  const columnBase = isHorizontalTable ? column : row;
  const columnNumbers = getNumbersByOutsideBet(BET_TYPE.RIGHT_COLUMN);

  let numbers;
  if (columnBase === 1) {
    numbers = [0, 1, 2, 3];
  } else {
    const baseNumber = columnNumbers[columnBase - 2];
    numbers = [baseNumber, baseNumber - 1, baseNumber - 2, baseNumber + 3, baseNumber + 2, baseNumber + 1];
  }

  const isCornerBet = columnBase === 1;
  const betKey = createBetKey(isCornerBet ? BET_TYPE.CORNER : BET_TYPE.SIX_LINE, numbers);
  const sensorWidth = 2 * sensorThickness;
  const sensorHeight = 2 * sensorThickness;

  const sensor = {
    path: getRectanglePath(sensorWidth, sensorHeight),
    betType: isCornerBet ? BET_TYPE.CORNER : BET_TYPE.SIX_LINE,
    betKey,
    itemWidth: sensorWidth,
    itemHeight: sensorHeight,
    x,
    y,
    color: 'sensor',
  };

  return sensor;
};

export const createCornerSensor = (
  tableOrientation,
  sensorThickness,
  tableWidth,
  column,
  row,
  itemWidth,
  itemHeight
) => {
  const isHorizontalTable = tableOrientation === 'horizontal';

  const x = isHorizontalTable
    ? column * itemWidth - sensorThickness
    : tableWidth - column * itemWidth - sensorThickness;

  const y = row * itemHeight - sensorThickness;

  const columnBase = isHorizontalTable ? column : row;
  const rowBase = isHorizontalTable ? row : column;

  const columnSet = rowBase === 1 ? BET_TYPE.RIGHT_COLUMN : BET_TYPE.MIDDLE_COLUMN;
  const columnNumbers = getNumbersByOutsideBet(columnSet);

  let numbers;
  if (columnBase === 1) {
    numbers = [0, 4 - rowBase, 3 - rowBase];
  } else {
    const baseNumber = columnNumbers[columnBase - 1];
    numbers = [baseNumber, baseNumber - 1, baseNumber - 3, baseNumber - 4];
  }

  const isStreetBet = columnBase === 1;
  const betKey = createBetKey(isStreetBet ? BET_TYPE.STREET : BET_TYPE.CORNER, numbers);

  const sensorWidth = 2 * sensorThickness;
  const sensorHeight = 2 * sensorThickness;

  const sensor = {
    path: getRectanglePath(sensorWidth, sensorHeight),
    betType: isStreetBet ? BET_TYPE.STREET : BET_TYPE.CORNER,
    numbers,
    betKey,
    itemWidth: sensorWidth,
    itemHeight: sensorHeight,
    x,
    y,
    color: 'sensor',
  };

  return sensor;
};
