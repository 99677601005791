import { getCircleXY } from '@/lib/mathService';
import { describeArc } from '@/lib/svgService';
import { useCallback, useMemo } from 'react';

const RacetrackVerticalBorder = ({ width, height }) => {
  const radius = useMemo(() => width / 2, [width]);
  const itemWidth = useMemo(() => width / 4, [width]);
  const itemHeight = useMemo(() => (height - 2 * radius) / 13, [height, radius]);
  const radiusInner = useMemo(() => radius - itemWidth, [radius, itemWidth]);
  const straightItemsHeight = useMemo(() => height - 2 * radius, [height, radius]);

  const describeStraightItemsBorder = useCallback(
    (startX, startY, itemWidth) => {
      return Array.from({ length: 14 }, (_, i) => `M ${startX} ${startY + i * itemHeight} h ${itemWidth}`).join(' ');
    },
    [itemHeight]
  );

  const describeNamedBetsBorder = useCallback(() => {
    return [
      `M ${width - itemWidth} ${radius + 2 * itemHeight} L ${itemWidth} ${radius + 4 * itemHeight}`,
      `M ${itemWidth} ${radius + 7 * itemHeight} h ${width - 2 * itemWidth}`,
      describeArc(2 * itemWidth, height - radius - itemHeight, radiusInner, -90, 90),
    ].join(' ');
  }, [width, itemWidth, radius, itemHeight, height, radiusInner]);

  const getCircleCoordinates = useCallback((cx, cy, radius, steps, startAngle) => {
    const step = 180 / steps;
    return Array.from({ length: steps }, (_, i) => getCircleXY(cx, cy, radius, startAngle + (i + 1) * step)).slice(
      0,
      -1
    );
  }, []);

  const describeBend = useCallback(
    (cx, cy, radius, radiusInner, steps, startAngle) => {
      const outerCircleCoordinates = getCircleCoordinates(cx, cy, radius, steps, startAngle);
      const innerCircleCoordinates = getCircleCoordinates(cx, cy, radiusInner, steps, startAngle);

      return outerCircleCoordinates
        .map(({ x: outerX, y: outerY }, i) => {
          const { x: innerX, y: innerY } = innerCircleCoordinates[i];
          return `M ${innerX} ${innerY} L ${outerX} ${outerY}`;
        })
        .join(' ');
    },
    [getCircleCoordinates]
  );

  const describeTopBend = useCallback(() => {
    return describeBend(width / 2, radius, radius, radiusInner, 6, 270);
  }, [width, radius, radiusInner, describeBend]);

  const describeBottomBend = useCallback(() => {
    return describeBend(width / 2, height - radius, radius, radiusInner, 5, 90);
  }, [width, height, radius, radiusInner, describeBend]);

  return (
    <>
      <path
        d={`
        ${describeArc(radius, radius, radius, 270, 90)}
        M 0 ${radius} v ${straightItemsHeight}
        M ${width} ${radius} v ${straightItemsHeight}
        ${describeArc(radius, height - radius, radius, 90, 270)}
        ${describeArc(radius, radius, radiusInner, 270, 90)}
        M ${itemWidth} ${radius} v ${straightItemsHeight}
        M ${width - itemWidth} ${radius} v ${straightItemsHeight}
        ${describeArc(radius, height - radius, radiusInner, 90, 270)}
        ${describeStraightItemsBorder(0, radius, itemWidth)}
        ${describeStraightItemsBorder(width - itemWidth, radius, itemWidth)}
        ${describeNamedBetsBorder()}
        ${describeTopBend()}
        ${describeBottomBend()}
      `}
        style={{
          pointerEvents: 'none',
          strokeWidth: 2,
          fill: 'none',
          stroke: '#f9e1cc',
        }}
      />
    </>
  );
};

export default RacetrackVerticalBorder;
