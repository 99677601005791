import { useState, useEffect } from 'react';

/**
 * Custom React hook that returns the current orientation of the device.
 * @returns {string} The current orientation ('portrait' or 'landscape').
 */
const useOrientation = () => {
  const [orientation, setOrientation] = useState('');

  useEffect(() => {
    /**
     * Updates the orientation based on the window match media.
     */
    function updateOrientation() {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientation('portrait');
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        setOrientation('landscape');
      }
    }

    window.addEventListener('resize', updateOrientation);
    updateOrientation(); // Detect initial orientation

    return () => {
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);

  return orientation;
};

export default useOrientation;
