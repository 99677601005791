import Racetrack from '@/components/common/Racetrack';
import NeighborCounter from '@/components/NeighborCounter';
import { ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import clsx from 'clsx';
import styles from './RacetrackWithSelector.module.scss';
import { useMeasure } from 'react-use';
import { roundNumber } from '@/lib/numberService';
import { useSelector } from 'react-redux';

const RacetrackWithSelector = ({
  orientation = ORIENTATION.HORIZONTAL,
  withFieldOpacity = false,
  backgroundFilled = false,
  displayChipsAndWinningNumber = false,
}) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const ASPECT_RATIO = orientation === ORIENTATION.HORIZONTAL ? 25 / 7 : 1 / 3;
  const GAP_SIZE = 8;
  const [racetrackContainerRef, { width: containerWidth, height: containerHeight }] = useMeasure();
  const [neighborCounterContainerRef, { width: neighborCounterWidth, height: neighborCounterHeight }] = useMeasure();

  let adjustedRacetrackWidth = roundNumber(containerWidth);
  let adjustedRacetrackHeight = roundNumber(containerHeight);

  if (adjustedRacetrackHeight !== 0 && adjustedRacetrackWidth !== 0) {
    if (containerWidth / ASPECT_RATIO > containerHeight) {
      adjustedRacetrackWidth = roundNumber(containerHeight * ASPECT_RATIO);
    } else {
      adjustedRacetrackHeight = roundNumber(containerWidth / ASPECT_RATIO);
    }

    if (orientation === ORIENTATION.VERTICAL || (!isTouchDevice && orientation === ORIENTATION.HORIZONTAL)) {
      adjustedRacetrackWidth = roundNumber(adjustedRacetrackWidth - neighborCounterWidth - GAP_SIZE);
    } else {
      adjustedRacetrackHeight = roundNumber(adjustedRacetrackHeight - neighborCounterHeight - GAP_SIZE);
    }
  }

  const fontSize = `${Math.min(adjustedRacetrackWidth, adjustedRacetrackHeight) * 0.07}px`;

  return (
    <div
      ref={racetrackContainerRef}
      className={clsx(
        styles.container,
        orientation === ORIENTATION.VERTICAL && styles.vertical,
        isTouchDevice && styles.isTouchDevice
      )}
      style={{
        fontSize,
      }}
    >
      <div style={{ width: adjustedRacetrackWidth, height: adjustedRacetrackHeight }}>
        <Racetrack
          withFieldOpacity={withFieldOpacity}
          backgroundFilled={backgroundFilled}
          orientation={orientation}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          parentWidth={adjustedRacetrackWidth}
          parentHeight={adjustedRacetrackHeight}
        />
      </div>
      <div
        ref={neighborCounterContainerRef}
        className={styles.selectorContainer}
        style={{
          marginBlockEnd: isTouchDevice && orientation === ORIENTATION.HORIZONTAL ? GAP_SIZE : 0,
          marginInlineEnd: isTouchDevice && orientation === ORIENTATION.VERTICAL ? GAP_SIZE : 0,
          marginInlineStart: !isTouchDevice && orientation === ORIENTATION.HORIZONTAL ? GAP_SIZE : 0,
        }}
      >
        <NeighborCounter orientation={orientation} />
      </div>
    </div>
  );
};

export default RacetrackWithSelector;
