import { BET_TYPE } from '@/enums/layoutTable';
import ROUND_STATE from '@/enums/roundState';
import { TooltipContext } from '@/context/TooltipContext';
import { createBetKey } from '@/lib/rouletteService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './HistoryLine.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { VIEW_TYPE } from '@/enums/ui';

const HistoryLine = ({ className, orientation = 'horizontal', withBoxShadow = false }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const { t } = useTranslation('tooltip');
  const [highlightedNumber, setHighlightedNumber] = useState(null);
  const winningNumberHistory = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumberHistory
  );
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const dispatch = useDispatch();
  const numberRefs = useRef([]);
  const isBettingTime = useSelector(selectIsBettingTime);

  const numberToDisplay = winningNumberHistory.slice(0, 10);

  const isDisabled = useCallback(() => {
    return !isBettingTime || !selectedChip;
  }, [isBettingTime, selectedChip]);

  const handleOnClicked = useCallback(
    (event, number) => {
      dispatch(addBetThunk([{ betType: createBetKey(BET_TYPE.STRAIGHT, number), bet: selectedChip }], event));
    },
    [dispatch, selectedChip]
  );

  const handleOnMouseEnter = useCallback(
    (number, itemRef) => {
      if (isTouchDevice) return;
      setHighlightedNumber(number);
      if (isDisabled()) return;
      dispatch(setHighlightedNumbers([number]));
      dispatch(
        setBetsPreview([
          {
            betType: BET_TYPE.STRAIGHT,
            numbers: [number],
            value: selectedChip,
          },
        ])
      );
      showTooltip(
        itemRef,
        t('historyLine', {
          number,
        })
      );
    },

    [dispatch, isDisabled, isTouchDevice, selectedChip, showTooltip, t]
  );

  const handleOnMouseLeave = useCallback(() => {
    setHighlightedNumber(null);
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  return (
    <div
      className={clsx(
        styles.container,
        styles[orientation],
        isTouchDevice && styles.isTouchDevice,
        roundState === ROUND_STATE.WINNING_NUMBER && styles.pulseWinningNumber,
        isDisabled() && styles.disabled,
        className
      )}
    >
      {numberToDisplay.map((number, index) => (
        <div
          key={`history-item-${index}`}
          ref={(el) => (numberRefs.current[index] = el)}
          className={clsx(
            styles.item,
            styles[numbers[number].color],
            index === 0 ? styles.lastWinningNumber : '',
            !isBettingTime && styles.disabled,
            withBoxShadow && styles.withBoxShadow,
            highlightedNumber === number && styles.highlighted
          )}
          onMouseEnter={() => handleOnMouseEnter(number, numberRefs.current[index])}
          onMouseLeave={handleOnMouseLeave}
          onClick={(event) => handleOnClicked(event, number)}
        >
          <div className={styles.number}>{number}</div>
        </div>
      ))}
    </div>
  );
};

export default HistoryLine;
