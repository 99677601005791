import socketApi from '@/lib/socketApi';
import { Logger } from '@vpmedia/simplify';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '../common/Tabs';
import GeneralSettingsView from './GeneralSettingsView';
import styles from './SettingsView.module.scss';
import SoundSettingsView from './SoundSettingsView';
import VideoSettingsView from './VideoSettingsView';

const logger = new Logger('SettingsView');

const SettingsView = ({ tabIndex = 0 }) => {
  const { t } = useTranslation('common');
  const settings = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings);
  const dispatch = useDispatch();
  const isInitialized = useRef(false);

  const tabs = [
    {
      name: 'settingsGeneralTab',
      label: t('settings.general'),
      content: <GeneralSettingsView />,
    },
    {
      name: 'settingsVideoTab',
      label: t('settings.video'),
      content: <VideoSettingsView />,
    },
    {
      name: 'settingsSoundTab',
      label: t('settings.sound'),
      content: <SoundSettingsView />,
    },
  ];

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      return;
    }
    socketApi.savePlayerSettings(settings);
  }, [dispatch, settings]);

  return (
    <div className={styles.container}>
      <Tabs tabs={tabs} defaultActiveIndex={tabIndex} />
    </div>
  );
};

export default SettingsView;
