import { useSelector } from 'react-redux';
import styles from './GameMessage.module.scss';
import ROUND_STATE from '@/enums/roundState';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const GameMessage = () => {
  const { t } = useTranslation('common');
  const [label, setLabel] = useState();
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const displayTimeoutId = useRef();
  const hideTimeoutId = useRef();
  const timeout = 4000;

  const roundStateMessages = useMemo(() => {
    return {
      [ROUND_STATE.PLACE_BETS]: t('status.placeYourBet'),
      [ROUND_STATE.FINAL_BETS]: t('status.finalBets'),
      [ROUND_STATE.NO_MORE_BETS]: t('nextGameStartsSoon'),
    };
  }, [t]);

  const clearTimeouts = useCallback(() => {
    if (displayTimeoutId.current) {
      clearTimeout(displayTimeoutId.current);
      displayTimeoutId.current = null;
    }
    if (hideTimeoutId.current) {
      clearTimeout(hideTimeoutId.current);
      hideTimeoutId.current = null;
    }
  }, []);

  useEffect(() => {
    return clearTimeouts;
  }, [clearTimeouts]);

  useEffect(() => {
    setLabel(null);
  }, [roundState]);

  useEffect(() => {
    switch (roundState) {
      case ROUND_STATE.PLACE_BETS:
        setLabel(roundStateMessages[roundState]);
        break;
      case ROUND_STATE.FINAL_BETS:
        setLabel(roundStateMessages[roundState]);
        break;
      case ROUND_STATE.NO_MORE_BETS:
        displayTimeoutId.current = setTimeout(() => {
          setLabel(roundStateMessages[roundState]);
          hideTimeoutId.current = setTimeout(() => {
            setLabel(null);
          }, timeout);
        }, timeout);
        break;
      case ROUND_STATE.WINNING_NUMBER:
        clearTimeouts();
        break;
    }
  }, [clearTimeouts, roundState, roundStateMessages, t]);

  if (!label) return null;

  return <div className={styles.container}>{label}</div>;
};

export default GameMessage;
