import enCommonTranslation from '@/assets/locales/en/common.json';
import enRouletteTranslation from '@/assets/locales/en/roulette.json';
import enTooltipTranslation from '@/assets/locales/en/tooltip.json';
import enWalletTranslation from '@/assets/locales/en/wallet.json';
import nlCommonTranslation from '@/assets/locales/nl/common.json';
import nlRouletteTranslation from '@/assets/locales/nl/roulette.json';
import nlTooltipTranslation from '@/assets/locales/nl/tooltip.json';
import nlWalletTranslation from '@/assets/locales/nl/wallet.json';
import { LANGUAGE } from '@/lib/paramService';
import { use as i18nextUse } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const initI18n = async () => {
  try {
    const resources = {
      en: {
        common: enCommonTranslation,
        roulette: enRouletteTranslation,
        tooltip: enTooltipTranslation,
        wallet: enWalletTranslation,
      },
      nl: {
        common: nlCommonTranslation,
        roulette: nlRouletteTranslation,
        tooltip: nlTooltipTranslation,
        wallet: nlWalletTranslation,
      },
    };

    await i18nextUse(initReactI18next).init({
      resources,
      lng: LANGUAGE,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
  } catch (error) {
    console.error('Error initializing i18n:', error);
  }
};
