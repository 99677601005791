import { setPointerTooltip } from '@/store/uiSlice';
import store from '@/store/index';

/**
 * Converts a pixel value to a rem value based on the root font size.
 * @param {number} pxValue - The pixel value to convert.
 * @returns {string} The converted value in rem.
 */
export const pxToRem = (pxValue) => {
  const rootFontSize = Number.parseFloat(getComputedStyle(document.documentElement).fontSize);
  return `${pxValue / rootFontSize}rem`;
};

/**
 * Gets the coordinates of a given event.
 * @param {object} event - The event containing the coordinates.
 * @returns {object} An object containing the x and y coordinates.
 */
export const getPointerCoordinates = (event) => {
  if (event.touches && event.touches.length > 0) {
    return { x: event.touches[0].clientX, y: event.touches[0].clientY };
  } else if (event.changedTouches && event.changedTouches.length > 0) {
    return { x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY };
  } else {
    return { x: event.clientX, y: event.clientY };
  }
};

/**
 * Calculates the relative coordinates of a given point on the roulette table.
 * @param {number} x - The x-coordinate of the point.
 * @param {number} y - The y-coordinate of the point.
 * @param {string} elementId - The id of the parent element.
 * @returns {object} An object containing the relative x and y coordinates of the point.
 */
export const getRelativeCoordinates = (x, y, elementId) => {
  const container = document.querySelector(`#${elementId}`);
  if (container) {
    const rect = container.getBoundingClientRect();
    // These scale values are needed to calculate the proper coordinates when the table is scaled down (touch view in portrait mode).
    const scaleX = rect.width / container.offsetWidth;
    const scaleY = rect.height / container.offsetHeight;
    return { x: (x - rect.left) / scaleX, y: (y - rect.top) / scaleY };
  }
  return { x, y };
};

/**
 * Shows a pointer tooltip at the specified event coordinates with the given message and type.
 * @param {object} event - The event containing the coordinates.
 * @param {string} message - The message to display in the tooltip.
 * @param {string} type - The type of the tooltip.
 */
export const showPointerTooltip = (event, message, type) => {
  const { x, y } = getPointerCoordinates(event);
  store.dispatch(
    setPointerTooltip({
      x,
      y,
      message,
      type,
    })
  );
};

/**
 * Calculates the bet type at the specified coordinates.
 * @param {number} x - The x-coordinate of the point.
 * @param {number} y - The y-coordinate of the point.
 * @returns {string|null} The bet type at the specified coordinates, or null if no bet type is found.
 */
export const getBetTypeByCoordinates = (x, y) => {
  /** @type {string|null} */
  let betType = null;
  const topElement = document.elementFromPoint(x, y);
  if (topElement && topElement.classList.contains('tableBetSpot')) {
    /** @type {HTMLElement} */
    const htmlElement = /** @type {HTMLElement} */ (topElement);
    betType = htmlElement.dataset.betKey;
  }

  return betType;
};
