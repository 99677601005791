import { useCallback } from 'react';
import Drawer from '../common/Drawer';
import cl from 'clsx';
import StatIcon from '@/assets/svg/statistics.svg?react';
import StatisticsView from '../StatisticsView';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeFavoritesPopup, closeStatisticsPopup, openFavoritesPopup, openStatisticsPopup } from '@/store/uiSlice';
import styles from './DesktopDrawer.module.scss';
import FavouriteAndSpecialBets from '../FavouriteAndSpecialBets';
import { playPressSound } from '@/lib/soundService';
import useShortcut from '@/hooks/useShortcut';

const DesktopDrawer = ({ className }) => {
  const { t } = useTranslation(['common']);
  const showStatisticsPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showStatisticsPopup
  );
  const showFavoritesPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showFavoritesPopup
  );
  const dispatch = useDispatch();

  const toggleStatistics = useCallback(() => {
    playPressSound();
    showStatisticsPopup ? dispatch(closeStatisticsPopup()) : dispatch(openStatisticsPopup());
  }, [dispatch, showStatisticsPopup]);

  const toggleFavorites = useCallback(() => {
    playPressSound();
    showFavoritesPopup ? dispatch(closeFavoritesPopup()) : dispatch(openFavoritesPopup());
  }, [dispatch, showFavoritesPopup]);

  const showAnimation = {
    opacity: 1,
    y: 0,
  };

  const hiddenAnimation = {
    opacity: 0,
    y: '20%',
  };

  const closeShortCut = [{ key: 'Escape' }];

  const handleShortcuts = useCallback(
    (event) => {
      if (showStatisticsPopup) {
        dispatch(closeStatisticsPopup());
      }

      if (showFavoritesPopup) {
        dispatch(closeFavoritesPopup());
      }
    },
    [dispatch, showFavoritesPopup, showStatisticsPopup]
  );

  useShortcut(closeShortCut, handleShortcuts);

  return (
    <>
      <Drawer
        isOpen={showStatisticsPopup}
        headerLabel={t('statistics')}
        headerIcon={<StatIcon width="1em" />}
        onClose={toggleStatistics}
        className={cl(styles.drawer, className)}
        showAnimation={showAnimation}
        hiddenAnimation={hiddenAnimation}
      >
        <StatisticsView />
      </Drawer>
      <Drawer
        isOpen={showFavoritesPopup}
        headerLabel={t('favoriteBets')}
        headerIcon={<FaStar size="1em" />}
        onClose={toggleFavorites}
        className={cl(styles.drawer, className)}
        showAnimation={showAnimation}
        hiddenAnimation={hiddenAnimation}
      >
        <FavouriteAndSpecialBets />
      </Drawer>
    </>
  );
};

export default DesktopDrawer;
