import PaperAirplaneIcon from '@/assets/svg/paper-airplane.svg?react';
import { setInputMessage } from '@/store/chatSlice';
import { addNewMessageThunk } from '@/store/thunks/chatThunks';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './MessageInput.module.scss';

const NewMessage = () => {
  const { t } = useTranslation('common');
  const inputMessage = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.chat.inputMessage);
  const dispatch = useDispatch();

  const sendMessage = useCallback(
    (event) => {
      event.preventDefault();
      if (!inputMessage || inputMessage.trim() === '') return;

      dispatch(addNewMessageThunk({ message: inputMessage }));
      dispatch(setInputMessage(''));
    },
    [dispatch, inputMessage]
  );

  const iconAnimations = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  const iconVisible = useCallback(() => inputMessage !== '', [inputMessage]);

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={sendMessage}>
        <input
          className={styles.input}
          value={inputMessage}
          placeholder={t('chat.placeholder')}
          onChange={(e) => {
            dispatch(setInputMessage(e.currentTarget.value));
          }}
        />
        <AnimatePresence>
          {iconVisible() && (
            <motion.div animate="visible" initial="hidden" exit="hidden" variants={iconAnimations}>
              <PaperAirplaneIcon onClick={sendMessage} className={styles.icon} height="1rem" />
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </div>
  );
};

export default NewMessage;
