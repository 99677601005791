import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import Sidebar from '../../Sidebar';
import styles from './MobileHorizontalView.module.scss';
import clsx from 'clsx';
import { useMemo } from 'react';
import RacetrackWithSelector from '../../RacetrackWithSelector';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import RouletteTable from '@/components/RouletteTable';
import { ORIENTATION, ROULETTE_TABLE_TYPE } from '@/enums/ui';

const MobileHorizontalView = () => {
  const { height: windowHeight } = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.sizes.window
  );
  const [ref, { width: playingAreaWidth, height: playingAreaHeight }] = useMeasure();
  const showMobileRacetrack = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showMobileRacetrack
  );
  const isBettingTime = useSelector(selectIsBettingTime);

  const closedPlayingAreaOffset = useMemo(() => {
    return (windowHeight - playingAreaHeight / 2) / 2;
  }, [playingAreaHeight, windowHeight]);

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.table, !isBettingTime && styles.closed)}
        style={{
          perspective: isBettingTime ? 'none' : '55rem',
          transform: isBettingTime ? 'translateY(0) scale(1)' : `translateY(10rem) scale(0.5)`,
        }}
      >
        <div ref={ref} className={styles.playingAreaWrapper}>
          {!showMobileRacetrack && playingAreaHeight > 0 && playingAreaWidth > 0 && (
            <RouletteTable
              type={ROULETTE_TABLE_TYPE.RESPONSIVE}
              orientation={ORIENTATION.HORIZONTAL}
              fieldOpacity={80}
            />
          )}
          {showMobileRacetrack && (
            <RacetrackWithSelector
              displayChipsAndWinningNumber={true}
              withFieldOpacity={true}
              backgroundFilled={true}
            />
          )}
        </div>
      </div>
      <Sidebar className={styles.sidebar} orientation="horizontal" />
    </div>
  );
};

export default MobileHorizontalView;
