import { motion, AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';
import styles from './TextInput.module.scss';

const TextInput = ({ value, onChange, placeholder, maxLength = 20, autoFocus = false }) => {
  const currentLength = value?.length ?? 0;
  const isLengthIndicatorVisible = useCallback(() => {
    return maxLength && currentLength >= Math.floor(maxLength / 2);
  }, [currentLength, maxLength]);

  const lengthIndicatorAnimations = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
      />
      <AnimatePresence>
        {isLengthIndicatorVisible() && (
          <motion.div
            animate="visible"
            initial="hidden"
            exit="hidden"
            variants={lengthIndicatorAnimations}
          >{`${currentLength}/${maxLength}`}</motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TextInput;
