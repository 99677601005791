import { forwardRef } from 'react';
import styles from './Button.module.scss';

const ButtonComponent = ({ text, className, children, onClick, ...rest }, ref) => {
  return (
    <button ref={ref} className={`${styles.button} ${className}`} onClick={onClick} {...rest}>
      {text}
      {children}
    </button>
  );
};

const Button = forwardRef(ButtonComponent);

export default Button;
