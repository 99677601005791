import cl from 'clsx';
import useMoney from '@/hooks/useMoney';
import { getNeighbors } from '@/lib/rouletteService';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './WinningNumber.module.scss';
import { VIEW_TYPE } from '@/enums/ui';
import { versionedUrl } from '@/lib/urlService';

const variants = {
  hidden: { opacity: 0, y: '1rem' },
  visible: { opacity: 1, y: '0rem' },
};

const WinningNumber = ({ number, showWin = false, ...rest }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { t } = useTranslation('roulette');
  const win = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.win);
  const { format } = useMoney();
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const numberOrder = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numberOrder);

  let leftNumber, rightNumber;
  if (number !== null) {
    const { left, right } = getNeighbors(numberOrder, number, 1);
    leftNumber = left[0];
    rightNumber = right[0];
  }

  return (
    <motion.div
      className={cl(styles.container, isTouchDevice && styles.touch)}
      animate="visible"
      initial="hidden"
      variants={variants}
    >
      {showWin && win > 0 && (
        <div className={styles.leftStars}>
          <img src={versionedUrl('./images/win_effect.webp')} alt="" />
        </div>
      )}
      <div className={styles.content}>
        <svg viewBox="0 0 283.36 106.26" height={'3em'} {...rest}>
          <defs>
            <linearGradient id="a" x1={84.79} y1={77.12} x2={365.21} y2={77.12} gradientUnits="userSpaceOnUse">
              <stop offset={0} stopOpacity={0} className={`${styles.stop} ${styles[numbers[leftNumber]?.color]}`} />
              <stop offset={0.52} stopOpacity={1} className={`${styles.stop} ${styles[numbers[leftNumber]?.color]}`} />
              <stop offset={0.55} stopOpacity={1} className={`${styles.stop} ${styles[numbers[rightNumber]?.color]}`} />
              <stop offset={1} stopOpacity={0} className={`${styles.stop} ${styles[numbers[rightNumber]?.color]}`} />
            </linearGradient>
            <linearGradient id="b" x1={83.32} y1={77.23} x2={366.68} y2={77.23} gradientUnits="userSpaceOnUse">
              <stop offset={0.05} stopColor="#fff" stopOpacity={0} />
              <stop offset={0.5} stopColor="#fff" stopOpacity={0.6} />
              <stop offset={0.95} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
          <path
            d="M365.21 45.26C326 34.61 284.35 28.53 228.68 28.53h-7.37c-55.66 0-97.36 6.08-136.52 16.73l21.07 80.44c34.91-7.54 78.31-11.76 119.14-11.77s84.23 4.23 119.14 11.77Z"
            transform="translate(-83.32 -20.87)"
            fill="url(#a)"
          />
          <path
            d="m345 127.13-1.13-.24C309.38 119.44 266 115.16 225 115.15s-84.38 4.29-118.88 11.74l-1.13.24-21.67-82.72 1.16-.31c42.64-11.6 84.84-16.77 136.84-16.77h7.37c52 0 94.2 5.17 136.84 16.77l1.16.31Zm-120-14.4c40.76 0 83.78 4.22 118.26 11.55l20.47-78.16c-42-11.32-83.74-16.38-135-16.38h-7.37c-51.31 0-93 5.05-135 16.38l20.47 78.16c34.39-7.34 77.41-11.54 118.17-11.55Z"
            transform="translate(-83.32 -20.87)"
            fill="url(#b)"
          />
          <text
            transform="rotate(9.17 -364.082 1484.981)"
            opacity={0.8}
            fontSize={52}
            fill="#fff"
            letterSpacing=".01em"
            textAnchor="middle"
          >
            {rightNumber}
          </text>
          <text
            transform="rotate(-6.86 692.374 -377.109)"
            opacity={0.8}
            fontSize={52}
            fill="#fff"
            letterSpacing=".01em"
            textAnchor="middle"
          >
            {leftNumber}
          </text>
          <path
            d="M200.95 3.6c-17.3-1-35.45-1.55-54.6-1.55h-4.37c-21.26 0-41.29.66-60.28 1.9l11.39 96c16.06-1 32.41-1.47 48.9-1.47h4.37c14.74 0 29.36.41 43.76 1.19Z"
            className={styles[numbers[number]?.color]}
          />
          <text transform="translate(141 72)" fontSize={58} fill="#fff" letterSpacing=".01em" textAnchor="middle">
            {number}
          </text>
          <path
            d="M92.9 102.13 81.43 2.05l2.14-.14C102.25.64 121.9 0 141.96 0H146.18c18.05 0 35.84.52 52.88 1.56l2.15.13-10.92 100.15-1.94-.11c-14.12-.79-28.31-1.19-42.17-1.19h-4.22c-15.62 0-31.47.5-47.12 1.47ZM85.99 5.84l10.54 92c15.1-.9 30.37-1.36 45.43-1.36h4.22c13.3 0 26.9.37 40.45 1.09l10-92c-16.26-.99-33.24-1.44-50.45-1.44H141.96c-19.2 0-38.02.56-55.97 1.72Z"
            fill="#fde464"
          />
        </svg>
        {showWin && win > 0 && (
          <div className={styles.winContainer}>
            <div className={styles.winLabel}>{t('winningNumber.youWin')}</div>
            <div className={styles.winValue}>{format(win)}</div>
          </div>
        )}
      </div>
      {showWin && win > 0 && (
        <div className={styles.rightStars}>
          <img src={versionedUrl('./images/win_effect.webp')} alt="" />
        </div>
      )}
    </motion.div>
  );
};

export default WinningNumber;
