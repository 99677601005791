import React from 'react';
import cl from 'clsx';
import { BET_TYPE } from '@/enums/layoutTable';
import { ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import { roundNumber } from '@/lib/numberService';
import styles from './BetSpot.module.scss';
import useHighlighted from '@/hooks/useHighlighted';
import { useSelector } from 'react-redux';

const BetSpot = ({
  content,
  path,
  color,
  x,
  y,
  itemWidth,
  itemHeight,
  betType,
  betKey,
  opacity = 100,
  textOrientation,
  onBetSpotEntered,
  onBetSpotLeaved,
}) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { isHighlighted } = useHighlighted();
  const isTextContent = ![
    BET_TYPE.RED,
    BET_TYPE.BLACK,
    BET_TYPE.SPLIT,
    BET_TYPE.SIX_LINE,
    BET_TYPE.CORNER,
    BET_TYPE.STREET,
  ].includes(betType);

  return (
    <svg
      data-bet-key={betKey}
      x={roundNumber(x, 2)}
      y={roundNumber(y, 2)}
      width={itemWidth}
      height={itemHeight}
      style={{ overflow: 'visible' }}
      className="betSpot"
    >
      <path
        d={path}
        data-bet-key={betKey}
        style={{ opacity: opacity / 100 }}
        className={cl(
          'tableBetSpot',
          styles.betSpot,
          styles[color],
          isHighlighted(betKey) && styles.isHighlighted,
          isTouchDevice && styles.isTouchDevice
        )}
        onMouseEnter={() => onBetSpotEntered(betKey)}
        onMouseLeave={() => onBetSpotLeaved()}
      />
      {isTextContent ? (
        <g
          className={cl(textOrientation === ORIENTATION.VERTICAL && styles.vertical)}
          style={{ pointerEvents: 'none' }}
        >
          <text
            dominantBaseline="central"
            textAnchor="middle"
            className={cl(styles.text, betType === BET_TYPE.STRAIGHT && styles.number)}
            x="50%"
            y="50%"
          >
            {content}
          </text>
        </g>
      ) : (
        content
      )}
    </svg>
  );
};

const MemoizedBetSpot = React.memo(BetSpot);

export default MemoizedBetSpot;
