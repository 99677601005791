import PerspectiveChip from '@/components/PerspectiveChip';
import { ROULETTE_TABLE_TYPE } from '@/enums/ui';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Chip from '../Chip';
import styles from './DragChip.module.scss';

const DragChip = ({ tableType = ROULETTE_TABLE_TYPE.RESPONSIVE, chipSize }) => {
  const dragActive = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.table.dragAndDrop.dragActive
  );
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const dragCoordinates = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.table.dragAndDrop.dragCoordinates
  );
  const draggedBetType = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.table.dragAndDrop.draggedBetType
  );

  const chipValue = useMemo(() => {
    if (!draggedBetType) return null;

    return bets[draggedBetType]?.value;
  }, [bets, draggedBetType]);

  if (!dragActive || !dragCoordinates) return null;

  return (
    <div
      className={styles.cursorChip}
      style={{
        position: 'absolute',
        top: dragCoordinates.y,
        left: dragCoordinates.x,
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        pointerEvents: 'none',
      }}
    >
      <div className={styles.chipContainer} style={{ width: chipSize, height: chipSize }}>
        {tableType === ROULETTE_TABLE_TYPE.THREE_DIMENSION ? (
          <PerspectiveChip value={chipValue} />
        ) : (
          <Chip value={chipValue} />
        )}
      </div>
    </div>
  );
};

export default DragChip;
