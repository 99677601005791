import { useSelector } from 'react-redux';
import clsx from 'clsx';
import styles from './PinButton.module.scss';

const PinButton = ({ onClick }) => {
  const tableLimitsPinned = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.tableLimitsPinned
  );

  return (
    <div className={clsx(styles.container, tableLimitsPinned && styles.pinned)} onClick={onClick}>
      <svg viewBox="0 0 176.87 169.44" fill="currentColor" height="0.75em">
        <path d="M117.05 106.35 153 68.11c-2.26-1.56-4.35-3.11-6.55-4.48A46 46 0 0 0 120 56.46a39.94 39.94 0 0 0-13.17 2.8 3.22 3.22 0 0 0-1.07.85Q94.94 71.57 84.17 83.05q-10.35 11-20.68 22a5.2 5.2 0 0 0-1.21 2.14c-3.26 12.05-1.6 23.46 4.52 34.28a48.17 48.17 0 0 0 7.63 10.16l35.73-38c.36.31.7.6 1 .9q17.43 16.37 34.84 32.74c5 4.74 10 9.5 15.11 14.22a9.45 9.45 0 0 0 2.26 1.55c3.86 1.88 7.75 3.72 11.63 5.56.53.25 1.07.46 1.85.8-.23-.53-.34-.86-.51-1.16-2.23-4.06-4.45-8.13-6.71-12.18a7.68 7.68 0 0 0-1.42-1.75q-14.14-13.29-28.32-26.57L118 107.18c-.3-.26-.59-.5-.95-.83ZM60.26 102.35 102.7 57.2 57.53 14.75 15.11 59.94ZM0 54.08c3.71 1.75 7.3 3.39 11.31 3.81a2.56 2.56 0 0 0 2.35-.89q11.28-12.11 22.63-24.18 9.09-9.67 18.24-19.31a3.07 3.07 0 0 0 .9-2.61C55 6.81 52.94 3.47 50.8 0Z" />
      </svg>
    </div>
  );
};

export default PinButton;
