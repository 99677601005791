import { browserTracingIntegration, captureException, init } from '@sentry/react';
import { Logger } from '@vpmedia/simplify';
import { config } from './configService';

/**
 * Returns sentry application semantic version.
 * @returns {string} Build semantic version.
 */
const getRelease = () => {
  const environment = config.VITE_APP_ENVIRONMENT;
  const appId = config.VITE_APP_ID;
  const appVersion = config.VITE_APP_VERSION;
  const appRevision = config.VITE_APP_REVISION;
  return `${appId}@${appVersion}+${environment}.${appRevision}`;
};

/**
 * Returns traces sample rate depending on deployment environment.
 * @returns {number} Traces sample rate.
 */
const getTracesSampleRate = () => {
  if (config.VITE_OTEL_ENABLED && !config.VITE_OTEL_SENTRY_ENABLED) {
    return 0;
  }
  const environment = config.VITE_APP_ENVIRONMENT;
  switch (environment) {
    case 'release': {
      return 1;
    }
    default: {
      return 1;
    }
  }
};

/**
 * Initialize Sentry SDK.
 * @returns {import('@sentry/types').Client} Sentry client.
 * @see https://docs.sentry.io/platforms/javascript/
 */
export const initSentry = () => {
  const dsn = config.VITE_SENTRY_DSN;
  const environment = config.VITE_APP_ENVIRONMENT;
  const release = getRelease();
  const tracesSampleRate = getTracesSampleRate();
  const integrations = tracesSampleRate === 0 ? [] : [browserTracingIntegration()];
  const client = init({
    dsn,
    environment,
    integrations,
    maxBreadcrumbs: 50,
    release,
    tracesSampleRate,
  });
  new Logger('Sentry').info('initSentry', { release });
  Logger.exceptionHandler = captureException;
  return client;
};
