import { polarToCartesian } from './mathService';

/**
 * Describes an arc based on the provided parameters.
 * @param {number} x - The x-coordinate of the arc.
 * @param {number} y - The y-coordinate of the arc.
 * @param {number} radius - The radius of the arc.
 * @param {number} startAngle - The start angle of the arc.
 * @param {number} endAngle - The end angle of the arc.
 * @returns {string} The description of the arc path.
 */
export const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

  return d;
};
