import { COLOR } from '@/enums/layoutTable';
import ROUND_STATE from '@/enums/roundState';
import { createBetKey } from '@/lib/rouletteService';
import { createSlice } from '@reduxjs/toolkit';
import { Logger } from '@vpmedia/simplify';

const logger = new Logger('gameSlice');

/**
 * TBD.
 * @param {number} number - TBD.
 * @returns {string} TBD.
 */
const getNumberColor = (number) => {
  if (number === 0) return COLOR.GREEN;
  if (
    (number % 2 === 0 && ((number >= 1 && number <= 10) || (number >= 19 && number <= 28))) ||
    (number % 2 === 1 && ((number >= 11 && number <= 18) || number >= 29))
  ) {
    return COLOR.BLACK;
  }

  return COLOR.RED;
};

const initNumbers = () =>
  [...Array.from({ length: 37 }).keys()].map((number) => ({
    number,
    color: getNumberColor(number),
  }));

/**
 * @typedef {object} BetHistoryItem - TBD.
 * @property {number} bet - Bet amount.
 * @property {string} betType - Bet type enum.
 * @property {string} transactionId - Transaction id.
 * @property {string} betId - Bet identifier.
 * @property {string} isWinningBet - Whether the bet is a winning bet from the previous round.
 * @property {string} createdAt - Date of placed bet.
 */

/**
 * @typedef {object} BetMovementItem - TBD.
 * @property {string} draggedBetType - The type of the bet that was dragged.
 * @property {string} droppedBetType - The type of the bet that was dropped.
 * @property {string} transactionId - The transaction ID related to the movement.
 * @property {string} betId - The identifier of the bet that was moved.
 * @property {string} createdAt - The date and time when the movement occurred.
 */

/**
 * @typedef {object} BetsItem - TBD.
 * @property {number} value - Bet amount.
 * @property {number} [win] - Win amount.
 * @property {string} date - Date of placed bet.
 */

/**
 * @typedef {object} BetsPreviewItem - TBD.
 * @property {number} value - Bet amount.
 * @property {string} betType - Bet type enum.
 * @property {number[]} numbers[] - TBD.
 */

/**
 * @typedef {object} GameSettings - TBD.
 * @property {{id: string, uri: string, provider: string}} stream - Stream settings.
 * @property {{[key: string]: {min: number, max: number}}} betLimits - Bet limits settings.
 * @property {{[key: string]: number}} payouts - Payouts settings.
 * @property {number[]} chips - List of available chips.
 * @property {{isDenyOppositeBets: boolean}} featureFlags - TBD.
 */

/**
 * @typedef {object} GameState - TBD.
 * @property {boolean} isInitialized - TBD.
 * @property {string} gameId - TBD.
 * @property {string} gameName - TBD.
 * @property {string} gameType - TBD.
 * @property {string} tableState - TBD.
 * @property {string} pendingTableState - TBD.
 * @property {string} roundId - TBD.
 * @property {string} time - TBD.
 * @property {{number: number, color: string}[]} numbers - TBD.
 * @property {number[]} numberOrder - TBD.
 * @property {GameSettings} settings - TBD.
 * @property {{[key: string]: {name: string, payout?: number, min: number, max: number}}} betLimits - TBD.
 * @property {number} selectedChip - TBD.
 * @property {number} racetrackNeighborCount - TBD.
 * @property {{[key: string]: BetsItem}} bets - TBD.
 * @property {BetHistoryItem[]} betHistory - TBD.
 * @property {BetMovementItem[]} betsMovements - TBD.
 * @property {BetHistoryItem[]} previousBet - TBD.
 * @property {number[]} winningNumberHistory - TBD.
 * @property {boolean} tableLimitReached - TBD.
 * @property {boolean} limitReachedBet - TBD.
 * @property {number[]} highlightedNumbers - TBD.
 * @property {{[key: string]: BetsPreviewItem}} betsPreview - TBD.
 * @property {{[key: string]: number}} results - TBD.
 * @property {string} roundState - TBD.
 * @property {string} roundStartedAt - TBD.
 * @property {number} winningNumber - TBD.
 * @property {number} win - TBD.
 * @property {number} lastWin - TBD.
 * @property {number} statRoundCount - TBD.
 * @property {object} error - TBD.
 * @property {object} dealer - TBD.
 */

/** @type {GameState} */
const initialState = {
  isInitialized: false,
  gameId: null,
  gameName: null,
  gameType: null,
  tableState: null,
  pendingTableState: null,
  roundId: null,
  time: null,
  numbers: initNumbers(),
  numberOrder: [
    0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29,
    7, 28, 12, 35, 3, 26,
  ],
  settings: null,
  betLimits: {
    straight: {
      name: 'straight up',
      min: 0,
      max: 0,
      payout: 35,
    },
    split: {
      name: 'split',
      min: 0,
      max: 0,
      payout: 17,
    },
    street: {
      name: 'street',
      min: 0,
      max: 0,
      payout: 11,
    },
    corner: {
      name: 'corner',
      min: 0,
      max: 0,
      payout: 8,
    },
    sixLine: {
      name: 'sixLine',
      min: 0,
      max: 0,
      payout: 5,
    },
    column: {
      name: 'column',
      min: 0,
      max: 0,
      payout: 2,
    },
    dozen: {
      name: 'dozen',
      min: 0,
      max: 0,
      payout: 2,
    },
    redBlack: {
      name: 'redBlack',
      min: 0,
      max: 0,
      payout: 1,
    },
    evenOdd: {
      name: 'evenOdd',
      min: 0,
      max: 0,
      payout: 1,
    },
    lowHigh: {
      name: '1-18/19-36',
      min: 0,
      max: 0,
      payout: 1,
    },
    table: {
      name: 'table',
      min: 0,
      max: 0,
    },
  },
  selectedChip: null,
  racetrackNeighborCount: localStorage.getItem('racetrackNeighborCount')
    ? Number.parseInt(localStorage.getItem('racetrackNeighborCount'))
    : 2,
  bets: {},
  betHistory: [],
  betsMovements: [],
  previousBet: null,
  winningNumberHistory: [],
  tableLimitReached: false,
  limitReachedBet: null,
  highlightedNumbers: [],
  betsPreview: {},
  results: null,
  roundState: null,
  roundStartedAt: null,
  winningNumber: null,
  win: null,
  lastWin: null,
  statRoundCount: 150,
  error: null,
  dealer: null,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      logger.info('setInitialized', action.payload);
      state.isInitialized = action.payload;
    },
    initGame: (state, action) => {
      logger.info('initGame', action.payload);
      const {
        gameId,
        roundId,
        gameName,
        gameType,
        tableState,
        pendingTableState,
        results,
        roundState,
        roundStartedAt,
        time,
        settings,
        dealer,
        betHistory,
        betsMovements,
        previousBet,
        winningNumber,
        lastTenWinningNumbers,
      } = action.payload;
      state.gameId = gameId;
      state.roundId = roundId;
      state.gameName = gameName;
      state.gameType = gameType;
      state.results = results;
      state.roundStartedAt = roundStartedAt;
      state.roundState = roundState;
      state.time = time;
      state.settings = settings;
      for (const [key, value] of Object.entries(state.settings.betLimits)) {
        if (state.betLimits[key]) {
          state.betLimits[key].min = value.min;
          state.betLimits[key].max = value.max;
        } else {
          logger.exception('initGame', new Error(`Table limits not defined for key: ${key}`));
        }
      }
      for (const [key, value] of Object.entries(state.settings.payouts)) {
        if (state.betLimits[key]) {
          state.betLimits[key].payout = value;
        } else {
          logger.exception('initGame', new Error(`Payout not defined for key: ${key}`));
        }
      }
      state.selectedChip = state.settings.chips[0];
      state.dealer = dealer;
      state.tableState = tableState;
      state.pendingTableState = pendingTableState;
      state.betHistory = betHistory.map((betItem) => ({ ...betItem, betId: betItem.publicBetId }));
      state.betsMovements = betsMovements.map((movement) => ({ ...movement, betId: movement.publicBetId }));
      state.bets = {};
      for (const { betType, bet, createdAt } of state.betHistory) {
        state.bets[betType] = {
          value: (state.bets[betType]?.value ?? 0) + bet,
          date: createdAt,
        };
      }

      if (results) {
        for (const key of Object.keys(results)) {
          if (results[key]) {
            state.bets[key].win = results[key] - state.bets[key].value;
          }
        }
      }

      state.previousBet = previousBet;
      state.winningNumber = winningNumber;
      state.winningNumberHistory = lastTenWinningNumbers;
      state.isInitialized = true;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - Store action.
     */
    selectChip: (state, action) => {
      state.selectedChip = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number[]>} action - Store action.
     */
    setHighlightedNumbers: (state, action) => {
      state.highlightedNumbers = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{betId: string, betType: string, bet: number, transactionId: string, createdAt: string}[]>} action - Store action.
     */
    addBet: (state, action) => {
      state.lastWin = null;
      const bets = action.payload;
      for (const { betType, bet } of bets) {
        state.bets[betType] = {
          value: (state.bets[betType]?.value ?? 0) + bet,
          date: new Date().toISOString(),
        };
      }
      state.betHistory.push(...bets);
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - Store action.
     */
    moveBet: (state, action) => {
      const { draggedBetType, droppedBetType, transactionId } = action.payload;
      const currentBet = state.bets[draggedBetType];
      const draggedHistoryItems = state.betHistory.filter((item) => item.betType === draggedBetType);

      for (const item of draggedHistoryItems) {
        item.betType = droppedBetType;
        state.betsMovements.push({
          transactionId,
          betId: item.betId,
          draggedBetType,
          droppedBetType,
          createdAt: new Date().toISOString(),
        });
      }

      state.bets[droppedBetType] = {
        value: (state.bets[droppedBetType]?.value ?? 0) + currentBet.value,
        date: Date.now().toString(),
      };

      delete state.bets[draggedBetType];
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    undoBet: (state, action) => {
      const transactionId = action.payload;
      const currentBetItems = state.betHistory.filter((betItem) => betItem.transactionId === transactionId);
      for (const { betType, bet } of currentBetItems) {
        state.bets[betType].value -= bet;
        if (state.bets[betType].value === 0) delete state.bets[betType];
      }

      state.betHistory = state.betHistory.filter((betItem) => betItem.transactionId !== transactionId);
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    undoBetMovement: (state, action) => {
      const transactionId = action.payload;
      const movements = state.betsMovements.filter((moveItem) => moveItem.transactionId === transactionId);

      if (movements.length === 0) {
        return;
      }

      const { draggedBetType } = movements[0];

      const betIds = new Set(movements.map((item) => item.betId));
      const movedBets = state.betHistory.filter((item) => betIds.has(item.betId));

      for (const betItem of movedBets) {
        const { betType, bet } = betItem;
        state.bets[betType].value -= bet;

        if (state.bets[betType].value === 0) delete state.bets[betType];

        state.bets[draggedBetType] = {
          value: (state.bets[draggedBetType]?.value ?? 0) + bet,
          date: state.bets[draggedBetType]?.date ?? new Date().toISOString(),
        };

        betItem.betType = draggedBetType;
      }

      state.betsMovements = state.betsMovements.filter((moveItem) => moveItem.transactionId !== transactionId);
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     */
    clearTable: (state) => {
      state.winningNumber = null;
      state.bets = {};

      if (state.betHistory?.length > 0) {
        state.previousBet = state.betHistory;
      }

      state.betHistory = [];
      state.win = null;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object[]>} action - Store action.
     */
    setBetsPreview: (state, action) => {
      // @ts-ignore
      if (![ROUND_STATE.PLACE_BETS, ROUND_STATE.FINAL_BETS].includes(state.roundState)) return;
      const bets = action.payload;
      for (const { betType, numbers, value } of bets) {
        const betKey = createBetKey(betType, numbers);
        state.betsPreview[betKey] = value;
      }
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     */
    clearBetsPreview: (state) => {
      state.betsPreview = {};
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - Store action.
     */
    setRacetrackNeighborCount: (state, action) => {
      state.racetrackNeighborCount = action.payload;
      localStorage.setItem('racetrackNeighborCount', action.payload.toString());
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{roundState: string, winningNumber: number}>} action - Store action.
     */
    setRoundState: (state, action) => {
      // logger.info('setRoundState', action.payload);
      const { roundState } = action.payload;
      if (roundState === ROUND_STATE.WINNING_NUMBER) {
        const { winningNumber } = action.payload;
        state.winningNumber = winningNumber;
        state.winningNumberHistory.unshift(winningNumber);
      }
      state.roundState = roundState;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{[key: string]: number}>} action - Store action.
     */
    setResult: (state, action) => {
      const results = action.payload;
      state.results = results;

      if (!results) {
        return;
      }

      const totalWin = Object.values(results).reduce((total, win) => {
        return total + win;
      }, 0);

      state.win = totalWin;

      if (totalWin > 0) {
        state.lastWin = totalWin;
      }

      for (const key of Object.keys(results)) {
        if (results[key]) {
          state.bets[key].win = results[key] - state.bets[key].value;
        }
      }
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setTableLimitReached: (state, action) => {
      state.tableLimitReached = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setLimitReachedBet: (state, action) => {
      state.limitReachedBet = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     */
    clearLimitReachedBet: (state) => {
      state.limitReachedBet = null;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setGameId: (state, action) => {
      state.gameId = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{roundId: string, roundStartedAt: string}>} action - Store action.
     */
    setNewRound: (state, action) => {
      const { roundId, roundStartedAt } = action.payload;
      state.roundId = roundId;
      state.roundStartedAt = roundStartedAt;
      state.roundState = ROUND_STATE.PLACE_BETS;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setTime: (state, action) => {
      state.time = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - Store action.
     */
    setError: (state, action) => {
      state.error = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - Store action.
     */
    setStatRoundCount: (state, action) => {
      state.statRoundCount = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setTableState: (state, action) => {
      state.tableState = action.payload;
      state.pendingTableState = null;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setPendingTableState: (state, action) => {
      state.pendingTableState = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     */
    resetGame: (state) => {
      state.roundId = null;
      state.roundStartedAt = null;
      state.roundState = null;
      state.winningNumber = null;
      state.bets = {};
      state.betHistory = [];
      state.win = null;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<BetHistoryItem[]>} action - Store action.
     */
    setBetHistory: (state, action) => {
      state.betHistory = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{[key: string]: BetsItem}>} action - Store action.
     */
    setBets: (state, action) => {
      state.bets = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<BetMovementItem[]>} action - Store action.
     */
    setBetsMovements: (state, action) => {
      state.betsMovements = action.payload;
    },
    /**
     * TBD.
     * @param {GameState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<GameSettings>} action - Store action.
     */
    setSettings: (state, action) => {
      state.settings = action.payload;
      state.selectedChip = state.settings.chips[0];
      for (const [key, value] of Object.entries(state.settings.betLimits)) {
        if (state.betLimits[key]) {
          state.betLimits[key].min = value.min;
          state.betLimits[key].max = value.max;
        } else {
          logger.exception('setSettings', new Error(`Table limits not defined for key: ${key}`));
        }
      }
      for (const [key, value] of Object.entries(state.settings.payouts)) {
        if (state.betLimits[key]) {
          state.betLimits[key].payout = value;
        } else {
          logger.exception('setSettings', new Error(`Payout not defined for key: ${key}`));
        }
      }
    },
  },
});

export const {
  addBet,
  clearBetsPreview,
  clearLimitReachedBet,
  clearTable,
  initGame,
  moveBet,
  resetGame,
  selectChip,
  setBetHistory,
  setBets,
  setBetsMovements,
  setBetsPreview,
  setError,
  setGameId,
  setHighlightedNumbers,
  setInitialized,
  setLimitReachedBet,
  setNewRound,
  setPendingTableState,
  setRacetrackNeighborCount,
  setResult,
  setRoundState,
  setSettings,
  setStatRoundCount,
  setTableLimitReached,
  setTableState,
  setTime,
  undoBet,
  undoBetMovement,
} = gameSlice.actions;

export default gameSlice.reducer;
