import { createContext, useState } from 'react';

export const LowBalanceContext = createContext({});

/**
 * TBD.
 * @param {object} props - TBD.
 * @returns {import('react').ReactElement} Output to render.
 */
export const LowBalanceContextProvider = (props) => {
  const [referenceElement, setReferenceElement] = useState();

  const setLowBalanceReference = (element) => {
    setReferenceElement(element);
  };

  return (
    <LowBalanceContext.Provider
      value={{
        referenceElement,
        setLowBalanceReference,
      }}
    >
      {props.children}
    </LowBalanceContext.Provider>
  );
};
