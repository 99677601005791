import { CLIENT_ERROR_TYPE, SERVER_ERROR_TYPE } from '@/enums/errorType';
import { SOCKET_ACTION } from '@/enums/socketEvent';
import { addSnackbar } from '@/store/uiSlice';
import { Logger } from '@vpmedia/simplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ErrorView from '../ErrorView';
import { refundThunk } from '@/store/thunks/gameThunks';

const logger = new Logger('ErrorManager');

const ErrorManager = () => {
  const errorData = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.error.errorData);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (!errorData) {
      setContent(null);
      return;
    }
    logger.warn('Show', errorData);

    const handleSnackbar = (titleKey, contentKey) => {
      dispatch(
        addSnackbar({
          key: titleKey,
          title: t(titleKey),
          content: t(contentKey),
        })
      );
    };

    switch (errorData.errorCode) {
      case SERVER_ERROR_TYPE.TIME_EXPIRED:
        switch (errorData.operation) {
          case SOCKET_ACTION.ON_BET_ADDED:
            handleSnackbar('error.addBetSnackbarTitle', 'error.addBetSnackbarContent');
            break;
          case SOCKET_ACTION.ON_BET_REMOVED:
            handleSnackbar('error.undoBetSnackbarTitle', 'error.undoBetSnackbarContent');
            break;
          default:
            break;
        }
        setContent(null);
        break;
      case SERVER_ERROR_TYPE.DEVICE_MANAGER_ERROR:
        handleSnackbar('error.voidGameSnackbarTitle', 'error.voidGameSnackbarContent');
        dispatch(refundThunk());
        setContent(null);
        break;
      default: {
        const errorCode = errorData.errorCode || CLIENT_ERROR_TYPE.GENERAL_ERROR;
        setContent(<ErrorView errorCode={errorCode} />);
        break;
      }
    }
  }, [errorData, t, dispatch]);

  return content;
};

export default ErrorManager;
