import { CLIENT_ERROR_TYPE, SERVER_ERROR_TYPE } from '@/enums/errorType';
import { redirectHome } from '@/lib/urlService';
import { SESSION_ID } from '@/socket';
import { captureMessage } from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import styles from './ErrorView.module.scss';

const ErrorView = ({ errorCode }) => {
  const { t } = useTranslation('common');

  const onRefresh = useCallback(() => {
    localStorage.setItem('sessionId', SESSION_ID);
    localStorage.setItem('sessionTime', new Date().toISOString());
    window.location.reload();
  }, []);

  const onCloseGame = useCallback(() => {
    redirectHome();
  }, []);

  useEffect(() => {
    captureMessage('ErrorView', { tags: { errorCode } });
  }, [errorCode]);

  const refreshNotAllowedList = [
    CLIENT_ERROR_TYPE.MISSING_TOKEN,
    SERVER_ERROR_TYPE.INVALID_TOKEN,
    SERVER_ERROR_TYPE.GAME_DISABLED,
    SERVER_ERROR_TYPE.GAME_NOT_FOUND,
    SERVER_ERROR_TYPE.GAMING_LIMIT_REACHED,
    SERVER_ERROR_TYPE.USER_FROZEN,
  ];
  const refreshEnabled = refreshNotAllowedList.includes(errorCode) === false;
  const errorMessage = t(`error.${errorCode}`);
  if (errorMessage === `error.${errorCode}`) {
    captureMessage(`Missing localization for error code: ${errorCode}`);
  }

  // <IoCloseCircleOutline size="3.5em" />
  // <div className={styles.errorTitle}>{t('error.title')}</div>

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        {<div>{errorMessage}</div>}
        <div className={styles.buttons}>
          {refreshEnabled && <Button text={t('error.refreshButton')} className={styles.button} onClick={onRefresh} />}
          <Button text={t('error.closeGameButton')} className={styles.button} onClick={onCloseGame} />
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
