import { QUALITY } from '@/enums/stream';
import socketApi from '@/lib/socketApi';
import { Director, Logger, View } from '@millicast/sdk';
import { Logger as ConsoleLogger } from '@vpmedia/simplify';
import cl from 'clsx';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './DolbyRTCPlayer.module.scss';

Logger.setLevel(Logger.WARN);
const logger = new ConsoleLogger('DolbyRTCPlayer');
const AUTO_QUALITY = 'auto';

const buildQualityOptions = (layers = []) => {
  const qualities = [];

  switch (layers.length) {
    case 2:
      qualities.push(QUALITY.HIGH, QUALITY.LOW);
      break;

    case 3:
      qualities.push(QUALITY.HIGH, QUALITY.MEDIUM, QUALITY.LOW);
      break;

    default:
      return [{ streamQuality: AUTO_QUALITY }];
  }

  const descendingLayers = layers.sort((a, b) => b.bitrate - a.bitrate);
  const qualityOptions = descendingLayers.map((layer, idx) => ({
    simulcastLayer: {
      bitrate: layer.bitrate,
      encodingId: layer.id,
      simulcastIdx: layer.simulcastIdx,
      spatialLayerId: layer.layers[0]?.spatialLayerId,
      temporalLayerId: layer.layers[0]?.temporalLayerId,
      maxSpatialLayerId: layer.layers[0]?.maxSpatialLayerId,
      maxTemporalLayerId: layer.layers[0]?.maxTemporalLayerId,
    },
    streamQuality: qualities[idx],
  }));
  return [{ streamQuality: AUTO_QUALITY }, ...qualityOptions];
};

const DolbyRTCPlayer = memo(({ streamName, accountId, onStreamLoaded, className }) => {
  const [currentStreamQuality, setCurrentStreamQuality] = useState(null);
  const [mainQualityOptions, setMainQualityOptions] = useState(buildQualityOptions());
  const mainSound = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume.main
  );
  const studioSound = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume.studio
  );
  const userInteracted = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.userInteracted
  );
  const playerStreamSettings = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.stream
  );
  const isSocketConnected = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isSocketConnected
  );
  const videoRef = useRef();
  const viewerRef = useRef();

  const isMuted = useMemo(() => {
    if (!userInteracted) return true;

    return mainSound.isMuted || studioSound.isMuted;
  }, [mainSound.isMuted, studioSound.isMuted, userInteracted]);

  const handleLayersChanged = useCallback((event) => {
    const { active } = event.data.medias[0] ?? {};
    setMainQualityOptions(buildQualityOptions(active));
  }, []);

  const handleReconnectEvent = useCallback((event) => {
    logger.info('handleReconnectEvent', event);
  }, []);

  const handleStateChangeEvent = useCallback((event) => {
    logger.info('handleStateChangeEvent', event);
  }, []);

  const handleBroadcastEvent = useCallback(
    (event) => {
      const { current: viewer } = videoRef;
      if (!viewer) {
        return;
      }
      // active, layers
      switch (event.name) {
        case 'layers': {
          handleLayersChanged(event);
          break;
        }
        default: {
          logger.debug('handleBroadcastEvent', event.name);
          break;
        }
      }
    },
    [handleLayersChanged]
  );

  const closeStream = useCallback(() => {
    if (!viewerRef.current) {
      return;
    }
    logger.info('closeStream');
    /** @type {View} */
    const millicastView = viewerRef.current;
    millicastView.stop();
    millicastView.removeAllListeners();
    millicastView?.signaling?.close();
  }, [viewerRef]);

  const initStream = useCallback(async () => {
    const video = document.querySelector('#stream-video');
    const token = await socketApi.getSubscriberToken(streamName);

    const tokenGenerator = () =>
      Director.getSubscriber({
        streamName,
        streamAccountId: accountId,
        subscriberToken: token,
      });

    const millicastView = new View(null, tokenGenerator, video, true);
    viewerRef.current = millicastView;
    logger.info('initStream');
    millicastView.on('broadcastEvent', handleBroadcastEvent);
    millicastView.on('reconnect', handleReconnectEvent);
    millicastView.on('connectionStateChange', handleStateChangeEvent);
    /* millicastView.webRTCPeer.on('stats', (stats) => {
      logger.info('Stats from event', stats);
    }); */

    try {
      logger.info('Connecting');
      await millicastView.connect({
        events: ['active', 'inactive', 'stopped', 'layers'],
      });
      logger.info('Connected');
    } catch (error) {
      // @ts-ignore
      logger.error('Connection error', error);
      await millicastView.reconnect();
    }
  }, [accountId, handleBroadcastEvent, handleReconnectEvent, handleStateChangeEvent, streamName]);

  const setStreamQuality = useCallback(async () => {
    /** @type {View} */
    const viewer = viewerRef.current;

    if (!viewer) {
      logger.warn('setStreamQuality - View not found');
      return;
    }

    if (!playerStreamSettings) {
      logger.warn('setStreamQuality - Player stream settings not found');
      return;
    }

    const selectedQuality = playerStreamSettings.automaticAdjustment
      ? null
      : mainQualityOptions.find((option) => option.streamQuality.toLowerCase() === playerStreamSettings.quality);

    const { streamQuality = AUTO_QUALITY, simulcastLayer } = selectedQuality ?? {};
    if (streamQuality === currentStreamQuality) {
      // logger.debug('Stream quality already set', { streamQuality });
      return;
    }

    try {
      if (streamQuality === AUTO_QUALITY) {
        await viewer.select();
      } else {
        await viewer.select(simulcastLayer);
      }
      logger.info('setStreamQuality', { streamQuality });
      setCurrentStreamQuality(streamQuality);
    } catch (error) {
      // @ts-ignore
      logger.error('setStreamQuality', error);
      logger.debug('Trying to reconnect to viewer ...');
      await viewer.reconnect();
      setCurrentStreamQuality(null);
    }
  }, [mainQualityOptions, setCurrentStreamQuality, currentStreamQuality, playerStreamSettings]);

  useEffect(() => {
    if (!viewerRef.current) {
      return;
    }
    if (isSocketConnected) {
      setStreamQuality();
    } else {
      setCurrentStreamQuality(null);
    }
  }, [isSocketConnected, setStreamQuality]);

  useEffect(() => {
    initStream();
    return () => {
      closeStream();
    };
  }, [initStream, closeStream]);

  useEffect(() => {
    if (!isMuted) {
      videoRef.current.volume = (mainSound.level / 100) * (studioSound.level / 100);
    }
  }, [isMuted, mainSound.level, studioSound]);

  const memoizedComponent = useMemo(
    () => (
      <>
        <div className={cl(styles.container, className)}>
          <video
            onPlay={() => onStreamLoaded()}
            ref={videoRef}
            id="stream-video"
            width="100%"
            height="100%"
            className={styles.video}
            controls={false}
            playsInline
            autoPlay
            muted={isMuted}
          ></video>
        </div>
      </>
    ),
    [isMuted, onStreamLoaded, className]
  );

  return memoizedComponent;
});

DolbyRTCPlayer.displayName = 'StreamPlayer';

export default DolbyRTCPlayer;
