import cl from 'clsx';
import Chip2D from '@/components/Chip2D';
import styles from './Chip.module.scss';

const Chip = ({ value, isPreview, isGlowing = false, isInactive, ...rest }) => {
  return (
    <div className={cl(styles.chipContainer, isInactive && styles.inactive)} {...rest}>
      {isGlowing && <div className={styles.glowEffect}></div>}
      <Chip2D value={value} isPreview={isPreview} className={styles.chip} />
    </div>
  );
};

export default Chip;
