import FavouriteAndSpecialBets from '@/components/FavouriteAndSpecialBets';
import StatisticsView from '@/components/StatisticsView';
import { SCREEN_ORIENTATION, SIDENAV_VIEW } from '@/enums/ui';
import StarIcon from '@/assets/svg/star.svg?react';
import StatIcon from '@/assets/svg/statistics.svg?react';
import { closeSidenav } from '@/store/uiSlice';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';
import cl from 'clsx';
import styles from './Sidenav.module.scss';
import Drawer from '../Drawer';

const Sidenav = () => {
  const { t } = useTranslation('common');
  const orientation = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.screenOrientation
  );
  const dispatch = useDispatch();
  const sidenavOpened = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.sidenavOpened);
  const sidenavView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.sidenavView);

  const menuItems = useMemo(
    () => ({
      [SIDENAV_VIEW.STATISTICS]: {
        id: uuidv4(),
        label: t('statistics'),
        icon: <StatIcon height="1em" />,
      },
      [SIDENAV_VIEW.FAVORITE_BETS]: {
        id: uuidv4(),
        label: t('favoriteAndSpecialBets'),
        icon: <StarIcon height="1em" />,
      },
    }),
    [t]
  );

  const drawView = useCallback(() => {
    let view;

    if (sidenavView === SIDENAV_VIEW.FAVORITE_BETS) {
      view = <FavouriteAndSpecialBets />;
    }

    if (sidenavView === SIDENAV_VIEW.STATISTICS) {
      view = <StatisticsView />;
    }

    return view;
  }, [sidenavView]);

  const showAnimation = useMemo(() => {
    return orientation === SCREEN_ORIENTATION.LANDSCAPE
      ? {
          opacity: 1,
          x: 0,
          y: 0,
        }
      : {
          opacity: 1,
          y: 0,
        };
  }, [orientation]);

  const hiddenAnimation = useMemo(() => {
    return orientation === SCREEN_ORIENTATION.LANDSCAPE
      ? {
          opacity: 0,
          x: '100%',
          y: 0,
        }
      : {
          opacity: 0,
          y: '100%',
        };
  }, [orientation]);

  return (
    <>
      <AnimatePresence>
        {sidenavOpened && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.overlay}
            onClick={() => dispatch(closeSidenav())}
          ></motion.div>
        )}
      </AnimatePresence>

      <Drawer
        headerLabel={menuItems[sidenavView]?.label}
        headerIcon={menuItems[sidenavView]?.icon}
        isOpen={sidenavOpened}
        onClose={() => dispatch(closeSidenav())}
        showAnimation={showAnimation}
        hiddenAnimation={hiddenAnimation}
        className={cl(styles.drawer, orientation === SCREEN_ORIENTATION.LANDSCAPE && styles.landscape)}
      >
        {drawView()}
      </Drawer>
    </>
  );
};

export default Sidenav;
