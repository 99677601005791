import clsx from 'clsx';
import styles from './CloseButton.module.scss';

const CloseButton = (props) => {
  return (
    <svg viewBox="0 0 30 30" className={clsx(styles.button, props.className)} {...props}>
      <path d="M16.895 15L24 7.895 22.105 6 15 13.105 7.895 6 6 7.895 13.105 15 6 22.105 7.895 24 15 16.895 22.105 24 24 22.105z"></path>
    </svg>
  );
};

export default CloseButton;
