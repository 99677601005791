import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import styles from './GameNumber.module.scss';

const GameNumber = () => {
  const roundStartedAt = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.roundStartedAt
  );
  return (
    <div className={styles.number}>{roundStartedAt ? format(new Date(roundStartedAt), '#HH:mm:ss') : '#--:--:--'}</div>
  );
};

export default GameNumber;
