import Scrollable from '@/components/common/Scrollable';
import useMoney from '@/hooks/useMoney';
import socketApi from '@/lib/socketApi';
import { getOutsideBetDisplayName, parseBetKey } from '@/lib/rouletteService';
import { changeBet, loadFavoriteBets, removeBet } from '@/store/favoriteBetsSlice';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BetEditField from './BetEditField';
import CloseButton from './CloseButton';
import styles from './FavouriteView.module.scss';
import StarPositionIcon from './StarPositionIcon';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import EditBetButton from './EditBetButton';
import { useTranslation } from 'react-i18next';
import { playNotAllowedSound, playPressSound } from '@/lib/soundService';
import { VIEW_TYPE } from '@/enums/ui';

const FavouriteView = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation(['roulette']);
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const favoriteBets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.favoriteBets.bets);
  const { format } = useMoney();
  const userBets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const dispatch = useDispatch();
  const isBettingTime = useSelector(selectIsBettingTime);

  const handleEditBetButtonClick = useCallback(() => {
    playPressSound();
    setIsEditMode((prev) => !prev);
  }, []);

  const isBetNotEmpty = useCallback(() => Object.keys(userBets)?.length > 0, [userBets]);

  const generateNameForFavBet = useCallback((bets) => {
    const betKeys = Object.keys(bets);
    const numberString = betKeys.map((key) => {
      const parsedBet = parseBetKey(key);
      const { numbers } = parsedBet;

      return numbers.length > 0 ? numbers.join('/') : getOutsideBetDisplayName(parsedBet.betType).toUpperCase();
    });

    return `${betKeys.length} ${betKeys.length > 1 ? 'CHIPS' : 'CHIP'}: ${numberString.join(', ')}`;
  }, []);

  const handleMouseClick = useCallback(
    (event, betItem) => {
      event.stopPropagation();
      const { items: bets } = betItem;
      if (bets) {
        const mappedBets = Object.keys(bets).map((key) => ({
          betType: key,
          bet: bets[key],
        }));

        dispatch(addBetThunk(mappedBets, event));
      } else {
        if (!isBetNotEmpty()) {
          playNotAllowedSound();
          return;
        }

        const mappedItems = {};
        for (const key of Object.keys(userBets)) {
          mappedItems[key] = userBets[key].value;
        }

        const newBet = {
          ...betItem,
          isNew: true,
          name: generateNameForFavBet(userBets),
          items: mappedItems,
        };

        dispatch(changeBet(newBet));
      }
    },
    [isBetNotEmpty, generateNameForFavBet, userBets, dispatch]
  );

  const handleMouseEnter = useCallback(
    (bets) => {
      if (!bets || isTouchDevice) return;
      const betKeys = Object.keys(bets);
      const numbersArrays = betKeys.map((key) => {
        const parsedBet = parseBetKey(key);

        return parsedBet.numbers;
      });

      const union = [...new Set(numbersArrays.flat())];
      dispatch(setHighlightedNumbers(union));

      const betPreview = Object.entries(bets).map(([key]) => ({
        ...parseBetKey(key),
        value: bets[key],
      }));

      dispatch(setBetsPreview(betPreview));
    },
    [dispatch, isTouchDevice]
  );

  const handleMouseLeave = useCallback(() => {
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
  }, [dispatch]);

  const handleDeleteItem = useCallback(
    async (event, betItem) => {
      event.stopPropagation();
      if (betItem) {
        playPressSound();
        await socketApi.removeFavoriteBet(betItem.betId);
        dispatch(removeBet(betItem.order));
      }
    },
    [dispatch]
  );

  const handleNameChanged = useCallback(
    (event, betItem) => {
      const { value } = event.target;
      dispatch(changeBet({ ...betItem, name: value }));
    },
    [dispatch]
  );

  const updateNewStatus = useCallback(
    async (betItem) => {
      const mappedBet = {};
      for (const key of Object.keys(betItem.items)) {
        mappedBet[key] = betItem.items[key];
      }

      const savedBet = await socketApi.saveFavoriteBet(betItem.name, betItem.order, mappedBet);
      dispatch(changeBet({ ...betItem, betId: savedBet.betId, isNew: false }));
    },
    [dispatch]
  );

  const calculateSumValue = useCallback((bets) => {
    let sumValue = 0;
    for (const key of Object.keys(bets)) {
      sumValue += bets[key];
    }

    return sumValue;
  }, []);

  const initFavoriteBets = useCallback(() => {
    dispatch(loadFavoriteBets());
  }, [dispatch]);

  useEffect(() => {
    initFavoriteBets();
  }, [initFavoriteBets]);

  return (
    <div className={clsx(styles.container, isBettingTime && styles.enabled)}>
      <div className={styles.editButtonContainer} onClick={handleEditBetButtonClick}>
        <EditBetButton className={clsx(styles.editButton, isEditMode && styles.active)} />
        {isEditMode && <div>{t('favBet.exitEditMode')}</div>}
      </div>
      {!favoriteBets && <div className={styles.emptyFavBet}>Place your bet and save it</div>}
      <Scrollable height={380} scrollHint={true}>
        {favoriteBets &&
          favoriteBets?.map((betItem, index) => (
            <div
              onClick={(event) => handleMouseClick(event, betItem)}
              onMouseEnter={() => handleMouseEnter(betItem.items)}
              onMouseLeave={handleMouseLeave}
              key={`favourite-bet-${index}`}
              className={clsx(
                styles.betItem,
                !betItem.items && isBetNotEmpty() && styles.activeEmptyBet,
                betItem.items && !isBettingTime && styles.disabled
              )}
            >
              <div className={styles.iconContainer}>
                {isEditMode && betItem.items && !betItem.isNew && (
                  <CloseButton onClick={(event) => handleDeleteItem(event, betItem)} />
                )}
                {!betItem.isNew && (!isEditMode || (isEditMode && !betItem.items)) && (
                  <StarPositionIcon className={styles.starIcon} number={index + 1} isSet={betItem.items} />
                )}
              </div>

              <BetEditField
                betItem={betItem}
                onNameChanged={handleNameChanged}
                isEditMode={isEditMode}
                updateNewStatus={updateNewStatus}
              />

              {betItem.items && <div className={styles.sumValue}>{format(calculateSumValue(betItem.items))}</div>}
            </div>
          ))}
      </Scrollable>
    </div>
  );
};

export default FavouriteView;
