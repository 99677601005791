import { SOCKET_CLIENT_EVENT } from '@/enums/socketEvent';
import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} ErrorData - TBD.
 * @property {string} errorCode - TBD.
 * @property {string} operation - TBD.
 * @property {string} [statusMessage] - TBD.
 */

/**
 * @typedef {object} ErrorState - TBD.
 * @property {ErrorData} errorData - TBD.
 */

/** @type {ErrorState} */
const initialState = {
  errorData: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {ErrorState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<ErrorData>} action - Store action.
     */
    setError: (state, action) => {
      // tricky: if connect error happens and onAuthenticationError sends an error, do not override with onDisconnect error
      if (
        state.errorData?.operation === SOCKET_CLIENT_EVENT.ON_CONNECT &&
        action.payload?.operation === SOCKET_CLIENT_EVENT.ON_DISCONNECT
      ) {
        return;
      }
      state.errorData = action.payload;
    },
  },
});

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
