import useMoney from '@/hooks/useMoney';

const RefundModalView = ({ credit }) => {
  const { format } = useMoney();
  return (
    <div>{`We would like to inform you that we have issued a ${format(
      credit
    )} refund, which has been credited to your balance.`}</div>
  );
};

export default RefundModalView;
