import cl from 'clsx';
import { useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import DesktopHeaderView from './DesktopHeaderView';
import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useOrientation from '@/hooks/useOrientation';
import HistoryLine from '@/components/HistoryLine';
import styles from './Header.module.scss';
import SingleLineMessageWall from '../SingleLineMessageWall';
import IconButton from '@/components/IconButton';
import { redirectLiveCasinoHomePage } from '@/lib/urlService';
import { useSelector } from 'react-redux';

const Header = () => {
  const orientation = useOrientation();
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const onCloseClick = useCallback(() => {
    redirectLiveCasinoHomePage();
  }, []);

  // console.log(isTouchDevice);

  return (
    <>
      {!isTouchDevice && <DesktopHeaderView />}
      {isTouchDevice && (
        <div className={styles.container}>
          {orientation === SCREEN_ORIENTATION.LANDSCAPE && (
            <IconButton icon={<IoMdClose size="24px" />} onClick={onCloseClick} className={styles.closeButton} />
          )}
          {orientation === SCREEN_ORIENTATION.LANDSCAPE && <SingleLineMessageWall className={styles.messageWall} />}
          <div className={cl(styles.history, orientation === SCREEN_ORIENTATION.LANDSCAPE && styles.landscape)}>
            <HistoryLine orientation="horizontal" />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
