import { v4 as uuidv4 } from 'uuid';
import { createBetKey } from '@/lib/rouletteService';
import { BET_TYPE } from '@/enums/layoutTable';

export const betSpots = [
  {
    id: uuidv4(),
    color: 'color-green',
    d: 'M250.8,24.2L204.6,2L81.7,83.6L37.4,211.2l47,24.9L250.8,24.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 0),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M141.6,163.4l-57.1,72.8l60.9,32.3l56.8-73.9L141.6,163.4L141.6,163.4z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 1),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M197,92.8l-55.4,70.6l60.5,31.1l56.8-73.9L197,92.8z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 2),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M250.9,24.1L197,92.7l60.2,29.9L310.6,53C310.6,53,250.9,24.2,250.9,24.1z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 3),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M202,194.5l-56.8,73.9l60.8,32.2c0.2,0.1,0.3,0.2,0.5,0.2l0.5,0.2l56.5-75.2L202,194.5L202,194.5z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 4),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M257.2,122.7l-55.1,71.8l61.5,31.5l54.8-72.9L257.2,122.7L257.2,122.7z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 5),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M310.7,53l-53.5,69.7l61,30.4l53.2-70.9C371.4,82.2,310.7,53,310.7,53z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 6),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M263.5,226L207,301.2l63.4,33.6l56.1-76.4L263.5,226z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 7),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M318.3,153l-54.8,72.9l63,32.4l54.4-74.2L318.3,153L318.3,153z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 8),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M371.4,82.2l-53.2,70.9l62.7,31.1l52.8-72L371.4,82.2L371.4,82.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 9),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M326.5,258.4l-56.1,76.4l64.7,34.4l55.7-77.8L326.5,258.4L326.5,258.4z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 10),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M380.9,184.2l-54.4,74.2l64.2,33l54-75.5L380.9,184.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 11),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M433.8,112.2l-52.8,72l63.9,31.7l52.3-73.2L433.8,112.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 12),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M390.9,291.4l-55.6,77.8l65.9,34.9l55.3-79.1L390.9,291.4L390.9,291.4z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 13),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M444.8,215.9l-54,75.4l65.5,33.6l53.6-76.7L444.8,215.9z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 14),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M497.1,142.7l-52.3,73.2l65.1,32.4l52-74.4L497.1,142.7z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 15),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M456.3,324.9L401,404l67.4,35.7l54.8-80.5L456.3,324.9L456.3,324.9z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 16),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M509.9,248.2l-53.6,76.7l66.8,34.3l53.1-78.1L509.9,248.2L509.9,248.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 17),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M561.9,173.8l-52,74.4l66.3,32.9l51.5-75.7L561.9,173.8z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 18),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M523.2,359.2l-54.8,80.5l68.8,36.5l54.3-81.9L523.2,359.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 19),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M576.2,281.1l-53.1,78.1l68.4,35.1l52.6-79.4L576.2,281.1L576.2,281.1z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 20),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M627.7,205.5l-51.5,75.6l67.9,33.8l51-76.9L627.7,205.5z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 21),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M591.5,394.4l-54.3,81.9l70.4,37.4l53.8-83.4L591.5,394.4z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 22),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M644.2,314.9l-52.7,79.4l69.9,35.9l52.1-80.9L644.2,314.9z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 23),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M695.1,238l-51,76.9l69.4,34.4l50.4-78.2L695.1,238L695.1,238z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 24),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M661.4,430.2l-53.8,83.4l71.6,38h0.1l53.2-84.8L661.4,430.2L661.4,430.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 25),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M713.5,349.3l-52.2,80.9l71.2,36.6l51.6-82.3L713.5,349.3L713.5,349.3z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 26),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M763.9,271l-50.4,78.2l70.7,35.2l49.9-79.6L763.9,271z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 27),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M732.6,466.8l-53.2,84.9l73.4,38.9l52.6-86.4L732.6,466.8L732.6,466.8z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 28),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M784.2,384.4l-51.7,82.3l72.8,37.3l51.1-83.8L784.2,384.4L784.2,384.4z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 29),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M834.1,304.9l-49.9,79.6l72.1,35.8l49.3-81L834.1,304.9L834.1,304.9z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 30),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M805.3,504l-52.7,86.4l75.2,39.9l52-88.1L805.3,504L805.3,504z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 31),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M856.4,420.2l-51,83.8l74.6,38.2l50.4-85.4L856.4,420.2L856.4,420.2z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 32),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M905.6,339.3l-49.3,80.9l73.9,36.7l48.7-82.4L905.6,339.3z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 33),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M879.8,542.3l-52,88.1l77.1,40.9l51.3-89.8L879.8,542.3L879.8,542.3z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 34),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M930.2,456.9l-50.4,85.4l76.5,39.2l49.6-87L930.2,456.9L930.2,456.9z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 35),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M978.9,374.5l-48.7,82.4l75.7,37.6l48-83.9L978.9,374.5z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 36),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M295.6,424.4L255,481.3l134.4,75.3l40.3-59.4L295.6,424.4z',
    betKey: createBetKey(BET_TYPE.RED),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M429.6,497.1l-40.3,59.4l140,78.5l40-62.1L429.6,497.1L429.6,497.1z',
    betKey: createBetKey(BET_TYPE.BLACK),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M569.3,573l-40,62.2l146.3,81.9l39.5-64.9L569.3,573z',
    betKey: createBetKey(BET_TYPE.ODD),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M167,354.5L126.1,409L255,481.2l40.7-56.9L167,354.5L167,354.5z',
    betKey: createBetKey(BET_TYPE.EVEN),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M44,287.8L3,340l123.1,69.1l40.9-54.5L44,287.8L44,287.8z',
    betKey: createBetKey(BET_TYPE.ONE_TO_EIGHTEEN),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M715.1,652.1L675.6,717l154,86.3l38.9-68L715.1,652.1z',
    betKey: createBetKey(BET_TYPE.NINETEEN_TO_THIRTY_SIX),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M206.5,300.9c-0.2-0.1-0.3-0.2-0.5-0.2L84.4,236.1l-40.5,51.6l251.7,136.7l39.5-55.2L206.5,300.9z',
    betKey: createBetKey(BET_TYPE.FIRST_TWELVE),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M335.1,369.1l-39.5,55.2L569.2,573l38.2-59.4L335.1,369.1L335.1,369.1z',
    betKey: createBetKey(BET_TYPE.SECOND_TWELVE),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M607.6,513.6l-38.2,59.3l299,162.4l36.6-64L607.6,513.6L607.6,513.6z',
    betKey: createBetKey(BET_TYPE.THIRD_TWELVE),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M1053.9,410.6l-48,83.9l77.2,38.3l47.4-85.4L1053.9,410.6L1053.9,410.6z',
    betKey: createBetKey(BET_TYPE.RIGHT_COLUMN),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M1006,494.5l-49.7,87l77.8,39.9l49.1-88.6L1006,494.5L1006,494.5z',
    betKey: createBetKey(BET_TYPE.MIDDLE_COLUMN),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M905,671.4L905,671.4l78.4,41.6l50.7-91.5l-77.8-39.9L905,671.4L905,671.4z',
    betKey: createBetKey(BET_TYPE.LEFT_COLUMN),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M261.45,29.25l-20.96-10l-53,68.46l20.1,10.3L261.45,29.25z',
    betKey: createBetKey(BET_TYPE.SPLIT, [0, 3]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M298.88,47.35l-52.8,69.35l23.3,11.9l53.74-69.62L298.88,47.35z',
    betKey: createBetKey(BET_TYPE.SPLIT, [3, 6]),
  },

  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M259.9,140.6l37,18.5l18-24.3l-36.6-18.1L259.9,140.6z',
    betKey: createBetKey(BET_TYPE.SPLIT, [5, 6]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M205.4,211.4l38,19.6l17-22.9l-37.6-19.3L205.4,211.4L205.4,211.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [4, 5]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M383.03,87.97l-24.39-11.91l-52.66,70.84l24.1,12L383.03,87.97z',
    betKey: createBetKey(BET_TYPE.SPLIT, [6, 9]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M444.97,117.57l-23.17-11.1l-52.72,71.83l23,11.4L444.97,117.57z',
    betKey: createBetKey(BET_TYPE.SPLIT, [9, 12]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M572.6,178.94l-22.19-10.57l-52.03,74.13l21.6,10.7L572.6,178.94z',
    betKey: createBetKey(BET_TYPE.SPLIT, [15, 18]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M509.3,148.58l-25.77-12.39l-52.41,72.92l25.06,12.49L509.3,148.58z',
    betKey: createBetKey(BET_TYPE.SPLIT, [12, 15]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M564.18,275.1l24.1,12l51.21-75.91l-24.35-11.82L564.18,275.1z',
    betKey: createBetKey(BET_TYPE.SPLIT, [18, 21]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M708.16,244.28l-27.45-13.22l-51.03,77.04l27.7,13.7L708.16,244.28z',
    betKey: createBetKey(BET_TYPE.SPLIT, [21, 24]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M775.87,276.76l-24.98-11.96l-49.8,78.3l25,12.4L775.87,276.76z',
    betKey: createBetKey(BET_TYPE.SPLIT, [24, 27]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M846.09,310.73l-24.07-11.74l-49.84,79.52l24.6,12.2L846.09,310.73z',
    betKey: createBetKey(BET_TYPE.SPLIT, [27, 30]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M917.08,344.81l-25.04-12.04l-49.66,80.52l25.4,12.5L917.08,344.81z',
    betKey: createBetKey(BET_TYPE.SPLIT, [30, 33]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M989.14,379.43l-22.34-10.74l-48.62,82.11l21.9,10.9L989.14,379.43z',
    betKey: createBetKey(BET_TYPE.SPLIT, [33, 36]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M197.9,109.8l38.5,19.2l18.4-23.8l-38.4-18.9L197.9,109.8z',
    betKey: createBetKey(BET_TYPE.SPLIT, [2, 3]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M320.9,171l39,19.5l18.1-24.6l-38.8-19.1L320.9,171L320.9,171z',
    betKey: createBetKey(BET_TYPE.SPLIT, [8, 9]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M382.8,201.9l39.3,19.6l17.9-24.9l-39-19.2L382.8,201.9L382.8,201.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [11, 12]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M489.4,255.1l17.8-25.4l-42-20.7l-18.1,24.9L489.4,255.1L489.4,255.1z',
    betKey: createBetKey(BET_TYPE.SPLIT, [14, 15]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M555.3,288l17.5-25.9l-44-21.7l-18,25.4L555.3,288L555.3,288z',
    betKey: createBetKey(BET_TYPE.SPLIT, [17, 18]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M621.1,320.8l17.4-26.2l-41.4-20.4l-17.5,26L621.1,320.8z',
    betKey: createBetKey(BET_TYPE.SPLIT, [20, 21]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M692.5,356.4l17-26.8l-43.4-21.4l-17.3,26.4L692.5,356.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [23, 24]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M734.5,341.9l-17,26.9l46.2,23l17-27.2L734.5,341.9L734.5,341.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [26, 27]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M805,376.8L788.1,404l45.7,22.8l17-27.5L805,376.8L805,376.8z',
    betKey: createBetKey(BET_TYPE.SPLIT, [29, 30]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M876.1,411.8l-16.9,27.7l50.5,25.1l16.7-28L876.1,411.8L876.1,411.8z',
    betKey: createBetKey(BET_TYPE.SPLIT, [32, 33]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M948.4,447.5l-16.7,28.1l52.9,26.4l16.4-28.6L948.4,447.5L948.4,447.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [35, 36]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M143.1,179.5l38.8,20l17.5-22.6l-38.6-19.8L143.1,179.5L143.1,179.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [1, 2]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M266.5,242.8l39.9,20.5l16.9-23L283.8,220L266.5,242.8L266.5,242.8z',
    betKey: createBetKey(BET_TYPE.SPLIT, [7, 8]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M328.7,274.9l40.2,20.7l16.6-23.2L345.6,252L328.7,274.9L328.7,274.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [10, 11]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M436.6,330.3l16.5-23.4l-42.9-22L393.4,308L436.6,330.3L436.6,330.3z',
    betKey: createBetKey(BET_TYPE.SPLIT, [13, 14]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M503.4,364.6l16-23.6L474,317.8l-16.5,23.4L503.4,364.6z',
    betKey: createBetKey(BET_TYPE.SPLIT, [16, 17]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M569.5,398.5l15.8-23.8l-41.7-21.4l-16,23.7L569.5,398.5L569.5,398.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [19, 20]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M641.9,435.9l15.3-24.1l-44.1-22.6L597.4,413L641.9,435.9L641.9,435.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [22, 23]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M713,472.4l15.2-24.3L682,424.5l-15.3,24.1L713,472.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [25, 26]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M783.4,508.5l15-24.4L753,460.8l-15,24.4L783.4,508.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [28, 29]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M824,497.2l-15,24.5l51.2,26.3l14.6-24.6L824,497.2L824,497.2z',
    betKey: createBetKey(BET_TYPE.SPLIT, [31, 32]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M896.8,534.6L882,559.2l53.7,27.6l14.4-24.9L896.8,534.6L896.8,534.6z',
    betKey: createBetKey(BET_TYPE.SPLIT, [34, 35]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M236.5,128.9l-37,47.9l23.4,12l37-48.2L236.5,128.9L236.5,128.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [2, 5]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M296.9,159l-36.4,49.1l23.3,11.9l37.1-49.1L296.9,159z',
    betKey: createBetKey(BET_TYPE.SPLIT, [5, 8]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M323.3,240.4l22.5,11.5l37-50l-22.9-11.4L323.3,240.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [8, 11]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M385.6,272.3l24.5,12.6l37-50.9l-25-12.4L385.6,272.3z',
    betKey: createBetKey(BET_TYPE.SPLIT, [11, 14]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M453,306.9l21,10.8l36.8-51.9l-21.4-10.7L453,306.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [14, 17]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M519.4,340.9l24.2,12.4l36-53.3l-24.2-12.1L519.4,340.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [17, 20]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M585.2,374.8L613,389l35.7-54.5L621,320.8L585.2,374.8z',
    betKey: createBetKey(BET_TYPE.SPLIT, [20, 23]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M657.2,411.6l24.9,12.8l35.3-55.6l-25-12.4L657.2,411.6z',
    betKey: createBetKey(BET_TYPE.SPLIT, [23, 26]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M728.2,448.1l24.8,12.7l35.1-56.8l-24.6-12.2L728.2,448.1z',
    betKey: createBetKey(BET_TYPE.SPLIT, [26, 29]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M798.4,484.1l25.5,13.1l35.2-57.8l-25.4-12.7L798.4,484.1z',
    betKey: createBetKey(BET_TYPE.SPLIT, [29, 32]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M874.8,523.4l21.9,11.2l35-58.9l-22.1-11L874.8,523.4L874.8,523.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [32, 35]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M205.4,211.4l-23.4-12l-41,53.1l23.3,12.3L205.4,211.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [1, 4]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M203.1,285.4l22.3,11.8l41.1-54.3L243.4,231L203.1,285.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [4, 7]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M328.7,274.9l-22.3-11.5l-40.5,55.2l21.9,11.5L328.7,274.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [7, 10]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M328.6,351.6l23.8,12.6l40.8-56.1l-24.4-12.6L328.6,351.6z',
    betKey: createBetKey(BET_TYPE.SPLIT, [10, 13]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M396.4,387.4l20.4,10.8l40.5-57.1l-20.9-10.8L396.4,387.4z',
    betKey: createBetKey(BET_TYPE.SPLIT, [13, 16]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M463.8,422.9l24.2,12.8l39.6-58.6l-24.2-12.4L463.8,422.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [16, 19]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M530.1,457.9l28.1,14.9l39.2-59.8l-28-14.4L530.1,457.9z',
    betKey: createBetKey(BET_TYPE.SPLIT, [19, 22]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M603.2,496.5l25,13.1l38.6-61l-24.9-12.8L603.2,496.5L603.2,496.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [22, 25]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M674.4,534l25.2,13.3l38.4-62.1l-24.9-12.8L674.4,534z',
    betKey: createBetKey(BET_TYPE.SPLIT, [25, 28]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M744.7,571.1l26,13.7l38.4-63.2l-25.7-13.2L744.7,571.1z',
    betKey: createBetKey(BET_TYPE.SPLIT, [28, 31]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M822.2,612.1l21.8,11.4l38.1-64.2l-21.9-11.2L822.2,612.1z',
    betKey: createBetKey(BET_TYPE.SPLIT, [31, 34]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M143.2,179.5l-19.1-10.2l-41.2,53.2l19.2,9.6L143.2,179.5L143.2,179.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [0, 1]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M178,99.5l-36,46.6l18.9,10.9l37.1-47.3L178,99.5z',
    betKey: createBetKey(BET_TYPE.SPLIT, [0, 2]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M261.45,29.25l-20.96-10l-53,68.46l20.1,10.3L261.45,29.25z',
    betKey: createBetKey(BET_TYPE.SPLIT, [0, 3]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M86.2,252.1l39.1,20.8l15.7-20.3L102,232L86.2,252.1L86.2,252.1z',
    betKey: createBetKey(BET_TYPE.STREET, [1, 2, 3]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M148.5,285.4l39.1,20.8l15.5-20.8l-38.8-20.5L148.5,285.4z',
    betKey: createBetKey(BET_TYPE.STREET, [4, 5, 6]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M250.5,339.5l15.4-21l-40.4-21.4l-15.7,20.8L250.5,339.5z',
    betKey: createBetKey(BET_TYPE.STREET, [7, 8, 9]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M313.4,372.9l15.2-21.3L287.7,330l-15.6,21L313.4,372.9z',
    betKey: createBetKey(BET_TYPE.STREET, [10, 11, 12]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M381.2,409l15.2-21.6l-43.9-23.2L337,385.5L381.2,409L381.2,409z',
    betKey: createBetKey(BET_TYPE.STREET, [13, 14, 15]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M448.9,444.9l14.9-22l-47-24.8l-15.3,21.6L448.9,444.9L448.9,444.9z',
    betKey: createBetKey(BET_TYPE.STREET, [16, 17, 18]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M515.4,480.2l14.8-22.3L488,435.8l-14.9,22.1L515.4,480.2z',
    betKey: createBetKey(BET_TYPE.STREET, [19, 20, 21]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M588.7,519.2L588.7,519.2l14.5-22.8l-45-23.8L543.5,495L588.7,519.2z',
    betKey: createBetKey(BET_TYPE.STREET, [22, 23, 24]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M659.9,557.1L659.9,557.1l14.5-23.1l-46.2-24.4l-14.5,22.9L659.9,557.1z',
    betKey: createBetKey(BET_TYPE.STREET, [25, 26, 27]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M730.3,594.5l14.4-23.3l-45.2-23.9l-14.3,23.2L730.3,594.5z',
    betKey: createBetKey(BET_TYPE.STREET, [28, 29, 30]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M770.7,584.9l-14.2,23.4l51.7,27.4l14.1-23.7L770.7,584.9L770.7,584.9z',
    betKey: createBetKey(BET_TYPE.STREET, [31, 32, 33]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M844,623.5l-14.2,23.8l54.5,28.9l13.9-24.1L844,623.5L844,623.5z',
    betKey: createBetKey(BET_TYPE.STREET, [34, 35, 36]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M196.6,75.4L178,99.5l20,10.2l18.5-23.6L196.6,75.4z',
    betKey: createBetKey(BET_TYPE.STREET, [0, 2, 3]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M141.9,146L124,169.1l19.2,10.2l17.6-22.4L141.9,146L141.9,146z',
    betKey: createBetKey(BET_TYPE.STREET, [0, 1, 2]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M102,232l-19.2-9.6l-15.3,19.8l18.7,9.9L102,232L102,232z',
    betKey: createBetKey(BET_TYPE.CORNER, [0, 1, 2, 3]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M254.8,105.1l-18.3,23.8l23.5,11.7l18.4-23.9L254.8,105.1L254.8,105.1z',
    betKey: createBetKey(BET_TYPE.CORNER, [2, 3, 5, 6]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M296.9,159l24,11.9l18.3-24.2l-24.2-12L296.9,159L296.9,159z',
    betKey: createBetKey(BET_TYPE.CORNER, [5, 6, 8, 9]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M378,165.9l-18,24.6l22.9,11.4l18.2-24.6L378,165.9z',
    betKey: createBetKey(BET_TYPE.CORNER, [8, 9, 11, 12]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M422.1,221.5l25,12.4l18.1-24.9L440,196.6L422.1,221.5z',
    betKey: createBetKey(BET_TYPE.CORNER, [11, 12, 14, 15]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M489.4,255.1l21.4,10.7l18-25.4l-21.7-10.7L489.4,255.1z',
    betKey: createBetKey(BET_TYPE.CORNER, [14, 15, 17, 18]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M555.3,288l24.2,12.1l17.5-26l-24.2-11.9L555.3,288z',
    betKey: createBetKey(BET_TYPE.CORNER, [17, 18, 20, 21]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M621.1,320.8l27.8,13.8l17.3-26.4l-27.6-13.6L621.1,320.8z',
    betKey: createBetKey(BET_TYPE.CORNER, [20, 21, 23, 24]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M709.6,329.5l-17,26.8l25,12.4l17-26.9L709.6,329.5z',
    betKey: createBetKey(BET_TYPE.CORNER, [23, 24, 26, 27]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M763.6,391.9l24.5,12.3l16.9-27.3l-24.5-12.1L763.6,391.9z',
    betKey: createBetKey(BET_TYPE.CORNER, [26, 27, 29, 30]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M833.8,426.8l25.4,12.7l16.9-27.7l-25.3-12.5L833.8,426.8L833.8,426.8z',
    betKey: createBetKey(BET_TYPE.CORNER, [29, 30, 32, 33]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M909.7,464.7l22,11l16.7-28.2l-22-10.9L909.7,464.7z',
    betKey: createBetKey(BET_TYPE.CORNER, [32, 33, 35, 36]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M182,199.4l23.4,12l17.4-22.6l-23.4-12L182,199.4z',
    betKey: createBetKey(BET_TYPE.CORNER, [1, 2, 4, 5]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M243.4,231l23.1,11.9l17.2-22.8l-23.3-11.9L243.4,231L243.4,231z',
    betKey: createBetKey(BET_TYPE.CORNER, [4, 5, 7, 8]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M323.3,240.3l-16.9,23l22.3,11.5l17-23L323.3,240.3L323.3,240.3z',
    betKey: createBetKey(BET_TYPE.CORNER, [7, 8, 10, 11]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M368.9,295.5l24.3,12.6L410,285l-24.5-12.6L368.9,295.5L368.9,295.5z',
    betKey: createBetKey(BET_TYPE.CORNER, [10, 11, 13, 14]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M436.5,330.3l20.8,10.8l16.6-23.4l-21-10.8L436.5,330.3z',
    betKey: createBetKey(BET_TYPE.CORNER, [13, 14, 16, 17]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M503.4,364.5l24.2,12.5l16-23.7l-24.2-12.4L503.4,364.5z',
    betKey: createBetKey(BET_TYPE.CORNER, [16, 17, 19, 20]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M569.5,398.5l28,14.4l15.7-23.9l-27.9-14.3L569.5,398.5z',
    betKey: createBetKey(BET_TYPE.CORNER, [19, 20, 22, 23]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M657.2,411.7l-15.3,24.1l25,12.8l15.3-24.2L657.2,411.7L657.2,411.7z',
    betKey: createBetKey(BET_TYPE.CORNER, [22, 23, 25, 26]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M713,472.4l25,12.8l15-24.4l-24.8-12.7L713,472.4L713,472.4z',
    betKey: createBetKey(BET_TYPE.CORNER, [25, 26, 28, 29]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M783.4,508.5l25.7,13.2l14.9-24.4l-25.5-13.1L783.4,508.5L783.4,508.5z',
    betKey: createBetKey(BET_TYPE.CORNER, [28, 29, 31, 32]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M860.2,548l21.8,11.2l14.7-24.6l-21.9-11.2L860.2,548z',
    betKey: createBetKey(BET_TYPE.CORNER, [31, 32, 34, 35]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M141,252.5l-15.7,20.4l23.2,12.4l15.8-20.4L141,252.5z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [1, 2, 3, 4, 5, 6]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M187.6,306.1l22.2,11.8l15.6-20.8l-22.3-11.8L187.6,306.1z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [4, 5, 6, 7, 8, 9]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M265.9,318.5l-15.4,21l21.7,11.6l15.6-21L265.9,318.5L265.9,318.5z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [7, 8, 9, 10, 11, 12]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M313.4,372.9l23.7,12.6l15.4-21.3l-23.8-12.6L313.4,372.9L313.4,372.9z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [10, 11, 12, 13, 14, 15]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M381.2,409l20.3,10.8l15.3-21.6l-20.4-10.8L381.2,409L381.2,409z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [13, 14, 15, 16, 17, 18]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M448.9,444.9l24.2,12.9l14.9-22.1l-24.1-12.8L448.9,444.9z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [16, 17, 18, 19, 20, 21]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M515.3,480.2l28.2,15l14.7-22.4l-28.1-14.9L515.3,480.2L515.3,480.2z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [19, 20, 21, 22, 23, 24]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M603.2,496.5l-14.5,22.8l24.9,13.2l14.5-22.9L603.2,496.5L603.2,496.5z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [22, 23, 24, 25, 26, 27]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M685.2,570.5L685.2,570.5l14.3-23.2L674.4,534l-14.5,23.1L685.2,570.5z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [25, 26, 27, 28, 29, 30]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M730.3,594.5l26,13.9l14.2-23.5l-26-13.7L730.3,594.5z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [28, 29, 30, 31, 32, 33]),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M808.1,635.8l21.7,11.6l14.1-23.8l-21.8-11.4L808.1,635.8L808.1,635.8z',
    betKey: createBetKey(BET_TYPE.SIX_LINE, [31, 32, 33, 34, 35, 36]),
  },
];
