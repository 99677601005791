import { TooltipContext } from '@/context/TooltipContext';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import styles from './CustomTooltip.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';

const Tooltip = () => {
  const [show, setShow] = useState(false);
  const { referenceElement, tooltipText, tooltipOptions, hideTooltip } = useContext(TooltipContext);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const timeoutRef = useRef();
  const showTimoutRef = useRef();
  const isBettingTime = useSelector(selectIsBettingTime);

  const { offset, delay, autoHide, showDuration } = tooltipOptions;

  useEffect(() => {
    setShow(false);
    if (referenceElement && isBettingTime && tooltipText) {
      timeoutRef.current = setTimeout(() => {
        setShow(true);
      }, delay);
    }

    if (autoHide) {
      showTimoutRef.current = setTimeout(() => {
        hideTooltip();
      }, showDuration);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      clearTimeout(showTimoutRef.current);
      showTimoutRef.current = null;
    };
  }, [autoHide, delay, hideTooltip, isBettingTime, referenceElement, showDuration, tooltipText]);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  const tooltipAnimations = {
    visible: { opacity: 1, transition: tooltipOptions.transitionDelay },
    hidden: { opacity: 0 },
  };

  return (
    <>
      {show && (
        <motion.div
          ref={setPopperElement}
          variants={tooltipAnimations}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.container}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {tooltipText}
          {<div ref={setArrowElement} className={styles.arrow} style={popperStyles.arrow} />}
        </motion.div>
      )}
    </>
  );
};

export default Tooltip;
