import ChatIcon from '@/assets/svg/chat.svg?react';
import HistoryIcon from '@/assets/svg/history.svg?react';
import SettingsIcon from '@/assets/svg/settings.svg?react';
import StarIcon from '@/assets/svg/star.svg?react';
import StatIcon from '@/assets/svg/statistics.svg?react';
import ChatView from '@/components/ChatView';
import FavouriteAndSpecialBets from '@/components/FavouriteAndSpecialBets';
import HelpView from '@/components/HelpView/Mobile/HelpView';
import HistoryView from '@/components/HistoryView';
import SettingsView from '@/components/SettingsView';
import StatisticsView from '@/components/StatisticsView';
import TableLimitsView from '@/components/TableLimitsView';
import { CHAT_VIEW_TYPE } from '@/enums/chat';
import { SCREEN_ORIENTATION, SIDENAV_VIEW } from '@/enums/ui';
import useOrientation from '@/hooks/useOrientation';
import { openResponsibleGamingPage, redirectHome } from '@/lib/urlService';
import { setTouchMenuOpened } from '@/store/uiSlice';
import cl from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowDownUp, BsFillHouseFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import { GoAlertFill, GoArrowLeft } from 'react-icons/go';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { LANGUAGE } from '@/lib/paramService';
import styles from './TouchMenu.module.scss';
import SoundIndicator from '../SoundIndicator';
import { muteSound, unmuteSound } from '@/store/settingsSlice';

const TouchMenu = () => {
  const { t } = useTranslation('common');
  const orientation = useOrientation();
  const { main: mainSound } = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume
  );
  const touchMenuOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.touchMenuOpened
  );
  const [selectedView, setSelectedView] = useState(null);
  const dispatch = useDispatch();
  const isLandScape = orientation === SCREEN_ORIENTATION.LANDSCAPE;

  const onResponsibleGamingClick = useCallback(() => {
    openResponsibleGamingPage();
  }, []);

  const onHomeClick = useCallback(() => {
    redirectHome();
  }, []);

  const handleSoundClicked = useCallback(() => {
    mainSound?.isMuted ? dispatch(unmuteSound({ type: 'main' })) : dispatch(muteSound({ type: 'main' }));
  }, [dispatch, mainSound?.isMuted]);

  const gridButtons = useMemo(
    () => [
      {
        id: uuidv4(),
        label: t('chat'),
        icon: <ChatIcon width="2em" height="2em" />,
        viewType: SIDENAV_VIEW.CHAT,
      },
      {
        id: uuidv4(),
        label: t('statistics'),
        icon: <StatIcon width="2em" height="2em" />,
        viewType: SIDENAV_VIEW.STATISTICS,
      },
      {
        id: uuidv4(),
        label: t('favoriteBets'),
        icon: <StarIcon width="2em" height="2em" />,
        viewType: SIDENAV_VIEW.FAVORITE_BETS,
      },
      {
        id: uuidv4(),
        label: t('history'),
        icon: <HistoryIcon width="2em" height="2em" />,
        viewType: SIDENAV_VIEW.HISTORY,
        smallLabel: LANGUAGE === 'nl',
      },
      {
        id: uuidv4(),
        label: t('settings'),
        icon: <SettingsIcon width="2em" height="2em" />,
        viewType: SIDENAV_VIEW.SETTINGS,
      },
      {
        id: uuidv4(),
        label: t('settings.sound'),
        icon: (
          <SoundIndicator
            size="2em"
            level={mainSound?.level}
            isMuted={mainSound?.isMuted}
            withUserInteractedIndicator={false}
          />
        ),
        onClick: handleSoundClicked,
      },
    ],
    [handleSoundClicked, mainSound?.isMuted, mainSound?.level, t]
  );

  const bottomMenuItems = useMemo(
    () => [
      {
        id: uuidv4(),
        label: t('howToPlay'),
        icon: <BsFillQuestionCircleFill size="1.5em" />,
        onClick: () =>
          setSelectedView({
            label: t('howToPlay'),
            icon: <BsFillQuestionCircleFill size="1.5em" />,
            viewType: SIDENAV_VIEW.HELP,
          }),
      },
      {
        id: uuidv4(),
        label: t('limitsAndPayouts'),
        icon: <BsArrowDownUp size="1.5em" />,
        onClick: () =>
          setSelectedView({
            label: t('limitsAndPayouts'),
            icon: <BsArrowDownUp size="1.5em" />,
            viewType: SIDENAV_VIEW.LIMITS_AND_PAYOUTS,
          }),
      },
      {
        id: uuidv4(),
        label: t('responsibleGaming'),
        icon: <GoAlertFill size="1.5em" />,
        onClick: onResponsibleGamingClick,
      },
      {
        id: uuidv4(),
        label: 'Home',
        icon: <BsFillHouseFill size="1.5em" />,
        onClick: onHomeClick,
      },
    ],
    [t, onResponsibleGamingClick, onHomeClick]
  );

  const onSelectView = useCallback((label, icon, viewType) => {
    setSelectedView({ label, icon, viewType });
  }, []);

  const clearSelectedView = useCallback(() => {
    setSelectedView(null);
  }, []);

  const onClose = useCallback(() => {
    clearSelectedView();
    dispatch(setTouchMenuOpened(false));
  }, [clearSelectedView, dispatch]);

  const renderHeaderLabel = useCallback(() => {
    return selectedView ? (
      <div className={styles.selectedViewHeader}>
        <div className={styles.headerIcon}>{selectedView.icon}</div>
        <div className={styles.headerLabel}>{selectedView.label}</div>
      </div>
    ) : (
      'Menu'
    );
  }, [selectedView]);

  const renderSelectedView = useCallback(() => {
    if (selectedView === null) return;

    switch (selectedView.viewType) {
      case SIDENAV_VIEW.CHAT:
        return <ChatView type={CHAT_VIEW_TYPE.TOUCH_MENU} />;
      case SIDENAV_VIEW.STATISTICS:
        return <StatisticsView />;
      case SIDENAV_VIEW.FAVORITE_BETS:
        return <FavouriteAndSpecialBets />;
      case SIDENAV_VIEW.HISTORY:
        return <HistoryView />;
      case SIDENAV_VIEW.SETTINGS:
        return <SettingsView />;
      case SIDENAV_VIEW.LIMITS_AND_PAYOUTS:
        return <TableLimitsView />;
      case SIDENAV_VIEW.HELP:
        return <HelpView />;
      default:
        return null;
    }
  }, [selectedView]);

  const renderGridButton = useCallback(
    (button) => (
      <div
        key={button.id}
        className={styles.button}
        onClick={() => (button.onClick ? button.onClick() : onSelectView(button.label, button.icon, button.viewType))}
      >
        <div className={styles.icon}>{button.icon}</div>
        <div className={cl(styles.label, button.smallLabel && styles.small)}>{button.label}</div>
      </div>
    ),
    [onSelectView]
  );

  const renderBottomMenuItems = useCallback(
    (item) => (
      <div key={item.id} className={styles.listItem} onClick={item.onClick}>
        <div className={styles.icon}>{item.icon}</div>
        <div className={styles.label}>{item.label}</div>
      </div>
    ),
    []
  );

  const drawerAnimations = useMemo(() => {
    const axis = orientation === SCREEN_ORIENTATION.LANDSCAPE ? 'x' : 'y';
    const initialPosition = '100%';
    const animatePosition = '0%';

    return {
      initial: {
        opacity: 0,
        [axis]: initialPosition,
      },
      exit: {
        opacity: 0,
        [axis]: initialPosition,
      },
      animate: {
        opacity: 1,
        [axis]: animatePosition,
      },
    };
  }, [orientation]);

  return (
    <AnimatePresence>
      {touchMenuOpened && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.overlay}
            onClick={onClose}
          ></motion.div>

          <motion.div
            variants={drawerAnimations}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ ease: [0.3, 1, 0.6, 1], duration: 0.4 }}
            className={cl(styles.container, isLandScape && styles.landscape)}
          >
            <div className={styles.header}>
              <div className={cl(styles.backButton, selectedView && styles.isVisible)} onClick={clearSelectedView}>
                <GoArrowLeft size="2em" />
              </div>
              <div className={styles.label}>{renderHeaderLabel()}</div>
              <div className={styles.closeButton} onClick={onClose}>
                <IoMdClose size="2em" />
              </div>
            </div>
            <div className={styles.content}>
              <motion.div
                initial={false}
                animate={selectedView ? 'hidden' : 'visible'}
                variants={{ visible: { opacity: 1, x: 0 }, hidden: { opacity: 0, x: '-100%' } }}
                transition={{ ease: 'linear', duration: 0.3 }}
                className={styles.mainMenu}
              >
                <div className={styles.gridButtons}>{gridButtons.map((element) => renderGridButton(element))}</div>
                <div className={styles.bottomMenuList}>
                  {bottomMenuItems.map((element) => renderBottomMenuItems(element))}
                </div>
              </motion.div>
              <motion.div
                initial={false}
                animate={selectedView ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1, x: 0 }, hidden: { opacity: 0, x: '100%' } }}
                transition={{ ease: 'linear', duration: 0.3 }}
                className={styles.selectedView}
              >
                {renderSelectedView()}
              </motion.div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default TouchMenu;
