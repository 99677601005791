import { BET_TYPE } from '@/enums/layoutTable';
import { isOutsideBet, parseBetKey } from '@/lib/rouletteService';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useHighlighted = () => {
  const highlightedNumbers = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.highlightedNumbers
  );
  const isBettingTime = useSelector(selectIsBettingTime);
  const itemHovered = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.table.itemHovered);

  const isHighlighted = useCallback(
    (betKey) => {
      if (!highlightedNumbers || !isBettingTime) return false;
      const bet = parseBetKey(betKey);
      const { numbers } = bet;
      if (bet.betType === BET_TYPE.STRAIGHT) {
        return highlightedNumbers.includes(numbers[0]);
      } else if (isOutsideBet(bet.betType) && itemHovered === betKey) {
        return true;
      }

      return false;
    },
    [highlightedNumbers, isBettingTime, itemHovered]
  );

  return { isHighlighted };
};

export default useHighlighted;
