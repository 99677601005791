import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import cl from 'clsx';
import styles from './StraightUpView.module.scss';
import { BET_TYPE, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import { useCallback, useMemo } from 'react';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createBetKey, getNumbersByOutsideBet, getRacetrackNamedBets, isRacetrackBet } from '@/lib/rouletteService';
import { addBetThunk } from '@/store/thunks/gameThunks';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { VIEW_TYPE } from '@/enums/ui';

const StraightUpView = () => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { t } = useTranslation('common');
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const isBettingTime = useSelector(selectIsBettingTime);
  const dispatch = useDispatch();
  const isDisabled = !isBettingTime;

  const firstGridItems = useMemo(
    () => [
      { id: uuidv4(), label: 'Straight Up', betType: BET_TYPE.STRAIGHT_UP_ALL },
      { id: uuidv4(), label: t('red'), betType: BET_TYPE.RED },
      { id: uuidv4(), label: t('black'), betType: BET_TYPE.BLACK },
      { id: uuidv4(), label: t('odd'), betType: BET_TYPE.ODD },
      { id: uuidv4(), label: t('even'), betType: BET_TYPE.EVEN },
      { id: uuidv4(), label: '1-18', betType: BET_TYPE.ONE_TO_EIGHTEEN },
      { id: uuidv4(), label: '19-36', betType: BET_TYPE.NINETEEN_TO_THIRTY_SIX },
      { id: uuidv4(), label: `${t('first')} ${t('column')}`, betType: BET_TYPE.RIGHT_COLUMN },
      { id: uuidv4(), label: `${t('first')} ${t('dozen')}`, betType: BET_TYPE.FIRST_TWELVE },
      { id: uuidv4(), label: `${t('second')} ${t('column')}`, betType: BET_TYPE.MIDDLE_COLUMN },
      { id: uuidv4(), label: `${t('second')} ${t('dozen')}`, betType: BET_TYPE.SECOND_TWELVE },
      { id: uuidv4(), label: `${t('third')} ${t('column')}`, betType: BET_TYPE.LEFT_COLUMN },
      { id: uuidv4(), label: `${t('third')} ${t('dozen')}`, betType: BET_TYPE.THIRD_TWELVE },
    ],
    [t]
  );

  const secondGridItems = useMemo(
    () => [
      { id: uuidv4(), label: 'Tier', betType: RACE_TRACK_BET_TYPE.TIER },
      { id: uuidv4(), label: 'Orphelins', betType: RACE_TRACK_BET_TYPE.ORPHELINS },
      { id: uuidv4(), label: 'Voisins', betType: RACE_TRACK_BET_TYPE.VOISINS },
      { id: uuidv4(), label: 'Zero', betType: RACE_TRACK_BET_TYPE.ZERO },
    ],
    []
  );

  const handleMouseEnter = useCallback(
    (item) => {
      if (isDisabled || isTouchDevice) return;

      const numbers = isRacetrackBet(item.betType)
        ? [...new Set(getRacetrackNamedBets(item.betType).flatMap((curr) => curr.numbers))]
        : getNumbersByOutsideBet(item.betType);

      dispatch(setHighlightedNumbers(numbers));
      const bets = numbers.map((number) => ({
        betType: BET_TYPE.STRAIGHT,
        numbers: [number],
        value: selectedChip,
      }));

      dispatch(setBetsPreview(bets));
    },
    [dispatch, isDisabled, isTouchDevice, selectedChip]
  );

  const handleMouseLeave = useCallback(() => {
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
  }, [dispatch]);

  const handleClick = useCallback(
    (event, item) => {
      const numbers = isRacetrackBet(item.betType)
        ? [...new Set(getRacetrackNamedBets(item.betType).flatMap((curr) => curr.numbers))]
        : getNumbersByOutsideBet(item.betType);

      const bets = numbers.map((number) => ({
        betType: createBetKey(BET_TYPE.STRAIGHT, [number]),
        bet: selectedChip,
      }));

      dispatch(addBetThunk(bets, event));
    },
    [dispatch, selectedChip]
  );

  const renderGrid = (items) =>
    items.map((item) => (
      <div
        key={item.id}
        className={styles.gridItem}
        onMouseEnter={() => handleMouseEnter(item)}
        onMouseLeave={handleMouseLeave}
        onClick={(event) => handleClick(event, item)}
      >
        {item.label}
      </div>
    ));

  return (
    <div className={cl(styles.container, isDisabled && styles.disabled)}>
      <div className={styles.firstGrid}>{renderGrid(firstGridItems)}</div>
      <div className={styles.secondGrid}>{renderGrid(secondGridItems)}</div>
    </div>
  );
};

export default StraightUpView;
