import useMoney from '@/hooks/useMoney';
import { LOCALE_TYPE } from '@/lib/paramService';
import clsx from 'clsx';
import { useState } from 'react';
import styles from './HistoryViewTableRow.module.scss';

const HistoryViewTableRow = ({ index, date, bet, win, onItemSelected }) => {
  const [isOver, setIsOver] = useState(false);
  const { format } = useMoney();

  return (
    <>
      <div
        className={clsx(styles.column, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={() => onItemSelected(index)}
      >
        {date.toLocaleTimeString(LOCALE_TYPE, { hour12: false })}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={() => onItemSelected(index)}
      >
        {format(bet)}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={() => onItemSelected(index)}
      >
        {format(win)}
      </div>
    </>
  );
};

export default HistoryViewTableRow;
