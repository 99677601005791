import { motion } from 'framer-motion';
import clsx from 'clsx';
import CloseIcon from '@/assets/svg/close.svg?react';
import { useDispatch } from 'react-redux';
import { closeSlidePopup } from '@/store/uiSlice';
import styles from './SlidePopup.module.scss';
import { useCallback } from 'react';
import { playPressSound } from '@/lib/soundService';
import useShortcut from '@/hooks/useShortcut';

const SlidePopup = ({ title, icon, showCloseButton = true, direction = 'left', className, children }) => {
  const dispatch = useDispatch();

  const variants = {
    open: {
      opacity: 1,
      x: 0,
    },
    closed: {
      opacity: 0,
      x: '100%',
    },
  };

  const handleCloseSlidePopup = useCallback(() => {
    playPressSound();
    dispatch(closeSlidePopup());
  }, [dispatch]);

  const closeShortCut = [{ key: 'Escape' }];

  const handleShortcuts = useCallback(
    (event) => {
      dispatch(closeSlidePopup());
    },
    [dispatch]
  );

  useShortcut(closeShortCut, handleShortcuts);

  return (
    <motion.div
      animate={'open'}
      initial={'closed'}
      transition={{ duration: 0.4, type: 'tween' }}
      variants={variants}
      className={clsx(styles.container, className)}
    >
      {title && (
        <div className={styles.header}>
          {icon && <div>{icon}</div>}
          <div>{title}</div>
          {showCloseButton && (
            <CloseIcon height=".75em" className={styles.closeButton} onClick={handleCloseSlidePopup} />
          )}
        </div>
      )}

      <div className={styles.content}>{children}</div>
    </motion.div>
  );
};

export default SlidePopup;
