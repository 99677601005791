export const SOCKET_IO_EVENT = Object.freeze({
  /** @type {string} Fired upon a connection error. */
  ON_ERROR: 'error',
  /** @type {string} Fired upon a successful reconnection. */
  ON_RECONNECT: 'reconnect',
  /** @type {string} Fired upon an attempt to reconnect. */
  ON_RECONNECT_ATTEMPT: 'reconnect_attempt',
  /** @type {string} Fired upon a reconnection attempt error. */
  ON_RECONNECT_ERROR: 'reconnect_error',
  /** @type {string} Fired when couldn't reconnect within reconnectionAttempts. */
  ON_RECONNECT_FAILED: 'reconnect_failed',
});

export const SOCKET_CLIENT_EVENT = Object.freeze({
  /** @type {string} This event is fired by the Socket instance upon connection and reconnection. */
  ON_CONNECT: 'connect',
  /** @type {string} This event is fired upon connection failure. */
  ON_CONNECT_ERROR: 'connect_error',
  /** @type {string} This event is fired upon disconnection. */
  ON_DISCONNECT: 'disconnect',
  /** @type {string} Fired when the client is going to be disconnected (but hasn't left its rooms yet). */
  ON_DISCONNECTING: 'disconnecting',
});

export const SOCKET_ACTION = Object.freeze({
  /** @type {string} Fired when a player adds a new bet to the table. */
  ON_BET_ADDED: 'onBetAdded',
  /** @type {string} Fired when a player removes all it's bets from the table at once. */
  ON_BET_ALL_REMOVED: 'onBetAllRemoved',
  /** @type {string} Fired when a player saves a new favorite bet. */
  ON_BET_FAVORITE_ADDED: 'onBetFavoriteAdded',
  /** @type {string} Fired when a player deletes an existing favorite bet. */
  ON_BET_FAVORITE_REMOVED: 'onBetFavoriteRemoved',
  /** @type {string} Fired when a player moves a bet between on the grid. */
  ON_BET_MOVEMENT_ADDED: 'onBetMovementAdded',
  /** @type {string} Fired when a player cancels a bet movement on the grid. */
  ON_BET_MOVEMENT_REMOVED: 'onBetMovementRemoved',
  /** @type {string} Fired when a player removes an existing bet from the table. */
  ON_BET_REMOVED: 'onBetRemoved',
  /** @type {string} Fired when a player sends a chat message. */
  ON_CHAT_MESSAGE_SENT: 'onChatMessageSent',
  /** @type {string} Fired when a player wants to get the advanced statistics of the previous games. */
  ON_GET_ADVANCED_STATISTICS: 'onGetAdvancedStatistics',
  /** @type {string} Fired when a player wants to get it's existing favorite bets. */
  ON_GET_FAVORITE_BETS: 'onGetFavoriteBets',
  /** @type {string} Fired when a player wants to a history entry data of the previous games. */
  ON_GET_HISTORY_ITEM: 'onGetHistoryItem',
  /** @type {string} Fired when a player wants to get the history of the previous games. */
  ON_GET_HISTORY: 'onGetHistory',
  /** @type {string} Fired when a player wants to get hot and cold data of the previous games. */
  ON_GET_HOT_AND_COLD_DATA: 'onGetHotAndColdData',
  /** @type {string} Fired when a player wants to get the winning numbers of the previous games. */
  ON_GET_WINNING_NUMBERS: 'onGetWinningNumbers',
  /** @type {string} Fired when a player changes it's custom game settings. */
  ON_PLAYER_SETTINGS_CHANGED: 'onPlayerSettingsChanged',
  /** @type {string} Fired when a player wants to get it's subscriber token. */
  ON_GET_SUBSCRIBER_TOKEN: 'onGetSubscriberToken',
});

export const SOCKET_EVENT = Object.freeze({
  /** @type {string} Fired when a client authentication succeeds. */
  ON_AUTHENTICATED: 'onAuthenticated',
  /** @type {string} Fired when a client authentication fails. */
  ON_AUTHENTICATION_ERROR: 'onAuthenticationError',
  /** @type {string} Fired when a client sends a chat message. */
  ON_CHAT_MESSAGE_SENT: 'onChatMessageSent',
  /** @type {string} Fired when a table dealer assignment happens by the pit boss. */
  ON_DEALER_CHANGED: 'onDealerChanged',
  /** @type {string} Fired when a game is voided. */
  ON_VOID_GAME: 'onVoidGame',
  /** @type {string} Fired after the platform authentication succeeds. */
  ON_PLAYER_CONNECTED: 'onPlayerConnected',
  /** @type {string} Fired when a player wins in a game round. */
  ON_RESULT_ARRIVED: 'onResultArrived',
  /** @type {string} Fired when a round state change event happens. */
  ON_ROUND_CHANGED: 'onRoundChanged',
  /** @type {string} Fired when a new game round creation happens. */
  ON_ROUND_CREATED: 'onRoundCreated',
  /** @type {string} Fired when the game settings has been changed at the admin interface. */
  ON_SETTINGS_CHANGED: 'onSettingsChanged',
  /** @type {string} Fired before a table state change will happen, so the actual game round can finish. */
  ON_TABLE_STATE_CHANGE_PENDING: 'onTableStateChangePending',
  /** @type {string} Fired when the pit boss changes the table state or the studio does this when detects an abnormality. */
  ON_TABLE_STATE_CHANGED: 'onTableStateChanged',
  /** @type {string} Fired on every minute to sync time between the clients. */
  ON_TIME_CHANGED: 'onTimeChanged',
  /** @type {string} Fired the current game round timer state changes. */
  ON_TIMER_CHANGED: 'onTimerChanged',
  /** @type {string} Fired the current game round timer finishes. */
  ON_TIMER_STOPPED: 'onTimerStopped',
  /** @type {string} Fired the current game round winners are calculated. */
  ON_WINNERS_ARRIVED: 'onWinnersArrived',
});
