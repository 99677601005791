import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import clsx from 'clsx';
import styles from './SingleLineMessageWall.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearWinners, setLastChatMessage } from '@/store/messageWallSlice';
import useWinners from '@/hooks/useWinners';
import { CHAT_MESSAGE_TYPE } from '@/enums/chat';

const SingleLineMessageWall = ({ className }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { messages: winnerMessages } = useWinners();
  const lastChatMessage = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.messageWall.lastChatMessage
  );
  const [messageToDisplay, setMessageToDisplay] = useState(null);
  const [chatMessageToDisplay, setChatMessageToDisplay] = useState(null);
  const chatTimeoutId = useRef(null);
  const dispatch = useDispatch();

  const messageAnimation = useMemo(
    () => ({
      initial: { transform: 'translateY(100%)', opacity: 0 },
      animate: { transform: 'translateY(0%)', opacity: 1 },
      exit: { transform: 'translateY(-100%)', opacity: 0 },
    }),
    []
  );

  const createMessageWrapper = useCallback(
    (key, children) => {
      return (
        <motion.div
          key={key}
          className={styles.message}
          variants={messageAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {children}
        </motion.div>
      );
    },
    [messageAnimation]
  );

  useEffect(() => {
    let intervalId;
    if (isInitialized && winnerMessages && winnerMessages?.length > 0) {
      let index = 0;
      setMessageToDisplay(index);
      intervalId = setInterval(() => {
        index++;
        if (index >= winnerMessages.length) {
          setMessageToDisplay(null);
          clearInterval(intervalId);
          dispatch(clearWinners());
          return;
        }
        setMessageToDisplay(index);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, isInitialized, winnerMessages]);

  useEffect(() => {
    if (isInitialized && lastChatMessage) {
      if (chatTimeoutId.current) {
        setChatMessageToDisplay(null);
        clearTimeout(chatTimeoutId.current);
        chatTimeoutId.current = null;
      }

      const messageToDisplay = createMessageWrapper(
        lastChatMessage.id,
        <>
          <span className={clsx(styles.userName, lastChatMessage.type === CHAT_MESSAGE_TYPE.DEALER && styles.dealer)}>
            {lastChatMessage.screenName}:
          </span>
          {lastChatMessage.message}
        </>
      );
      setChatMessageToDisplay(messageToDisplay);
      chatTimeoutId.current = setTimeout(() => {
        dispatch(setLastChatMessage(null));
        setChatMessageToDisplay(null);
      }, 3000);
    }
  }, [createMessageWrapper, dispatch, isInitialized, lastChatMessage, messageAnimation]);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  return (
    <div className={clsx(styles.container, className)}>
      <AnimatePresence>
        {!chatMessageToDisplay &&
          messageToDisplay !== null &&
          createMessageWrapper(winnerMessages[messageToDisplay].id, winnerMessages[messageToDisplay].message)}
        {chatMessageToDisplay && chatMessageToDisplay}
      </AnimatePresence>
    </div>
  );
};

export default SingleLineMessageWall;
