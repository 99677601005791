import { POINTER_TOOLTIP_TYPE } from '@/enums/pointerTooltipType';
import { getBetSumValue } from '@/lib/betService';
import {
  aggregateBetsByType,
  checkBetLimit,
  isBetMovementOpposite,
  isBetPlacementOpposite,
} from '@/lib/rouletteService';
import socketApi from '@/lib/socketApi';
import { playBetMultipleSound, playBetOneChipSound, playNotAllowedSound } from '@/lib/soundService';
import { showPointerTooltip } from '@/lib/uiService';
import { Logger } from '@vpmedia/simplify';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  addBet,
  clearTable,
  moveBet,
  setBetHistory,
  setBets,
  setBetsMovements,
  setNewRound,
  setRoundState,
  undoBet,
  undoBetMovement,
} from '../gameSlice';
import { selectIsBettingTime } from '../selectors/gameSelectors';
import { decreaseBalance, increaseBalance, setBalance } from '../userSlice';
import { setIsWinningBetRetained } from '../tableSlice';
import { mapAddBetsPayload } from '@/lib/mapperService';

const logger = new Logger('gameThunks');

const transactionsQueue = [];

/**
 * Handles the logic after a transaction is completed, ensuring the correct order.
 * @param {string} transactionId - The ID of the transaction that was completed.
 * @param {number} balance - The new balance to be set.
 * @param {Function} dispatch - The dispatch function to send actions to the Redux store.
 */
const handleTransactionCompletion = (transactionId, balance, dispatch) => {
  const index = transactionsQueue.indexOf(transactionId);
  if (index !== -1) {
    transactionsQueue.splice(index, 1);
  }

  if (transactionsQueue.length === 0 || transactionsQueue.at(-1) === transactionId) {
    dispatch(setBalance(balance));
  }
};

/**
 * TBD.
 * @param {{roundState: string, winningNumber: number}} payload - TBD.
 * @returns {object} TBD.
 */
export const setRoundStateThunk = (payload) => (dispatch, getState) => {
  dispatch(setRoundState(payload));
};

/**
 * TBD.
 * @param {string} roundId - TBD.
 * @param {string} roundStartedAt - TBD.
 * @returns {object} TBD.
 */
export const newRoundThunk = (roundId, roundStartedAt) => (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState, settings: import('../settingsSlice').SettingsState }} */
  const { game: gameState, settings: settingsState } = getState();

  if (gameState.win > 0) {
    dispatch(increaseBalance(gameState.win));
  }

  dispatch(setIsWinningBetRetained(false));
  dispatch(clearTable());
  dispatch(setNewRound({ roundId, roundStartedAt }));

  //handle leave winning bets
  const results = gameState.results;
  const leaveWinningBets = settingsState.general.leaveWinningBets;
  // since getState made a shallow copy of gameState, betHistory contains previousBet
  const previousBet = gameState.betHistory;

  if (leaveWinningBets && results && previousBet?.length > 0) {
    const winnerBetTypes = new Set(Object.keys(results).filter((key) => results[key] > 0));
    const winnerPrevBets = previousBet.filter((bet) => winnerBetTypes.has(bet.betType));
    const betsToRepeat = aggregateBetsByType(winnerPrevBets).map((bet) => ({ ...bet, isWinningBet: true }));

    if (betsToRepeat.length > 0) {
      dispatch(addBetThunk(betsToRepeat));
      dispatch(setIsWinningBetRetained(true));
    }
  }
};

/**
 * TBD.
 * @param {object} event - TBD.
 * @param {string} message - TBD.
 * @param {string} type - TBD.
 * @returns {boolean} TBD.
 */
const showTooltipAndReturn = (event, message, type) => {
  if (event) {
    showPointerTooltip(event, message, type);
  }
  playNotAllowedSound();
  return true;
};

/**
 * TBD.
 * @param {{betType: string, bet: number}[]} bets - TBD.
 * @param {object} event - TBD.
 * @returns {object} - TBD.
 */
export const addBetThunk = (bets, event) => async (dispatch, getState) => {
  if (!bets || bets.length === 0) return;

  /** @type {{ user: import('../userSlice').UserState, game: import('../gameSlice').GameState }} */
  const { user: userState, game: gameState } = getState();
  const transactionId = uuidv4();
  const mappedBets = bets.map((bet) => ({
    ...bet,
    betId: uuidv4(),
    transactionId,
    createdAt: new Date().toISOString(),
  }));

  const betSumValue = getBetSumValue(mappedBets);
  const betLimitError = checkBetLimit(gameState.betLimits, bets, gameState.bets);

  if (!selectIsBettingTime(getState())) {
    return showTooltipAndReturn(event, t('common:nextGameStartsSoon'), POINTER_TOOLTIP_TYPE.NEW_GAME_SOON);
  }

  if (!betSumValue || betSumValue > userState.balance) {
    return showTooltipAndReturn(event, t('common:lowBalance'), POINTER_TOOLTIP_TYPE.LOW_BALANCE);
  }

  const { settings } = gameState;
  if (settings?.featureFlags?.isDenyOppositeBets && isBetPlacementOpposite(gameState.betHistory, mappedBets)) {
    return showTooltipAndReturn(event, t('common:oppositeBet'), POINTER_TOOLTIP_TYPE.OPPOSITE_BET);
  }

  if (betLimitError) {
    return showTooltipAndReturn(
      event,
      `${t('common:maxBet')}: ${betLimitError.maxBet}`,
      POINTER_TOOLTIP_TYPE.BET_LIMIT_REACHED
    );
  }

  if (bets.length === 1) {
    playBetOneChipSound();
  } else {
    playBetMultipleSound();
  }

  try {
    transactionsQueue.push(transactionId);
    dispatch(decreaseBalance(betSumValue));
    dispatch(addBet(mappedBets));

    const addBetPayload = mapAddBetsPayload(mappedBets);
    const addBetResult = await socketApi.addBet(addBetPayload);
    // logger.info('addBetThunk', addBetResult);
    if (!Number.isNaN(addBetResult.balance)) {
      handleTransactionCompletion(transactionId, addBetResult.balance, dispatch);
    }
  } catch (error) {
    // @ts-ignore
    logger.error('addBetThunk', error);
    dispatch(increaseBalance(betSumValue));
    dispatch(undoBet(transactionId));
    throw error;
  } finally {
    const index = transactionsQueue.indexOf(transactionId);
    if (index !== -1) {
      transactionsQueue.splice(index, 1);
    }
  }
};

/**
 * TBD.
 * @param {object} event - TBD.
 * @returns {object} - TBD.
 * @throws {Error} If there is an error moving the bet.
 */
export const moveBetThunk = (event) => {
  return async (dispatch, getState) => {
    const { table: tableState, game: gameState } = getState();
    const { dragAndDrop } = tableState;
    const transactionId = uuidv4();
    const draggedBetType = dragAndDrop.draggedBetType;
    const droppedBetType = dragAndDrop.betSpotHovered;
    if (draggedBetType === droppedBetType) return;

    const bets = gameState.betHistory
      .filter((bet) => bet.betType === draggedBetType)
      .map((bet) => ({ ...bet, betType: droppedBetType }));
    const betLimitError = checkBetLimit(gameState.betLimits, bets, gameState.bets);

    if (betLimitError) {
      return showTooltipAndReturn(
        event,
        `${t('common:maxBet')}: ${betLimitError.maxBet}`,
        POINTER_TOOLTIP_TYPE.BET_LIMIT_REACHED
      );
    }

    const { settings } = gameState;
    if (
      settings?.featureFlags?.isDenyOppositeBets &&
      isBetMovementOpposite(gameState.betHistory, draggedBetType, droppedBetType)
    ) {
      return showTooltipAndReturn(event, t('common:oppositeBet'), POINTER_TOOLTIP_TYPE.OPPOSITE_BET);
    }

    const payload = {
      transactionId,
      draggedBetType,
      droppedBetType,
    };

    try {
      dispatch(moveBet({ draggedBetType, droppedBetType, transactionId }));
      await socketApi.addBetMovement(payload);
    } catch (error) {
      // @ts-ignore
      logger.error('moveBetThunk', error);
      dispatch(undoBet(transactionId));
      throw error;
    }
  };
};

// eslint-disable-next-line unicorn/consistent-function-scoping
export const undoBetThunk = () => async (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState }} */
  const { game: state } = getState();
  const betHistory = state.betHistory;
  const betsMovements = state.betsMovements;

  const combinedTransaction = [...betHistory, ...betsMovements];
  if (combinedTransaction.length === 0) {
    return;
  }

  // @ts-ignore
  combinedTransaction.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const lastTransactionId = combinedTransaction[0]?.transactionId;
  const transactionItem = combinedTransaction.find((item) => item.transactionId === lastTransactionId);
  const firstItem = transactionItem;
  // @ts-ignore
  const isMovement = firstItem?.draggedBetType;

  try {
    if (isMovement) {
      dispatch(undoBetMovement(lastTransactionId));
      await socketApi.removeBetMovement(lastTransactionId);
    } else {
      transactionsQueue.push(lastTransactionId);
      const currentBets = betHistory.filter((bet) => bet.transactionId === lastTransactionId);
      const betSumValue = getBetSumValue(currentBets);
      dispatch(increaseBalance(betSumValue));
      dispatch(undoBet(lastTransactionId));
      const removeBetResult = await socketApi.removeBet(lastTransactionId);
      logger.info('undoBetThunk', removeBetResult);
      if (!Number.isNaN(removeBetResult.balance)) {
        handleTransactionCompletion(lastTransactionId, removeBetResult.balance, dispatch);
      }
    }
  } catch (error) {
    // @ts-ignore
    logger.error('undoBetThunk', error);
    if (isMovement) {
      // @ts-ignore
      const draggedBetType = firstItem.draggedBetType;
      // @ts-ignore
      const droppedBetType = firstItem.droppedBetType;
      dispatch(moveBet({ draggedBetType, droppedBetType, transactionId: lastTransactionId }));
    } else {
      const currentBets = betHistory.filter((bet) => bet.transactionId === lastTransactionId);
      const betSumValue = getBetSumValue(currentBets);
      dispatch(decreaseBalance(betSumValue));
      dispatch(addBet(currentBets));
    }
  } finally {
    if (!isMovement) {
      const index = transactionsQueue.indexOf(lastTransactionId);
      if (index !== -1) {
        transactionsQueue.splice(index, 1);
      }
    }
  }
};

// eslint-disable-next-line unicorn/consistent-function-scoping
export const undoAllBetsThunk = () => async (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState }} */
  const { game: state } = getState();
  const originalBetHistory = [...state.betHistory];
  const originalBets = { ...state.bets };
  const originalBetsMovements = [...state.betsMovements];

  if (originalBetHistory.length === 0) {
    return;
  }
  const transactionId = uuidv4();
  try {
    transactionsQueue.push(transactionId);
    const betSumValue = getBetSumValue(originalBetHistory);
    dispatch(increaseBalance(betSumValue));
    dispatch(setBetHistory([]));
    dispatch(setBets({}));
    dispatch(setBetsMovements([]));
    const removeAllBetsResult = await socketApi.removeAllBets();
    logger.info('undoAllBetsThunk', removeAllBetsResult);
    if (!Number.isNaN(removeAllBetsResult.balance)) {
      handleTransactionCompletion(transactionId, removeAllBetsResult.balance, dispatch);
    }
  } catch (error) {
    // @ts-ignore
    logger.error('undoAllBetsThunk', error);
    const betSumValue = getBetSumValue(originalBetHistory);
    dispatch(decreaseBalance(betSumValue));
    dispatch(setBetHistory(originalBetHistory));
    dispatch(setBets(originalBets));
    dispatch(setBetsMovements(originalBetsMovements));
  } finally {
    const index = transactionsQueue.indexOf(transactionId);
    if (index !== -1) {
      transactionsQueue.splice(index, 1);
    }
  }
};

// eslint-disable-next-line unicorn/consistent-function-scoping
export const refundThunk = () => (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState }} */
  const { game: state } = getState();

  if (state.betHistory.length > 0) {
    const betSumValue = getBetSumValue(state.betHistory);
    dispatch(increaseBalance(betSumValue));
    dispatch(setBetHistory([]));
    dispatch(setBets({}));
    dispatch(setBetsMovements([]));
  }
};
