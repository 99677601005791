import _ from 'lodash';

/**
 * TBD.
 * @param {number} start - TBD.
 * @param {number} end - TBD.
 * @returns {number[]} TBD.
 */
export const range = (start, end) => {
  return Array.from({ length: end - start + 1 })
    .fill()
    .map((_, idx) => start + idx);
};

/**
 * TBD.
 * @param {object[]} arr - TBD.
 * @param {string[]} allowedFields - TBD.
 * @returns {object[]} TBD.
 */
export const filterObjectFields = (arr, allowedFields) => {
  return arr.map((item) => _.pick(item, allowedFields));
};
