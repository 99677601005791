import ChipSelector from '@/components/ChipSelector';
import HistoryLine from '@/components/HistoryLine';
import IconButton from '@/components/IconButton';
import Timer from '@/components/Timer';
import Wallet from '@/components/Wallet';
import WinningNumber from '@/components/WinningNumber';
import ROUND_STATE from '@/enums/roundState';
import StatIcon from '@/assets/svg/statistics.svg?react';
import { closeFavoritesPopup, closeStatisticsPopup, openFavoritesPopup, openStatisticsPopup } from '@/store/uiSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import RacetrackWithSelector from '../../RacetrackWithSelector';
import Tooltip from '../../Tooltip';
import styles from './DesktopView.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import GameMessage from '@/components/GameMessage';
import DesktopDrawer from '@/components/DesktopDrawer';
import { playPressSound } from '@/lib/soundService';
import { QUICK_CHAT_TYPE } from '@/enums/ui';
import QuickChat from '../../QuickChat';
import RouletteTable from '@/components/RouletteTable';

const DesktopView = () => {
  const [ref, { width, height }] = useMeasure();
  const { t } = useTranslation(['common', 'tooltip']);
  const rightSectionRef = useRef();
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const showStatisticsPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showStatisticsPopup
  );
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const layoutTableContainerRef = useRef();
  const showFavoritesPopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showFavoritesPopup
  );
  const dispatch = useDispatch();

  const isBettingTime = useSelector(selectIsBettingTime);

  const toggleStatistics = useCallback(() => {
    playPressSound();
    showStatisticsPopup ? dispatch(closeStatisticsPopup()) : dispatch(openStatisticsPopup());
  }, [dispatch, showStatisticsPopup]);

  const toggleFavorites = useCallback(() => {
    playPressSound();
    showFavoritesPopup ? dispatch(closeFavoritesPopup()) : dispatch(openFavoritesPopup());
  }, [dispatch, showFavoritesPopup]);

  const perspectiveContainerAnimations = {
    opened: {
      y: '0%',
      transition: {
        duration: 0.8,
      },
    },
    closed: {
      y: '4rem',
      transition: {
        duration: 0.8,
      },
    },
  };

  const tableVariants = {
    opened: {
      rotateX: '0deg',
      scaleY: 1,
      transition: {
        ease: 'linear',
        duration: 0.6,
      },
    },
    closed: {
      rotateX: '32deg',
      scaleY: 0.9,
      transition: {
        ease: 'linear',
        duration: 0.6,
      },
    },
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.wallet}>
            <Wallet className={styles.wallet} />
          </div>
        </div>
        <div className={styles.center}>
          <div ref={layoutTableContainerRef} className={styles.layoutTableContainer}>
            <div key="main-timer" className={styles.timer}>
              <Timer size={100} />
            </div>
            <div className={clsx(styles.gameMessage, !isBettingTime && styles.stickToCollapsedTable)}>
              <GameMessage />
            </div>

            {roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null && (
              <div className={styles.winningNumber}>
                <WinningNumber showWin={true} number={winningNumber} />
              </div>
            )}

            <motion.div
              ref={ref}
              animate={isBettingTime ? 'opened' : 'closed'}
              initial={isBettingTime ? 'opened' : 'closed'}
              variants={perspectiveContainerAnimations}
              className={styles.perspectiveContainer}
            >
              <motion.div
                className={styles.rouletteTable}
                animate={isBettingTime ? 'opened' : 'closed'}
                initial={false}
                variants={tableVariants}
              >
                <RouletteTable />
              </motion.div>
            </motion.div>
          </div>
          <div className={clsx(styles.chipSelectorContainer, !isBettingTime && styles.closed)}>
            <ChipSelector mode="list" />
          </div>
        </div>
        <div ref={rightSectionRef} className={styles.right}>
          <div className={styles.historyContainer}>
            <HistoryLine className={styles.history} withBoxShadow={true} />
          </div>

          <div className={styles.racetrackContainer}>
            <RacetrackWithSelector backgroundFilled={true} />
          </div>
          <div className={styles.buttonContainer}>
            <Tooltip content={t('favoriteAndSpecialBets')} distance={6}>
              <IconButton
                icon={<FaStar size="1em" />}
                className={clsx(styles.iconButton, showFavoritesPopup && styles.activeMenuItem)}
                onClick={toggleFavorites}
              />
            </Tooltip>
            <Tooltip content={t('statistics')} distance={6}>
              <IconButton
                icon={<StatIcon height="1em" />}
                className={clsx(styles.iconButton, showStatisticsPopup && styles.activeMenuItem)}
                onClick={toggleStatistics}
              />
            </Tooltip>
          </div>
        </div>

        <DesktopDrawer />
      </div>
      <div className={styles.quickChat}>
        <QuickChat type={QUICK_CHAT_TYPE.NORMAL} />
      </div>
    </>
  );
};

export default DesktopView;
