import AutoRouletteVoices from '@/components/AutoRouletteVoices';
import Modal from '@/components/Modal';
import SocketHandler from '@/components/SocketHandler';
import LowBalancePopup from '@/components/Wallet/LowBalancePopup';
import { GAME_TYPE } from '@/enums/gameType';
import styles from '@/styles/App.module.scss';
import { Logger } from '@vpmedia/simplify';
import cl from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AspectRatioContainer from './components/AspectRatioContainer';
import LoadingScreen from './components/LoadingScreen';
import Managers from './components/Managers';
import PointerTooltip from './components/PointerTooltip';
import TableMaintenanceView from './components/TableMaintenanceView';
import CustomTooltip from './components/Tooltip';
import { TABLE_STATE } from './enums/tableState';
import { VIEW_TYPE } from './enums/ui';
import { isErrorTerminating } from './lib/errorService';
import DesktopView from './views/DesktopView/DesktopView';
import TouchView from './views/TouchView';

const logger = new Logger('App');

/**
 * TBD.
 * @returns {import('react').ReactElement} Output to render.
 */
const App = () => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const [isAspectRatioContainerInitialized, setIsAspectRatioContainerInitialized] = useState(false);
  const isUiInitialized = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.isInitialized
  );
  const isGameInitialized = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.isInitialized
  );
  const gameType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameType);
  const tableState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.tableState);
  const errorData = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.error.errorData);

  const onAspectRatioContainerInitialized = useCallback(() => {
    setIsAspectRatioContainerInitialized(true);
  }, []);

  const isCriticalError = errorData && isErrorTerminating(errorData.errorCode);
  const isRenderLoadingScreen = !isUiInitialized || !isGameInitialized || !viewType;
  const isRenderSocketHandler = isUiInitialized && viewType;
  const isRenderGameScreen = isUiInitialized && isGameInitialized && viewType && !isCriticalError;

  logger.info('Render', { isUiInitialized, isGameInitialized, viewType });

  const renderContent = useMemo(() => {
    return (
      <>
        {isRenderLoadingScreen && <LoadingScreen />}
        {isRenderSocketHandler && <SocketHandler />}
        {isRenderGameScreen && (
          <div
            className={cl(
              styles.container,
              viewType === VIEW_TYPE.TOUCH ? styles.isTouchDevice : styles.isDesktopDevice
            )}
          >
            <>
              {viewType === VIEW_TYPE.TOUCH ? <TouchView /> : isAspectRatioContainerInitialized && <DesktopView />}
              <LowBalancePopup />
              {gameType === GAME_TYPE.AUTO_ROULETTE && <AutoRouletteVoices />}
            </>
          </div>
        )}

        <Managers />
        <Modal />
        <CustomTooltip id="global-tooltip" />
        <PointerTooltip />
      </>
    );
  }, [
    gameType,
    isAspectRatioContainerInitialized,
    isRenderGameScreen,
    isRenderLoadingScreen,
    isRenderSocketHandler,
    viewType,
  ]);

  if (tableState === TABLE_STATE.CLOSED) {
    return (
      <>
        <SocketHandler />
        <TableMaintenanceView />
      </>
    );
  }

  return viewType === VIEW_TYPE.DESKTOP ? (
    <AspectRatioContainer onAspectRatioContainerInitialized={onAspectRatioContainerInitialized}>
      {isAspectRatioContainerInitialized && renderContent}
    </AspectRatioContainer>
  ) : (
    renderContent
  );
};

export default App;
