import { Logger } from '@vpmedia/simplify';
import axios from 'axios';
import { config } from './configService';

const logger = new Logger('APIService');

const API_BASE_URL = config.VITE_BACKEND_BASE_URI;

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const apiService = axios.create();

apiService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      logger.info('Response error', error.response.data);
      logger.info('Status code', error.response.status);
    } else if (error.request) {
      logger.info('No response received', error.request);
    } else {
      logger.info('Error', error.message);
    }
    return Promise.reject(error);
  }
);

const api = {
  login: async () => {
    const response = await apiService.post('/api/auth/login');
    const { token } = response;
    logger.info('login', token);
    return token;
  },
};

export default api;
