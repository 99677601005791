import { BET_TYPE, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import { range } from '@/lib/arrayService';
import { collectBets } from './betService';

export const createBetKey = (betType, numbers) => {
  if (numbers || numbers === 0) {
    const mappedNumbers = Array.isArray(numbers) ? [...numbers] : [numbers];
    return `${[betType, ...mappedNumbers.sort()].join('_')}`;
  } else {
    return betType;
  }
};

export const parseBetKey = (key) => {
  const [betType, ...numbers] = key.split('_');
  return { betType, numbers: numbers.map(Number) };
};

export const getNeighbors = (numberOrder, number, length) => {
  const currentIndex = numberOrder.indexOf(number);

  if (currentIndex === -1) throw new Error(`Number hasn't been found on the wheel: ${number}`);

  let left, right;

  if (currentIndex - length > 0) {
    left = numberOrder.slice(currentIndex - length, currentIndex);
  } else {
    const diff = length - currentIndex;
    left = [...[...numberOrder].reverse().slice(0, diff).reverse(), ...numberOrder.slice(0, currentIndex)];
  }

  if (currentIndex + length < numberOrder.length) {
    right = numberOrder.slice(currentIndex + 1, currentIndex + 1 + length);
  } else {
    const diff = currentIndex + length - numberOrder.length + 1;
    right = [...numberOrder.slice(currentIndex + 1), ...numberOrder.slice(0, diff)];
  }

  return { left, right };
};

export const isOutsideBet = (betType) =>
  ![BET_TYPE.CORNER, BET_TYPE.STRAIGHT, BET_TYPE.STREET, BET_TYPE.SPLIT, BET_TYPE.SIX_LINE].includes(betType);

export const isRacetrackBet = (betType) =>
  [
    RACE_TRACK_BET_TYPE.ORPHELINS,
    RACE_TRACK_BET_TYPE.TIER,
    RACE_TRACK_BET_TYPE.VOISINS,
    RACE_TRACK_BET_TYPE.ZERO,
  ].includes(betType);

export const getNumbersByOutsideBet = (selectedBetType) => {
  switch (selectedBetType) {
    case BET_TYPE.STRAIGHT_UP_ALL:
      return range(0, 36);
    case BET_TYPE.FIRST_TWELVE:
      return range(1, 12);
    case BET_TYPE.SECOND_TWELVE:
      return range(13, 24);
    case BET_TYPE.THIRD_TWELVE:
      return range(25, 36);
    case BET_TYPE.RIGHT_COLUMN:
      return range(1, 36).filter((item) => item % 3 === 0);
    case BET_TYPE.MIDDLE_COLUMN:
      return range(1, 36).filter((item) => item % 3 === 2);
    case BET_TYPE.LEFT_COLUMN:
      return range(1, 36).filter((item) => item % 3 === 1);
    case BET_TYPE.EVEN:
      return range(1, 36).filter((item) => item % 2 === 0);
    case BET_TYPE.ODD:
      return range(1, 36).filter((item) => item % 2 === 1);
    case BET_TYPE.ONE_TO_EIGHTEEN:
      return range(1, 18);
    case BET_TYPE.NINETEEN_TO_THIRTY_SIX:
      return range(19, 36);
    case BET_TYPE.RED:
      return [
        ...range(1, 10).filter((item) => item % 2 === 1),
        ...range(11, 18).filter((item) => item % 2 === 0),
        ...range(19, 28).filter((item) => item % 2 === 1),
        ...range(29, 36).filter((item) => item % 2 === 0),
      ];
    case BET_TYPE.BLACK:
      return [
        ...range(1, 10).filter((item) => item % 2 === 0),
        ...range(11, 18).filter((item) => item % 2 === 1),
        ...range(19, 28).filter((item) => item % 2 === 0),
        ...range(29, 36).filter((item) => item % 2 === 1),
      ];

    default:
      throw new Error(`Unknown bet type value: ${selectedBetType}`);
  }
};

export const getColumnByNumber = (number) => Math.floor((number - 1) / 3) + 1;

export const getRacetrackNamedBets = (betType) => {
  switch (betType) {
    case RACE_TRACK_BET_TYPE.ZERO:
      return [
        { betType: BET_TYPE.STRAIGHT, numbers: [26] },
        { betType: BET_TYPE.SPLIT, numbers: [0, 3] },
        { betType: BET_TYPE.SPLIT, numbers: [12, 15] },
        { betType: BET_TYPE.SPLIT, numbers: [32, 35] },
      ];
    case RACE_TRACK_BET_TYPE.TIER:
      return [
        { betType: BET_TYPE.SPLIT, numbers: [5, 8] },
        { betType: BET_TYPE.SPLIT, numbers: [10, 11] },
        { betType: BET_TYPE.SPLIT, numbers: [13, 16] },
        { betType: BET_TYPE.SPLIT, numbers: [23, 24] },
        { betType: BET_TYPE.SPLIT, numbers: [27, 30] },
        { betType: BET_TYPE.SPLIT, numbers: [33, 36] },
      ];
    case RACE_TRACK_BET_TYPE.ORPHELINS:
      return [
        { betType: BET_TYPE.STRAIGHT, numbers: [1] },
        { betType: BET_TYPE.SPLIT, numbers: [6, 9] },
        { betType: BET_TYPE.SPLIT, numbers: [14, 17] },
        { betType: BET_TYPE.SPLIT, numbers: [17, 20] },
        { betType: BET_TYPE.SPLIT, numbers: [31, 34] },
      ];
    case RACE_TRACK_BET_TYPE.VOISINS:
      return [
        { betType: BET_TYPE.STREET, numbers: [0, 2, 3], multiplier: 2 },
        { betType: BET_TYPE.SPLIT, numbers: [4, 7] },
        { betType: BET_TYPE.SPLIT, numbers: [12, 15] },
        { betType: BET_TYPE.SPLIT, numbers: [18, 21] },
        { betType: BET_TYPE.SPLIT, numbers: [19, 22] },
        {
          betType: BET_TYPE.CORNER,
          numbers: [25, 26, 28, 29],
          multiplier: 2,
        },
        { betType: BET_TYPE.SPLIT, numbers: [32, 35] },
      ];
  }
};

export const getLimitForBetType = (betLimits, betType) => {
  switch (betType) {
    case BET_TYPE.STRAIGHT:
    case BET_TYPE.CORNER:
    case BET_TYPE.SPLIT:
    case BET_TYPE.SIX_LINE:
    case BET_TYPE.STREET:
      return betLimits[betType];
    case BET_TYPE.LEFT_COLUMN:
    case BET_TYPE.MIDDLE_COLUMN:
    case BET_TYPE.RIGHT_COLUMN:
      return betLimits.column;
    case BET_TYPE.BLACK:
    case BET_TYPE.RED:
      return betLimits.redBlack;
    case BET_TYPE.EVEN:
    case BET_TYPE.ODD:
      return betLimits.evenOdd;
    case BET_TYPE.FIRST_TWELVE:
    case BET_TYPE.SECOND_TWELVE:
    case BET_TYPE.THIRD_TWELVE:
      return betLimits.dozen;
    case BET_TYPE.ONE_TO_EIGHTEEN:
    case BET_TYPE.NINETEEN_TO_THIRTY_SIX:
      return betLimits.lowHigh;
    default:
      throw new Error(`Unsupported bet type: ${betType}`);
  }
};

export const checkBetLimit = (betLimits, bet, bets) => {
  if (!bet) return false;

  let betLimitReached;
  for (const betItem of bet) {
    const { betType: key } = betItem;
    const { betType } = parseBetKey(key);

    const limit = getLimitForBetType(betLimits, betType);
    if (!limit) {
      throw new Error(`No limit defined for bet type: ${betType}`);
    }

    const { max } = limit;
    const existingBet = bets?.[key]?.value || 0;
    const currentBet = betItem.bet || 0;

    if (max < currentBet + existingBet) {
      betLimitReached = {
        betKey: key,
        maxBet: max,
      };
    }
  }

  return betLimitReached;
};

export const checkTableLimit = (tableLimit, bet, bets) => {
  if (!bet) return false;

  const betsTotalValue = getTotalBetValue(bets);
  const incomingBetsTotalValue = getTotalBetValue(bet);

  return betsTotalValue + incomingBetsTotalValue > tableLimit.max;
};

export const isBetEmpty = (bets) => Object.keys(bets).length === 0;

export const aggregateBetsByType = (betHistory) => {
  // eslint-disable-next-line unicorn/no-array-reduce
  const betMap = betHistory.reduce((acc, betItem) => {
    if (acc[betItem.betType]) {
      acc[betItem.betType] += betItem.bet;
    } else {
      acc[betItem.betType] = betItem.bet;
    }
    return acc;
  }, {});

  return Object.keys(betMap).map((betType) => ({
    betType,
    bet: betMap[betType],
  }));
};

export const getTotalBetValue = (bets) => {
  let sumValue = 0;

  for (const key in bets) {
    if (Object.hasOwnProperty.call(bets, key)) {
      const { value } = bets[key];
      sumValue += value;
    }
  }
  return sumValue;
};

export const hasEnoughCredit = (actualBalance, bet, selectedChip) => {
  if (actualBalance === 0) return false;

  let sumBetValue;
  if (Array.isArray(bet)) {
    sumBetValue = bet.reduce((previousValue, { multiplier }) => previousValue + selectedChip * (multiplier ?? 1), 0);
  } else {
    const { multiplier } = bet;
    sumBetValue = selectedChip * (multiplier ?? 1);
  }

  return actualBalance - sumBetValue >= 0;
};

export const getOutsideBetDisplayName = (betType) => {
  switch (betType) {
    case BET_TYPE.BLACK:
      return 'black';
    case BET_TYPE.RED:
      return 'red';
    case BET_TYPE.ODD:
      return 'odd';
    case BET_TYPE.EVEN:
      return 'even';
    case BET_TYPE.ONE_TO_EIGHTEEN:
      return '1-18';
    case BET_TYPE.NINETEEN_TO_THIRTY_SIX:
      return '19-36';
    case BET_TYPE.LEFT_COLUMN:
      return '1st column';
    case BET_TYPE.MIDDLE_COLUMN:
      return '2nd column';
    case BET_TYPE.RIGHT_COLUMN:
      return '3rd column';
    case BET_TYPE.FIRST_TWELVE:
      return '1st dozen';
    case BET_TYPE.SECOND_TWELVE:
      return '2nd dozen';
    case BET_TYPE.THIRD_TWELVE:
      return '3rd dozen';
  }
};

export const getRacetrackNamedBetDisplayName = (betType) => {
  switch (betType) {
    case RACE_TRACK_BET_TYPE.VOISINS:
      return 'Serie 0/2/3';
    case RACE_TRACK_BET_TYPE.ORPHELINS:
      return 'Orphelins a cheval';
    case RACE_TRACK_BET_TYPE.TIER:
      return 'Serie 5/8';
    case RACE_TRACK_BET_TYPE.ZERO:
      return 'Jeux Zero';
  }
};

export const getChipColor = (value) => {
  if (value === 0.5) return '#faad65';
  if (value >= 1 && value < 2) return '#757575';
  if (value >= 2 && value < 5) return '#ff82d6';
  if (value >= 5 && value < 10) return '#db0000';
  if (value >= 10 && value < 25) return '#359efc';
  if (value >= 25 && value < 100) return '#39b54a';
  if (value >= 100 && value < 500) return '#000000';
  if (value >= 500 && value < 1000) return '#a700ff';
  return '#ff9d00';
};

/**
 * Checks if any bet in the history is opposite to any bet in the current bets.
 * @param {Array} betHistory - Current bet history.
 * @param {Array} bets - Array of new bet objects.
 * @returns {boolean} True if there is an opposite bet, false otherwise.
 */
export const isBetPlacementOpposite = (betHistory, bets) => {
  const combinedBets = [...betHistory, ...bets];
  const affectedNumbers = new Set();

  for (const betItem of combinedBets) {
    const bet = parseBetKey(betItem.betType);
    const numbers = isOutsideBet(bet.betType) ? getNumbersByOutsideBet(bet.betType) : bet.numbers;

    for (const number of numbers) {
      affectedNumbers.add(number);
      if (affectedNumbers.size >= 36) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Checks if dragging a bet to a new type results in an opposite bet or invalid combination.
 * @param {Array} betHistory - Current bet history.
 * @param {string} draggedBetType - The type of the bet being dragged.
 * @param {string} droppedBetType - The type of the bet being dropped.
 * @returns {boolean} True if the bet movement is opposite or invalid, false otherwise.
 */
export const isBetMovementOpposite = (betHistory, draggedBetType, droppedBetType) => {
  const cleanedBetHistory = betHistory.filter((item) => item.betType !== draggedBetType);
  const combinedBets = [...cleanedBetHistory, { betType: droppedBetType }];
  const affectedNumbers = new Set();

  for (const betItem of combinedBets) {
    const bet = parseBetKey(betItem.betType);
    const numbers = isOutsideBet(bet.betType) ? getNumbersByOutsideBet(bet.betType) : bet.numbers;

    for (const number of numbers) {
      affectedNumbers.add(number);
      if (affectedNumbers.size >= 36) {
        return true;
      }
    }
  }

  return false;
};
