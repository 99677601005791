import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} ChatMessage
 * @property {string} chatId - UUIDv4 identifier.
 * @property {string} message - Message text.
 * @property {string} screenName - Screen name.
 * @property {string} userId - Like 'GR_1'.
 * @property {string} userType - Enum player, dealer, or pitBoss.
 * @property {boolean} [isHidden] - Do not broadcast message to other players.
 */

/**
 * @typedef {object} MessageWallState - TBD.
 * @property {{publicPlayerId: string, screenName: string, win: number}[]} winners - TBD.
 * @property {ChatMessage} lastChatMessage - TBD.
 */

/** @type {MessageWallState} */
const initialState = {
  winners: null,
  lastChatMessage: null,
};

export const messageWallSlice = createSlice({
  name: 'messageWall',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {MessageWallState} state - State data.
     */
    clearWinners: (state) => {
      state.winners = null;
    },
    /**
     * TBD.
     * @param {MessageWallState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{publicPlayerId: string, screenName: string, win: number}[]>} action - Store action.
     */
    setWinners: (state, action) => {
      state.winners = action.payload;
    },
    /**
     * TBD.
     * @param {MessageWallState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<ChatMessage>} action - Store action.
     */
    setLastChatMessage: (state, action) => {
      state.lastChatMessage = action.payload;
    },
  },
});

export const { clearWinners, setWinners, setLastChatMessage } = messageWallSlice.actions;

export default messageWallSlice.reducer;
