import { useSelector } from 'react-redux';
import { GAME_TYPE } from '@/enums/gameType';
import AutoRouletteTimer from './AutoRouletteTimer';
import ClassicRouletteTimer from './ClassicRouletteTimer';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';

const Timer = ({ size }) => {
  const gameType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameType);
  const isBettingTime = useSelector(selectIsBettingTime);

  if (!isBettingTime) return null;

  return gameType === GAME_TYPE.AUTO_ROULETTE ? (
    <AutoRouletteTimer size={size} />
  ) : (
    <ClassicRouletteTimer size={size} />
  );
};

export default Timer;
