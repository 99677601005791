import { useRef, useCallback, useState, useEffect } from 'react';
import { BiCheck } from 'react-icons/bi';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './BetEditField.module.scss';

const BetEditField = ({ betItem, onNameChanged, updateNewStatus, isEditMode }) => {
  const { t } = useTranslation('roulette');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef();
  const { name } = betItem;

  const handleFocus = useCallback(() => {
    const inputElement = inputRef.current;
    inputElement.select();
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(async () => {
    await updateNewStatus(betItem);
    setIsFocused(false);
  }, [betItem, updateNewStatus]);

  const handleEsc = useCallback((event) => {
    if (event.keyCode === 27) {
      const inputElement = inputRef.current;
      inputElement.blur();
    }
  }, []);

  useEffect(() => {
    if (betItem && betItem?.isNew && inputRef.current) {
      const inputElement = inputRef.current;
      inputElement.focus();
    }
  }, [betItem]);

  return (
    <div className={styles.container}>
      {(isEditMode || betItem.isNew) && betItem.items ? (
        <div className={styles.inputContainer}>
          <input
            ref={inputRef}
            className={styles.input}
            value={name}
            onChange={(event) => onNameChanged(event, betItem)}
            onClick={(event) => event.stopPropagation()}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleEsc}
          />
          {isFocused && (
            <div className={styles.checkIcon}>
              <BiCheck />
            </div>
          )}
        </div>
      ) : (
        <div className={clsx(styles.betName, !name && styles.empty)}>{name ?? t('favBet.saveLastBet')}</div>
      )}
    </div>
  );
};

export default BetEditField;
