import styles from './NeighborNumberPicker3D.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { setRacetrackNeighborCount } from '@/store/gameSlice';
import { playPressSound } from '@/lib/soundService';

const NeighborNumberPicker3D = () => {
  const racetrackNeighborCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.racetrackNeighborCount
  );
  const dispatch = useDispatch();

  const isIncreaseEnabled = racetrackNeighborCount < 9;
  const isDecreaseEnabled = racetrackNeighborCount > 0;

  const increaseNeighborCount = useCallback(() => {
    playPressSound();
    dispatch(setRacetrackNeighborCount(racetrackNeighborCount + 1));
  }, [dispatch, racetrackNeighborCount]);

  const decreaseNeighborCount = useCallback(() => {
    playPressSound();
    dispatch(setRacetrackNeighborCount(racetrackNeighborCount - 1));
  }, [dispatch, racetrackNeighborCount]);

  return (
    <svg viewBox="0 0 103.53 57.38">
      <g>
        {isDecreaseEnabled && (
          <>
            <path
              onClick={() => decreaseNeighborCount()}
              className={styles.button}
              d="M25.49,2.12c7.22,3.26,8.95,10.82,3.9,16.83c-5,5.97-14.8,8.17-21.92,4.96C0.35,20.69-1.48,13.24,3.43,7.22 C8.37,1.15,18.27-1.15,25.49,2.12z"
              style={{ stroke: 'rgb(242, 238, 214)', fill: 'rgb(242, 238, 214)' }}
            ></path>

            <polygon
              className={styles.icon}
              points="24.27,15.04 10.57,8.86 8.69,10.99 22.37,17.17 "
              style={{ stroke: 'rgb(242, 238, 214)', fill: 'rgb(242, 238, 214)' }}
            ></polygon>
          </>
        )}
        <text
          className={styles.number}
          transform="matrix(0.947 0.412 -0.6441 0.765 38.4503 31.4047)"
          style={{ fill: 'rgb(242, 238, 214)' }}
        >
          {racetrackNeighborCount}
        </text>
        {isIncreaseEnabled && (
          <>
            <path
              onClick={() => increaseNeighborCount()}
              className={styles.button}
              d="M96.32,33.96c7.17,3.24,8.66,10.67,3.36,16.54c-5.26,5.83-15.22,7.94-22.3,4.74 c-7.08-3.19-8.67-10.52-3.51-16.4C79.07,32.92,89.14,30.72,96.32,33.96z"
              style={{ stroke: 'rgb(242, 238, 214)', fill: 'rgb(242, 238, 214)' }}
            ></path>
            <polygon
              className={styles.icon}
              points="90.38,38.25 86.5,42.96 80.91,40.43 79.12,42.62 84.7,45.14 80.85,49.8 83.43,50.97 87.29,46.31 92.87,48.83 94.68,46.65 89.1,44.13 92.99,39.43 "
              style={{ stroke: 'rgb(242, 238, 214)', fill: 'rgb(242, 238, 214)' }}
            ></polygon>
          </>
        )}
      </g>
    </svg>
  );
};

export default NeighborNumberPicker3D;
