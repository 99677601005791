import DolbyRTCPlayer from '@/components/common/DolbyRTCPlayer';
import ROUND_STATE from '@/enums/roundState';
import { DESKTOP_VIEW_TYPE, VIEW_TYPE } from '@/enums/ui';
import { versionedUrl } from '@/lib/urlService';
import { setIsStreamFullScreen, toogleStreamFullScreen } from '@/store/uiSlice';
import { Logger } from '@vpmedia/simplify';
import cl from 'clsx';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styles from './StreamView.module.scss';

const logger = new Logger('StreamView');

const StreamView = forwardRef(({ width, height, className, isCovered, fill = false }, ref) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const isSocketConnected = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isSocketConnected
  );
  const isUserConnected = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isUserConnected
  );
  const desktopView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.desktopView);
  const isStreamFullScreen = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.isStreamFullScreen
  );
  const streamSettings = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.stream
  );
  const generalSettings = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.general
  );
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const dispatch = useDispatch();
  const fullscreenTimeoutId = useRef();
  const timeout = 5000;

  const isZoomInEnabled =
    isTouchDevice &&
    [ROUND_STATE.NO_MORE_BETS, ROUND_STATE.WINNING_NUMBER].includes(roundState) &&
    generalSettings?.autoZoom;

  const isThreeDimensionView = desktopView === DESKTOP_VIEW_TYPE.THREE_DIMENSION;

  const clearFullscreenTimeoutId = useCallback(() => {
    if (fullscreenTimeoutId.current) {
      clearTimeout(fullscreenTimeoutId.current);
      fullscreenTimeoutId.current = null;
    }
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (!isThreeDimensionView) return;

    dispatch(toogleStreamFullScreen());
  }, [isThreeDimensionView, dispatch]);

  const handleStreamLoaded = useCallback(() => {
    logger.info('handleStreamLoaded');
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (roundState === ROUND_STATE.NO_MORE_BETS && isThreeDimensionView && generalSettings?.autoZoom) {
      fullscreenTimeoutId.current = setTimeout(() => {
        dispatch(setIsStreamFullScreen(true));
      }, timeout);
    }

    if (roundState === ROUND_STATE.WINNING_NUMBER) {
      clearFullscreenTimeoutId();
      dispatch(setIsStreamFullScreen(false));
    }
  }, [clearFullscreenTimeoutId, dispatch, generalSettings?.autoZoom, isThreeDimensionView, roundState]);

  useEffect(() => {
    return clearFullscreenTimeoutId;
  }, [clearFullscreenTimeoutId]);

  useEffect(() => {
    if (!isSocketConnected || !isUserConnected) {
      logger.info('handleSocketDisconnected');
      setIsInitialized(false);
    }
  }, [isSocketConnected, isUserConnected]);

  return (
    <div ref={ref} style={{ width, height }} className={cl(styles.container, className)}>
      <div className={cl(styles.streamWrapper, isZoomInEnabled && styles.zoomIn)}>
        {streamSettings?.id && isSocketConnected && isUserConnected && (
          <DolbyRTCPlayer
            accountId={streamSettings.id}
            streamName={streamSettings.uri}
            isCovered={isCovered}
            onStreamLoaded={handleStreamLoaded}
          />
        )}
        <img src={versionedUrl('/images/roulette-red-bg.png')} alt="" className={styles.carpet} />
        {!isInitialized && (
          <div className={styles.loadingOverlay}>
            <img
              src={versionedUrl('/images/stream-loading-overlay.jpg')}
              alt=""
              className={styles.loadingOverlayImage}
            />
            <Grid height="2rem" width="2rem" color="white" wrapperClass={styles.loader} />
          </div>
        )}
      </div>
      {isThreeDimensionView && (
        <div
          className={cl(styles.fullScreenToggle, isStreamFullScreen && styles.fullScreen)}
          onClick={() => toggleFullscreen()}
        />
      )}
    </div>
  );
});

StreamView.displayName = 'StreamView';

export default StreamView;
