import {
  PiSpeakerSimpleLowFill,
  PiSpeakerSimpleHighFill,
  PiSpeakerSimpleXFill,
  PiSpeakerSimpleNoneFill,
} from 'react-icons/pi';
import { IoAlert } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import styles from './SoundIndicator.module.scss';

const SoundIndicator = ({ level, isMuted, size, withUserInteractedIndicator = false }) => {
  const userInteracted = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.userInteracted
  );

  if (!userInteracted && withUserInteractedIndicator) {
    return (
      <div className={styles.iconContainer}>
        <PiSpeakerSimpleNoneFill size={size} />
        <IoAlert size={size} className={styles.alertIcon} />
      </div>
    );
  }

  if (level > 0 && !isMuted) {
    if (level >= 50) return <PiSpeakerSimpleHighFill size={size} />;

    return <PiSpeakerSimpleLowFill size={size} />;
  }

  return <PiSpeakerSimpleXFill size={size} />;
};
export default SoundIndicator;
