import { DEVICE_TYPE } from '@/enums/deviceType';
import apiClient from '@/lib/apiService';
import { config } from '@/lib/configService';
import { GAME_ID, IS_DESKTOP, TOKEN } from '@/lib/paramService';
import { Logger } from '@vpmedia/simplify';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

const URL = config.VITE_BACKEND_BASE_URI;

const logger = new Logger('Socket');

let authToken = TOKEN;

if (!TOKEN && !import.meta.env.PROD) {
  authToken = await apiClient.login();
}

// reload session id from local storage after page refresh button click
const SESSION_TIME = localStorage.getItem('sessionTime');
localStorage.removeItem('sessionTime');
if (SESSION_TIME) {
  const sessionTime = new Date(SESSION_TIME);
  if (Date.now() - sessionTime.getTime() > 5000) {
    logger.info('Session expired', { time: SESSION_TIME });
    localStorage.removeItem('sessionId');
  } else {
    logger.info('Loaded session', { time: SESSION_TIME });
  }
}

export const SESSION_ID = localStorage.getItem('sessionId') || uuidv4();
localStorage.removeItem('sessionId');

export const DEVICE = IS_DESKTOP ? DEVICE_TYPE.DESKTOP : DEVICE_TYPE.MOBILE;

export const socket = io(URL, {
  autoConnect: false,
  transports: ['websocket'],
  auth: { token: authToken, publicGameId: GAME_ID, sessionId: SESSION_ID, deviceType: DEVICE },
});
