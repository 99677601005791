import { MODAL_TYPE } from '@/enums/modalType';
import { setError } from '@/store/gameSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '../../hooks/useModal';

const StateObserver = () => {
  const { openModal } = useModal();
  const screenName = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings.screenName);
  const isInitialized = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.isInitialized
  );
  const error = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.error);
  const dispatch = useDispatch();

  const closeCustomErrorDialog = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialized && !screenName) {
      openModal('Screen name', MODAL_TYPE.SCREEN_NAME, null, false);
    }
  }, [isInitialized, openModal, screenName]);

  useEffect(() => {
    if (error) {
      openModal(
        'Error',
        MODAL_TYPE.CUSTOM_ERROR,
        { errorMessage: error },
        true,
        true,
        closeCustomErrorDialog,
        closeCustomErrorDialog
      );
    }
  }, [closeCustomErrorDialog, error, openModal]);

  return null;
};

export default StateObserver;
