import { getCircleXY } from '@/lib/mathService';
import { describeArc } from '@/lib/svgService';
import { useCallback } from 'react';

const RacetrackHorizontalBorder = ({ width, height }) => {
  const radius = height / 2;
  const itemHeight = height / 4;
  const radiusInner = radius - itemHeight;
  const straightItemsWidth = width - 2 * radius;

  const describeStraightItemsBorder = (startX, startY, itemHeight) => {
    const d = [];
    const itemWidth = (width - 2 * radius) / 13;
    for (let i = 0; i < 14; i++) {
      d.push(`M ${startX + i * itemWidth} ${startY} v ${itemHeight}`);
    }

    return d.join(' ');
  };

  const describeNamedBetsBorder = () => {
    const itemWidth = (width - 2 * radius) / 13;

    const d = [
      'M',
      radius + 2 * itemWidth,
      itemHeight,
      'L',
      radius + 4 * itemWidth,
      height - itemHeight,
      'M',
      radius + 7 * itemWidth,
      itemHeight,
      'v',
      height - 2 * itemHeight,
      describeArc(width - radius - itemWidth, 2 * itemHeight, radiusInner, -180, 0),
    ].join(' ');

    return d;
  };

  const getCircleCoordinates = useCallback((cx, cy, radius, steps, antiClockwise = false) => {
    const step = 180 / steps;
    const coordinates = [];
    for (let i = 1; i <= steps; i++) {
      coordinates.push(getCircleXY(cx, cy, radius, (antiClockwise ? 180 : 0) + i * step));
    }

    return coordinates.slice(0, -1);
  }, []);

  const describeRightBend = useCallback(() => {
    const outerCircleCoordinates = getCircleCoordinates(width - radius, height / 2, radius, 5);
    const innerCircleCoordinates = getCircleCoordinates(width - radius, height / 2, radiusInner, 5);

    let d = [];

    for (const [i, { x: outerX, y: outerY }] of outerCircleCoordinates.entries()) {
      const { x: innerX, y: innerY } = innerCircleCoordinates[i];
      d = [...d, 'M', innerX, innerY, 'L', outerX, outerY];
    }

    return d.join(' ');
  }, [getCircleCoordinates, height, radius, radiusInner, width]);

  const describeLeftBend = useCallback(() => {
    const outerCircleCoordinates = getCircleCoordinates(radius, height / 2, radius, 6, true);
    const innerCircleCoordinates = getCircleCoordinates(radius, height / 2, radiusInner, 6, true);

    let d = [];

    for (const [i, { x: outerX, y: outerY }] of outerCircleCoordinates.entries()) {
      const { x: innerX, y: innerY } = innerCircleCoordinates[i];
      d = [...d, 'M', innerX, innerY, 'L', outerX, outerY];
    }

    return d.join(' ');
  }, [getCircleCoordinates, height, radius, radiusInner]);

  return (
    <>
      <path
        d={`${describeArc(radius, radius, radius, -180, 0)} M ${radius} 0 h ${straightItemsWidth} ${describeArc(
          width - radius,
          radius,
          radius,
          0,
          180
        )} M ${radius} ${height} h ${straightItemsWidth}
    ${describeArc(radius, radius, radiusInner, -180, 0)} 
    M ${radius} ${itemHeight} h ${straightItemsWidth}
    ${describeArc(width - radius, radius, radiusInner, 0, 180)} 
    M ${radius} ${height - itemHeight} h ${straightItemsWidth}
    ${describeStraightItemsBorder(radius, 0, itemHeight)}
    ${describeStraightItemsBorder(radius, height - itemHeight, itemHeight)}
    ${describeNamedBetsBorder()}
    ${describeRightBend()}
    ${describeLeftBend()}
    `}
        style={{
          pointerEvents: 'none',
          strokeWidth: '0.2em',
          fill: 'none',
          stroke: '#f9e1cc',
        }}
      />
    </>
  );
};

export default RacetrackHorizontalBorder;
