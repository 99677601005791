import { USER_TYPE } from '@/enums/userType';
import socketApi from '@/lib/socketApi';
import { v4 as uuidv4 } from 'uuid';
import { addNewMessage } from '../chatSlice';
import { setLastChatMessage } from '../messageWallSlice';

export const addNewMessageThunk =
  ({ message }) =>
  async (dispatch, getState) => {
    /** @type {{ user: import('../userSlice').UserState, settings: import('../settingsSlice').SettingsState }} */
    const { user: userState, settings: settingsState } = getState();
    const { publicPlayerId } = userState;
    const { screenName } = settingsState;

    const payload = {
      chatId: uuidv4(),
      userId: publicPlayerId,
      userType: USER_TYPE.PLAYER,
      screenName,
      message,
    };

    dispatch(addNewMessage(payload));
    dispatch(setLastChatMessage(payload));
    await socketApi.sendMessage(payload);
  };
