import SettingsIcon from '@/assets/svg/settings.svg?react';
import { MENU_VIEW } from '@/enums/ui';
import socketApi from '@/lib/socketApi';
import { setSoundVolume } from '@/store/settingsSlice';
import { openSlidePopup, setSlidePopupView } from '@/store/uiSlice';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SoundSlider from '../SettingsView/SoundSlider';
import styles from './SoundView.module.scss';

const SoundView = () => {
  const settings = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings);
  const { soundVolume } = settings;
  const dispatch = useDispatch();
  const isInitialized = useRef(false);

  const openSettings = useCallback(() => {
    dispatch(setSlidePopupView({ view: MENU_VIEW.SETTINGS, options: { tabIndex: 2 } }));
    dispatch(openSlidePopup());
  }, [dispatch]);

  const handleSoundVolumeChanged = useCallback(
    (level) => {
      dispatch(setSoundVolume({ type: 'main', level }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      return;
    }
    socketApi.savePlayerSettings(settings);
  }, [dispatch, settings]);

  return (
    <div className={styles.container}>
      <SoundSlider
        level={soundVolume?.main?.level}
        isMuted={soundVolume?.main?.isMuted}
        onChanged={handleSoundVolumeChanged}
        withIcon={false}
        className={styles.slider}
      />
      <SettingsIcon onClick={openSettings} height="2em" className={styles.iconButton} />
    </div>
  );
};

export default SoundView;
