import { roundNumber } from '@/lib/numberService';

const ResponsiveBorder = ({ tableOptions, orientation }) => {
  const { tableWidth, borderColor, numberItem, outerItem } = tableOptions;

  const itemWidth = roundNumber(numberItem.width, 2);
  const itemHeight = roundNumber(numberItem.height, 2);

  let stringBuilder = [];

  if (orientation === 'horizontal') {
    stringBuilder.push(
      `M 0 ${(3 * itemHeight) / 2} L ${itemWidth * 0.3} 0 H ${itemWidth} M 0 ${(3 * itemHeight) / 2} L ${
        itemWidth * 0.3
      } ${3 * itemHeight}  H ${itemWidth}`
    );

    for (let i = 0; i < 6; i++) {
      const height = i <= 3 ? i * itemHeight : 3 * itemHeight + (6 - i) * outerItem.height;
      stringBuilder.push(`M ${itemWidth} ${height} H ${i <= 3 ? tableWidth : tableWidth - itemWidth}`);
    }

    for (let i = 2; i < 13; i++) {
      stringBuilder.push(
        `M ${i * itemWidth} 0 V ${(i - 1) % 4 === 0 ? 3 * itemHeight + outerItem.height : 3 * itemHeight}`
      );
    }

    for (let i = 1; i < 6; i++) {
      stringBuilder.push(
        `M ${i * (2 * itemWidth) + itemWidth} ${3 * itemHeight + outerItem.height} V ${
          3 * itemHeight + 2 * outerItem.height
        }`
      );
    }

    stringBuilder.push(
      `M ${itemWidth}  0 V ${itemHeight * 3 + outerItem.height * 2}`,
      `M ${tableWidth - itemWidth}  0 V ${itemHeight * 3 + outerItem.height * 2}`,
      `M ${tableWidth} 0 V ${itemHeight * 3}`
    );
  } else {
    const innerTableHeight = 13 * numberItem.height;
    const innerTableWidth = 3 * numberItem.width;

    stringBuilder.push(
      `M ${tableWidth} ${numberItem.height} V ${numberItem.height * 0.3}
        L ${tableWidth - innerTableWidth / 2} ${0}
        L ${tableWidth - innerTableWidth} ${numberItem.height * 0.3}
        L ${tableWidth - innerTableWidth} ${numberItem.height}
        `
    );

    let columnGap = tableWidth;
    for (let i = 0; i < 6; i++) {
      const lineHeight = i < 4 ? innerTableHeight + itemHeight : innerTableHeight;
      if (i > 0) {
        columnGap = i < 4 ? columnGap - itemWidth : columnGap - outerItem.width;
      }

      stringBuilder.push(`M ${columnGap} ${itemHeight} V ${lineHeight}`);
    }

    let baseX = tableWidth - 3 * itemWidth;
    for (let i = 1; i < 15; i++) {
      stringBuilder.push(`M ${baseX} ${i * itemHeight} H ${tableWidth}`);
    }

    baseX = baseX - outerItem.width;
    for (let i = 0; i < 4; i++) {
      stringBuilder.push(`M ${baseX} ${itemHeight + i * (4 * itemHeight)} H ${baseX + outerItem.width}`);
    }

    baseX = baseX - outerItem.width;
    for (let i = 0; i < 7; i++) {
      stringBuilder.push(`M ${baseX} ${itemHeight + i * (2 * itemHeight)} H ${baseX + outerItem.width}`);
    }
  }

  return (
    <path
      fill="transparent"
      stroke={borderColor}
      strokeWidth="0.1rem"
      style={{ pointerEvents: 'none' }}
      d={stringBuilder.join(' ')}
    ></path>
  );
};

export default ResponsiveBorder;
