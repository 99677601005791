import { BET_TYPE } from '@/enums/layoutTable';
import ROUND_STATE from '@/enums/roundState';
import { createBetKey } from '@/lib/rouletteService';
import { useSelector } from 'react-redux';
import styles from './DollyWrapper.module.scss';
import Dolly from '@/components/RouletteTable/Dolly';

const DollyWrapper = ({ chipPositions }) => {
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );

  const currentPosition = chipPositions[createBetKey(BET_TYPE.STRAIGHT, winningNumber)];
  if (roundState !== ROUND_STATE.WINNING_NUMBER || winningNumber === null || !currentPosition) return null;

  return (
    <div
      className={styles.dolly}
      style={{
        left: `${currentPosition.x}%`,
        top: `${currentPosition.y}%`,
      }}
    >
      <Dolly />
    </div>
  );
};

export default DollyWrapper;
