import clsx from 'clsx';
import styles from './MainContent.module.scss';
import DesktopCompactView from '@/components/DesktopCompactView';
import { useSelector } from 'react-redux';
import DesktopView from './DesktopView';
import { DESKTOP_VIEW_TYPE } from '@/enums/ui';
import { useMemo } from 'react';
import ThreeDimensionView from '@/components/ThreeDimensionView';

const MainContent = () => {
  const desktopView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.desktopView);

  const drawDesktopView = useMemo(() => {
    let currentView;

    switch (desktopView) {
      case DESKTOP_VIEW_TYPE.THREE_DIMENSION:
        currentView = <ThreeDimensionView />;
        break;
      case DESKTOP_VIEW_TYPE.CLASSIC:
        currentView = <DesktopCompactView />;
        break;
      case DESKTOP_VIEW_TYPE.IMMERSIVE:
        currentView = <DesktopView />;
        break;
    }

    return currentView;
  }, [desktopView]);

  return <div className={clsx(styles.container, styles.isDesktop)}>{drawDesktopView}</div>;
};

export default MainContent;
