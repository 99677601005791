import { TiEdit } from 'react-icons/ti';
import styles from './EditBetButton.module.scss';

const EditBetButton = () => {
  return (
    <div className={styles.container}>
      <TiEdit size="2em" />
    </div>
  );
};

export default EditBetButton;
