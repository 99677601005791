import { LANGUAGE } from '@/lib/paramService';
import scrollbarStyle from '@/styles/Scrollbar.module.scss';
import cl from 'clsx';
import { useTranslation } from 'react-i18next';
import { Element, Link } from 'react-scroll';
import contentEN from './Content/en';
import contentNL from './Content/nl';
import styles from './HelpView.module.scss';

const HelpView = () => {
  const content = LANGUAGE === 'nl' ? contentNL : contentEN;
  const { t } = useTranslation('common');
  return (
    <div className={styles.container}>
      <div className={styles.sections}>
        {content.map((section) => (
          <Link
            key={section.title}
            activeClass={styles.selected}
            className={styles.section}
            to={section.title}
            spy={true}
            smooth={true}
            duration={500}
            containerId="helpContent"
          >
            {t(`help.${section.title}`)}
          </Link>
        ))}
      </div>
      <div id="helpContent" className={cl(styles.content, scrollbarStyle.scrollbar)}>
        {content.map((section) => (
          <Element key={`${section.title}-content`} name={section.title}>
            <div className={styles.title}>{t(`help.${section.title}`)}</div>
            {section.content}
          </Element>
        ))}
      </div>
    </div>
  );
};

export default HelpView;
