import { useSelector } from 'react-redux';
import ROUND_STATE from '@/enums/roundState';
import { useEffect, useRef } from 'react';
import { playRoundStateVoice, playWinningNumberVoice } from '@/lib/soundService';

const AutoRouletteVoices = () => {
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const win = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.win);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const prevRoundState = useRef();

  useEffect(() => {
    if (roundState) {
      if (roundState === prevRoundState.current) return;

      if ([ROUND_STATE.PLACE_BETS, ROUND_STATE.FINAL_BETS, ROUND_STATE.NO_MORE_BETS].includes(roundState)) {
        playRoundStateVoice(roundState);
      }

      if (roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null) {
        playWinningNumberVoice(winningNumber, win > 0);
      }

      // @ts-ignore
      prevRoundState.current = roundState;
    }
  }, [roundState, win, winningNumber]);

  return null;
};

export default AutoRouletteVoices;
