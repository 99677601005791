import SelectCircle from '@/assets/svg/select-circle.svg?react';
import Chip from '@/components/Chip2D';
import { TooltipContext } from '@/context/TooltipContext';
import { ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useShortcut from '@/hooks/useShortcut';
import { playChipsSound, playNotAllowedSound } from '@/lib/soundService';
import { selectChip } from '@/store/gameSlice';
import { selectBetsAreOnlyWinningBets, selectIsBettingTime } from '@/store/selectors/gameSelectors';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChipSelectorMenu from '../common/ChipSelectorMenu';
import DoubleButton from '../common/DoubleButton';
import RepeatButton from '../common/RepeatButton';
import styles from './ChipSelector.module.scss';
import UndoButton from './UndoButton';

const ChipSelector = ({ orientation, className }) => {
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const { t } = useTranslation(['roulette', 'tooltip']);
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const settings = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.settings);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const previousBet = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.previousBet);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const betsAreOnlyWinningBets = useSelector(selectBetsAreOnlyWinningBets);
  const dispatch = useDispatch();
  const [lastSelectedChip, setLastSelectedChip] = useState(selectedChip);
  const chipRefs = useRef([]);
  const isBetEmpty = useCallback(() => Object.keys(bets).length === 0, [bets]);
  const isBettingTime = useSelector(selectIsBettingTime);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const MAX_DEFAULT_CHIP_NUMBER = 5;

  const hasEnoughBalance = useCallback((betValue) => balance >= betValue, [balance]);

  const chipsToDisplay = useMemo(() => {
    const newChips = [...settings.chips];
    while (newChips.length > MAX_DEFAULT_CHIP_NUMBER && newChips.at(-1) > balance) {
      newChips.pop();
    }
    return newChips;
  }, [balance, settings]);

  const handleSelection = useCallback(
    (value) => {
      if (!selectedChip || !hasEnoughBalance(value)) {
        playNotAllowedSound();
      }

      if (!isBettingTime || balance < value || selectedChip === value) return;

      playChipsSound();
      dispatch(selectChip(value));
      setLastSelectedChip(value);
    },
    [selectedChip, hasEnoughBalance, isBettingTime, balance, dispatch]
  );

  const numberShortCuts = Array.from({ length: chipsToDisplay?.length }, (_, index) => ({
    key: (index + 1).toString(),
  }));

  const handleNumberShortcut = useCallback(
    (event) => {
      const position = Number(event.key) - 1;
      const selectedValue = chipsToDisplay[position];
      if (selectedValue) {
        handleSelection(selectedValue);
      }
    },
    [chipsToDisplay, handleSelection]
  );

  useShortcut(numberShortCuts, handleNumberShortcut);

  const handleMouseEnter = useCallback(
    (index) => {
      if (!selectedChip || !hasEnoughBalance(chipsToDisplay[index])) {
        const currentItem = chipRefs.current[index];
        showTooltip(currentItem, t('lowBalance', { ns: 'tooltip' }), {
          delay: 50,
        });
      }
    },
    [chipsToDisplay, hasEnoughBalance, selectedChip, showTooltip, t]
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  useEffect(() => {
    let newChip;
    const largestAvailableChip = [...chipsToDisplay]
      .sort((a, b) => a - b)
      .reverse()
      .find((chip) => chip <= balance);

    if (balance > 0 && lastSelectedChip <= balance) {
      newChip = lastSelectedChip;
    } else {
      newChip = largestAvailableChip;
    }

    if (newChip !== selectedChip) {
      dispatch(selectChip(newChip));
    }
  }, [balance, chipsToDisplay, dispatch, lastSelectedChip, selectedChip]);

  return (
    <div
      className={clsx(
        styles.container,
        viewType === VIEW_TYPE.TOUCH ? styles.gooey : styles.list,
        styles[orientation],
        !isBettingTime && styles.closed,
        className
      )}
    >
      <div className={styles.leftButtonContainer}>
        <UndoButton
          className={styles.button}
          disabled={!isBettingTime || isBetEmpty()}
          withLabel={isTouchDevice && orientation === ORIENTATION.VERTICAL ? false : true}
          labelDirection={isTouchDevice && orientation === ORIENTATION.HORIZONTAL ? 'bottom' : 'left'}
        />
      </div>
      {viewType === VIEW_TYPE.DESKTOP &&
        chipsToDisplay.map((value, index) => (
          <div
            key={index}
            ref={(el) => (chipRefs.current[index] = el)}
            className={styles.chipWrapper}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {hasEnoughBalance(value) && (
              <div className={styles.hoverEffect}>
                <svg viewBox="0 0 64 64" height="2.5em" width="2.5em">
                  <defs>
                    <linearGradient id="strokeGradient" x2="0" y2="1">
                      <stop offset="0" stopColor="rgba(255, 255, 255, 0.2)"></stop>
                      <stop offset="1" stopColor="transparent"></stop>
                    </linearGradient>
                    <radialGradient id="fillGradient">
                      <stop offset="0" stopColor="transparent"></stop>
                      <stop offset="1" stopColor="rgba(255, 255, 255, 0.2)"></stop>
                    </radialGradient>
                  </defs>
                  <circle
                    cx="32"
                    cy="32"
                    r="31.5"
                    fill="url(#fillGradient)"
                    stroke="url(#strokeGradient)"
                    strokeWidth="1"
                  ></circle>
                </svg>
              </div>
            )}
            {value === selectedChip && <SelectCircle className={styles.selectCircle} height="2.5em" width="2.5em" />}
            <Chip
              value={value}
              size="2.5em"
              onClick={handleSelection}
              isDisabled={!hasEnoughBalance(value)}
              className={styles.chip}
            />
          </div>
        ))}
      {viewType === VIEW_TYPE.TOUCH && (
        <div className={styles.gooeyContainer}>
          <ChipSelectorMenu
            chipSize="3em"
            chips={chipsToDisplay}
            handleSelection={handleSelection}
            orientation={orientation}
          />
        </div>
      )}
      <div className={styles.rightButtonContainer}>
        {previousBet?.length > 0 &&
        ((isBettingTime && isBetEmpty()) || (!isBettingTime && previousBet) || betsAreOnlyWinningBets) ? (
          <RepeatButton
            className={styles.button}
            withLabel={isTouchDevice && orientation === ORIENTATION.VERTICAL ? false : true}
            labelDirection={isTouchDevice && orientation === ORIENTATION.HORIZONTAL ? 'bottom' : 'right'}
          />
        ) : (
          <DoubleButton
            className={styles.button}
            withLabel={isTouchDevice && orientation === ORIENTATION.VERTICAL ? false : true}
            labelDirection={isTouchDevice && orientation === ORIENTATION.HORIZONTAL ? 'bottom' : 'right'}
          />
        )}
      </div>
    </div>
  );
};

export default ChipSelector;
