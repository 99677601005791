import { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useScroll } from 'react-use';
import styles from './Scrollable.module.scss';

const Scrollable = ({ children, scrollHint = false }) => {
  const [scrollHeight, setScrollHeight] = useState(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);
  const bottomReached = scrollHeight - y === scrollRef?.current?.offsetHeight;

  useEffect(() => {
    if (scrollRef.current) {
      const { current: element } = scrollRef;
      const scrollHeight = element.scrollHeight;
      const hasVerticalScrollbar = scrollHeight > element.clientHeight;
      setHasScrollbar(hasVerticalScrollbar);
      setScrollHeight(scrollHeight);
    }
  }, []);

  return (
    <div className={styles.outerContainer}>
      {scrollHint && hasScrollbar && <div className={clsx(styles.mask, y > 0 && styles.visible)}></div>}
      <div ref={scrollRef} className={styles.innerContainer}>
        {children}
      </div>
      {scrollHint && hasScrollbar && (
        <div className={clsx(styles.mask, styles.bottom, !bottomReached && styles.visible)}></div>
      )}
    </div>
  );
};

export default Scrollable;
