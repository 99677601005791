import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './slider.scss';

const CustomSlider = ({ value, defaultValue, onChange, step, min = 0, max = 100 }) => {
  const defaultStyles = {
    track: {
      backgroundColor: '#faaf3b',
      height: '0.5em',
      borderRadius: '0.25em',
    },
    handle: {
      borderColor: '#faa728',
      backgroundColor: '#faa728',
      opacity: 1,
      boxShadow: 'none',
      height: '1em',
      width: '1em',
    },
    rail: {
      height: '0.5em',
      borderRadius: '0.25em',
    },
  };

  return (
    <Slider
      styles={defaultStyles}
      dotStyle={{ borderColor: '#faa728' }}
      min={min}
      max={max}
      step={step}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      className="slider"
    />
  );
};

export default CustomSlider;
