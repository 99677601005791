import ClassicViewBackground from '@/assets/svg/compact-view-bg.svg?react';
import Header from '@/components/common/Header';
import MainContent from '@/components/common/MainContent';
import NoConnection from '@/components/common/NoConnection';
import StreamView from '@/components/StreamView';
import { DESKTOP_VIEW_TYPE } from '@/enums/ui';
import cl from 'clsx';
import { useSelector } from 'react-redux';
import styles from './DesktopView.module.scss';
import { versionedUrl } from '@/lib/urlService';

/**
 * TBD.
 * @returns {import('react').ReactElement} Output to render.
 */
const DesktopView = () => {
  const desktopView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.desktopView);
  const isStreamFullScreen = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.isStreamFullScreen
  );

  return (
    <>
      {desktopView === DESKTOP_VIEW_TYPE.CLASSIC && (
        <ClassicViewBackground width="100%" height="100%" className={styles.compactViewBackground} />
      )}

      <div className={cl(styles.stream, styles[desktopView], isStreamFullScreen && styles.fullScreen)}>
        <StreamView width="100%" />
      </div>

      {desktopView === DESKTOP_VIEW_TYPE.THREE_DIMENSION && (
        <img className={styles.threeDimensionOverlay} src={versionedUrl('/images/3d-view-overlay.png')} alt="" />
      )}

      <Header />
      <MainContent />
      <NoConnection />
    </>
  );
};

export default DesktopView;
