import scrollbarStyles from '@/styles/Scrollbar.module.scss';
import cl from 'clsx';
import { motion } from 'framer-motion';
import { useState } from 'react';
import styles from './Tabs.module.scss';
import { playPressSound } from '@/lib/soundService';

const Tabs = ({ tabs, defaultActiveIndex = 0, children }) => {
  const [activeTab, setActiveTab] = useState(tabs?.[defaultActiveIndex]);

  const handleClick = (tab) => {
    playPressSound();
    setActiveTab(tab);
  };

  const isSelected = (tab) => activeTab?.name === tab.name;

  if (tabs?.length === 0) return null;

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.tabHeader}>
        {tabs?.map((tab) => (
          <div key={tab.name} className={[styles.tabItem, isSelected(tab) ? styles.selected : ''].join(' ')}>
            <div onClick={() => handleClick(tab)}>{tab.label}</div>

            {isSelected(tab) && <motion.div key={tab.name} layoutId="indicator" className={styles.indicator} />}
          </div>
        ))}
      </div>
      {children && <div className={styles.tabChildren}>{children}</div>}
      <div className={cl(styles.tabContent, scrollbarStyles.scrollbar)}>{activeTab && activeTab?.content}</div>
    </div>
  );
};

export default Tabs;
