import ErrorManager from '../ErrorManager';
import PageLifeCycleManager from '../PageLifeCycleManager';
import SnackbarManager from '../SnackbarManager';
import TableStateManager from '../TableStateManager';
import UiManager from '../UiManager';

const Managers = () => {
  return (
    <>
      <UiManager />
      <PageLifeCycleManager />
      <TableStateManager />
      <SnackbarManager />
      <ErrorManager />
    </>
  );
};

export default Managers;
