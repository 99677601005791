import { USER_TYPE } from '@/enums/userType';

/**
 * TBD.
 * @param {string} userType - TBD.
 * @param {string} screenName - TBD.
 * @returns {string} TBD.
 */
export const getScreenName = (userType, screenName) => {
  return userType === USER_TYPE.DEALER ? 'DEALER' : screenName;
};
