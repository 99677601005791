import { TooltipContext } from '@/context/TooltipContext';
import { SPECIAL_BET_TYPE } from '@/enums/layoutTable';
import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useOrientation from '@/hooks/useOrientation';
import { createBetKey } from '@/lib/rouletteService';
import { getSpecialBets } from '@/lib/specialBetsService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SpecialBetsView.module.scss';

const SpecialBetsView = () => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const orientation = useOrientation();
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const { t } = useTranslation(['roulette', 'tooltip', 'common']);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const dispatch = useDispatch();
  const finaleEnPleinRefs = useRef([]);
  const finalesACheval = useRef([]);
  const completeBetRefs = useRef([]);
  const isBettingTime = useSelector(selectIsBettingTime);

  const handleBetAction = useCallback(
    (event, betType, number) => {
      const bets = getSpecialBets(betType, number);
      const mappedBets = bets.map((betItem) => ({
        betType: createBetKey(betItem.betType, betItem.numbers),
        bet: (betItem?.multiplier ?? 1) * selectedChip,
      }));

      dispatch(addBetThunk(mappedBets, event));
    },
    [dispatch, selectedChip]
  );

  const handleTooltipAction = useCallback(
    (betType, number, itemRef) => {
      if (isTouchDevice) return;
      const bets = getSpecialBets(betType, number);
      const numbers = bets.flatMap((bet) => bet.numbers);
      dispatch(setHighlightedNumbers(numbers));

      const betsPreview = bets.map((bet) => ({
        ...bet,
        value: selectedChip * (bet.multiplier ?? 1),
      }));

      const requiredChips = betsPreview.reduce((total, bet) => total + (bet.multiplier ?? 1), 0);

      let tooltip;
      switch (betType) {
        case SPECIAL_BET_TYPE.COMPLETE_BET:
          tooltip = t('completeBet', {
            ns: 'tooltip',
            bet:
              number === 0
                ? t('zero', { ns: 'common' }).toLowerCase()
                : `${t('number', { ns: 'common' }).toLowerCase()} ${number}`,
            requiredChips,
          });
          break;
        case SPECIAL_BET_TYPE.FINALE_EN_PLEIN:
          tooltip = t('finaleEnPlein', { ns: 'tooltip', number, requiredChips });
          break;
        case SPECIAL_BET_TYPE.FINALES_A_CHEVAL: {
          const numberCovered = betsPreview.reduce((total, bet) => total + bet.numbers.length, 0);
          tooltip = t('finalesACheval', {
            ns: 'tooltip',
            from: number,
            to: number + 3,
            requiredChips,
            numberCount: numberCovered,
          });
          break;
        }
      }
      dispatch(setBetsPreview(betsPreview));
      showTooltip(itemRef, tooltip);
    },
    [dispatch, isTouchDevice, selectedChip, showTooltip, t]
  );

  const handleMouseEnter = useCallback(
    (betType, number, itemRef) => handleTooltipAction(betType, number, itemRef),
    [handleTooltipAction]
  );

  const handleMouseLeave = useCallback(() => {
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  const renderBetGrid = useCallback(
    (betType, count, itemRefs, renderItem) => (
      <div className={clsx(styles.betContainer, styles[betType])}>
        {Array.from({ length: count })
          .fill()
          .map((_, index) => (
            <div
              key={`${betType}-${index}`}
              ref={(el) => (itemRefs.current[index] = el)}
              className={styles.gridItem}
              onMouseEnter={() => handleMouseEnter(betType, index, itemRefs.current[index])}
              onMouseLeave={handleMouseLeave}
              onClick={(event) => handleBetAction(event, betType, index)}
            >
              {renderItem(index)}
            </div>
          ))}
      </div>
    ),
    [handleMouseEnter, handleMouseLeave, handleBetAction]
  );

  return (
    <div
      className={clsx(
        styles.container,
        !isBettingTime && styles.disabled,
        isTouchDevice && orientation === SCREEN_ORIENTATION.PORTRAIT && styles.vertical
      )}
    >
      <div className={styles.section}>
        <div className={styles.title}>FINALE EN PLEIN</div>
        {renderBetGrid(SPECIAL_BET_TYPE.FINALE_EN_PLEIN, 10, finaleEnPleinRefs, (index) => index)}
      </div>
      <div className={styles.section}>
        <div className={styles.title}>FINALES A CHEVAL</div>
        {renderBetGrid(SPECIAL_BET_TYPE.FINALES_A_CHEVAL, 10, finalesACheval, (index) => `${index}/${index + 3}`)}
      </div>
      <div className={styles.section}>
        <div className={styles.title}>{t('favBet.completeBet')}</div>
        {renderBetGrid(SPECIAL_BET_TYPE.COMPLETE_BET, 37, completeBetRefs, (index) => index)}
      </div>
    </div>
  );
};

export default SpecialBetsView;
