import { createPortal } from 'react-dom';
import { useCallback, useMemo } from 'react';
import styles from './Snackbar.module.scss';
import CountdownCloseButton from '../CountdownCloseButton';

const Snackbar = ({ title, onClose = () => {}, children }) => {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const content = useMemo(() => {
    return (
      <div className={styles.snackbar}>
        <div className={styles.content}>
          {title && <div className={styles.title}>{title}</div>}
          {children}
        </div>
        <CountdownCloseButton onClose={handleClose} className={styles.closeButton} size="2rem" />
      </div>
    );
  }, [children, handleClose, title]);

  return createPortal(content, document.querySelector('#portal-container'));
};

export default Snackbar;
