import Chip from '@/components/Chip2D';
import { ORIENTATION } from '@/enums/ui';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './RacetrackItem.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';

const RacetrackItem = forwardRef(
  (
    {
      text,
      bets,
      pathDescr,
      centerCoordinates,
      chipSize,
      orientation,
      displayChipsAndWinningNumber,
      backgroundFilled = false,
      withFieldOpacity,
      isHighlighted,
      onClick,
      onMouseEnter,
      onMouseLeave,
    },
    ref
  ) => {
    const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
    const isBettingTime = useSelector(selectIsBettingTime);

    return (
      <>
        <path
          ref={ref}
          d={pathDescr}
          className={clsx(
            styles.container,
            backgroundFilled && Number.isInteger(text) && styles[numbers[text].color],
            withFieldOpacity && styles.withFieldOpacity,
            isHighlighted && styles.highlighted,
            !isBettingTime && styles.disabled
          )}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        <text
          x={centerCoordinates.x}
          y={centerCoordinates.y}
          className={clsx(
            styles.text,
            Number.isInteger(text) && !backgroundFilled && styles[numbers[Number(text)].color],
            !Number.isInteger(text) && styles.small,
            styles[orientation]
          )}
          style={{
            dominantBaseline: 'central',
          }}
        >
          {text}
        </text>
        {bets && (
          <g transform={`translate(${centerCoordinates.x - chipSize / 2} ${centerCoordinates.y - chipSize / 2})`}>
            {displayChipsAndWinningNumber && (
              <Chip style={{ pointerEvents: 'none' }} size={chipSize} value={bets.value} />
            )}
          </g>
        )}
      </>
    );
  }
);

RacetrackItem.displayName = 'RacetrackItem';

export default RacetrackItem;
