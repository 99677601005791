import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { Resource } from '@opentelemetry/resources';
import { ConsoleMetricExporter, MeterProvider, PeriodicExportingMetricReader } from '@opentelemetry/sdk-metrics';
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  TraceIdRatioBasedSampler,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import {
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
} from '@opentelemetry/semantic-conventions';
import { config } from './configService';

const resource = new Resource({
  [ATTR_SERVICE_NAME]: config.VITE_APP_ID,
  [ATTR_SERVICE_VERSION]: config.VITE_APP_VERSION,
  [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: config.VITE_APP_ENVIRONMENT,
});

/** @type {WebTracerProvider} */
let traceProvider;
/** @type {MeterProvider} */
let metricProvider;

/**
 * Initialize OTEL Trace SDK.
 */
const initOpenTelemetryTraces = () => {
  // provider
  const sampler = new TraceIdRatioBasedSampler(config.VITE_OTEL_TRACES_SAMPLER_ARG);
  const providerConfig = { resource, sampler };
  const provider = new WebTracerProvider(providerConfig);
  // instrumentation
  const documentLoadInstrumentation = new DocumentLoadInstrumentation({});
  documentLoadInstrumentation.setTracerProvider(provider);
  const fetchInstrumentation = new FetchInstrumentation({});
  fetchInstrumentation.setTracerProvider(provider);
  // exporter
  if (config.VITE_OTEL_DEBUG) {
    const exporter = new ConsoleSpanExporter();
    const processor = new SimpleSpanProcessor(exporter);
    provider.addSpanProcessor(processor);
  } else {
    const exporterConfig = { url: `${config.VITE_OTEL_COLLECTOR_URI}/v1/traces`, headers: {}, concurrencyLimit: 1 };
    const exporter = new OTLPTraceExporter(exporterConfig);
    const processor = new BatchSpanProcessor(exporter);
    provider.addSpanProcessor(processor);
  }
  // finish initialization
  const sdkRegistrationConfig = {
    contextManager: new ZoneContextManager(),
  };
  provider.register(sdkRegistrationConfig);
  registerInstrumentations({
    instrumentations: [documentLoadInstrumentation, fetchInstrumentation],
    tracerProvider: provider,
  });
  traceProvider = provider;
};

/**
 * Initialize OTEL Metric SDK.
 */
const initOpenTelemetryMetric = () => {
  // provider
  const readers = [];
  // exporter
  const exportIntervalMillis = config.VITE_OTEL_METRIC_EXPORT_INTERVAL;
  if (config.VITE_OTEL_DEBUG) {
    const exporter = new ConsoleMetricExporter();
    const readerConfig = { exporter, exportIntervalMillis };
    const reader = new PeriodicExportingMetricReader(readerConfig);
    readers.push(reader);
  } else {
    const exporterConfig = {
      url: `${config.VITE_OTEL_COLLECTOR_URI}/v1/metrics`,
      headers: {},
      concurrencyLimit: 1,
    };
    const exporter = new OTLPMetricExporter(exporterConfig);
    const readerConfig = { exporter, exportIntervalMillis };
    const reader = new PeriodicExportingMetricReader(readerConfig);
    readers.push(reader);
  }
  const provider = new MeterProvider({ resource, readers });
  metricProvider = provider;
};

/**
 * Initialize OTEL SDKs.
 */
export const initOpenTelemetry = () => {
  if (!config.VITE_OTEL_ENABLED) {
    return;
  }
  initOpenTelemetryTraces();
  initOpenTelemetryMetric();
};

/**
 * Returns the global WebTracerProvider instance.
 * @returns {WebTracerProvider} Reference to the WebTracerProvider instance.
 */
export const getTraceProvider = () => {
  return traceProvider;
};

/**
 * Returns the global MetricProvider instance.
 * @returns {MeterProvider} Reference to the MetricProvider instance.
 */
export const getMetricProvider = () => {
  return metricProvider;
};
