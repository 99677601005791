import { BET_TYPE, NAMED_BET_VALUES, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import { createBetKey, getNeighbors, getRacetrackNamedBets, parseBetKey } from '@/lib/rouletteService';
import { v4 as uuidv4 } from 'uuid';
import cl from 'clsx';
import styles from './Racetrack3D.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { useCallback, useState } from 'react';
import { setItemHovered } from '@/store/tableSlice';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import { versionedUrl } from '@/lib/urlService';

const betSpots = [
  {
    id: uuidv4(),
    color: 'color-green',
    d: 'M528.65,306.17c1.2-0.83,2.31-1.66,3.42-2.59l-33.67-30.99c-0.37,0.18-0.74,0.46-1.11,0.65l-1.11,0.65l-4.62,2.03c-1.66,0.55-3.33,1.02-5.09,1.39c-3.61,0.74-7.12,1.11-10.64,1.02l7.86,44.68c5.64-0.46,11.28-1.48,16.83-3.05C510.61,316.81,520.05,312.28,528.65,306.17z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 0),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M190.1,37.79l-25.81,33.02l29.14,13.6l25.53-33.39L190.1,37.79z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 1),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M299.59,198.89l-25.9,37.09l30.62,14.71l25.81-37.28L299.59,198.89z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 2),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M560.69,256.36c1.48-9.16,0.83-18.04-1.76-26.64c-1.57-5.09-3.79-9.9-6.66-14.43l-47.64,17.02c2.59,2.77,4.44,5.83,5.73,9.34c1.39,3.61,1.94,7.12,1.66,10.54l48.01,7.49C560.32,258.68,560.51,257.47,560.69,256.36z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 3),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M360.81,227.99l-25.62,37.55l27.75,13.32l25.16-37.83L360.81,227.99L360.81,227.99z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 4),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M88.41,1.59C81.75,1.68,75,2.51,68.25,4.09c-6.1,1.39-11.84,3.14-17.2,5.36l15.54,32.47c3.05-1.2,6.47-2.13,10.17-2.68c3.05-0.46,6.1-0.65,9.06-0.55L88.41,1.59z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 5),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M183.85,144.44l-26.45,35.52l28.4,13.69l26.45-36.26l-28.49-13.6L183.85,144.44z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 6),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M420.81,143.04l-24.42,35.8l27.1,12.67l24.14-36.17L420.81,143.04z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 7),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M53.38,78.24c-1.85-2.31-3.14-4.81-3.98-7.58c-0.83-2.5-1.11-4.9-1.02-7.31l-46.7,4.43c-0.46,4.9-0.18,9.8,0.74,14.61c1.11,5.55,3.14,10.82,6.1,15.72L53.38,78.24z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 8),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M302.37,89.04l-24.97,34.41l28.49,13.23l25.53-34.5L302.37,89.04z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 9),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M16.89,33.76l36.63,17.2c0.18-0.18,0.28-0.37,0.46-0.55c3.33-3.79,7.49-6.66,12.58-8.69L51.02,9.25C37.43,14.8,26.05,22.94,16.89,33.76z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 10),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M69.5,89.44l-27.75,34.97l30.71,14.8l27.56-35.24L69.5,89.44z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 11),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M479.69,169.89l-24.05,36.54l0.92,0.46c0.28,0.18,0.65,0.28,0.92,0.37l28.3,13.13l23.86-36.91L479.69,169.89L479.69,169.89z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 12),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M126.38,116.54l-27.29,35.43l30.25,14.52l27.01-35.7L126.38,116.54z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 13),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M246.59,63.54l-25.35,33.77l27.93,12.95l25.16-34.13L246.59,63.54z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 14),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M420.61,256.44l-25.07,38.11l30.52,14.71l24.79-38.39L420.61,256.44z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 15),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M133.84,12.19l-25.9,32.47l30.34,14.06l25.9-32.74C164.18,25.98,133.84,12.19,133.84,12.19z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 16),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M240.85,170.94l-26.36,36.44l28.86,13.87l26.92-36.44L240.85,170.94L240.85,170.94z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 17),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M360.4,115.49l-24.7,35.15l30.06,14.06l24.6-35.43L360.4,115.49z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 18),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M388.05,240.99l-25.16,37.83l32.65,15.63l25.07-38.11L388.05,240.99L388.05,240.99z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 19),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M218.97,50.99l-25.53,33.39l27.75,12.95l25.34-33.76C246.53,63.57,218.97,50.99,218.97,50.99z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 20),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M330.06,213.34l-25.71,37.28l30.8,14.8l25.62-37.55L330.06,213.34L330.06,213.34z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 21),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M331.37,102.24l-25.53,34.5l29.88,13.87l24.7-35.15L331.37,102.24z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 22),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M48.36,63.3c0.18-4.35,1.85-8.42,5.09-12.3L16.82,33.7C10.44,41.19,6,49.24,3.5,57.75c-0.92,3.33-1.57,6.57-1.85,9.9L48.36,63.3z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 23),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M133.89,12.18l-6.75-3.05c-8.79-3.98-18.32-6.38-28.68-7.22c-3.33-0.28-6.66-0.37-10.08-0.28l-2.59,37c6.47,0.18,12.67,1.66,18.59,4.35l3.61,1.66L133.89,12.18L133.89,12.18z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 24),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M270.26,184.94l-26.92,36.44l30.34,14.61l25.9-37.09L270.26,184.94z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 25),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M550.19,283.42c4.9-7.58,8.14-15.54,9.9-23.68l-48.01-7.58l-0.65,3.42l-1.48,3.98c-0.65,1.39-1.48,2.77-2.4,4.16c-2.5,3.61-5.55,6.47-9.25,8.69l33.67,30.99C539.18,297.85,545.29,291.09,550.19,283.42L550.19,283.42z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 26),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M183.86,144.43l-0.18-0.74l-27.38-13.04l-27.01,35.7l28.12,13.5L183.86,144.43z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 27),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M447.63,155.29l-24.14,36.17l32.19,14.89l24.05-36.54L447.63,155.29z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 28),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M390.5,129.19l-24.6,35.43l30.52,14.24l24.42-35.8L390.5,129.19z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 29),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M69.45,89.44l-3.61-1.66c-5.36-2.59-9.53-5.73-12.49-9.53L8.5,98.13c1.29,2.13,2.77,4.25,4.44,6.29c5.64,6.84,12.95,12.49,21.83,16.74l6.94,3.33L69.45,89.44z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 30),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M274.35,76.24l-25.16,34.13l28.21,13.14l24.97-34.41L274.35,76.24z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 31),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M475.67,278.15c-0.37,0-0.65-0.09-1.02-0.09l-0.37-0.09c-0.46,0-0.92-0.09-1.39-0.09c-5.73-0.46-11.38-2.03-17.02-4.81l-4.99-2.4l-24.79,38.39l10.36,4.9c10.17,4.9,20.9,7.77,32.1,8.69c4.99,0.37,9.99,0.37,14.98,0L475.67,278.15z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 32),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M164.14,25.94l-25.9,32.74l26.08,12.12l25.81-33.02L164.14,25.94z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 33),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M212.25,157.34L185.8,193.6l28.67,13.78l26.36-36.44L212.25,157.34z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 34),
  },
  {
    id: uuidv4(),
    color: 'color-black',
    d: 'M552.3,215.27c-2.03-3.24-4.35-6.29-7.03-9.16c-6.75-7.31-15.08-13.23-25.07-17.76l-10.64-4.81l-23.86,36.91l5.55,2.59c0.18,0.09,0.46,0.18,0.65,0.28l0.28,0.18c0.18,0.09,0.46,0.18,0.65,0.28c4.81,2.4,8.69,5.27,11.75,8.51L552.3,215.27z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 35),
  },
  {
    id: uuidv4(),
    color: 'color-red',
    d: 'M100.06,103.99l-27.47,35.24l26.55,12.76l27.29-35.43L100.06,103.99z',
    betKey: createBetKey(BET_TYPE.STRAIGHT, 36),
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M104.39,42.94c-5.92-2.77-12.12-4.16-18.59-4.44c-2.96-0.09-6.01,0.09-9.06,0.55c-3.7,0.55-7.12,1.48-10.17,2.68c-5.09,1.94-9.25,4.81-12.58,8.6c-0.18,0.18-0.28,0.37-0.46,0.55c-3.24,3.88-4.9,7.95-5.09,12.3c-0.09,2.4,0.18,4.81,1.02,7.31c0.83,2.77,2.22,5.27,3.98,7.58c2.96,3.79,7.12,6.94,12.49,9.53l117.75,56.05l-19.42-72.89L104.39,42.94z',
    betKey: RACE_TRACK_BET_TYPE.TIER,
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M164.29,70.89l19.42,72.89l86.49,41.07l35.61-48.19L164.29,70.89L164.29,70.89z',
    betKey: RACE_TRACK_BET_TYPE.ORPHELINS,
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M429.06,202.91c9.43-1.66,18.58,0.06,27.46,3.95l-150.68-70.11l-35.61,48.19l150.31,71.5c-8.99-5.51-13.8-9.7-17.09-17.49c-2.96-7.86-2.49-16.55,2.67-23.58C411.03,208.52,419.26,204.67,429.06,202.91z',
    betKey: RACE_TRACK_BET_TYPE.VOISINS,
  },
  {
    id: uuidv4(),
    color: 'color-none',
    d: 'M504.66,232.35c-3.05-3.33-6.94-6.2-11.75-8.51c-0.18-0.09-0.46-0.18-0.65-0.28l-0.28-0.18c-0.18-0.09-0.46-0.18-0.65-0.28l-33.85-15.72c-0.28-0.09-0.65-0.28-0.92-0.46c-8.88-3.88-18.06-5.67-27.49-4.01c-9.8,1.76-18.04,5.79-22.94,12.45c-4.9,6.84-5.83,15.2-2.88,23.06c3.05,7.86,9.47,13.8,17.33,17.97l35.33,16.74c5.64,2.68,11.28,4.25,17.02,4.72c0.46,0.09,0.92,0.09,1.39,0.18h0.37c0.37,0,0.65,0,1.02,0c3.24,0.09,6.57-0.18,9.8-0.83c2.13-0.37,4.16-0.92,6.01-1.57l4.62-2.03l1.11-0.55c4.16-2.4,7.68-5.55,10.36-9.53c0.92-1.29,1.66-2.59,2.31-3.88l1.48-3.98l0.65-3.42c0.28-3.42-0.37-7.03-1.76-10.64C509.1,238.27,507.16,235.13,504.66,232.35L504.66,232.35z',
    betKey: RACE_TRACK_BET_TYPE.ZERO,
  },
];

const Racetrack3D = () => {
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const numberOrder = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numberOrder);
  const racetrackNeighborCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.racetrackNeighborCount
  );
  const isBettingTime = useSelector(selectIsBettingTime);
  const [highlightedBetSpots, setHighlightedBetSpots] = useState([]);
  const dispatch = useDispatch();

  const calculateCenterPoints = useCallback((path) => {
    const bbox = path.getBBox();
    const parentBBox = path.parentNode.getBBox();
    const parentWidth = parentBBox.width;
    const parentHeight = parentBBox.height;
    const centerX = ((bbox.x + bbox.width / 2) / Number.parseFloat(parentWidth)) * 100;
    const centerY = ((bbox.y + bbox.height / 2) / Number.parseFloat(parentHeight)) * 100;
    return { x: centerX, y: centerY };
  }, []);

  const onBetSpotEntered = useCallback(
    (event, item) => {
      if (!selectedChip && !isBettingTime) return;

      const { betKey } = item;
      dispatch(setItemHovered(betKey));
      const bet = parseBetKey(betKey);
      const { betType, numbers } = bet;
      let currentBetSpots, betsPreview, affectedNumbers;
      if (betType === BET_TYPE.STRAIGHT) {
        const { left, right } = getNeighbors(numberOrder, Number(numbers[0]), racetrackNeighborCount);
        affectedNumbers = [...left, ...right, ...numbers];
        currentBetSpots = affectedNumbers.map((number) => createBetKey(BET_TYPE.STRAIGHT, number));
        betsPreview = currentBetSpots.map((betKey) => ({
          ...parseBetKey(betKey),
          value: selectedChip,
        }));
      } else {
        affectedNumbers = NAMED_BET_VALUES[betType];
        currentBetSpots = [betKey, ...affectedNumbers.map((number) => createBetKey(BET_TYPE.STRAIGHT, number))];
        betsPreview = getRacetrackNamedBets(betType).map((bet) => ({
          ...bet,
          value: selectedChip * (bet.multiplier ?? 1),
        }));
      }

      setHighlightedBetSpots(currentBetSpots);
      dispatch(setHighlightedNumbers(affectedNumbers));
      dispatch(setBetsPreview(betsPreview));
    },
    [dispatch, isBettingTime, numberOrder, racetrackNeighborCount, selectedChip]
  );

  const onBetSpotLeaved = useCallback(() => {
    setHighlightedBetSpots([]);
    dispatch(setHighlightedNumbers(null));
    dispatch(clearBetsPreview());
  }, [dispatch]);

  const getBets = useCallback(
    (betSpotItem) => {
      const { betKey } = betSpotItem;
      const bet = parseBetKey(betKey);
      const { betType, numbers } = bet;
      let bets;
      if (betType === BET_TYPE.STRAIGHT) {
        const number = Number(numbers[0]);
        const { left, right } = getNeighbors(numberOrder, number, racetrackNeighborCount);
        const selectedItems = [number, ...left, ...right];
        bets = selectedItems.map((item) => ({
          betType: BET_TYPE.STRAIGHT,
          numbers: [item],
          multiplier: 1,
        }));
      } else {
        bets = getRacetrackNamedBets(betType);
      }

      return bets;
    },
    [numberOrder, racetrackNeighborCount]
  );

  const onBetSpotClicked = useCallback(
    async (event, betSpotItem) => {
      const bets = getBets(betSpotItem);

      const mappedBets = bets.map((betItem) => ({
        betType: createBetKey(betItem.betType, betItem.numbers),
        bet: (betItem?.multiplier ?? 1) * selectedChip,
      }));

      await dispatch(addBetThunk(mappedBets, event));
    },
    [dispatch, getBets, selectedChip]
  );

  const isHighlighted = useCallback(
    (item) => {
      if (!isBettingTime) return false;
      const { betKey } = item;
      return highlightedBetSpots.includes(betKey);
    },
    [highlightedBetSpots, isBettingTime]
  );

  return (
    <>
      <svg
        id="racetrack3D"
        className={cl(styles.racetrack, isBettingTime && styles.enabled)}
        viewBox="0 0 562.92 324.6"
        textRendering="geometricPrecision"
      >
        <defs>
          <style>
            {`
      .slingshotRacetrackWrapper {
        opacity: 1 !important;
        transition: opacity 0.1s ease-in;
      }
     
      .slingshotRacetrackWrapper .color-none {
        fill: white;
        opacity: 0;
      }
      .slingshotRacetrackWrapper .color-none.highlighted {
        fill: #aaa;
        opacity: 0.5;
      }
    `}
          </style>
        </defs>
        <g className="slingshotRacetrackWrapper">
          {betSpots.map((betSpot) => (
            <path
              id={betSpot.id}
              key={betSpot.id}
              className={cl(
                styles.betSpot,
                styles[betSpot.color],
                isBettingTime && isHighlighted(betSpot) && styles.highlighted
              )}
              d={betSpot.d}
              data-bet-key={betSpot.betKey}
              onMouseEnter={(event) => onBetSpotEntered(event, betSpot)}
              onMouseLeave={() => onBetSpotLeaved()}
              onClick={(event) => onBetSpotClicked(event, betSpot)}
            ></path>
          ))}

          <image
            xlinkHref={versionedUrl('/images/3d-racetrack.svg')}
            x="0"
            y="0"
            height="100%"
            width="100%"
            style={{ pointerEvents: 'none' }}
          ></image>
        </g>
      </svg>
    </>
  );
};

export default Racetrack3D;
