import cl from 'clsx';
import { roundNumber } from '@/lib/numberService';
import { useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import styles from './TableResponsive.module.scss';
import ResponsiveBorder from './ResponsiveBorder';
import ResponsiveBetSpots from '../BetSpots/ResponsiveBetSpots';
import ROUND_STATE from '@/enums/roundState';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { useSelector } from 'react-redux';
import useClickAndDrag from '@/hooks/useClickAndDrag';

const TableResponsive = ({
  orientation,
  onTableOptionsChanged,
  fieldOpacity,
  onChipPositionCalculated,
  onBetSpotEntered,
  onBetSpotLeaved,
  onBetSpotClicked,
}) => {
  const [tableOptions, setTableOptions] = useState(null);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const isBettingTime = useSelector(selectIsBettingTime);
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure();
  const dragAreaRef = useClickAndDrag(onBetSpotClicked, onBetSpotEntered, onBetSpotLeaved);

  const betSpotSizes = useMemo(() => {
    const baseHeight =
      orientation === 'horizontal' ? roundNumber(containerHeight / 5, 2) : roundNumber(containerHeight / 14, 2);
    const baseWidth =
      orientation === 'horizontal' ? roundNumber(containerWidth / 14, 2) : roundNumber(containerWidth / 5, 2);

    return {
      numberItem: {
        height: baseHeight,
        width: baseWidth,
      },
      outerItem: {
        height: baseHeight,
        width: baseWidth,
      },
      sensorThickness: Math.min(baseWidth * 0.25, baseHeight * 0.25),
    };
  }, [orientation, containerHeight, containerWidth]);

  useEffect(() => {
    if (containerWidth && containerHeight) {
      const options = {
        tableWidth: containerWidth,
        tableHeight: containerHeight,
        borderColor: '#F9E1CC',
        zeroWidth: 70,
        fieldOpacity,
        ...betSpotSizes,
      };
      setTableOptions(options);
      onTableOptionsChanged(options);
    }
  }, [betSpotSizes, containerHeight, containerWidth, fieldOpacity, onTableOptionsChanged]);

  const memoizedBetSpots = useMemo(() => {
    return (
      <ResponsiveBetSpots
        orientation={orientation}
        tableOptions={tableOptions}
        onChipPositionCalculated={onChipPositionCalculated}
        onBetSpotEntered={onBetSpotEntered}
        onBetSpotLeaved={onBetSpotLeaved}
      />
    );
  }, [onBetSpotEntered, onBetSpotLeaved, onChipPositionCalculated, orientation, tableOptions]);

  const memoizedBorder = useMemo(() => {
    return <ResponsiveBorder orientation={orientation} tableOptions={tableOptions} />;
  }, [orientation, tableOptions]);

  const tableDisabled = !isBettingTime && roundState !== ROUND_STATE.WINNING_NUMBER;

  return (
    <div
      ref={(el) => {
        (dragAreaRef.current = el), containerRef(el);
      }}
      className={cl(styles.container, tableDisabled && styles.disabled)}
    >
      {containerHeight && containerWidth && tableOptions && (
        <svg className={styles.table}>
          {memoizedBetSpots}
          {memoizedBorder}
        </svg>
      )}
    </div>
  );
};

export default TableResponsive;
