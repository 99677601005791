import clsx from 'clsx';
import { useSelector } from 'react-redux';
import styles from './Racetrack.module.scss';

const numbersPathDrawn = {
  0: 'M774.6 129.65a50.2 50.2 0 0 1-20.9 26l-.3.2 35.1 45 .3-.2a101 101 0 0 0 16-13.9 106.64 106.64 0 0 0 23.2-39.5l.1-.3-53.3-17.6Z',
  1: 'M214.2.75H260v55.8h-45.8z',
  2: 'M489.48 162.91h45.8v55.8h-45.8z',
  3: 'M752.1 62.75a50.53 50.53 0 0 1 22.5 26.9l.1.3 53.3-17.6-.1-.3a107.44 107.44 0 0 0-23.2-39.5 99.94 99.94 0 0 0-17.5-14.9l-.3-.2-35.2 45.1Z',
  4: 'M581.24 162.91h45.8v55.8h-45.8z',
  5: 'M63.5 83.65a54.08 54.08 0 0 1 20.6-20.5l.3-.2-29.3-47.1-.2-.3-.3.2a109.82 109.82 0 0 0-37 36l-.1.3 45.9 32Z',
  6: 'M305.96 162.91h45.8v55.8h-45.8z',
  7: 'M581.4.75h45.8v55.8h-45.8z',
  8: 'm85.6 157-.3-.2a53.61 53.61 0 0 1-21.8-21.2l-.2-.3-45.9 32.1.2.3a111.81 111.81 0 0 0 38.2 36.7l.3.2 29.3-47.2Z',
  9: 'M397.72.75h45.8v55.8h-45.8z',
  10: 'M56.7 109.25a53.5 53.5 0 0 1 6.5-25l.2-.3-46-31.9-.2.3a109 109 0 0 0-16.3 56.9v.3h55.8Z',
  11: 'M121 162.91h45.8v55.8H121z',
  12: 'M673 .75h45.8v55.8H673z',
  13: 'M214.2 162.91H260v55.8h-45.8z',
  14: 'M305.96.75h45.8v55.8h-45.8z',
  15: 'M673 162.91h45.8v55.8H673z',
  16: 'M121 .75h45.8v55.8H121z',
  17: 'M397.72 162.91h45.8v55.8h-45.8z',
  18: 'M489.6.75h45.8v55.8h-45.8z',
  19: 'M626.96 162.91h45.8v55.8h-45.8z',
  20: 'M260.08.75h45.8v55.8h-45.8z',
  21: 'M535.36 162.91h45.8v55.8h-45.8z',
  22: 'M443.7.75h45.8v55.8h-45.8z',
  23: 'M63.1 134.85a53.81 53.81 0 0 1-6.4-24.8v-.3H.9v.3A109 109 0 0 0 17.2 167l.2.3 45.8-32.1Z',
  24: 'm55.3 15.35-.3.2L84.5 63l.3-.2a52.72 52.72 0 0 1 25.2-6.4h11.2V.65H110a109.11 109.11 0 0 0-54.7 14.7Z',
  25: 'M443.53 162.91h45.8v55.8h-45.8z',
  26: 'm828.1 72.75-.1-.3-53.3 17.6.1.3a60.7 60.7 0 0 1 0 38.6l-.1.3 53.3 17.6.1-.3a122.29 122.29 0 0 0 0-73.8Z',
  27: 'M260.08 162.91h45.8v55.8h-45.8z',
  28: 'M626.96.75h45.8v55.8h-45.8z',
  29: 'M535.36.75h45.8v55.8h-45.8z',
  30: 'm85.9 157.05-.3-.1-29.3 47.2-.2.3.3.2a108.63 108.63 0 0 0 53.4 14H121v-55.8h-11.2a52.67 52.67 0 0 1-23.9-5.8Z',
  31: 'M351.84.75h45.8v55.8h-45.8z',
  32: 'm753.3 155.85-.3.1a49.47 49.47 0 0 1-25.5 6.8h-9.9v55.8h12.8A100.84 100.84 0 0 0 788 201l.3-.2-34.8-44.6Z',
  33: 'M168.32.75h45.8v55.8h-45.8z',
  34: 'M351.84 162.91h45.8v55.8h-45.8z',
  35: 'm751.4 62.35.3.1 35.2-45.1-.3-.2A102.55 102.55 0 0 0 730.4.65h-12.8v55.8h9.9a49.77 49.77 0 0 1 23.9 5.9Z',
  36: 'M168.32 162.91h45.8v55.8h-45.8z',
};

const textItems = {
  0: {
    x: '94.6%',
    y: '73.6%',
  },
  1: {
    x: '28.36%',
    y: '13.08%',
  },
  2: {
    x: '61.29%',
    y: '87.13%',
  },
  3: {
    x: '94.47%',
    y: '25.87%',
  },
  4: {
    x: '72.27%',
    y: '87.13%',
  },
  5: {
    x: '6.18%',
    y: '22.92%',
  },
  6: {
    x: '39.34%',
    y: '87.13%',
  },
  7: {
    x: '72.28%',
    y: '13.08%',
  },
  8: {
    x: '6.25%',
    y: '76.95%',
  },
  9: {
    x: '50.31%',
    y: '13.08%',
  },
  10: {
    x: '3.46%',
    y: '39.65%',
  },
  11: {
    x: '17.21%',
    y: '87.13%',
  },
  12: {
    x: '83.24%',
    y: '13.08%',
  },
  13: {
    x: '28.36%',
    y: '87.13%',
  },
  14: {
    x: '39.34%',
    y: '13.08%',
  },
  15: {
    x: '83.24%',
    y: '87.13%',
  },
  16: {
    x: '17.21%',
    y: '13.08%',
  },
  17: {
    x: '50.31%',
    y: '87.13%',
  },
  18: {
    x: '61.3%',
    y: '13.08%',
  },
  19: {
    x: '77.73%',
    y: '87.13%',
  },
  20: {
    x: '33.85%',
    y: '13.08%',
  },
  21: {
    x: '66.78%',
    y: '87.13%',
  },
  22: {
    x: '55.81%',
    y: '13.08%',
  },
  23: {
    x: '3.45%',
    y: '60.07%',
  },
  24: {
    x: '11.25%',
    y: '13.08%',
  },
  25: {
    x: '55.79%',
    y: '87.13%',
  },
  26: {
    x: '96.33%',
    y: '49.87%',
  },
  27: {
    x: '33.85%',
    y: '87.13%',
  },
  28: {
    x: '77.73%',
    y: '13.08%',
  },
  29: {
    x: '66.78%',
    y: '13.08%',
  },
  30: {
    x: '11.3%',
    y: '86.82%',
  },
  31: {
    x: '44.83%',
    y: '13.08%',
  },
  32: {
    x: '89%',
    y: '86.6%',
  },
  33: {
    x: '22.87%',
    y: '13.08%',
  },
  34: {
    x: '44.83%',
    y: '87.13%',
  },
  35: {
    x: '89%',
    y: '13.3%',
  },
  36: {
    x: '22.87%',
    y: '87.13%',
  },
};

const RaceTrack = ({ className, ...props }) => {
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);

  return (
    <svg width="100%" viewBox="0 0 836 219" textRendering="geometricPrecision" {...props}>
      <defs>
        <style>
          {
            '.dddRacetrack-wrapper .black_color,.dddRacetrack-wrapper .red_color,.dddRacetrack-wrapper .sector_color{fill:transparent}.dddRacetrack-wrapper .black_color_label,.dddRacetrack-wrapper .called_bets_label,.dddRacetrack-wrapper .red_color_label{transition:fill .15s linear;pointer-events:none;font-weight:400;font-family:Roulette,Arial;text-anchor:middle}.dddRacetrack-wrapper .vertical_center{dominant-baseline:central}.dddRacetrack-wrapper .red_color_label{fill:#b51c12}.dddRacetrack-wrapper .black_color_label,.dddRacetrack-wrapper .called_bets_label{fill:#f9e1cc}.dddRacetrack-wrapper .black_color_label,.dddRacetrack-wrapper .called_bets_label,.dddRacetrack-wrapper .red_color_label{font-size:27px}'
          }
        </style>
      </defs>
      <g className="dddRacetrack-wrapper">
        {Object.keys(numbersPathDrawn).map((key, index) => (
          <path key={`racetrack-number-path-${key}`} className={styles.item} d={numbersPathDrawn[key]} />
        ))}

        {Object.keys(textItems).map((key) => (
          <text
            key={`racetrack-text-${key}`}
            className={clsx(styles.text, styles[numbers[Number(key)].color])}
            x={textItems[key].x}
            y={textItems[key].y}
            style={{ dominantBaseline: 'central', textAnchor: 'middle' }}
          >
            {key}
          </text>
        ))}

        <path className={styles.namedBet} d="M305.6 162.07h137.09V55.93H213.64l91.96 106.14z" />
        <path
          className={styles.namedBet}
          d="M443.88 162.07h222.73c-40.853-3.368-62.741-49.697-39.398-83.394A53.25 53.25 0 0 1 666.61 55.93H443.88Z"
        />
        <path className={styles.namedBet} d="M109 162.07h196.34l-92-106.14H109a53.07 53.07 0 0 0 0 106.14Z" />
        <path
          className={styles.namedBet}
          d="M763.81 146.53C773 136.69 778 123.36 778 109s-5-27.69-14.18-37.53a48.748 48.748 0 0 0-36.16-15.54H671c-40.853 0-66.387 44.225-45.96 79.605A53.07 53.07 0 0 0 671 162.07h56.69a48.749 48.749 0 0 0 36.12-15.54Z"
        />
        <path
          className="border"
          d="M804.5 32c-19.2-20.7-45.7-32-74.6-32H109.4a109.36 109.36 0 0 0-2.1 218.7h622.6c28.9 0 55.4-11.4 74.6-32 18.8-20.3 29.2-47.8 29.2-77.3S823.3 52.3 804.5 32ZM727 162.2h-56.7a52.8 52.8 0 0 1 0-105.6H727c28.5 0 50.1 22.7 50.1 52.8s-21.5 52.8-50.1 52.8ZM56.6 109.4a52.8 52.8 0 0 1 52.8-52.8h104.2l91.5 105.6H109.4a53 53 0 0 1-52.8-52.8Zm-.7-.4H.77A107.9 107.9 0 0 1 17 52.3L62.4 84a52.4 52.4 0 0 0-6.5 25Zm0 .8a54.6 54.6 0 0 0 6.4 25L17 166.5A107.3 107.3 0 0 1 .77 109.8Zm617.1-54V.8h43.7V56l-43.7-.1Zm-.8 0h-45.1V.8h45.1ZM443.5 162.2V56.6h217.9a53.54 53.54 0 0 0 0 105.6Zm-137.4 0L214.6 56.6h228.1v105.6ZM626.4 55.8h-45.1V.8h45.1Zm-45.9 0h-45.1V.8h45.1Zm-45.9 0h-45.1V.8h45.1Zm-45.9 0h-45.2V.8h45.2Zm-46 0h-45V.8h45.1Zm-45.8 0h-45.1V.8H397Zm-45.9 0h-45V.8h45.1Zm-45.8 0h-45.1V.8h45.1Zm-45.9 0h-45.1V.8h45.1Zm-45.9 0h-45.1V.8h45.1Zm-45.9 0H121V.8h46.6Zm0 107.15v55.1H121V163Zm.8 0h45.1v55.1h-45.1Zm45.8 0h45.1v55.1h-45.1Zm45.9 0h45.1v55.1H260Zm45.9 0H351v55.1h-45Zm45.9 0H397v55.1h-45.2Zm45.9 0h45.1v55.1h-45Zm45.8 0h45.2v55.1h-45.2Zm46 0h45.1v55.1h-45.1Zm45.9 0h45.1v55.1h-45.1Zm45.8 0h45.1v55.1h-45.1Zm45.9 0h45.1v55.1h-45.1Zm45.9 0h43.7v55.1H673Zm104.8-53.5a61.16 61.16 0 0 0-3.2-19.4l52.6-17.3a121.3 121.3 0 0 1 0 73.5l-52.6-17.4a56 56 0 0 0 3.2-19.4Zm26.1-76.8A107.4 107.4 0 0 1 827 72l-52.6 17.4a50.34 50.34 0 0 0-22.6-27.1l34.8-44.5a89.84 89.84 0 0 1 17.3 14.85Zm-18-15.3L751.1 62a50.64 50.64 0 0 0-24.1-6h-9.5V.8H730a100.2 100.2 0 0 1 55.9 16.5ZM120.2.8V56h-10.8A53.5 53.5 0 0 0 84 62.35l-29-46.9A107.2 107.2 0 0 1 109.4.8Zm-65.9 15 29.1 46.9a53.65 53.65 0 0 0-20.6 20.6L17.4 51.6a108.94 108.94 0 0 1 36.9-35.8ZM17.4 167.1l45.2-31.7a54.35 54.35 0 0 0 21.9 21.4l-29.1 46.9a110 110 0 0 1-38-36.6ZM56.1 204l29.1-46.9a53.6 53.6 0 0 0 24.2 5.8h10.8V218h-10.9a108.16 108.16 0 0 1-53.2-14Zm661.4 14v-55h9.5a50.54 50.54 0 0 0 25.7-6.9l34.7 44.5a101.1 101.1 0 0 1-57.4 17.5Zm86.4-31.8a101 101 0 0 1-16 13.9l-34.7-44.4a51 51 0 0 0 21.1-26.2l52.6 17.5a104.8 104.8 0 0 1-23 39.25Z"
          style={{
            pointerEvents: 'none',
            strokeWidth: 1,
            fill: '#f9e1cc',
            stroke: '#f9e1cc',
          }}
        />

        <text className="called_bets_label vertical_center" x="41.87%" y="49.7%" dx="0em" dy="0em">
          {'ORPHELINS'}
        </text>
        <text className="called_bets_label vertical_center" x="63.5%" y="49.7%" dx="0em" dy="0em">
          {'SERIE 0/2/3'}
        </text>
        <text className="called_bets_label vertical_center" x="19.78%" y="49.7%" dx="0em" dy="0em">
          {'SERIE 5/8'}
        </text>
        <text className="called_bets_label vertical_center" x="83.63%" y="49.7%" dx="0em" dy="0em">
          {'ZERO'}
        </text>
      </g>
    </svg>
  );
};

export default RaceTrack;
