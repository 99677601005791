import Button from '@/components/common/Button';
import { BET_TYPE } from '@/enums/layoutTable';
import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useMoney from '@/hooks/useMoney';
import useOrientation from '@/hooks/useOrientation';
import { LOCALE_TYPE } from '@/lib/paramService';
import { getOutsideBetDisplayName, isOutsideBet, parseBetKey } from '@/lib/rouletteService';
import scrollbarStyles from '@/styles/Scrollbar.module.scss';
import clsx from 'clsx';
import { format } from 'date-fns';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import styles from './HistoryDetails.module.scss';

const HistoryDetails = ({ itemsCount, selectedHistoryItem, backToGameHistory, onItemSelected }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const orientation = useOrientation();
  const { t } = useTranslation('common');
  const rouletteNumbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const { format: moneyFormat } = useMoney();
  const { index, closedAt, startedAt, winningNumber, results } = selectedHistoryItem;

  const getUserFriendlyBetName = useCallback(
    (key) => {
      let betName;
      const parsedBet = parseBetKey(key);
      const { numbers, betType } = parsedBet;
      if (betType === BET_TYPE.STRAIGHT) {
        const number = numbers[0];
        betName = `${number} ${t(rouletteNumbers[number].color)}`;
      } else if (isOutsideBet(betType)) {
        betName = getOutsideBetDisplayName(parsedBet.betType).toUpperCase();
      } else {
        betName = `${_.capitalize(betType)}: ${numbers.join('/')}`;
      }

      return betName;
    },
    [rouletteNumbers, t]
  );

  const betsSumValue = useMemo(() => _.sumBy(Object.values(results), (result) => result.bet), [results]);
  const winsSumValue = useMemo(() => _.sumBy(Object.values(results), (result) => result.win), [results]);

  return (
    <div className={styles.container}>
      {isTouchDevice && (
        <div className={styles.headerSection}>
          {orientation === SCREEN_ORIENTATION.PORTRAIT && (
            <div className={clsx(index === 0 && styles.hidden)} onClick={() => onItemSelected(index - 1)}>
              <MdKeyboardArrowLeft size={24} />
            </div>
          )}

          {orientation === SCREEN_ORIENTATION.LANDSCAPE && (
            <Button disabled={index === 0} text={t('history.previousGame')} onClick={() => onItemSelected(index - 1)} />
          )}
          <Button
            className={styles.backToGameHistoryButton}
            text={t('history.backToGameHistory')}
            onClick={backToGameHistory}
          />
          {orientation === SCREEN_ORIENTATION.PORTRAIT && (
            <div className={clsx(index === itemsCount - 1 && styles.hidden)} onClick={() => onItemSelected(index + 1)}>
              <MdKeyboardArrowRight size={24} />
            </div>
          )}
          {orientation === SCREEN_ORIENTATION.LANDSCAPE && (
            <Button
              disabled={index === itemsCount - 1}
              text={t('history.nextGame')}
              onClick={() => onItemSelected(index + 1)}
            />
          )}
        </div>
      )}
      <div className={styles.roundInfo}>
        <div className={styles.roundInfoSection}>
          <div className={styles.label}>{t('history.gameNumber')}:</div>
          <div className={styles.value}>{format(new Date(startedAt), '#HH:mm:ss')}</div>
        </div>
        <div className={styles.roundInfoSection}>
          <div className={styles.label}>{t('history.gameClosed')}:</div>
          <div className={styles.value}>{new Date(closedAt).toLocaleString(LOCALE_TYPE, { hour12: false })}</div>
        </div>
      </div>
      <div className={clsx(styles.results, scrollbarStyles.scrollbar)}>
        <div className={styles.title}>{t('history.result')}</div>
        <div className={styles.symbolContainer}>
          <div className={clsx(styles.symbol, styles[rouletteNumbers[winningNumber]?.color])}>{winningNumber}</div>
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('betType')}</th>
              <th>{t('bet')}</th>
              <th>Win</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(results).map((betType) => (
              <tr key={`bet-history-item-${betType}`}>
                <td>{getUserFriendlyBetName(betType)}</td>
                <td>{moneyFormat(results[betType].bet)}</td>
                <td>{moneyFormat(results[betType].win)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>{t('history.total')}</td>
              <td>{moneyFormat(betsSumValue)}</td>
              <td>{moneyFormat(winsSumValue)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      {!isTouchDevice && (
        <div className={styles.footerSection}>
          <Button disabled={index === 0} text={t('history.previousGame')} onClick={() => onItemSelected(index - 1)} />
          <Button text={t('history.backToGameHistory')} onClick={backToGameHistory} />
          <Button
            disabled={index === itemsCount - 1}
            text={t('history.nextGame')}
            onClick={() => onItemSelected(index + 1)}
          />
        </div>
      )}
    </div>
  );
};

export default HistoryDetails;
