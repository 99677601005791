import cl from 'clsx';
import styles from './AutoRouletteTimer.module.scss';
import { useSelector } from 'react-redux';
import ROUND_STATE from '@/enums/roundState';
import { useEffect } from 'react';
import { playFinalBetsTimerSound } from '@/lib/soundService';

const AutoRouletteTimer = ({ size }) => {
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const isFinalBets = roundState === ROUND_STATE.FINAL_BETS;
  const root = document.documentElement;
  const placeBetsColor = getComputedStyle(root).getPropertyValue('--place-bets-timer-color');
  const finalBetsColor = getComputedStyle(root).getPropertyValue('--final-bets-timer-color');
  const activeColor = isFinalBets ? finalBetsColor : placeBetsColor;

  useEffect(() => {
    let intervalId;
    if (roundState === ROUND_STATE.FINAL_BETS) {
      intervalId = setInterval(() => {
        playFinalBetsTimerSound();
      }, 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [roundState]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height="100%"
      viewBox="0 0 108 100"
      fill="none"
      className={cl(styles.timer, isFinalBets && styles.finalBets)}
    >
      <circle cx={54} cy={50} r={50} fill="#000" fillOpacity={0.3} />
      <g>
        <path stroke={activeColor} strokeWidth={4} d="M54 24v26" />
      </g>
      <g>
        <path stroke={activeColor} strokeWidth={4} d="M74.062 62.051 52.931 48.69" />
      </g>
      <g className={styles.rotatingPath}>
        <path
          fill={activeColor}
          d="M83.533 90.346a50.002 50.002 0 0 0 .22-80.53A50 50 0 0 0 23.41 89.55l9.188-11.878a34.984 34.984 0 1 1 42.066.557l8.87 12.117Z"
        />
      </g>
    </svg>
  );
};

export default AutoRouletteTimer;
