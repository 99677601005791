import ROUND_STATE from '@/enums/roundState';
import { SOUND_TYPE } from '@/enums/sound';
import store from '@/store/index';
import { Logger } from '@vpmedia/simplify';
import { Howl } from 'howler';
import { LANGUAGE } from './paramService';

const logger = new Logger('SoundService');

/**
 * TBD.
 * @param {string} source - TBD.
 * @param {string} soundType - TBD.
 * @param {boolean} [loop] - TBD.
 * @param {import('howler').HowlCallback} [onPlayEnd] - TBD.
 */
export const playSound = (source, soundType, loop = false, onPlayEnd) => {
  const { ui: uiState, settings: settingsState } = store.getState();
  const { userInteracted } = uiState;
  const { soundVolume } = settingsState;

  const { isMuted: isMainMuted } = soundVolume.main;
  if (isMainMuted || !userInteracted) return;

  const level = soundVolume[soundType].level;
  const isMuted = soundVolume[soundType].isMuted;

  const soundToPlay = new Howl({
    src: [source],
    volume: (level / 100) * (soundVolume.main.level / 100),
    autoplay: false,
    loop,
    onend: onPlayEnd || (() => {}),
  });
  soundToPlay.once('loaderror', () => {
    logger.warn('Error loading sound', { source });
  });
  soundToPlay.once('playerror', () => {
    logger.warn('Error playing sound', { source });
  });

  if (isMuted) {
    soundToPlay.mute();
  }
  // logger.info('playSound', { source, soundType, loop, isMuted });
  try {
    soundToPlay.play();
  } catch (error) {
    // @ts-ignore
    logger.exception('playSound() error', error);
  }
};

export const playWinningNumberVoice = (winningNumber, withWon = false) => {
  if (winningNumber === null) {
    logger.warn('playWinningNumberVoice received null winningNumber');
    return;
  }
  const language = LANGUAGE;
  const { settings: settingsState } = store.getState();
  const { voiceType } = settingsState;

  const callback = withWon
    ? () => playSound(`./sounds/voices/${language}/${voiceType}/you_won.mp3`, SOUND_TYPE.EFFECTS)
    : null;
  playSound(
    `./sounds/voices/${language}/${voiceType}/number_${winningNumber}.mp3`,
    SOUND_TYPE.EFFECTS,
    false,
    callback
  );
};

export const playBetOneChipSound = () => playSound('./sounds/bet_one_chip.mp3', SOUND_TYPE.EFFECTS);
export const playBetMultipleSound = () => playSound('./sounds/bet_multiple.mp3', SOUND_TYPE.EFFECTS);
export const playChipsSound = () => playSound('./sounds/chips.mp3', SOUND_TYPE.EFFECTS);
export const playPressSound = () => playSound('./sounds/press_icon_or_close.mp3', SOUND_TYPE.EFFECTS);
export const playNotAllowedSound = () => playSound('./sounds/not_allowed.mp3', SOUND_TYPE.EFFECTS);
export const playOutOfMoneySound = () => playSound('./sounds/out_of_money.mp3', SOUND_TYPE.EFFECTS);
export const playUndoSound = () => playSound('./sounds/undo.mp3', SOUND_TYPE.EFFECTS);
export const playFinalBetsTimerSound = () => playSound('./sounds/final_bets_timer_sound.mp3', SOUND_TYPE.EFFECTS);
export const playRoundStateVoice = (roundState) => {
  const language = LANGUAGE;
  const { settings: settingsState } = store.getState();
  const { voiceType } = settingsState;

  switch (roundState) {
    case ROUND_STATE.PLACE_BETS:
      playSound(`./sounds/voices/${language}/${voiceType}/place_your_bets.mp3`, SOUND_TYPE.EFFECTS);
      break;
    case ROUND_STATE.FINAL_BETS:
      playSound(`./sounds/voices/${language}/${voiceType}/final_bets.mp3`, SOUND_TYPE.EFFECTS);
      break;
    case ROUND_STATE.NO_MORE_BETS:
      playSound(`./sounds/voices/${language}/${voiceType}/no_more_bets.mp3`, SOUND_TYPE.EFFECTS);
      break;
  }
};
