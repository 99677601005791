import useMoney from '@/hooks/useMoney';
import { LOCALE_TYPE } from '@/lib/paramService';
import clsx from 'clsx';
import { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import HistoryViewTableRow from '../HistoryViewTableRow';
import styles from './HistoryViewTableGroup.module.scss';

const HistoryViewTableGroup = ({ group, onItemSelected }) => {
  const [isOver, setIsOver] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const { format } = useMoney();
  const { date, bet, win, items } = group;

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  return (
    <>
      <div
        className={clsx(styles.column, styles.first, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={toggleIsOpen}
      >
        {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
        {date.toLocaleDateString(LOCALE_TYPE)}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={toggleIsOpen}
      >
        {format(bet)}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={toggleIsOpen}
      >
        {format(win)}
      </div>
      {isOpen &&
        items.map(({ index, roundId, date, bet, win }) => (
          <HistoryViewTableRow
            key={roundId}
            index={index}
            date={date}
            bet={bet}
            win={win}
            onItemSelected={onItemSelected}
          />
        ))}
    </>
  );
};

export default HistoryViewTableGroup;
