import IconButton from '@/components/IconButton';
import SoundIndicator from '../../SoundIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { muteSound, unmuteSound } from '@/store/settingsSlice';
import { playPressSound } from '@/lib/soundService';
import CustomTooltip from '@/components/Tooltip';
import { useTranslation } from 'react-i18next';

const DesktopSoundToggleButton = ({ className }) => {
  const { t } = useTranslation('common');
  const [isButtonVisited, setIsButtonVisited] = useState(false);
  const userInteracted = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.userInteracted
  );
  const { main: mainSound } = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume
  );
  const dispatch = useDispatch();

  const handleSoundClicked = useCallback(() => {
    if (!userInteracted) return;
    playPressSound();
    mainSound?.isMuted ? dispatch(unmuteSound({ type: 'main' })) : dispatch(muteSound({ type: 'main' }));
  }, [dispatch, mainSound?.isMuted, userInteracted]);

  const handleMouseEnter = useCallback(() => {
    if (isButtonVisited) return;

    setIsButtonVisited(true);
  }, [isButtonVisited]);

  return (
    <>
      <div data-tooltip-id="disable-mute-tooltip" onMouseEnter={handleMouseEnter}>
        <IconButton
          className={className}
          icon={
            <SoundIndicator
              size="1em"
              level={mainSound?.level}
              isMuted={mainSound?.isMuted}
              withUserInteractedIndicator={true}
            />
          }
          onClick={handleSoundClicked}
        />
      </div>

      <CustomTooltip
        id="disable-mute-tooltip"
        content={t('settings.disableMute')}
        isOpen={!userInteracted && !isButtonVisited}
      />
    </>
  );
};

export default DesktopSoundToggleButton;
