import cl from 'clsx';
import styles from './QuickChat.module.scss';
import MessageInput from '@/components/common/QuickChat/MessageInput';
import MessageList from '@/components/common/QuickChat/MessageList';
import MessageWall from '@/components/MessageWall';
import { MENU_VIEW, QUICK_CHAT_TYPE } from '@/enums/ui';
import { useMeasure } from 'react-use';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const QuickChat = ({ type = QUICK_CHAT_TYPE.NORMAL, className }) => {
  const slidePopupOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOpened
  );
  const slidePopupView = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupView
  );
  const [messagesAndWinnersRef, { height: mainHeight }] = useMeasure();
  const [measureRef, { height: messageContainerHeight }] = useMeasure();
  const reverseMessagesContainerRef = useRef();

  const isNormalChat = type === QUICK_CHAT_TYPE.NORMAL;
  const isChatHidden = slidePopupOpened && slidePopupView === MENU_VIEW.CHAT;

  const scrollToBottom = useCallback(() => {
    if (isNormalChat) return;
    if (reverseMessagesContainerRef.current) {
      const { current: container } = reverseMessagesContainerRef;
      container.scrollTop = container.scrollHeight;
    }
  }, [isNormalChat]);

  const onMessageItemAdded = useCallback(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const onMessageItemDeleted = useCallback(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    scrollToBottom();
  }, [messageContainerHeight, scrollToBottom]);

  return (
    <div className={cl(styles.container, className, styles[type])}>
      <div className={cl(styles.input, isChatHidden && styles.hidden)}>
        <MessageInput />
      </div>

      <div ref={messagesAndWinnersRef} className={styles.messagesAndWinners}>
        <div className={styles.innerWrapper}>
          {isNormalChat && !isChatHidden && (
            <div className={styles.messages}>
              <MessageList />
            </div>
          )}
          <div className={styles.winnersOuterContainer}>
            <div className={styles.winnersContainer}>
              <div className={styles.winners}>
                <MessageWall height={`${mainHeight}px`} />
              </div>
            </div>
          </div>
          {!isNormalChat && !isChatHidden && (
            <div
              ref={(el) => {
                reverseMessagesContainerRef.current = el;
                measureRef(el);
              }}
              className={styles.messages}
            >
              <MessageList onItemAdded={onMessageItemAdded} onItemDeleted={onMessageItemDeleted} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuickChat;
