import Logo from '@/assets/svg/auto-roulette-loader-game-name.svg?react';
import PresentedBy from '@/assets/svg/auto-roulette-loader-presented-by.svg?react';
import { versionedUrl } from '@/lib/urlService';
import { setUiInitialized } from '@/store/uiSlice';
import { Logger } from '@vpmedia/simplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProgressBar from '../ProgressBar';
import styles from './LoadingScreen.module.scss';

const logger = new Logger('LoadingScreen');

const LoadingScreen = () => {
  const [loadedCount, setLoadedCount] = useState(0);
  const dispatch = useDispatch();

  const assets = useMemo(
    () => [
      versionedUrl('images/roulette-red-bg.png'),
      versionedUrl('images/stream-loading-overlay.jpg'),
      versionedUrl('images/3d-view-overlay.png'),
      versionedUrl('images/3d-roulette-table.svg'),
      versionedUrl('images/3d-racetrack.svg'),
      versionedUrl('images/win_effect.webp'),
    ],
    []
  );

  const incrementLoadedCount = useCallback(() => {
    return new Promise((resolve) => {
      setLoadedCount((prev) => {
        const newValue = prev + 1;
        resolve(newValue);
        return newValue;
      });
    });
  }, []);

  const loadAsset = useCallback((url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.addEventListener('load', resolve);
      img.addEventListener('error', reject);
    });
  }, []);

  const loadAllAssets = useCallback(async () => {
    try {
      await Promise.all(
        assets.map(async (asset) => {
          await loadAsset(asset);
          await incrementLoadedCount();
        })
      );
    } catch (error) {
      // @ts-ignore
      logger.exception('Error loading assets', error);
    }
  }, [assets, incrementLoadedCount, loadAsset]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && loadedCount === assets.length) {
      dispatch(setUiInitialized(true));
    }
    return () => {
      isMounted = false;
    };
  }, [assets.length, dispatch, loadedCount]);

  useEffect(() => {
    loadAllAssets();
  }, [loadAllAssets]);

  const progress = Math.round((loadedCount / assets.length) * 100);

  return (
    <div className={styles.loadingScreen}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <Logo width="100%" height="100%" />
        </div>
        <div className={styles.progressBar}>
          <ProgressBar progress={progress} />
        </div>
        <div className={styles.presentedBy}>
          <PresentedBy width="15em" height="100%" />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
