import StreamView from '@/components/StreamView';
import WinningNumber from '@/components/WinningNumber';
import ROUND_STATE from '@/enums/roundState';
import { setStreamViewSize } from '@/store/uiSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import useOrientation from '@/hooks/useOrientation';
import cl from 'clsx';
import styles from './MobileStreamView.module.scss';
import { SCREEN_ORIENTATION } from '@/enums/ui';

const MobileStreamView = () => {
  const orientation = useOrientation();
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const [ref, { width: streamWidth, height: streamHeight }] = useMeasure();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setStreamViewSize({
        width: streamWidth,
        height: streamHeight,
      })
    );
  }, [dispatch, streamHeight, streamWidth]);

  return (
    <div className={cl(styles.container, orientation === SCREEN_ORIENTATION.LANDSCAPE && styles.landscape)}>
      <StreamView ref={ref} className={styles.stream} />
      {roundState === ROUND_STATE.WINNING_NUMBER && (
        <div className={styles.winningNumber}>
          <WinningNumber showWin={true} number={winningNumber} />
        </div>
      )}
    </div>
  );
};

export default MobileStreamView;
