import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} ChatMessage
 * @property {string} chatId - UUIDv4 identifier.
 * @property {string} message - Message text.
 * @property {string} screenName - Screen name.
 * @property {string} userId - Like 'GR_1'.
 * @property {string} userType - Enum player, dealer, or pitBoss.
 * @property {boolean} [isHidden] - Do not broadcast message to other players.
 */

/**
 * @typedef {object} ChatState - TBD.
 * @property {string} inputMessage - TBD.
 * @property {ChatMessage[]} messages - TBD.
 */

/** @type {ChatState} */
const initialState = {
  inputMessage: '',
  messages: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {ChatState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setInputMessage: (state, action) => {
      const value = action.payload;
      if (value.length <= 255) {
        state.inputMessage = value;
      } else {
        state.inputMessage = value.slice(0, 255);
      }
    },
    /**
     * TBD.
     * @param {ChatState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - Store action.
     */
    addNewMessage: (state, action) => {
      const newMessage = action.payload;
      state.messages.push(newMessage);
    },
  },
});

export const { setInputMessage, addNewMessage } = chatSlice.actions;

export default chatSlice.reducer;
