import cl from 'clsx';
import { useCallback } from 'react';
import IconButton from '@/components/IconButton';
import RepeatIcon from '@/assets/svg/repeat.svg?react';
import { useSelector, useDispatch } from 'react-redux';
import { selectBetsAreOnlyWinningBets, selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { getBetSumValue } from '@/lib/betService';
import { useTranslation } from 'react-i18next';
import { aggregateBetsByType } from '@/lib/rouletteService';
import { addBetThunk } from '@/store/thunks/gameThunks';
import styles from './RepeatButton.module.scss';
import { playNotAllowedSound } from '@/lib/soundService';
import useShortcut from '@/hooks/useShortcut';

const RepeatButton = ({ labelDirection = 'right', withLabel = true, className }) => {
  const { t } = useTranslation(['roulette']);
  const betHistory = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory);
  const previousBet = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.previousBet);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const betsAreOnlyWinningBets = useSelector(selectBetsAreOnlyWinningBets);
  const isBettingTime = useSelector(selectIsBettingTime);
  const dispatch = useDispatch();

  const isRepeatDisabled = useCallback(() => {
    if (!isBettingTime || previousBet?.length === 0) return true;

    const betTypeToRemove = betsAreOnlyWinningBets ? [...new Set(betHistory.map((bet) => bet.betType))] : [];
    return getBetSumValue(previousBet.filter((bet) => !betTypeToRemove.includes(bet.betType))) > balance;
  }, [balance, betHistory, betsAreOnlyWinningBets, isBettingTime, previousBet]);

  const handleRepeatBet = useCallback(
    async (event) => {
      if (isRepeatDisabled()) {
        playNotAllowedSound();
        return;
      }

      const betTypeToRemove = betsAreOnlyWinningBets ? [...new Set(betHistory.map((bet) => bet.betType))] : [];

      const aggregatedBets = aggregateBetsByType(previousBet);
      const mappedBets =
        betTypeToRemove.length > 0
          ? aggregatedBets.filter((bet) => !betTypeToRemove.includes(bet.betType))
          : aggregatedBets;

      await dispatch(addBetThunk(mappedBets, event));
    },
    [betHistory, betsAreOnlyWinningBets, dispatch, isRepeatDisabled, previousBet]
  );

  const spaceShortcut = [{ key: ' ' }];
  const handleSpaceShortcut = useCallback(
    (event) => {
      handleRepeatBet();
    },
    [handleRepeatBet]
  );

  useShortcut(spaceShortcut, handleSpaceShortcut);

  return (
    <IconButton
      onClick={(event) => handleRepeatBet(event)}
      icon={<RepeatIcon height="1.25em" />}
      className={cl(styles.button, className)}
      disabled={isRepeatDisabled() || !isBettingTime}
      label={withLabel && t('chipSelector.repeat')}
      labelDirection={labelDirection}
    />
  );
};

export default RepeatButton;
