import cl from 'clsx';
import Chip from '@/components/Chip2D';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectCircle from '@/assets/svg/select-circle.svg?react';
import ROUND_STATE from '@/enums/roundState';
import styles from './ChipSelectorMenu.module.scss';

const ChipSelectorMenu = ({ chipSize, chips, handleSelection, orientation }) => {
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const [isOpen, setIsOpen] = useState(false);

  const startDegree = orientation === 'horizontal' ? 180 : 90;
  const endDegree = orientation === 'horizontal' ? 360 : 270;
  const radius = 15 * (chips.length ?? 1);

  const handleSelectorClick = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleFloatedChipClick = useCallback(
    (value) => {
      handleSelection(value);
      setIsOpen(false);
    },
    [handleSelection]
  );

  const startRadian = useMemo(() => (startDegree * Math.PI) / 180, [startDegree]);
  const endRadian = useMemo(() => (endDegree * Math.PI) / 180, [endDegree]);
  const childrenCount = chips.length;
  const offset = childrenCount === 1 ? 0 : (endRadian - startRadian) / (childrenCount - 1);

  const selectCircleAnimations = useMemo(
    () => ({
      close: { opacity: 0, scale: 1.2 },
      open: { opacity: 1, scale: 1.2, transition: { delay: 0.3 } },
    }),
    []
  );

  const floatedChipAnimation = useMemo(
    () => ({
      close: (index) => {
        const closedOffset = 0.5;
        let xPosition = 0;
        let yPosition = 0;

        if (orientation === 'horizontal') {
          xPosition = (index === 1 ? -1 : index === chips.length - 2 ? 1 : 0) * closedOffset;
        } else {
          yPosition = (index === 1 ? 1 : index === chips.length - 2 ? -1 : 0) * closedOffset;
        }

        return {
          x: `${xPosition}rem`,
          y: `${yPosition}rem`,
          scale: 0.9,
          rotate: 180,
        };
      },
      open: (index) => ({
        x: radius * Math.cos(startRadian + index * offset),
        y: radius * Math.sin(startRadian + index * offset),
        scale: 0.9,
        rotate: 360,
      }),
    }),
    [orientation, chips.length, radius, startRadian, offset]
  );

  const selectedChipAnimation = useMemo(
    () => ({
      close: { x: 0, y: 0 },
      open: { x: orientation === 'horizontal' ? 0 : '-2rem', y: orientation === 'horizontal' ? '-2rem' : 0 },
    }),
    [orientation]
  );

  useEffect(() => {
    const allowedRoundState = [ROUND_STATE.PLACE_BETS, ROUND_STATE.FINAL_BETS];

    if (!allowedRoundState.includes(roundState) && isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, roundState]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={cl(styles.backdrop, orientation === 'horizontal' && styles.horizontal)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
          ></motion.div>
        )}
      </AnimatePresence>
      <div className={styles.container}>
        <motion.div
          variants={selectedChipAnimation}
          initial="close"
          animate={isOpen ? 'open' : 'close'}
          className={styles.selectedChip}
          onClick={handleSelectorClick}
          transition={{ duration: 0.4 }}
        >
          <motion.div
            variants={selectCircleAnimations}
            initial="close"
            animate={isOpen ? 'open' : 'close'}
            className={styles.selectCircle}
          >
            <SelectCircle height={chipSize} width={chipSize} />
          </motion.div>
          <Chip value={selectedChip} size={chipSize} onClick={handleSelection} isTableChip={false} />
        </motion.div>

        {chips.map((value, index) => (
          <motion.div
            key={`chip-${value}`}
            className={styles.floatedChip}
            variants={floatedChipAnimation}
            initial="close"
            custom={index}
            animate={isOpen ? 'open' : 'close'}
            transition={{ duration: 0.4 }}
          >
            <Chip value={value} size={chipSize} onClick={() => handleFloatedChipClick(value)} isTableChip={false} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default ChipSelectorMenu;
