import { DESKTOP_VIEW_TYPE } from '@/enums/ui';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {object} UIState - TBD.
 * @property {boolean} isInitialized - TBD.
 * @property {string} viewType - TBD.
 * @property {boolean} userInteracted - TBD.
 * @property {boolean} sidenavOpened - TBD.
 * @property {string} screenOrientation - TBD.
 * @property {string} sidenavView - TBD.
 * @property {boolean} slidePopupOpened - TBD.
 * @property {string} slidePopupView - TBD.
 * @property {object} slidePopupOptions - TBD.
 * @property {boolean} tableLimitsOpened - TBD.
 * @property {boolean} tableLimitsPinned - TBD.
 * @property {string} desktopView - TBD.
 * @property {number} walletHeight - TBD.
 * @property {boolean} showStatisticsPopup - TBD.
 * @property {boolean} showFavoritesPopup - TBD.
 * @property {boolean} showLowBalancePopup - TBD.
 * @property {{duration: number, remainingTime: number}} timer - TBD.
 * @property {{window: {width: number, height: number}}} sizes - TBD.
 * @property {boolean} mouseOverOnTable - TBD.
 * @property {boolean} showMobileRacetrack - TBD.
 * @property {boolean} fullscreenVerticalPlayingArea - TBD.
 * @property {{width: number, height: number}} streamViewSize - TBD.
 * @property {boolean} touchMenuOpened - TBD.
 * @property {{key: string, title: string, content: string, id: string}[]} snackbars - TBD.
 * @property {boolean} isStreamFullScreen - TBD.
 * @property {boolean} isPageVisible - TBD.
 * @property {boolean} isPageFrozen - TBD.
 * @property {{x: number, y: number, message: string, type: string, id: string}} pointerTooltip - TBD.
 */

/**
 * Resets all popup and menu-related states to their initial values.
 * @param {UIState} state - State data.
 */
const resetMenus = (state) => {
  state.slidePopupOpened = false;
  state.showFavoritesPopup = false;
  state.showStatisticsPopup = false;
};

/** @type {UIState} */
const initialState = {
  isInitialized: false,
  viewType: null,
  userInteracted: false,
  sidenavOpened: false,
  screenOrientation: null,
  sidenavView: null,
  slidePopupOpened: false,
  slidePopupView: null,
  slidePopupOptions: null,
  tableLimitsOpened: false,
  tableLimitsPinned: false,
  desktopView: DESKTOP_VIEW_TYPE.THREE_DIMENSION,
  walletHeight: null,
  showStatisticsPopup: false,
  showFavoritesPopup: false,
  showLowBalancePopup: false,
  timer: null,
  sizes: {
    window: {
      height: null,
      width: null,
    },
  },
  mouseOverOnTable: false,
  showMobileRacetrack: false,
  fullscreenVerticalPlayingArea: true,
  streamViewSize: null,
  touchMenuOpened: false,
  snackbars: [],
  isStreamFullScreen: false,
  isPageVisible: true,
  isPageFrozen: false,
  pointerTooltip: null,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setUserInteracted: (state, action) => {
      state.userInteracted = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - Store action.
     */
    setWindowSize: (state, action) => {
      state.sizes.window = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setScreenOrientation: (state, action) => {
      state.screenOrientation = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    openFavoritesPopup: (state) => {
      resetMenus(state);
      state.showFavoritesPopup = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    closeFavoritesPopup: (state) => {
      state.showFavoritesPopup = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    openStatisticsPopup: (state) => {
      resetMenus(state);
      state.showStatisticsPopup = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    closeStatisticsPopup: (state) => {
      state.showStatisticsPopup = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setDesktopView: (state, action) => {
      state.desktopView = action.payload;
      state.slidePopupOpened = false;
      state.slidePopupView = null;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    openTableLimits: (state) => {
      state.tableLimitsOpened = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    closeTableLimits: (state) => {
      state.tableLimitsOpened = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    pinTableLimits: (state) => {
      state.tableLimitsPinned = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    unpinTableLimits: (state) => {
      state.tableLimitsPinned = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - Store action.
     */
    openSidenav: (state, action) => {
      state.sidenavView = action.payload;
      state.sidenavOpened = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    closeSidenav: (state) => {
      state.sidenavOpened = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{view: object, options: object}>} action - Store action.
     */
    setSlidePopupView: (state, action) => {
      resetMenus(state);
      const { view, options } = action.payload;
      state.slidePopupView = view;
      state.slidePopupOptions = options;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    openSlidePopup: (state) => {
      state.slidePopupOpened = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    closeSlidePopup: (state) => {
      state.slidePopupOpened = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - Store action.
     */
    setWalletHeight: (state, action) => {
      state.walletHeight = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{duration: number, remainingTime: number}>} action - Store action.
     */
    setTimer: (state, action) => {
      const { duration, remainingTime } = action.payload;
      if (remainingTime === 0) {
        state.timer = null;
      } else {
        state.timer = {
          duration,
          remainingTime,
        };
      }
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    clearTimer: (state) => {
      state.timer = null;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setMouseOverOnTable: (state, action) => {
      state.mouseOverOnTable = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    openLowBalancePopup: (state) => {
      state.showLowBalancePopup = true;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    closeLowBalancePopup: (state) => {
      state.showLowBalancePopup = false;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    toogleRacetrack: (state) => {
      state.showMobileRacetrack = !state.showMobileRacetrack;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setVerticalPlayingArea: (state, action) => {
      state.fullscreenVerticalPlayingArea = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{width: number, height: number}>} action - Store action.
     */
    setStreamViewSize: (state, action) => {
      state.streamViewSize = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setTouchMenuOpened: (state, action) => {
      state.touchMenuOpened = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{key: string, title: string, content: string}>} action - Store action.
     */
    addSnackbar: (state, action) => {
      const { key } = action.payload;

      if (state.snackbars.some((snackbar) => snackbar.key === key)) return;

      const snackbar = {
        ...action.payload,
        id: uuidv4(),
      };

      state.snackbars.push(snackbar);
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{id: string}>} action - Store action.
     */
    removeSnackbar: (state, action) => {
      state.snackbars = state.snackbars.filter((snackbar) => snackbar.id !== action.payload.id);
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    toogleStreamFullScreen: (state) => {
      state.isStreamFullScreen = !state.isStreamFullScreen;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setIsStreamFullScreen: (state, action) => {
      state.isStreamFullScreen = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setUiInitialized: (state, action) => {
      state.isInitialized = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setIsPageVisible: (state, action) => {
      state.isPageVisible = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setIsPageFrozen: (state, action) => {
      state.isPageFrozen = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{x: number, y: number, message: string, type: string}>} action - Store action.
     */
    setPointerTooltip: (state, action) => {
      state.pointerTooltip = { id: uuidv4(), ...action.payload };
    },
    /**
     * TBD.
     * @param {UIState} state - State data.
     */
    clearPointerTooltip: (state) => {
      state.pointerTooltip = null;
    },
  },
});

export const {
  setUserInteracted,
  setViewType,
  setWindowSize,
  setScreenOrientation,
  openFavoritesPopup,
  closeFavoritesPopup,
  openStatisticsPopup,
  closeStatisticsPopup,
  setDesktopView,
  openTableLimits,
  closeTableLimits,
  pinTableLimits,
  unpinTableLimits,
  openSidenav,
  closeSidenav,
  setSlidePopupView,
  openSlidePopup,
  closeSlidePopup,
  setWalletHeight,
  setTimer,
  clearTimer,
  setMouseOverOnTable,
  openLowBalancePopup,
  closeLowBalancePopup,
  toogleRacetrack,
  setVerticalPlayingArea,
  setStreamViewSize,
  setTouchMenuOpened,
  addSnackbar,
  removeSnackbar,
  toogleStreamFullScreen,
  setIsStreamFullScreen,
  setUiInitialized,
  setIsPageFrozen,
  setIsPageVisible,
  setPointerTooltip,
  clearPointerTooltip,
} = uiSlice.actions;

export default uiSlice.reducer;
