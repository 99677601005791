import cl from 'clsx';
import { IoMdClose } from 'react-icons/io';
import { motion } from 'framer-motion';
import styles from './Drawer.module.scss';
import { useCallback } from 'react';

const Drawer = ({ children, headerLabel, headerIcon, isOpen, onClose, className, hiddenAnimation, showAnimation }) => {
  const onCloseClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <motion.div
      initial={false}
      animate={isOpen ? 'visible' : 'hidden'}
      variants={{ visible: showAnimation, hidden: hiddenAnimation }}
      transition={{ ease: 'linear', duration: 0.3 }}
      className={cl(styles.container, !isOpen && styles.isClosed, className)}
    >
      <div className={styles.header}>
        <div className={styles.labelContainer}>
          {headerIcon && <div className={styles.icon}>{headerIcon}</div>}
          <div className={styles.label}>{headerLabel}</div>
        </div>
        <div className={styles.closeButton} onClick={onCloseClick}>
          <IoMdClose size="1.25em" />
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </motion.div>
  );
};

export default Drawer;
