import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useRef, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import CountdownCircleTimer from '../../common/CountdownCircleTimer';
import { clearTimer } from '../../../store/uiSlice';

const ClassicRouletteTimer = ({ size }) => {
  const isPageVisible = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.isPageVisible);
  const timer = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.timer);
  const timerId = useRef(uuid());
  const dispatch = useDispatch();
  const duration = timer?.duration;
  const startAt = timer?.remainingTime;

  const handleTimerComplete = useCallback(() => {
    dispatch(clearTimer());
  }, [dispatch]);

  useEffect(() => {
    if (isPageVisible) {
      timerId.current = uuid();
    }
  }, [isPageVisible]);

  if (!timer) return;

  return (
    <AnimatePresence>
      <motion.div key={timerId.current} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <CountdownCircleTimer size={size} duration={duration} startAt={startAt} onTimerComplete={handleTimerComplete} />
      </motion.div>
    </AnimatePresence>
  );
};

export default ClassicRouletteTimer;
