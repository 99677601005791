import { LOCALE_TYPE } from '@/lib/paramService';
import { useCallback } from 'react';

const useMoney = () => {
  const format = useCallback((amount, withSymbol = true) => {
    const formatter = new Intl.NumberFormat(LOCALE_TYPE, {
      currency: 'EUR',
      minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    });
    return `${withSymbol ? '€ ' : ''}${formatter.format(amount)}`;
  }, []);

  return { format };
};

export default useMoney;
