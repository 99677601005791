import { createPortal } from 'react-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { useCallback, useMemo } from 'react';
import styles from './Modal.module.scss';

const Modal = ({ onClose, children }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const content = useMemo(() => {
    return (
      <div className={styles.modal}>
        <div className={styles.content}>
          {onClose && (
            <button className={styles.closeButton} onClick={handleClose}>
              <AiOutlineClose size="1.5rem" />
            </button>
          )}
          {children}
        </div>
      </div>
    );
  }, [children, handleClose, onClose]);

  return createPortal(content, document.querySelector('#portal-container'));
};

export default Modal;
