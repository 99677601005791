import { BET_TYPE } from '@/enums/layoutTable';
import { TooltipContext } from '@/context/TooltipContext';
import socketApi from '@/lib/socketApi';
import { createBetKey } from '@/lib/rouletteService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Last500.module.scss';
import NumberItem from './NumberItem';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { VIEW_TYPE } from '@/enums/ui';

const Last500 = () => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const [winningNumbers, setWinningNumbers] = useState();
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const { t } = useTranslation(['tooltip', 'common']);
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const rounds = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.statRoundCount);
  const [highlightedItem, setHighlightedItem] = useState();
  const dispatch = useDispatch();
  const itemsRef = useRef([]);
  const isBettingTime = useSelector(selectIsBettingTime);

  const handleMouseEnter = useCallback(
    (index, number) => {
      if (isTouchDevice) return;
      setHighlightedItem(number);
      dispatch(setHighlightedNumbers([number]));
      dispatch(
        setBetsPreview([
          {
            betType: BET_TYPE.STRAIGHT,
            numbers: [number],
            value: selectedChip,
          },
        ])
      );
      showTooltip(itemsRef.current[index], t('last500.common', { number }));
    },
    [dispatch, isTouchDevice, selectedChip, showTooltip, t]
  );

  const handleMouseLeave = useCallback(() => {
    setHighlightedItem(null);
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  const handleClick = useCallback(
    (event, number) => {
      const bet = {
        betType: createBetKey(BET_TYPE.STRAIGHT, number),
        bet: selectedChip,
      };
      dispatch(addBetThunk([bet], event));
    },
    [dispatch, selectedChip]
  );

  const isEnabled = useCallback(() => {
    return isBettingTime;
  }, [isBettingTime]);

  const initData = useCallback(async () => {
    const result = await socketApi.getWinningNumbers(rounds);
    setWinningNumbers(result);
  }, [rounds]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <div className={styles.container}>
      {winningNumbers &&
        winningNumbers.map((item, index) => (
          <NumberItem
            key={`result-item-${index}`}
            ref={(el) => (itemsRef.current[index] = el)}
            index={index}
            item={numbers[item]}
            disabled={!isBettingTime}
            isHighlighted={item === highlightedItem}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            isEnabled={isEnabled()}
          />
        ))}
    </div>
  );
};

export default Last500;
