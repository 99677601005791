import cl from 'clsx';
import { getChipColor } from '@/lib/rouletteService';
import Chip from '../Chip2D';
import styles from './PerspectiveChip.module.scss';

const PerspectiveChip = ({ value, isGlowing = false, isInactive = false, isPreview = false }) => {
  return (
    <div className={cl(styles.perspectiveContainer, isInactive && styles.inactive, isPreview && styles.isPreview)}>
      {isGlowing && <div className={styles.glowEffect}></div>}
      <Chip value={value} className={styles.chip} />
      <div className={styles.chipSide} style={{ background: getChipColor(value) }}></div>
    </div>
  );
};

export default PerspectiveChip;
