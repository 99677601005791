/**
 * Calculates the coordinates (x, y) of a point on a circle.
 * @param {number} cx - The x-coordinate of the circle's center.
 * @param {number} cy - The y-coordinate of the circle's center.
 * @param {number} radius - The radius of the circle.
 * @param {number} angle - The angle in degrees from the x-axis to the point.
 * @returns {{x: number, y: number}} - The coordinates (x, y) of the point.
 */
export const getCircleXY = (cx, cy, radius, angle) => {
  const angleRad = angle * (Math.PI / 180);
  const x = cx + radius * Math.sin(angleRad);
  const y = cy - radius * Math.cos(angleRad);
  return { x, y };
};

/**
 * Converts polar coordinates to Cartesian coordinates.
 * @param {number} centerX - The x-coordinate of the center of the polar coordinate system.
 * @param {number} centerY - The y-coordinate of the center of the polar coordinate system.
 * @param {number} radius - The radius of the polar coordinate system.
 * @param {number} angleInDegrees - The angle in degrees from the x-axis to the point.
 * @returns {{x: number, y: number}} - The coordinates (x, y) of the point in Cartesian coordinate system.
 */
export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};
