import { useMeasure, useWindowSize } from 'react-use';
import styles from './AspectRatioContainer.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInitialized } from '@/store/gameSlice';

const AspectRatioContainer = ({ widthRatio = 16, heightRatio = 9, children, onAspectRatioContainerInitialized }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [ref, { width: containerWidth, height: containerHeight }] = useMeasure();
  const dispatch = useDispatch();

  const ratioX = windowWidth / widthRatio;
  const ratioY = windowHeight / heightRatio;
  const minRatio = Math.min(ratioX, ratioY);

  useEffect(() => {
    if (!isInitialized && containerWidth > 0 && containerHeight > 0) {
      setIsInitialized(true);
      if (onAspectRatioContainerInitialized) {
        onAspectRatioContainerInitialized();
      }
    }

    document.querySelector('html').style.fontSize = `${containerWidth === null ? 16 : containerWidth * 0.015}px`;
  }, [containerHeight, containerWidth, dispatch, isInitialized, onAspectRatioContainerInitialized]);

  useEffect(() => {
    return () => {
      document.querySelector('html').removeAttribute('style');
      setInitialized(false);
    };
  }, []);

  if (windowWidth === 0 || windowHeight === 0) return;

  const width = minRatio * widthRatio;
  const height = minRatio * heightRatio;

  return (
    <div ref={ref} style={{ width, height }} className={styles.aspectRatioContainer}>
      {children}
    </div>
  );
};

export default AspectRatioContainer;
