import clsx from 'clsx';
import styles from './IconButton.module.scss';
import { useMemo } from 'react';

const IconButton = ({
  id,
  icon,
  className,
  containerClassName,
  labelClassName,
  onClick,
  disabled,
  readonly,
  tooltip,
  label,
  labelDirection = 'bottom',
}) => {
  const button = useMemo(
    () => (
      <div
        {...(id && { id })}
        {...(tooltip && {
          'data-tooltip-id': tooltip.id,
          'data-tooltip-content': tooltip.content,
          'data-tooltip-place': tooltip.place,
        })}
        {...(!label && { onClick })}
        className={clsx(styles.button, className)}
      >
        {icon}
      </div>
    ),
    [id, tooltip, onClick, className, icon, label]
  );

  const containerClasses = useMemo(
    () =>
      clsx(
        styles.buttonContainer,
        containerClassName,
        {
          [styles.disabled]: disabled,
          [styles.readonly]: readonly,
        },
        styles[labelDirection]
      ),
    [containerClassName, disabled, readonly, labelDirection]
  );

  const handleClick = (e) => {
    if (!disabled && !readonly && onClick) {
      onClick(e);
    }
  };

  return label ? (
    <div className={containerClasses} onClick={handleClick}>
      {button}
      <div className={clsx(styles.label, labelClassName)}>{label}</div>
    </div>
  ) : (
    button
  );
};

export default IconButton;
