import ROUND_STATE from '@/enums/roundState';
import socketApi from '@/lib/socketApi';
import scrollbarStyles from '@/styles/Scrollbar.module.scss';
import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HistoryDetails from './HistoryDetails';
import styles from './HistoryView.module.scss';
import HistoryViewTableGroup from './HistoryViewTableGroup';

const HistoryView = () => {
  const { t } = useTranslation('common');
  const [history, setHistory] = useState([]);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [isBusy, setIsBusy] = useState(true);

  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const isRefreshHistory = roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null;

  const mappedHistory = useMemo(() => {
    const remappedHistoryItems = history.map((item, index) => ({
      index,
      date: new Date(item.closedAt),
      bet: item.totalBet,
      win: item.totalWin,
      roundId: item.roundId,
    }));

    const grouppedItems = _.groupBy(remappedHistoryItems, (item) => item.date.toLocaleDateString('en-US'));

    return Object.keys(grouppedItems).map((key) => ({
      date: new Date(key),
      bet: _.sumBy(grouppedItems[key], (item) => item.bet),
      win: _.sumBy(grouppedItems[key], (item) => item.win),
      items: grouppedItems[key],
    }));
  }, [history]);

  const getDetailsItem = useCallback((index) => ({ ...history[index], index }), [history]);

  const onHistoryItemSelected = useCallback(
    async (index) => {
      const item = getDetailsItem(index);

      const historyItem = await socketApi.getHistoryItem(item.roundId);
      setSelectedHistoryItem({ ...historyItem, index });
    },
    [getDetailsItem]
  );

  const backToGameHistory = useCallback(() => setSelectedHistoryItem(null), []);

  const loadHistoryItems = useCallback(async () => {
    setIsBusy(true);
    const newItems = await socketApi.getHistory();
    setHistory(newItems);
    setIsBusy(false);
  }, []);

  useEffect(() => {
    loadHistoryItems();
  }, [loadHistoryItems]);

  useEffect(() => {
    if (isRefreshHistory) {
      loadHistoryItems();
    }
  }, [isRefreshHistory, loadHistoryItems]);

  if (isBusy)
    return (
      <div className={styles.container}>
        <div className={styles.header}>{t('history.loading')}</div>
      </div>
    );

  if (history.length === 0)
    return (
      <div className={styles.container}>
        <div className={styles.header}>{t('history.noItemsFound')}</div>
      </div>
    );

  return (
    <div className={clsx(styles.container, scrollbarStyles.scrollbar)}>
      {selectedHistoryItem ? (
        <HistoryDetails
          itemsCount={history.length}
          onItemSelected={onHistoryItemSelected}
          selectedHistoryItem={selectedHistoryItem}
          backToGameHistory={backToGameHistory}
        />
      ) : (
        <div className={styles.gridContainer}>
          <div className={styles.header}>{t('date')}</div>
          <div className={clsx(styles.header, styles.right)}>{t('bet')}</div>
          <div className={clsx(styles.header, styles.right)}>{t('payout')}</div>
          {mappedHistory.map((group, index) => (
            <HistoryViewTableGroup
              key={`history-table-group-${index}`}
              group={group}
              onItemSelected={onHistoryItemSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HistoryView;
