import UndoIcon from '@/assets/svg/undo.svg?react';
import IconButton from '@/components/IconButton';
import useShortcut from '@/hooks/useShortcut';
import { playNotAllowedSound, playUndoSound } from '@/lib/soundService';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { undoAllBetsThunk, undoBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './../ChipSelector.module.scss';

const UndoButton = ({ disabled, labelDirection = 'left', withLabel = true, className }) => {
  const { t } = useTranslation('roulette');
  const isBettingTime = useSelector(selectIsBettingTime);
  const undoIntervalRef = useRef(null);
  const timeoutRef = useRef(null);
  const betHistory = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory);
  const dispatch = useDispatch();

  const canUndoBet = useCallback(() => {
    return isBettingTime && betHistory.length > 0;
  }, [betHistory.length, isBettingTime]);

  const executeUndoBet = useCallback(() => {
    if (!canUndoBet()) {
      playNotAllowedSound();
      return;
    }
    playUndoSound();
    dispatch(undoBetThunk());
  }, [canUndoBet, dispatch]);

  const startUndo = useCallback(() => {
    if (undoIntervalRef.current) return;
    executeUndoBet();
    undoIntervalRef.current = setInterval(() => {
      executeUndoBet();
    }, 200);

    timeoutRef.current = setTimeout(() => {
      stopUndo();
      dispatch(undoAllBetsThunk());
    }, 2000);
  }, [dispatch, executeUndoBet]);

  const stopUndo = () => {
    if (undoIntervalRef.current) {
      clearInterval(undoIntervalRef.current);
      undoIntervalRef.current = null;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const undoShortcuts = [
    { key: 'Delete', eventType: 'keydown' },
    { key: 'Delete', eventType: 'keyup' },
    { key: 'Backspace', eventType: 'keydown' },
    { key: 'Backspace', eventType: 'keyup' },
  ];

  const handleShortcuts = useCallback(
    (event) => {
      if (event.type === 'keydown') {
        startUndo();
      } else if (event.type === 'keyup') {
        stopUndo();
      }
    },
    [startUndo]
  );

  useShortcut(undoShortcuts, handleShortcuts);

  useEffect(() => {
    return () => stopUndo();
  }, []);

  return (
    <div
      className={clsx(disabled && styles.disabled)}
      onMouseDown={startUndo}
      onMouseUp={stopUndo}
      onMouseLeave={stopUndo}
    >
      <IconButton
        className={clsx(styles.button, className)}
        icon={<UndoIcon height="1em" />}
        disabled={!canUndoBet()}
        label={withLabel && t('chipSelector.undo')}
        labelDirection={labelDirection}
      />
    </div>
  );
};

export default UndoButton;
