import ErrorView from './components/ErrorView';

/**
 * TBD.
 * @param {{error: object}} props - TBD.
 * @returns {import('react').ReactElement} Output to render.
 */
const AppError = ({ error }) => {
  const errorCode = typeof error === 'object' ? (error.code ?? error.errorCode) : error.toString();
  return <ErrorView errorCode={errorCode} />;
};

export default AppError;
