import Accordion from '@/components/common/Accordion';
import { LANGUAGE } from '@/lib/paramService';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import contentEN from '../Content/en';
import contentNL from '../Content/nl';
import styles from './HelpView.module.scss';

const initHelpItems = (content) => {
  return content.map((item, index) => {
    return { ...item, isOpen: false, id: `help-accordion-${index}` };
  });
};

const HelpView = () => {
  const content = LANGUAGE === 'nl' ? contentNL : contentEN;
  const { t } = useTranslation('common');
  const [helpItems, setHelpItems] = useState(() => initHelpItems(content));

  const handleExpand = (index) => {
    const prevOpenState = helpItems[index].isOpen;
    const helpCopy = helpItems.map((item) => ({ ...item, isOpen: false }));
    helpCopy[index] = {
      ...helpCopy[index],
      isOpen: !prevOpenState,
    };
    setHelpItems(helpCopy);
  };

  return (
    <motion.div className={styles.container} layoutScroll>
      {helpItems.map(({ id, title, content: helpContent, isOpen }, index) => (
        <Accordion key={id} headerTitle={t(`help.${title}`)} isOpen={isOpen} setExpanded={() => handleExpand(index)}>
          {helpContent}
        </Accordion>
      ))}
    </motion.div>
  );
};

export default HelpView;
