import { useCallback, useEffect, useRef } from 'react';
import { useDebounce } from 'use-debounce';

const useShortcut = (shortcuts, handler, debounceTime = 200) => {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  const [debouncedHandler] = useDebounce(handlerRef.current, debounceTime);

  const handleKeyEvent = useCallback(
    (event) => {
      const activeElement = document.activeElement;
      if (
        activeElement.tagName !== 'INPUT' &&
        activeElement.tagName !== 'TEXTAREA' &&
        // @ts-ignore
        activeElement.contentEditable !== 'true'
      ) {
        for (const {
          key,
          ctrlKey = false,
          shiftKey = false,
          altKey = false,
          metaKey = false,
          eventType = 'keydown',
        } of shortcuts) {
          if (event.type !== eventType) continue;

          const eventKey = event.key.toLowerCase();
          const shortcutKey = key.toLowerCase();

          if (
            eventKey === shortcutKey &&
            (ctrlKey === undefined || event.ctrlKey === ctrlKey) &&
            (shiftKey === undefined || event.shiftKey === shiftKey) &&
            (metaKey === undefined || event.metaKey === metaKey) &&
            (altKey === undefined || event.altKey === altKey)
          ) {
            debouncedHandler(event);
          }
        }
      }
    },
    [debouncedHandler, shortcuts]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent);
    document.addEventListener('keyup', handleKeyEvent);

    return () => {
      document.removeEventListener('keydown', handleKeyEvent);
      document.removeEventListener('keyup', handleKeyEvent);
    };
  }, [handleKeyEvent]);

  return null;
};

export default useShortcut;
