import { Tooltip } from 'react-tooltip';

const CustomTooltip = ({ id, ...props }) => {
  return (
    <>
      <style>
        {`
     .tooltipStyle {
      font-size: 0.85rem;
      font-weight: 500;
      background-color: black;
      padding: 0.25rem 0.5rem;
      color: #fff;
      z-index: 1000;
    }
    
     .tooltipStyle .customArrow {
      background-color: black;
    }
  `}
      </style>
      <Tooltip
        id={id}
        className="tooltipStyle"
        classNameArrow="customArrow"
        border="0.1rem solid rgba(255, 255, 255, 0.6)"
        opacity={0.8}
        {...props}
      />
    </>
  );
};

export default CustomTooltip;
