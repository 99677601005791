import styles from './ProgressBar.module.scss';

const ProgressBar = ({ progress = 0 }) => {
  return (
    <div className={styles.container}>
      <div className={styles.firstLine}></div>
      <div className={styles.secondLine}></div>
      <div className={styles.star} style={{ '--left-value': `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
