import styles from './FavouriteAndSpecialBets.module.scss';
import clsx from 'clsx';
import FavouriteView from './FavouriteView';
import { useCallback, useState } from 'react';
import SpecialBetsView from './SpecialBetsView';
import EditBetButton from './FavouriteView/EditBetButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Tabs from '../common/Tabs';
import StraightUpView from './StraightUpView';
import { playPressSound } from '@/lib/soundService';

const FavouriteAndSpecialBets = ({ className }) => {
  const { t } = useTranslation(['roulette', 'common']);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const handleEditBetButtonClick = useCallback(() => {
    playPressSound();
    setIsEditMode((prev) => !prev);
  }, []);

  const drawEditButton = () => {
    return (
      <div className={styles.editButtonContainer} onClick={handleEditBetButtonClick}>
        <EditBetButton className={clsx(styles.editButton, isEditMode && styles.active)} />
        {isEditMode && <div>{t('favBet.exitEditMode')}</div>}
      </div>
    );
  };

  const tabs = [
    {
      name: 'favoriteBetsTab',
      label: t('favorite', { ns: 'common' }),
      content: <FavouriteView isEditMode={isEditMode} />,
    },
    {
      name: 'specialBetsTab',
      label: t('special', { ns: 'common' }),
      content: <SpecialBetsView />,
    },
    {
      name: 'straightUpBets',
      label: 'Straight Up',
      content: <StraightUpView />,
    },
  ];

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.content}>
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default FavouriteAndSpecialBets;
