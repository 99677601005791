import styles from './Dolly.module.scss';

const Dolly = ({ straightItems, winningNumber, chipSize }) => {
  const winningItem = straightItems.find((item) => item.text === winningNumber);
  const { centerCoordinates } = winningItem;
  return (
    <g transform={`translate(${centerCoordinates.x - chipSize / 2} ${centerCoordinates.y - chipSize / 2})`}>
      <svg viewBox="0 0 500 500" height={chipSize} width={chipSize} className={styles.pulseSvg}>
        <path
          className={styles.firstCircle}
          d="M250 29c122.1 0 221 99 221 221s-99 221-221 221S29 372 29 250 127.9 29 250 29m0-29C112.1 0 0 112.1 0 250s112.1 250 250 250 250-112.1 250-250S387.9 0 250 0z"
        />
        <path
          className={styles.secondCircle}
          d="M250 29c122.1 0 221 99 221 221s-99 221-221 221S29 372 29 250 127.9 29 250 29m0-29C112.1 0 0 112.1 0 250s112.1 250 250 250 250-112.1 250-250S387.9 0 250 0z"
        />
      </svg>
    </g>
  );
};

export default Dolly;
