import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import cl from 'clsx';
import { useCallback, useMemo } from 'react';
import { DESKTOP_VIEW_TYPE } from '@/enums/ui';
import { useDispatch, useSelector } from 'react-redux';
import { setDesktopView } from '@/store/uiSlice';
import ThreeDimensionViewIcon from '@/assets/svg/three-dimension-icon.svg?react';
import ClassicViewIcon from '@/assets/svg/classic-view-icon.svg?react';
import ImmersiveViewIcon from '@/assets/svg/captivating-view-icon.svg?react';
import styles from './ViewSelectorPopoverView.module.scss';
import { playPressSound } from '@/lib/soundService';

const ViewSelectorPopoverView = () => {
  const { t } = useTranslation('common');
  const desktopView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.desktopView);
  const dispatch = useDispatch();

  const items = useMemo(
    () => [
      {
        id: uuidv4(),
        icon: <ThreeDimensionViewIcon width="1.5rem" />,
        label: t('settings.3dView'),
        type: DESKTOP_VIEW_TYPE.THREE_DIMENSION,
      },
      {
        id: uuidv4(),
        icon: <ClassicViewIcon width="1.5rem" />,
        label: t('settings.classicView'),
        type: DESKTOP_VIEW_TYPE.CLASSIC,
      },
      {
        id: uuidv4(),
        icon: <ImmersiveViewIcon width="1.5rem" />,
        label: t('settings.immersiveView'),
        type: DESKTOP_VIEW_TYPE.IMMERSIVE,
      },
    ],
    [t]
  );

  const onChangeViewClicked = useCallback(
    (view) => {
      playPressSound();
      dispatch(setDesktopView(view));
    },
    [dispatch]
  );

  return (
    <div className={styles.container}>
      {items.map((item) => (
        <div
          key={item.id}
          className={cl(styles.item, desktopView === item.type && styles.selected)}
          onClick={() => onChangeViewClicked(item.type)}
        >
          <div className={styles.icon}>{item.icon}</div>
          <div className={styles.label}>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ViewSelectorPopoverView;
