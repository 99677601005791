import { useState } from 'react';
import { usePopper } from 'react-popper';
import { motion, AnimatePresence } from 'framer-motion';
import clsx from 'clsx';
import styleModule from './Popover.module.scss';

const Popover = ({ content, children, placement = 'bottom', delay = 300, onClick = () => {} }) => {
  const [active, setActive] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  const showPopover = () => {
    setActive(true);
  };

  const hidePopover = () => {
    setActive(false);
  };

  const popoverNode = (
    <motion.div
      animate={active ? 'open' : 'closed'}
      exit={'closed'}
      variants={variants}
      initial={'closed'}
      transition={{ duration: 0.3, delay: delay / 1000 }}
      className={clsx(styleModule.container, active && styleModule.active)}
      ref={setPopperElement}
      style={styles.popper}
      onMouseEnter={showPopover}
      onMouseLeave={hidePopover}
      {...attributes.popper}
    >
      {content}
      <div className={styleModule.arrow} ref={setArrowElement} style={styles.arrow} />
    </motion.div>
  );

  return (
    <div className={styleModule.wrapper} ref={setAnchorElement}>
      <div onMouseEnter={showPopover} onMouseLeave={hidePopover} onClick={onClick}>
        {children}
      </div>
      <AnimatePresence>{active && popoverNode}</AnimatePresence>
    </div>
  );
};

export default Popover;
