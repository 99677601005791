import SoundIndicator from '@/components/common/SoundIndicator';
import { Logger } from '@vpmedia/simplify';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import styles from './SoundSlider.module.scss';
import Slider from '@/components/common/Slider';

const logger = new Logger('SoundSlider');

const SoundSlider = ({ level: defaultLevel, isMuted, onChanged, onMute, withIcon = true, className }) => {
  const [level, setLevel] = useState(isMuted ? 0 : defaultLevel);
  const [debouncedLevel] = useDebounce(level, 500);

  useEffect(() => {
    if (isMuted) {
      setLevel(0);
    } else {
      setLevel(defaultLevel);
    }
  }, [defaultLevel, isMuted]);

  useEffect(() => {
    logger.info('SoundSlider', debouncedLevel);
    onChanged(debouncedLevel);
  }, [debouncedLevel, onChanged]);

  return (
    <div className={clsx(styles.container, className)}>
      {withIcon && (
        <SoundIndicator className={styles.indicator} height="1em" level={level} isMuted={isMuted} onClick={onMute} />
      )}
      <Slider min={0} max={100} value={level} onChange={(value) => setLevel(value)} />
    </div>
  );
};

export default SoundSlider;
