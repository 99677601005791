/**
 * TBD.
 * @param {{bet: number}[]} bets - TBD.
 * @returns {number} TBD.
 */
export const getBetSumValue = (bets) => {
  return bets.reduce((total, item) => total + item.bet, 0);
};

/**
 * TBD.
 * @param {object} betLimits - TBD.
 * @returns {{minBet: number, maxBet: number}} TBD.
 */
export const findMinAndMaxBetLimits = (betLimits) => {
  if (!betLimits) return null;

  let minBet = Infinity;
  let maxBet = -Infinity;

  for (const key in betLimits) {
    if (key === 'table') continue;

    const limit = betLimits[key];
    if (limit.min < minBet) minBet = limit.min;
    if (limit.max > maxBet) maxBet = limit.max;
  }

  return { minBet, maxBet };
};

/**
 * Collects bets from the source array into the target set if they belong to the specified bet types.
 * @param {Array} source - The array of objects with 'betType' property.
 * @param {Set} targetSet - The set to collect bet types.
 * @param {Set} betTypes - The set of bet types to collect.
 */
export const collectBets = (source, targetSet, betTypes) => {
  for (const item of source) {
    if (betTypes.has(item.betType)) {
      targetSet.add(item.betType);
    }
  }
};
