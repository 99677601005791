import { VIEW_TYPE } from '@/enums/ui';
import useMoney from '@/hooks/useMoney';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './TableLimitsView.module.scss';

const TableLimitItem = ({ name, min, max, payout }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { format } = useMoney();
  const { t } = useTranslation('common');

  return (
    <>
      <div className={styles.bet}>{t(name)}</div>
      {isTouchDevice ? (
        <div className={styles.limits}>{`${format(min)} - ${format(max)}`}</div>
      ) : (
        <>
          <div className={styles.limits}>{format(min)}</div>
          <div className={styles.limits}>{format(max)}</div>
        </>
      )}
      <div className={styles.payout}>{payout ? `${payout}:1` : ''}</div>
    </>
  );
};

const TableLimitsView = () => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { t } = useTranslation('common');
  const betLimits = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betLimits);

  if (!betLimits) return null;

  return (
    <div className={clsx(styles.container, isTouchDevice && styles.mobile)}>
      <div className={styles.header}>{t('bet')}</div>
      {isTouchDevice ? (
        <div className={styles.header}>Min-max</div>
      ) : (
        <>
          <div className={styles.header}>Min</div>
          <div className={styles.header}>Max</div>
        </>
      )}
      <div className={styles.header}>{t('payout')}</div>
      <div className={styles.separator}></div>
      {Object.keys(betLimits).map((key, index) => (
        <TableLimitItem
          key={`table-limit-${index}`}
          className={index % 2 === 0 ? styles.even : ''}
          {...betLimits[key]}
        />
      ))}
    </div>
  );
};

export default TableLimitsView;
