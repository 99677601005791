import Modal from '@/components/common/Modal';
import { TABLE_STATE } from '@/enums/tableState';
import { resetGame, setPendingTableState } from '@/store/gameSlice';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '../common/Snackbar';
import MaintenanceModalView from '../Modal/Views/MaintenanceModalView';

const TableStateManager = () => {
  const { t } = useTranslation('common');
  const tableState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.tableState);
  const pendingTableState = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.pendingTableState
  );
  const dispatch = useDispatch();

  const renderSnackbar = (message) => (
    <Snackbar title={t('tableStateChangeTitle')} onClose={handleCloseSnackbar}>
      {message}
    </Snackbar>
  );

  const handleCloseSnackbar = useCallback(() => {
    dispatch(setPendingTableState(null));
  }, [dispatch]);

  const tableStateMessages = {
    [TABLE_STATE.CLOSED]: t('tableClosingMessage'),
    [TABLE_STATE.MAINTENANCE]: t('tableMaintainingMessage'),
  };

  useEffect(() => {
    if ([TABLE_STATE.CLOSED, TABLE_STATE.MAINTENANCE].includes(tableState)) {
      dispatch(resetGame());
    }
  }, [dispatch, tableState]);

  if (pendingTableState) {
    return renderSnackbar(tableStateMessages[pendingTableState]);
  }

  if (tableState === TABLE_STATE.MAINTENANCE) {
    return (
      <Modal>
        <MaintenanceModalView />
      </Modal>
    );
  }

  return null;
};

export default TableStateManager;
