import { Logger } from '@vpmedia/simplify';

const logger = new Logger('StoreErrorMiddleware');

// eslint-disable-next-line unicorn/consistent-function-scoping
const errorMiddleware = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    // @ts-ignore
    logger.exception('Store error', error);
    return error;
  }
};

export default errorMiddleware;
