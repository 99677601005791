import { BET_TYPE } from '@/enums/layoutTable';
import { TooltipContext } from '@/context/TooltipContext';
import { createBetKey } from '@/lib/rouletteService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './HeatMapCircle.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { VIEW_TYPE } from '@/enums/ui';

const HeatMapCircle = ({ hotNumbers, coldNumbers, heatMapValues, ...props }) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const { t } = useTranslation('tooltip');
  const labelHeight = 0.15;
  const numbers = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numbers);
  const numberOrder = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numberOrder);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const [slices, setSlices] = useState();
  const [highlightedNumber, setHighlightedNumber] = useState();
  const dispatch = useDispatch();
  const circleRef = useRef();
  const isBettingTime = useSelector(selectIsBettingTime);

  const getHeatClass = useCallback(
    (number) => {
      if (coldNumbers.includes(number)) {
        return 'coldSector';
      }
      if (hotNumbers.includes(number)) {
        return 'hotSector';
      }
      return 'neutralSector';
    },
    [coldNumbers, hotNumbers]
  );

  const getCoordinatesForPercent = useCallback((percent) => {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);

    return [x, y];
  }, []);

  const handleMouseEnter = useCallback(
    (number) => {
      if (!isBettingTime || isTouchDevice) return;
      setHighlightedNumber(number);
      dispatch(setHighlightedNumbers([number]));
      dispatch(
        setBetsPreview([
          {
            betType: BET_TYPE.STRAIGHT,
            numbers: [number],
            value: selectedChip,
          },
        ])
      );
      showTooltip(
        circleRef.current,
        t('heatMap', {
          number,
        })
      );
    },
    [dispatch, isBettingTime, isTouchDevice, selectedChip, showTooltip, t]
  );

  const handleMouseLeave = useCallback(() => {
    setHighlightedNumber(null);
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  const handleMouseClick = useCallback(
    (event, number) => {
      dispatch(addBetThunk([{ betType: createBetKey(BET_TYPE.STRAIGHT, number), bet: selectedChip }], event));
    },
    [dispatch, selectedChip]
  );

  useEffect(() => {
    let cumulativePercent = 0;

    const slices = Array.from({ length: 37 })
      .fill()
      .map((_, index) => ({
        number: numberOrder[index],
        percent: 100 / 37 / 100,
        color: numbers[numberOrder[index]].color,
        heatPercent: heatMapValues[numberOrder[index]] / 100,
      }));

    const mappedSlice = slices.map((slice) => {
      const { percent, heatPercent } = slice;
      const transformedHeatPercent = heatPercent * (1 - labelHeight);
      const [startX, startY] = getCoordinatesForPercent(cumulativePercent);
      const innerStartX = startX * (1 - labelHeight);
      const innerStartY = startY * (1 - labelHeight);
      cumulativePercent += percent;
      const [endX, endY] = getCoordinatesForPercent(cumulativePercent);
      const innerEndX = endX * (1 - labelHeight);
      const innerEndY = endY * (1 - labelHeight);
      const largeArcFlag = percent > 0.5 ? 1 : 0;
      const labelPath = [`M ${startX} ${startY}`, `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, `L 0 0`].join(' ');

      const backgroundPath = [
        `M ${innerStartX} ${innerStartY}`,
        `A ${1 - labelHeight} ${1 - labelHeight}  0 ${largeArcFlag} 1 ${innerEndX} ${innerEndY}`,
        `L 0 0`,
      ].join(' ');

      const heatPath = [
        `M ${startX * transformedHeatPercent} ${startY * transformedHeatPercent}`,
        `A ${transformedHeatPercent} ${transformedHeatPercent} 0 ${largeArcFlag} 1 ${endX * transformedHeatPercent} ${
          endY * transformedHeatPercent
        }`,
        `L 0 0`,
      ].join(' ');

      return {
        ...slice,
        backgroundPath,
        labelPath,
        heatPath,
        startX,
        startY,
      };
    });
    setSlices(mappedSlice);
  }, [getCoordinatesForPercent, heatMapValues, numberOrder, numbers]);

  return (
    <svg ref={circleRef} viewBox="-1 -1 2 2" style={{ overflow: 'visible' }} {...props}>
      {slices?.map(({ color, labelPath, backgroundPath, heatPath, number, heatPercent, startX, startY }) => (
        <g
          key={`slice-bg-${number}`}
          className={clsx(
            styles.slice,
            highlightedNumber === number && styles.highlighted,
            isBettingTime && styles.enabled
          )}
          onMouseEnter={() => handleMouseEnter(number)}
          onMouseLeave={handleMouseLeave}
          onClick={(event) => handleMouseClick(event, number)}
        >
          <path className={styles[color]} d={labelPath} />
          <path id={`bg-${number}`} className={styles.background} d={backgroundPath} />
          <text dy="-0.04" fill="white" fontSize={0.09} textAnchor="middle" className={styles.text}>
            <textPath href={`#bg-${number}`} startOffset="0.075">
              {number}
            </textPath>
          </text>
          <path className={styles[number === 0 ? 'zeroSector' : getHeatClass(number)]} d={heatPath} />
          <line className={styles.border} x1={startX} y1={startY} x2="0" y2="0" />
        </g>
      ))}
      <circle className={styles.outerCircleBorder} cx="0" cy="0" r="1" />
      <circle className={styles.innerCircleBorder} cx="0" cy="0" r={1 - labelHeight} />
      <circle className={styles.centralCircle} cx="0" cy="0" r="0.05" />
    </svg>
  );
};

export default HeatMapCircle;
