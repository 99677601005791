import { BET_TYPE, NAMED_BET_VALUES, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import { TooltipContext } from '@/context/TooltipContext';
import socketApi from '@/lib/socketApi';
import {
  createBetKey,
  getNumbersByOutsideBet,
  getOutsideBetDisplayName,
  getRacetrackNamedBetDisplayName,
  getRacetrackNamedBets,
  isOutsideBet,
  isRacetrackBet,
} from '@/lib/rouletteService';
import { clearBetsPreview, setBetsPreview, setHighlightedNumbers } from '@/store/gameSlice';
import { addBetThunk } from '@/store/thunks/gameThunks';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Advanced.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { VIEW_TYPE } from '@/enums/ui';

const Advanced = () => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { t } = useTranslation(['tooltip', 'common']);
  const [stat, setStat] = useState();
  const { showTooltip, hideTooltip } = useContext(TooltipContext);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const rounds = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.statRoundCount);
  const dispatch = useDispatch();
  const itemRefs = useRef([]);

  const isBettingTime = useSelector(selectIsBettingTime);

  const items = [
    [
      {
        label: 0,
        className: styles.zero,
        betType: BET_TYPE.STRAIGHT,
        numbers: [0],
      },
      {
        label: `${t('first', { ns: 'common' })} Dozen`,
        betType: BET_TYPE.FIRST_TWELVE,
      },
      {
        label: `${t('second', { ns: 'common' })} Dozen`,
        betType: BET_TYPE.SECOND_TWELVE,
      },
      {
        label: `${t('third', { ns: 'common' })} Dozen`,
        betType: BET_TYPE.THIRD_TWELVE,
      },
    ],
    [
      {
        label: `${t('first', { ns: 'common' })} Column`,
        betType: BET_TYPE.RIGHT_COLUMN,
      },
      {
        label: `${t('second', { ns: 'common' })} Column`,
        betType: BET_TYPE.MIDDLE_COLUMN,
      },
      {
        label: `${t('third', { ns: 'common' })} Column`,
        betType: BET_TYPE.LEFT_COLUMN,
      },
    ],
    [
      {
        label: 'Serie 5/8',
        betType: RACE_TRACK_BET_TYPE.TIER,
      },
      {
        label: 'Orphelins',
        betType: RACE_TRACK_BET_TYPE.ORPHELINS,
      },
      {
        label: 'Serie 0/2/3',
        betType: RACE_TRACK_BET_TYPE.VOISINS,
      },
      {
        label: 'Zero',
        betType: RACE_TRACK_BET_TYPE.ZERO,
      },
    ],
    [
      {
        label: '1-18',
        betType: BET_TYPE.ONE_TO_EIGHTEEN,
      },
      {
        label: 'Even',
        betType: BET_TYPE.EVEN,
      },
      {
        label: (
          <svg viewBox="0 0 99 44" height={24}>
            <path
              stroke="#828181"
              strokeWidth="2"
              d="m1.12801,22.02819l48.33871,-21.46375l48.33871,21.46375l-48.33871,21.46375l-48.33871,-21.46375z"
              fill="#d20404"
            ></path>
          </svg>
        ),
        betType: BET_TYPE.RED,
      },
      {
        label: (
          <svg viewBox="0 0 99 44" height={24}>
            <path
              stroke="#828181"
              strokeWidth="2"
              d="m1.12801,22.02819l48.33871,-21.46375l48.33871,21.46375l-48.33871,21.46375l-48.33871,-21.46375z"
              fill="#1A1A1A"
            ></path>
          </svg>
        ),
        betType: BET_TYPE.BLACK,
      },
      {
        label: t('odd', { ns: 'common' }),
        betType: BET_TYPE.ODD,
      },
      {
        label: '19-36',
        betType: BET_TYPE.NINETEEN_TO_THIRTY_SIX,
      },
    ],
  ];

  const isEnabled = useCallback(() => {
    return isBettingTime;
  }, [isBettingTime]);

  const handleMouseEnter = useCallback(
    (betType, numbers, itemRef) => {
      if (isTouchDevice) return;

      let highlightedNumbers, betsPreview, displayName, tooltip;
      if (isRacetrackBet(betType)) {
        highlightedNumbers = NAMED_BET_VALUES[betType];
        betsPreview = getRacetrackNamedBets(betType).map((bet) => ({
          ...bet,
          value: selectedChip * (bet.multiplier ?? 1),
        }));

        const requiredChips = getRacetrackNamedBets(betType).reduce((total, bet) => {
          return total + (bet.multiplier ?? 1);
        }, 0);

        tooltip = t('racetrackNamedBet', {
          betType: getRacetrackNamedBetDisplayName(betType),
          requiredChips,
          numberCount: highlightedNumbers.length,
        });
      } else if (isOutsideBet(betType)) {
        highlightedNumbers = getNumbersByOutsideBet(betType);
        betsPreview = [{ betType, value: selectedChip }];
        displayName = t(getOutsideBetDisplayName(betType), {
          ns: 'common',
        }).toLowerCase();
        tooltip = t('advanced.common', { item: displayName });
      } else {
        highlightedNumbers = numbers;
        betsPreview = [
          {
            betType: BET_TYPE.STRAIGHT,
            numbers,
            value: selectedChip,
          },
        ];
        displayName = numbers[0];
        tooltip = t('advanced.common', { item: displayName });
      }

      dispatch(setHighlightedNumbers(highlightedNumbers));
      dispatch(setBetsPreview(betsPreview));
      showTooltip(itemRef, tooltip);
    },
    [dispatch, isTouchDevice, selectedChip, showTooltip, t]
  );

  const handleMouseLeave = useCallback(() => {
    dispatch(setHighlightedNumbers([]));
    dispatch(clearBetsPreview());
    hideTooltip();
  }, [dispatch, hideTooltip]);

  const handleOnClick = useCallback(
    (event, betType, numbers) => {
      let bet;
      if (isRacetrackBet(betType)) {
        bet = getRacetrackNamedBets(betType);
      } else {
        bet = { betType, numbers };
      }

      let mappedBets;
      if (isRacetrackBet(betType)) {
        mappedBets = bet.map((betItem) => ({
          betType: createBetKey(betItem.betType, betItem.numbers),
          bet: (betItem?.multiplier ?? 1) * selectedChip,
        }));
      } else {
        mappedBets = [
          {
            betType: createBetKey(bet.betType, bet.numbers),
            bet: selectedChip,
          },
        ];
      }

      dispatch(addBetThunk(mappedBets, event));
    },
    [dispatch, selectedChip]
  );

  const initData = useCallback(async () => {
    const result = await socketApi.getAdvancedStatistics(rounds);
    setStat(result);
  }, [rounds]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <>
      {stat &&
        items.map((row, rowIndex) => (
          <div key={`advanced-row-${rowIndex}`} className={styles.section}>
            {row.map(({ label, className, betType, numbers }, itemIndex) => (
              <div
                key={`advanced-item-${rowIndex}-${itemIndex}`}
                ref={(el) => (itemRefs.current[rowIndex * items.length + itemIndex] = el)}
                className={clsx(styles.item, isEnabled() && styles.enabled, className)}
              >
                <div className={styles.percentage}>{stat[betType]}%</div>
                <div
                  className={styles.label}
                  onMouseEnter={() =>
                    handleMouseEnter(betType, numbers, itemRefs.current[rowIndex * items.length + itemIndex])
                  }
                  onClick={(event) => handleOnClick(event, betType, numbers)}
                  onMouseLeave={handleMouseLeave}
                >
                  {label}
                </div>
              </div>
            ))}
          </div>
        ))}
    </>
  );
};

export default Advanced;
