import { useSelector } from 'react-redux';
import Sidebar from '../../Sidebar';
import styles from './MobileVerticalView.module.scss';
import { motion } from 'framer-motion';
import { useMeasure } from 'react-use';
import Timer from '@/components/Timer';
import { useMemo } from 'react';
import ROUND_STATE from '@/enums/roundState';
import RacetrackWithSelector from '../../RacetrackWithSelector';
import { ORIENTATION, ROULETTE_TABLE_TYPE } from '@/enums/ui';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import RouletteTable from '@/components/RouletteTable';

const MobileVerticalView = () => {
  const [ref, { width, height }] = useMeasure();
  const [mainContainerRef, { height: mainContainerHeight }] = useMeasure();
  const showMobileRacetrack = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showMobileRacetrack
  );
  const streamViewSize = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.streamViewSize
  );
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const fullscreenVerticalPlayingArea = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.fullscreenVerticalPlayingArea
  );
  const isBettingTime = useSelector(selectIsBettingTime);

  const minimizedPlayingArea = useMemo(() => {
    return (
      !fullscreenVerticalPlayingArea ||
      roundState === ROUND_STATE.NO_MORE_BETS ||
      roundState === ROUND_STATE.WINNING_NUMBER
    );
  }, [fullscreenVerticalPlayingArea, roundState]);

  const calculatedPlayingAreaScaleValue = useMemo(() => {
    if (!mainContainerHeight || !streamViewSize?.height) return null;

    return `${1 - streamViewSize.height / mainContainerHeight}`;
  }, [mainContainerHeight, streamViewSize?.height]);

  return (
    <div ref={mainContainerRef} className={styles.container}>
      <div className={styles.timer}>
        <Timer size={48} />
      </div>
      <div className={styles.tableAndRacetrackContainer}>
        {calculatedPlayingAreaScaleValue && (
          <motion.div
            ref={ref}
            transition={{ ease: 'linear', duration: 0.6 }}
            style={{ transformOrigin: 'center bottom' }}
            initial={false}
            animate={{
              transform: `scale(${!isBettingTime || minimizedPlayingArea ? calculatedPlayingAreaScaleValue : '1'})`,
            }}
            className={styles.scalingWrapper}
          >
            <div className={styles.playingAreaWrapper}>
              {!showMobileRacetrack && width > 0 && height > 0 && (
                <RouletteTable
                  type={ROULETTE_TABLE_TYPE.RESPONSIVE}
                  orientation={ORIENTATION.VERTICAL}
                  fieldOpacity={80}
                />
              )}
              {showMobileRacetrack && (
                <div className={styles.racetrack}>
                  <RacetrackWithSelector
                    orientation={ORIENTATION.VERTICAL}
                    displayChipsAndWinningNumber={true}
                    backgroundFilled={true}
                    withFieldOpacity={true}
                  />
                </div>
              )}
            </div>
          </motion.div>
        )}
      </div>
      <Sidebar orientation="vertical" />
    </div>
  );
};

export default MobileVerticalView;
