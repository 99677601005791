import { BET_TYPE, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import ROUND_STATE from '@/enums/roundState';
import { createBetKey, getNeighbors, getRacetrackNamedBets } from '@/lib/rouletteService';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Dolly from './Dolly';
import RacetrackItem from './RacetrackItem';

const RacetrackHorizontalItems = ({
  height,
  width,
  backgroundFilled,
  withFieldOpacity,
  displayChipsAndWinningNumber,
  onClick,
  onMouseEnter,
  onMouseLeave,
  racetrackHighlightedNumbers,
}) => {
  const itemHeight = height / 4;
  const radius = height / 2;
  const radiusInner = radius - itemHeight;
  const straightItemWidth = (width - 2 * radius) / 13;
  const chipSize = Math.min(itemHeight * 0.6, straightItemWidth * 0.6);
  const numberRefs = useRef([]);
  const namedBetRefs = useRef({});

  const numberOrder = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numberOrder);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);

  const isHighlighted = useCallback(
    (number) => racetrackHighlightedNumbers?.includes(number),
    [racetrackHighlightedNumbers]
  );

  const getCenterCoordinates = useCallback(
    (x, y, width, height) => ({
      x: x + width / 2,
      y: y + height / 2,
    }),
    []
  );

  const polarToCartesian = useCallback((centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }, []);

  const describeArcLine = useCallback(
    (x, y, radius, startAngle, endAngle) => {
      const start = polarToCartesian(x, y, radius, endAngle);
      const end = polarToCartesian(x, y, radius, startAngle);
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
      return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`;
    },
    [polarToCartesian]
  );

  const describeArc = useCallback(
    (x, y, radius, spread, startAngle, endAngle) => {
      const innerStart = polarToCartesian(x, y, radius, endAngle);
      const innerEnd = polarToCartesian(x, y, radius, startAngle);
      const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
      const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      return [
        `M ${outerStart.x} ${outerStart.y}`,
        `A ${radius + spread} ${radius + spread} 0 ${largeArcFlag} 0 ${outerEnd.x} ${outerEnd.y}`,
        `L ${innerEnd.x} ${innerEnd.y}`,
        `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${innerStart.x} ${innerStart.y}`,
        'Z',
      ].join(' ');
    },
    [polarToCartesian]
  );

  const getItemArcParams = useCallback(
    (x, y, steps, index, antiClockwise = false) => {
      const step = 180 / steps;
      const radiusInner = radius - itemHeight;
      const startAngle = antiClockwise ? 360 - (index + 1) * step : index * step;
      const endAngle = antiClockwise ? 360 - index * step : (index + 1) * step;

      return {
        centerCoordinates: polarToCartesian(
          x,
          y,
          radiusInner + itemHeight / 2,
          startAngle + (endAngle - startAngle) / 2
        ),
        description: describeArc(x, y, radiusInner, itemHeight, startAngle, endAngle),
      };
    },
    [describeArc, itemHeight, radius, polarToCartesian]
  );

  const leftBendItems = useMemo(() => {
    const startItem = 35;
    const items = [startItem, ...getNeighbors(numberOrder, startItem, 4).right].map((number, index) => {
      const arcParams = getItemArcParams(width - radius, height / 2, 5, index);
      return {
        text: number,
        bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
        centerCoordinates: arcParams.centerCoordinates,
        pathDescr: arcParams.description,
      };
    });

    return items;
  }, [bets, getItemArcParams, height, numberOrder, radius, width]);

  const rightBendItems = useMemo(() => {
    const startItem = 24;
    const items = [startItem, ...getNeighbors(numberOrder, startItem, 5).left.reverse()].map((number, index) => {
      const arcParams = getItemArcParams(radius, height / 2, 6, index, true);
      return {
        text: number,
        bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
        centerCoordinates: arcParams.centerCoordinates,
        pathDescr: arcParams.description,
      };
    });

    return items;
  }, [bets, getItemArcParams, height, numberOrder, radius]);

  const topStraightItems = useMemo(() => {
    const firstItemIndex = numberOrder.indexOf(16);
    const numberItems = numberOrder.slice(firstItemIndex, firstItemIndex + 13);
    return numberItems.map((number, index) => ({
      text: number,
      bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
      centerCoordinates: getCenterCoordinates(radius + index * straightItemWidth, 0, straightItemWidth, itemHeight),
      pathDescr: `M ${radius + index * straightItemWidth} 0 h ${straightItemWidth} v ${itemHeight} h ${
        -1 * straightItemWidth
      } z`,
    }));
  }, [bets, getCenterCoordinates, itemHeight, numberOrder, radius, straightItemWidth]);

  const bottomStraightItems = useMemo(() => {
    const firstItemIndex = numberOrder.indexOf(11);
    const numberItems = numberOrder.slice(firstItemIndex - 12, firstItemIndex + 1).reverse();
    return numberItems.map((number, index) => ({
      text: number,
      bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
      centerCoordinates: getCenterCoordinates(
        radius + index * straightItemWidth,
        height - itemHeight,
        straightItemWidth,
        itemHeight
      ),
      pathDescr: `M ${radius + index * straightItemWidth} ${
        height - itemHeight
      } h ${straightItemWidth} v ${itemHeight} h ${-1 * straightItemWidth} z`,
    }));
  }, [bets, getCenterCoordinates, height, itemHeight, numberOrder, radius, straightItemWidth]);

  const straightItems = useMemo(() => {
    return [...topStraightItems, ...bottomStraightItems, ...leftBendItems, ...rightBendItems];
  }, [bottomStraightItems, leftBendItems, rightBendItems, topStraightItems]);

  const namedBets = useMemo(() => {
    const items = [];

    items.push({
      text: 'SERIE 5/8',
      type: RACE_TRACK_BET_TYPE.TIER,
      centerCoordinates: {
        x: (radius + 4.5 * straightItemWidth) / 2,
        y: height / 2,
      },
      pathDescr: `M ${radius} ${itemHeight} h ${2 * straightItemWidth} L ${radius + 4 * straightItemWidth} ${
        height - itemHeight
      } h ${-4 * straightItemWidth}  ${describeArcLine(radius, radius, radiusInner, -180, 0)} `,
    });

    items.push({
      text: 'ORPHELINS',
      type: RACE_TRACK_BET_TYPE.ORPHELINS,
      centerCoordinates: {
        x: items[0].centerCoordinates.x + 4 * straightItemWidth,
        y: height / 2,
      },
      pathDescr: `M ${radius + 2 * straightItemWidth} ${itemHeight} L ${radius + 4 * straightItemWidth} ${
        height - itemHeight
      } h ${3 * straightItemWidth} v ${-1 * (height - 2 * itemHeight)} z `,
    });

    items.push({
      text: 'SERIE 0/2/3',
      type: RACE_TRACK_BET_TYPE.VOISINS,
      centerCoordinates: {
        x: width - radius - 4 * straightItemWidth,
        y: height / 2,
      },
      pathDescr: `${describeArcLine(width - radius - straightItemWidth, height / 2, radiusInner, -180, 0)} h ${
        -5 * straightItemWidth
      } v ${-1 * (height - 2 * itemHeight)} z `,
    });

    items.push({
      text: 'ZERO',
      type: RACE_TRACK_BET_TYPE.ZERO,
      centerCoordinates: {
        x: width - (2 * radius + straightItemWidth) / 2,
        y: height / 2,
      },
      pathDescr: `${describeArcLine(
        width - radius - straightItemWidth,
        height / 2,
        radiusInner,
        -180,
        0
      )} h ${straightItemWidth} ${describeArcLine(width - radius, radius, radiusInner, 0, 180)} h ${
        -1 * straightItemWidth
      }`,
    });

    return items;
  }, [describeArcLine, height, itemHeight, radius, radiusInner, straightItemWidth, width]);

  const namedBetsValues = useCallback(
    (type) => {
      let bet = null;
      const notStraightNamedBetsKey = getRacetrackNamedBets(type)
        .filter((bet) => bet.betType !== BET_TYPE.STRAIGHT)
        .map(({ betType, numbers }) => createBetKey(betType, numbers));
      if (notStraightNamedBetsKey) {
        const everyBetsPlaced = notStraightNamedBetsKey.every((key) => Object.prototype.hasOwnProperty.call(bets, key));
        if (everyBetsPlaced) {
          bet = {
            value: _.sum(notStraightNamedBetsKey.map((key) => bets[key].value)),
          };
        }
      }

      return bet;
    },
    [bets]
  );

  return (
    <>
      {straightItems.map((item, index) => (
        <RacetrackItem
          key={`racetrack-item-${item.text}`}
          ref={(el) => (numberRefs.current[index] = el)}
          chipSize={chipSize}
          isHighlighted={isHighlighted(item.text)}
          withFieldOpacity={withFieldOpacity}
          backgroundFilled={backgroundFilled}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          onClick={(event) => onClick(event, RACE_TRACK_BET_TYPE.NUMBER, Number(item.text))}
          onMouseEnter={() => onMouseEnter(RACE_TRACK_BET_TYPE.NUMBER, Number(item.text), numberRefs.current[index])}
          onMouseLeave={onMouseLeave}
          {...item}
        />
      ))}
      {namedBets.map((item) => (
        <RacetrackItem
          key={`racetrack-item-${item.text}`}
          chipSize={chipSize}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          withFieldOpacity={withFieldOpacity}
          ref={(el) => (namedBetRefs.current[item.type] = el)}
          bets={namedBetsValues(item.type)}
          onClick={(event) => onClick(event, item.type)}
          onMouseEnter={() => onMouseEnter(item.type, null, namedBetRefs.current[item.type])}
          onMouseLeave={onMouseLeave}
          {...item}
        />
      ))}
      {displayChipsAndWinningNumber && roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null && (
        <Dolly straightItems={straightItems} winningNumber={winningNumber} chipSize={chipSize} />
      )}
    </>
  );
};

export default RacetrackHorizontalItems;
