import { clearDragData, setBetSpotHovered, setDragCoordinates, setDraggedBetType } from '@/store/tableSlice';
import { moveBetThunk } from '@/store/thunks/gameThunks';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { getBetTypeByCoordinates, getPointerCoordinates, getRelativeCoordinates } from '@/lib/uiService';
import { VIEW_TYPE } from '@/enums/ui';

const useClickAndDrag = (onClick, onBetSpotEntered, onBetSpotLeaved, threshold = 5) => {
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const isBettingTime = useSelector(selectIsBettingTime);
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const betSpotHovered = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.table.dragAndDrop.betSpotHovered
  );
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const elementRef = useRef(null);
  const dragHoverBetSpotRef = useRef(null);
  const startBetSpotRef = useRef(null);
  const dispatch = useDispatch();

  const resetDrag = useCallback(() => {
    setIsDragging(false);
    setIsPointerDown(false);
    dragHoverBetSpotRef.current = null;
    startBetSpotRef.current = null;
    dispatch(clearDragData());
    onBetSpotLeaved();
  }, [dispatch, onBetSpotLeaved]);

  const handleMove = useCallback(
    (event) => {
      if (!isBettingTime) return;

      const { x, y } = getPointerCoordinates(event);
      const betType = getBetTypeByCoordinates(x, y);

      if (isDragging) {
        if (!isBettingTime) {
          resetDrag();
          return;
        }

        if (betType) {
          if (isTouchDevice && dragHoverBetSpotRef.current !== betType) {
            onBetSpotEntered(betType);
          }
          dragHoverBetSpotRef.current = betType;
          dispatch(setBetSpotHovered(betType));
        } else {
          if (isTouchDevice && dragHoverBetSpotRef.current) {
            onBetSpotLeaved();
          }
          dragHoverBetSpotRef.current = null;
          dispatch(setBetSpotHovered(null));
        }
        const { x: relativeX, y: relativeY } = getRelativeCoordinates(x, y, 'rouletteTable');
        dispatch(setDragCoordinates({ x: relativeX, y: relativeY }));
      } else {
        const dx = x - startX;
        const dy = y - startY;

        if (
          (Math.abs(dx) > threshold || Math.abs(dy) > threshold) &&
          betType &&
          bets[betType] &&
          betType === startBetSpotRef.current
        ) {
          setIsDragging(true);
          dispatch(setDraggedBetType(betType));
        }
      }
    },
    [
      bets,
      dispatch,
      isBettingTime,
      isDragging,
      isTouchDevice,
      onBetSpotEntered,
      onBetSpotLeaved,
      resetDrag,
      startX,
      startY,
      threshold,
    ]
  );

  const handleEnd = useCallback(
    (event) => {
      const { x, y } = getPointerCoordinates(event);
      const betType = getBetTypeByCoordinates(x, y);

      if (isDragging) {
        if (betSpotHovered) {
          dispatch(moveBetThunk(event));
        }
        if (isTouchDevice) {
          onBetSpotLeaved();
        }
        resetDrag();
      } else {
        if (startBetSpotRef.current === betType) {
          onClick(event, betType);
        }
      }
      setIsDragging(false);
      setIsPointerDown(false);
      startBetSpotRef.current = null;
      event.preventDefault();
    },
    [betSpotHovered, dispatch, isDragging, isTouchDevice, onBetSpotLeaved, onClick, resetDrag]
  );

  const handleStart = useCallback((event) => {
    const { x, y } = getPointerCoordinates(event);
    const betType = getBetTypeByCoordinates(x, y);
    if (betType === null) return;

    setIsPointerDown(true);
    setStartX(x);
    setStartY(y);
    startBetSpotRef.current = betType;
    setIsDragging(false);
    event.preventDefault();
  }, []);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.addEventListener('mousedown', handleStart);
      element.addEventListener('touchstart', handleStart);
    }

    return () => {
      if (element) {
        element.removeEventListener('mousedown', handleStart);
        element.removeEventListener('touchstart', handleStart, { passive: false });
      }
    };
  }, [handleStart]);

  useEffect(() => {
    if (!isBettingTime && isDragging) {
      resetDrag();
    }
  }, [isBettingTime, isDragging, resetDrag]);

  useEffect(() => {
    if (isPointerDown) {
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleMove, { passive: false });
      document.addEventListener('touchend', handleEnd);
    } else {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isPointerDown, handleMove, handleEnd]);

  return elementRef;
};

export default useClickAndDrag;
