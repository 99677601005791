import WheelImg from '@/assets/svg/wheel.svg?react';
import { useTranslation } from 'react-i18next';
import styles from './TableMaintenanceView.module.scss';
import { useSelector } from 'react-redux';
import { TABLE_STATE } from '@/enums/tableState';

const TableMaintenanceView = () => {
  const { t } = useTranslation('common');
  const tableState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.tableState);
  const stateMessage = tableState === TABLE_STATE.MAINTENANCE ? t('tableMaintenanceMessage') : t('tableClosedMessage');
  return (
    <div className={styles.container}>
      <div className={styles.wheelImg}>
        <WheelImg width={'100%'} height={'100%'} />
      </div>
      <div className={styles.message}>{stateMessage}</div>
    </div>
  );
};

export default TableMaintenanceView;
