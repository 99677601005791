import Checkbox from '@/components/common/Checkbox';
import { VOICE_TYPE } from '@/enums/sound';
import { muteSound, setSoundVolume, setVoiceType, unmuteSound } from '@/store/settingsSlice';
import cl from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SoundSlider from '../SoundSlider';
import styles from './SoundSettingsView.module.scss';
import { VIEW_TYPE } from '@/enums/ui';

const SoundSettingsView = () => {
  const { t } = useTranslation('common');
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const soundVolume = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume
  );
  const voiceType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings.voiceType);
  const dispatch = useDispatch();

  const handleSoundChanged = useCallback(
    (type, level) => {
      dispatch(setSoundVolume({ type, level }));
    },
    [dispatch]
  );

  const handleOnMute = useCallback(
    (type) => {
      soundVolume[type].isMuted ? dispatch(unmuteSound({ type })) : dispatch(muteSound({ type }));
    },
    [dispatch, soundVolume]
  );

  const handleVoiceTypeChanged = useCallback(
    (newVoiceType) => {
      dispatch(setVoiceType(newVoiceType));
    },
    [dispatch]
  );

  return (
    <div className={cl(styles.volumeContainer, isTouchDevice && styles.touchDevice)}>
      <div className={styles.volumeItem}>
        <div className={styles.label}>{t('settings.mainVolume')}</div>
        <div className={styles.sliderContainer}>
          <SoundSlider
            level={soundVolume?.main?.level}
            isMuted={soundVolume?.main?.isMuted}
            onChanged={(level) => handleSoundChanged('main', level)}
            onMute={() => handleOnMute('main')}
          />
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.volumeItem}>
        <div className={styles.label}>{t('settings.studioSounds')}</div>
        <div className={styles.sliderContainer}>
          <SoundSlider
            level={soundVolume?.studio?.level}
            isMuted={soundVolume?.studio?.isMuted}
            onChanged={(level) => handleSoundChanged('studio', level)}
            onMute={() => handleOnMute('studio')}
          />
        </div>
      </div>
      <div className={styles.volumeItem}>
        <div className={styles.label}>{t('settings.soundEffects')}</div>
        <div className={styles.sliderContainer}>
          <SoundSlider
            level={soundVolume?.effects?.level}
            isMuted={soundVolume?.effects?.isMuted}
            onChanged={(level) => handleSoundChanged('effects', level)}
            onMute={() => handleOnMute('effects')}
          />
        </div>
      </div>
      <div className={styles.voices}>
        <div className={styles.label}>{t('settings.voiceType')}</div>
        <div className={styles.voiceOptions}>
          <Checkbox
            label={t('settings.voiceTypeMale')}
            checked={voiceType === VOICE_TYPE.MALE}
            onChange={() => handleVoiceTypeChanged(VOICE_TYPE.MALE)}
          />
          <Checkbox
            label={t('settings.voiceTypeFemale')}
            checked={voiceType === VOICE_TYPE.FEMALE}
            onChange={() => handleVoiceTypeChanged(VOICE_TYPE.FEMALE)}
          />
        </div>
      </div>
    </div>
  );
};

export default SoundSettingsView;
