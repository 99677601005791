import Button from '@/components/common/Button';
import GameLogo from '@/assets/svg/auto-roulette-logo.svg?react';
import CasinoLogo from '@/assets/svg/casino-logo.svg?react';
import { LowBalanceContext } from '@/context/LowBalanceContext';
import useMoney from '@/hooks/useMoney';
import { selectIsBettingTime, selectPlayerBets } from '@/store/selectors/gameSelectors';
import { openLowBalancePopup, setWalletHeight } from '@/store/uiSlice';
import clsx from 'clsx';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import styles from './Wallet.module.scss';
import { DESKTOP_VIEW_TYPE } from '@/enums/ui';
import { playOutOfMoneySound } from '@/lib/soundService';

const Wallet = ({ className }) => {
  const { format } = useMoney();
  const { t } = useTranslation('wallet');
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const totalBet = useSelector(selectPlayerBets);
  const desktopView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.desktopView);
  const { setLowBalanceReference } = useContext(LowBalanceContext);

  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const lastWin = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.lastWin);
  const win = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.win);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const dispatch = useDispatch();
  const [ref, { height }] = useMeasure();
  const balanceButtonRef = useRef();

  const isBettingTime = useSelector(selectIsBettingTime);

  useEffect(() => {
    dispatch(setWalletHeight(height));
  }, [dispatch, height]);

  useEffect(() => {
    if (balanceButtonRef.current) {
      setLowBalanceReference(balanceButtonRef.current);
    }
  }, [setLowBalanceReference]);

  useEffect(() => {
    const showLowBalancePopup = isBettingTime && !selectedChip && totalBet === 0;
    if (showLowBalancePopup) {
      playOutOfMoneySound();
      dispatch(openLowBalancePopup());
    }
  }, [dispatch, selectedChip, isBettingTime, totalBet]);

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      <div className={styles.grid}>
        {desktopView !== DESKTOP_VIEW_TYPE.CLASSIC && (
          <div className={styles.logoContainer}>
            <div className={styles.casinoLogo}>
              <CasinoLogo className={styles.logo} />
            </div>
            <div className={styles.gameLogo}>
              <GameLogo className={styles.logo} />
            </div>
          </div>
        )}
        <div className={clsx(styles.walletContainer, 'buttonContainer')}>
          <Button ref={balanceButtonRef} className={clsx(styles.item, 'button')}>
            <div className={styles.label}>{t('balance')}</div>
            <div className={styles.amount}>{format(balance)}</div>
          </Button>

          <Button className={clsx(styles.item, styles.totalBet, winningNumber && win && styles.pulsing, 'button')}>
            <div className={styles.label}>{lastWin ? t('lastWin') : t('bet')}</div>
            <div className={styles.amount}>{lastWin ? format(lastWin) : format(totalBet)}</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
