import ChipSelector from '@/components/ChipSelector';
import IconButton from '@/components/IconButton';
import Timer from '@/components/Timer';
import { SIDENAV_VIEW } from '@/enums/ui';
import { IoMdClose } from 'react-icons/io';
import MenuIcon from '@/assets/svg/main-menu.svg?react';
import RacetrackIcon from '@/assets/svg/racetrack-icon.svg?react';
import StatIcon from '@/assets/svg/statistics.svg?react';
import { openSidenav, setTouchMenuOpened, setVerticalPlayingArea, toogleRacetrack } from '@/store/uiSlice';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Sidebar.module.scss';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { redirectLiveCasinoHomePage } from '@/lib/urlService';

const Sidebar = ({ className, orientation = 'vertical' }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { t } = useTranslation('common');
  const fullscreenVerticalPlayingArea = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.fullscreenVerticalPlayingArea
  );
  const showMobileRacetrack = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showMobileRacetrack
  );
  const sidenavOpened = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.sidenavOpened);
  const sidenavView = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.sidenavView);
  const isBettingTime = useSelector(selectIsBettingTime);
  const racetrackTooltipRef = useRef();

  const dispatch = useDispatch();

  const openMenu = useCallback(() => {
    dispatch(setTouchMenuOpened(true));
  }, [dispatch]);

  const openStatistics = useCallback(() => {
    dispatch(openSidenav(SIDENAV_VIEW.STATISTICS));
  }, [dispatch]);

  const openFavoriteBets = useCallback(() => {
    dispatch(openSidenav(SIDENAV_VIEW.FAVORITE_BETS));
  }, [dispatch]);

  const togglePlayingAreaSize = useCallback(() => {
    dispatch(setVerticalPlayingArea(!fullscreenVerticalPlayingArea));
  }, [dispatch, fullscreenVerticalPlayingArea]);

  const onCloseClick = useCallback(() => {
    redirectLiveCasinoHomePage();
  }, []);

  const onToggleRacetrack = useCallback(() => {
    dispatch(toogleRacetrack());
    if (racetrackTooltipRef.current) {
      racetrackTooltipRef.current?.close();
      racetrackTooltipRef.current?.open({
        anchorSelect: '#racetrackToggle',
        content: showMobileRacetrack ? t('mainPlayingField') : t('racetrack'),
        place: 'top',
      });
      racetrackTooltipRef.current?.close({ delay: 2000 });
    }
  }, [dispatch, showMobileRacetrack, racetrackTooltipRef, t]);

  const showMinimizePlayingAreaButton = useMemo(() => {
    return orientation === 'vertical' && isBettingTime;
  }, [orientation, isBettingTime]);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  return (
    <>
      <div className={clsx(styles.container, isInitialized && styles.isInitialized, styles[orientation], className)}>
        <div className={styles.left}>
          {orientation === 'vertical' && (
            <>
              <IconButton icon={<IoMdClose size="24px" />} className={styles.button} onClick={onCloseClick} />

              <IconButton
                className={clsx(styles.button, !showMinimizePlayingAreaButton && styles.hidden)}
                icon={fullscreenVerticalPlayingArea ? <FaChevronDown width="20px" /> : <FaChevronUp width="20px" />}
                onClick={togglePlayingAreaSize}
                disabled={!showMinimizePlayingAreaButton}
              />
            </>
          )}
          {orientation === 'horizontal' && (
            <div className={styles.timer}>
              <Timer size={40} />
            </div>
          )}
        </div>
        <div className={styles.chipSelectorContainer}>
          <ChipSelector orientation={orientation} />
        </div>
        <div className={styles.menu}>
          <IconButton
            icon={<FaStar fontSize={26} />}
            onClick={openFavoriteBets}
            className={clsx(
              styles.button,
              sidenavOpened && sidenavView === SIDENAV_VIEW.FAVORITE_BETS && styles.activeButton
            )}
          />
          <IconButton
            icon={<StatIcon width="20px" />}
            onClick={openStatistics}
            className={clsx(
              styles.button,
              sidenavOpened && sidenavView === SIDENAV_VIEW.STATISTICS && styles.activeButton
            )}
          />
          <IconButton
            id="racetrackToggle"
            icon={<RacetrackIcon width="24px" />}
            className={clsx(styles.button, showMobileRacetrack && styles.activeButton)}
            onClick={onToggleRacetrack}
          />
          <IconButton className={styles.button} icon={<MenuIcon width="20px" />} onClick={openMenu} />
        </div>
      </div>
      <Tooltip ref={racetrackTooltipRef} noArrow={true} />
    </>
  );
};

export default Sidebar;
