import ROUND_STATE from '@/enums/roundState';
import { TABLE_STATE } from '@/enums/tableState';
import { createSelector } from '@reduxjs/toolkit';

/**
 * Selector to calculate the total value of all bets placed by the player.
 * @param {import('@/store/index').RootState} state - The current application state.
 * @returns {number} - The total value of all bets placed by the player.
 */
export const selectPlayerBets = createSelector(
  (/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory,
  (betHistory) => betHistory.reduce((acc, betItem) => acc + betItem.bet, 0)
);

/**
 * Selector to check if the current round state is either "PLACE_BETS" or "FINAL_BETS" and if the table state is "OPEN".
 * @param {import('@/store/index').RootState} state - The current application state.
 * @returns {boolean} - True if the current round state is either "PLACE_BETS" or "FINAL_BETS" and the table state is "OPEN", false otherwise.
 */
export const selectIsBettingTime = createSelector(
  (/** @type {import('@/store/index').RootState} */ state) => state.game.roundState,
  (/** @type {import('@/store/index').RootState} */ state) => state.game.tableState,
  (roundState, tableState) =>
    // @ts-ignore
    [ROUND_STATE.PLACE_BETS, ROUND_STATE.FINAL_BETS].includes(roundState) && tableState === TABLE_STATE.OPEN
);

/**
 * Selector to check if all bets in betHistory are winning bets.
 * @param {import('@/store/index').RootState} state - The current application state.
 * @returns {boolean} - True if all bets are winning bets, false otherwise.
 */
export const selectBetsAreOnlyWinningBets = createSelector(
  (/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory,
  (betHistory) => betHistory.length > 0 && betHistory.every((betItem) => betItem.isWinningBet)
);
