import HistoryLine from '../HistoryLine';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import styles from './StatisticsView.module.scss';
import HotCold from './HotCold';
import RoundSelector from '../common/RoundSelector';
import Advanced from './Advanced';
import Last500 from './Last500';
import Scrollable from '../common/Scrollable';
import { useTranslation } from 'react-i18next';
import { SCREEN_ORIENTATION, VIEW_TYPE } from '@/enums/ui';
import useOrientation from '@/hooks/useOrientation';
import Tabs from '../common/Tabs';
import { setStatRoundCount } from '@/store/gameSlice';

const StatisticsView = ({ className, type = 'compact' }) => {
  const orientation = useOrientation();
  const viewType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.viewType);
  const isTouchDevice = viewType === VIEW_TYPE.TOUCH;
  const { t } = useTranslation('common');
  const rounds = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.statRoundCount);
  const dispatch = useDispatch();

  const tabs = [
    {
      name: 'hotAndColdTab',
      label: t('statistics.hotAndCold'),
      content: (
        <HotCold view={isTouchDevice ? (orientation === SCREEN_ORIENTATION.PORTRAIT ? 'vertical' : 'tablet') : type} />
      ),
    },
    {
      name: 'advancedTab',
      label: t('statistics.advanced'),
      content: <Advanced />,
    },
    {
      name: 'last200Tab',
      label: t('statistics.last200'),
      content: (
        <Scrollable scrollHint={true}>
          <Last500 />
        </Scrollable>
      ),
    },
  ];

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.content}>
        <Tabs tabs={tabs}>
          <HistoryLine withBoxShadow={true} />
        </Tabs>
      </div>
      <div className={styles.roundSelectorContainer}>
        <RoundSelector
          rounds={rounds}
          onChanged={(value) => dispatch(setStatRoundCount(value))}
          min={50}
          max={200}
          step={10}
        />
      </div>
    </div>
  );
};

export default StatisticsView;
