export const SERVER_ERROR_TYPE = Object.freeze({
  CONNECTION_ERROR: 'HPGS_CONNECTION_ERROR',
  GAME_DISABLED: 'HPGS_GAME_DISABLED',
  GAME_NOT_FOUND: 'HPGS_GAME_NOT_FOUND',
  GAME_SESSION_ENDED: 'HPGS_GAME_SESSION_ENDED',
  GAMING_LIMIT_REACHED: 'HPGS_GAMING_LIMIT_REACHED',
  TABLE_LIMIT_REACHED: 'HPGS_TABLE_LIMIT_REACHED',
  GENERAL_ERROR: 'HPGS_GENERAL_ERROR',
  INSUFFICIENT_BALANCE: 'HPGS_INSUFFICIENT_BALANCE',
  INTERNAL_SERVER_ERROR: 'HPGS_INTERNAL_SERVER_ERROR',
  INVALID_BET_ADD: 'HPGS_INVALID_BET_ADD',
  INVALID_BET_REMOVE: 'HPGS_INVALID_BET_REMOVE',
  INVALID_TOKEN: 'HPGS_INVALID_TOKEN',
  REAL_MONEY_NOT_ALLOWED: 'HPGS_REAL_MONEY_NOT_ALLOWED',
  TIME_EXPIRED: 'HPGS_TIME_EXPIRED_ERROR',
  USER_FROZEN: 'HPGS_USER_FROZEN',
  DEVICE_MANAGER_ERROR: 'HPGS_DEVICE_MANAGER_ERROR',
});

export const CLIENT_ERROR_TYPE = Object.freeze({
  CONNECTION_ERROR: 'HPGC_CONNECTION_ERROR',
  GENERAL_ERROR: 'HPGC_GENERAL_ERROR',
  MISSING_TOKEN: 'HPGC_MISSING_TOKEN',
});
