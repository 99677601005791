import MarkerIcon from '@/assets/svg/marker.svg?react';
import styles from './BetEditModeButton.module.scss';

const BetEditModeButton = () => {
  return (
    <div className={styles.container}>
      <MarkerIcon height={'1em'} />
    </div>
  );
};

export default BetEditModeButton;
