import { ORIENTATION, ROULETTE_TABLE_TYPE } from '@/enums/ui';
import styles from './RouletteTable.module.scss';
import { useCallback, useMemo, useState } from 'react';
import Chips from './Chips';
import DollyWrapper from './DollyWrapper';
import { pxToRem } from '@/lib/uiService';
import { addBetThunk } from '@/store/thunks/gameThunks';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsBettingTime } from '@/store/selectors/gameSelectors';
import TableResponsive from './TableResponsive';
import Table3D from './Table3D';
import { setItemHovered } from '@/store/tableSlice';
import { getNumbersByOutsideBet, isOutsideBet, parseBetKey } from '@/lib/rouletteService';
import { setHighlightedNumbers } from '@/store/gameSlice';
import DragChip from './Chips/DragChip';

const RouletteTable = ({
  type = ROULETTE_TABLE_TYPE.RESPONSIVE,
  orientation = ORIENTATION.HORIZONTAL,
  fieldOpacity = 100,
}) => {
  const TABLE_ID = 'rouletteTable';
  const [chipSize, setChipSize] = useState('1.8rem');
  const isBettingTime = useSelector(selectIsBettingTime);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const [chipPositions, setChipPositions] = useState();
  const dispatch = useDispatch();

  const onBetSpotEntered = useCallback(
    (betKey) => {
      if (!selectedChip && !isBettingTime) return;
      dispatch(setItemHovered(betKey));
      const bet = parseBetKey(betKey);
      const { numbers } = bet;
      if (isOutsideBet(bet.betType)) {
        dispatch(setHighlightedNumbers(getNumbersByOutsideBet(bet.betType)));
      } else {
        dispatch(setHighlightedNumbers(numbers));
      }
    },
    [dispatch, isBettingTime, selectedChip]
  );

  const onBetSpotLeaved = useCallback(() => {
    dispatch(setItemHovered(null));
    dispatch(setHighlightedNumbers(null));
  }, [dispatch]);

  const onBetSpotClicked = useCallback(
    async (event, betKey) => {
      await dispatch(
        addBetThunk(
          [
            {
              betType: betKey,
              bet: selectedChip,
            },
          ],
          event
        )
      );
    },
    [dispatch, selectedChip]
  );

  const memoizedChipSize = useMemo(() => {
    if (type === ROULETTE_TABLE_TYPE.RESPONSIVE) {
      return chipSize;
    } else {
      return '1.8rem';
    }
  }, [chipSize, type]);

  const onChipPositionCalculated = useCallback((positions) => {
    setChipPositions(positions);
  }, []);

  const onTableOptionsChanged = useCallback((tableOptions) => {
    const { numberItem } = tableOptions;
    const maxWidth = numberItem.width * 0.8;
    const maxHeight = numberItem.height * 0.8;
    const chipSize = Math.min(maxWidth, maxHeight);
    setChipSize(pxToRem(chipSize));
  }, []);

  const memoizedDolly = useMemo(() => {
    if (!chipPositions) return null;
    return <DollyWrapper chipPositions={chipPositions} />;
  }, [chipPositions]);

  const memoizedResponsiveTable = useMemo(() => {
    return (
      <TableResponsive
        orientation={orientation}
        fieldOpacity={fieldOpacity}
        onTableOptionsChanged={onTableOptionsChanged}
        onBetSpotClicked={onBetSpotClicked}
        onBetSpotEntered={onBetSpotEntered}
        onBetSpotLeaved={onBetSpotLeaved}
        onChipPositionCalculated={onChipPositionCalculated}
      />
    );
  }, [
    fieldOpacity,
    onBetSpotClicked,
    onBetSpotEntered,
    onBetSpotLeaved,
    onChipPositionCalculated,
    onTableOptionsChanged,
    orientation,
  ]);

  const memoizedTable3D = useMemo(() => {
    return (
      <Table3D
        onBetSpotClicked={onBetSpotClicked}
        onBetSpotEntered={onBetSpotEntered}
        onBetSpotLeaved={onBetSpotLeaved}
        onChipPositionCalculated={onChipPositionCalculated}
      />
    );
  }, [onBetSpotClicked, onBetSpotEntered, onBetSpotLeaved, onChipPositionCalculated]);

  return (
    <div id={TABLE_ID} className={styles.container}>
      {type === ROULETTE_TABLE_TYPE.RESPONSIVE ? memoizedResponsiveTable : memoizedTable3D}
      {chipPositions && <Chips tableType={type} chipPositions={chipPositions} chipSize={memoizedChipSize} />}
      {memoizedDolly}
      <DragChip tableType={type} chipSize={memoizedChipSize} />
    </div>
  );
};

export default RouletteTable;
