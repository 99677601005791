import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} TableState - TBD.
 * @property {string} itemHovered - TBD.
 * @property {boolean} isDragging - TBD.
 * @property {{x: number, y: number}} dragCoordinates - TBD.
 * @property {string} draggedBet - TBD.
 * @property {number} dragThreshold - TBD.
 * @property {number} chipSize - TBD.
 * @property {{dragActive: boolean, dragCoordinates: {x: number, y: number}, draggedBetType: string, betSpotHovered: string}} dragAndDrop - TBD.
 * @property {boolean} isWinningBetRetained - TBD.
 */

/** @type {TableState} */
const initialState = {
  itemHovered: null,
  isDragging: false,
  dragCoordinates: null,
  draggedBet: null,
  dragThreshold: 10,
  chipSize: 55,
  dragAndDrop: {
    dragActive: false,
    dragCoordinates: null,
    draggedBetType: null,
    betSpotHovered: null,
  },
  isWinningBetRetained: false,
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {TableState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setItemHovered: (state, action) => {
      if (state.itemHovered !== action.payload) {
        state.itemHovered = action.payload;
      }
    },
    /**
     * TBD.
     * @param {TableState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setDraggedBetType: (state, action) => {
      state.dragAndDrop.draggedBetType = action.payload;
      state.dragAndDrop.dragActive = true;
    },
    /**
     * TBD.
     * @param {TableState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - Store action.
     */
    setDraggedBet: (state, action) => {
      state.draggedBet = action.payload;
    },
    /**
     * TBD.
     * @param {TableState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<{x: number, y: number}>} action - Store action.
     */
    setDragCoordinates: (state, action) => {
      state.dragAndDrop.dragCoordinates = action.payload;
    },
    setBetSpotHovered: (state, action) => {
      if (state.dragAndDrop.betSpotHovered !== action.payload) {
        state.dragAndDrop.betSpotHovered = action.payload;
      }
    },
    /**
     * TBD.
     * @param {TableState} state - State data.
     */
    clearDragData: (state) => {
      state.dragAndDrop = {
        dragActive: false,
        dragCoordinates: null,
        draggedBetType: null,
        betSpotHovered: null,
      };
    },
    /**
     * TBD.
     * @param {TableState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - Store action.
     */
    setChipSize: (state, action) => {
      state.chipSize = action.payload;
    },
    setIsWinningBetRetained: (state, action) => {
      state.isWinningBetRetained = action.payload;
    },
  },
});

export const {
  setItemHovered,
  setDragCoordinates,
  setDraggedBetType,
  setBetSpotHovered,
  setDraggedBet,
  clearDragData,
  setChipSize,
  setIsWinningBetRetained,
} = tableSlice.actions;

export default tableSlice.reducer;
