export const BET_TYPE = Object.freeze({
  STRAIGHT: 'straight',
  STREET: 'street',
  SPLIT: 'split',
  CORNER: 'corner',
  SIX_LINE: 'sixLine',
  RIGHT_COLUMN: 'rightColumn',
  MIDDLE_COLUMN: 'middleColumn',
  LEFT_COLUMN: 'leftColumn',
  FIRST_TWELVE: 'firstTwelve',
  SECOND_TWELVE: 'secondTwelve',
  THIRD_TWELVE: 'thirdTwelve',
  EVEN: 'even',
  ODD: 'odd',
  RED: 'red',
  BLACK: 'black',
  ONE_TO_EIGHTEEN: 'oneToEighteen',
  NINETEEN_TO_THIRTY_SIX: 'nineteenToThirtySix',
  STRAIGHT_UP_ALL: 'straightUpAll',
});

export const MAXIMUM_BET = Object.freeze({
  STRAIGHT: 250,
  SPLIT: 500,
  THREE_LINE: 800,
  CORNER: 1000,
  SIX_LINE: 1600,
  COLUMN: 3500,
  DOZEN: 3500,
  RED_BLACK: 5000,
  ODD_EVEN: 5000,
  LOW_HIGH: 5000,
});

export const COLOR = Object.freeze({
  RED: 'red',
  BLACK: 'black',
  GREEN: 'green',
  NONE: 'none',
});

export const RACE_TRACK_BET_TYPE = Object.freeze({
  NUMBER: 'number',
  TIER: 'tier',
  ORPHELINS: 'orphelins',
  VOISINS: 'voisins',
  ZERO: 'zero',
});

export const SPECIAL_BET_TYPE = Object.freeze({
  FINALE_EN_PLEIN: 'finaleEnPlein',
  FINALES_A_CHEVAL: 'finalesACheval',
  COMPLETE_BET: 'completeBet',
});

export const NAMED_BET_VALUES = Object.freeze({
  tier: [5, 8, 10, 11, 13, 16, 23, 24, 27, 30, 33, 36],
  orphelins: [1, 6, 9, 14, 17, 20, 31, 34],
  voisins: [22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25],
  zero: [0, 26, 32, 15, 3, 35, 12],
});
