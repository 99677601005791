import UserIcon from '@/assets/svg/users.svg?react';
import useMoney from '@/hooks/useMoney';
import { Trans } from 'react-i18next';
import cl from 'clsx';
import styles from './WinnerList.module.scss';

const WinnerList = ({ winners }) => {
  const { format } = useMoney();
  const topLimit = 500;

  const calculateSumWin = () => winners?.reduce((accumulator, currentValue) => accumulator + currentValue.win, 0);

  const sumPayout = format(calculateSumWin());
  const winnersCount = winners?.length ?? 0;

  return (
    <div className={styles.container}>
      <div className={styles.summary}>
        <div className={styles.winnerCount}>
          <div>{winnersCount}</div>
          <UserIcon height="1.5em" />
        </div>
        <div className={styles.sumWin}>
          <Trans i18nKey="messageWall.winnerSummary" ns="common" count={winnersCount}>
            have won <strong>{{ sumPayout }}</strong>
          </Trans>
        </div>
      </div>
      {winners.map(({ screenName, win }, index) => (
        <div key={`user-${index}`} className={cl(styles.userWin, topLimit < win && styles.top)}>
          <div className={styles.win}>{format(win)}</div>
          <div className={styles.screenName}>{screenName}</div>
        </div>
      ))}
    </div>
  );
};

export default WinnerList;
