import { CLIENT_ERROR_TYPE, SERVER_ERROR_TYPE } from '@/enums/errorType';
import { SOCKET_CLIENT_EVENT, SOCKET_EVENT, SOCKET_IO_EVENT } from '@/enums/socketEvent';
import usePageVisibility from '@/hooks/usePageVisiblity';
import { addNewMessage } from '@/store/chatSlice';
import { setError } from '@/store/errorSlice';
import { initGame, setInitialized, setPendingTableState, setTableState, setTime } from '@/store/gameSlice';
import { setPlayerSettings } from '@/store/settingsSlice';
import { newRoundThunk, setRoundStateThunk } from '@/store/thunks/gameThunks';
import { clearTimer, setTimer } from '@/store/uiSlice';
import { initUser, setIsSocketConnected, setIsUserAuthenticated, setIsUserConnected } from '@/store/userSlice';
import { Logger } from '@vpmedia/simplify';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../socket/index';
import { setResult, setSettings } from '../../store/gameSlice';
import { setWinners } from '../../store/messageWallSlice';

const logger = new Logger('SocketHandler');

const SocketHandler = () => {
  const isPageVisible = usePageVisibility();
  const isUserAuthenticated = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isUserAuthenticated
  );
  const isGameInitialized = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.isInitialized
  );
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const gameId = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameId);
  logger.info('created', { isGameInitialized, publicPlayerId, gameId });
  const dispatch = useDispatch();

  // ********************************************************************************
  // SOCKET IO EVENT HANDLERS
  // ********************************************************************************

  const onError = useCallback((error) => {
    logger.info(SOCKET_IO_EVENT.ON_ERROR, error);
  }, []);

  const onReconnect = useCallback((/** @type {number} */ attempt) => {
    logger.info(SOCKET_IO_EVENT.ON_RECONNECT, { attempt });
  }, []);

  const onReconnectAttempt = useCallback((/** @type {number} */ attempt) => {
    logger.info(SOCKET_IO_EVENT.ON_RECONNECT_ATTEMPT, { attempt });
  }, []);

  const onReconnectError = useCallback((error) => {
    logger.warn(SOCKET_IO_EVENT.ON_RECONNECT_ERROR, error);
  }, []);

  const onReconnectFailed = useCallback(() => {
    logger.info(SOCKET_IO_EVENT.ON_RECONNECT_FAILED);
  }, []);

  // ********************************************************************************
  // SOCKET CLIENT EVENT HANDLERS
  // ********************************************************************************

  const onConnect = useCallback(() => {
    logger.info(SOCKET_CLIENT_EVENT.ON_CONNECT);
    dispatch(setIsSocketConnected(true));
  }, [dispatch]);

  const onConnectError = useCallback((error) => {
    logger.info(SOCKET_CLIENT_EVENT.ON_CONNECT_ERROR, error);
  }, []);

  const onDisconnect = useCallback(
    (reason, description) => {
      logger.info(SOCKET_CLIENT_EVENT.ON_DISCONNECT, { reason, description, isUserAuthenticated });
      if (isUserAuthenticated) {
        dispatch(setIsSocketConnected(false));
        dispatch(setIsUserAuthenticated(false));
        dispatch(setIsUserConnected(false));
      } else {
        dispatch(
          setError({ operation: SOCKET_CLIENT_EVENT.ON_DISCONNECT, errorCode: CLIENT_ERROR_TYPE.CONNECTION_ERROR })
        );
      }
    },
    [dispatch, isUserAuthenticated]
  );

  const onAuthenticated = useCallback(
    (/** @type {{publicPlayerId: string, balance: number}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_AUTHENTICATED, payload);
      dispatch(initUser(payload));
    },
    [dispatch]
  );

  const onAuthenticationError = useCallback(
    (/** @type {string} */ errorCode) => {
      logger.warn(SOCKET_EVENT.ON_AUTHENTICATION_ERROR, { errorCode });
      dispatch(setError({ operation: SOCKET_CLIENT_EVENT.ON_CONNECT, errorCode }));
      dispatch(setIsUserAuthenticated(false));
    },
    [dispatch]
  );

  // ********************************************************************************
  // SOCKET SERVER EVENT HANDLERS
  // ********************************************************************************

  const onPlayerConnected = useCallback(
    (/** @type {{gameState: object, playerSettings: object}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_PLAYER_CONNECTED, payload);
      const { gameState, playerSettings } = payload;
      dispatch(initGame(gameState));
      dispatch(setPlayerSettings(playerSettings));
      dispatch(setInitialized(true));
      dispatch(setIsUserConnected(true));
      if (gameState.gameName) {
        document.title = gameState.gameName;
      }
    },
    [dispatch]
  );

  const onTimeChanged = useCallback(
    (/** @type {string} */ time) => {
      logger.info(SOCKET_EVENT.ON_TIME_CHANGED, { time });
      dispatch(setTime(time));
    },
    [dispatch]
  );

  const onTimerChanged = useCallback(
    (/** @type {{duration: number, remainingTime: number}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_TIMER_CHANGED, payload);
      dispatch(setTimer(payload));
    },
    [dispatch]
  );

  const onTimerStopped = useCallback(() => {
    logger.info(SOCKET_EVENT.ON_TIMER_STOPPED);
    dispatch(clearTimer());
  }, [dispatch]);

  const onRoundChanged = useCallback(
    (/** @type {{roundState: string, winningNumber: number}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_ROUND_CHANGED, payload);
      dispatch(setRoundStateThunk(payload));
    },
    [dispatch]
  );

  const onRoundCreated = useCallback(
    (/** @type {{roundId: string, roundStartedAt: string}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_ROUND_CREATED, payload);
      const { roundId, roundStartedAt } = payload;
      dispatch(newRoundThunk(roundId, roundStartedAt));
    },
    [dispatch]
  );

  const onResultArrived = useCallback(
    (/** @type {{[key: string]: number}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_RESULT_ARRIVED, payload);
      dispatch(setResult(payload));
    },
    [dispatch]
  );

  const onChatMessageSent = useCallback(
    (/** @type {object} */ payload) => {
      logger.info(SOCKET_EVENT.ON_CHAT_MESSAGE_SENT, payload);
      const { userId } = payload;
      if (userId === publicPlayerId) return;
      dispatch(addNewMessage(payload));
    },
    [dispatch, publicPlayerId]
  );

  const onSettingsChanged = useCallback(
    (/** @type {object} */ payload) => {
      logger.info(SOCKET_EVENT.ON_SETTINGS_CHANGED, payload);
      dispatch(setSettings(payload));
    },
    [dispatch]
  );

  const onWinnersArrived = useCallback(
    (/** @type {{publicPlayerId: string, screenName: string, win: number}[]} */ payload) => {
      logger.info(SOCKET_EVENT.ON_WINNERS_ARRIVED, payload);
      dispatch(setWinners(payload));
    },
    [dispatch]
  );

  const onTableStateChanged = useCallback(
    (/** @type {string} */ state) => {
      logger.info(SOCKET_EVENT.ON_TABLE_STATE_CHANGED, { state });
      dispatch(setTableState(state));
    },
    [dispatch]
  );

  const onTableStateChangePending = useCallback(
    (/** @type {string} */ state) => {
      logger.info(SOCKET_EVENT.ON_TABLE_STATE_CHANGE_PENDING, { state });
      dispatch(setPendingTableState(state));
    },
    [dispatch]
  );

  const onVoidGame = useCallback(() => {
    logger.info(SOCKET_EVENT.ON_VOID_GAME);
    dispatch(
      setError({
        operation: SOCKET_EVENT.ON_VOID_GAME,
        errorCode: SERVER_ERROR_TYPE.DEVICE_MANAGER_ERROR,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    if (!isPageVisible && socket?.connected) {
      logger.info('Disconnecting');
      socket?.disconnect();
    } else if (!socket?.connected && socket?.auth?.token) {
      logger.info('Connecting');
      socket?.connect();
    }
  }, [isPageVisible]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    if (!socket.auth.token) {
      dispatch(setError({ operation: SOCKET_CLIENT_EVENT.ON_CONNECT, errorCode: CLIENT_ERROR_TYPE.MISSING_TOKEN }));
      return;
    }
    logger.info('Adding socket event listeners');
    socket.io.on(SOCKET_IO_EVENT.ON_ERROR, onError);
    socket.io.on(SOCKET_IO_EVENT.ON_RECONNECT_ATTEMPT, onReconnectAttempt);
    socket.io.on(SOCKET_IO_EVENT.ON_RECONNECT_ERROR, onReconnectError);
    socket.io.on(SOCKET_IO_EVENT.ON_RECONNECT_FAILED, onReconnectFailed);
    socket.io.on(SOCKET_IO_EVENT.ON_RECONNECT, onReconnect);
    socket.on(SOCKET_CLIENT_EVENT.ON_CONNECT_ERROR, onConnectError);
    socket.on(SOCKET_CLIENT_EVENT.ON_CONNECT, onConnect);
    socket.on(SOCKET_CLIENT_EVENT.ON_DISCONNECT, onDisconnect);
    socket.on(SOCKET_EVENT.ON_AUTHENTICATED, onAuthenticated);
    socket.on(SOCKET_EVENT.ON_AUTHENTICATION_ERROR, onAuthenticationError);
    socket.on(SOCKET_EVENT.ON_CHAT_MESSAGE_SENT, onChatMessageSent);
    socket.on(SOCKET_EVENT.ON_VOID_GAME, onVoidGame);
    socket.on(SOCKET_EVENT.ON_ROUND_CREATED, onRoundCreated);
    socket.on(SOCKET_EVENT.ON_PLAYER_CONNECTED, onPlayerConnected);
    socket.on(SOCKET_EVENT.ON_RESULT_ARRIVED, onResultArrived);
    socket.on(SOCKET_EVENT.ON_ROUND_CHANGED, onRoundChanged);
    socket.on(SOCKET_EVENT.ON_SETTINGS_CHANGED, onSettingsChanged);
    socket.on(SOCKET_EVENT.ON_TABLE_STATE_CHANGE_PENDING, onTableStateChangePending);
    socket.on(SOCKET_EVENT.ON_TABLE_STATE_CHANGED, onTableStateChanged);
    socket.on(SOCKET_EVENT.ON_TIME_CHANGED, onTimeChanged);
    socket.on(SOCKET_EVENT.ON_TIMER_CHANGED, onTimerChanged);
    socket.on(SOCKET_EVENT.ON_TIMER_STOPPED, onTimerStopped);
    socket.on(SOCKET_EVENT.ON_WINNERS_ARRIVED, onWinnersArrived);

    return () => {
      if (!socket) {
        return;
      }

      logger.info('Removing socket event listeners');
      socket.io.off(SOCKET_IO_EVENT.ON_ERROR, onError);
      socket.io.off(SOCKET_IO_EVENT.ON_RECONNECT_ATTEMPT, onReconnectAttempt);
      socket.io.off(SOCKET_IO_EVENT.ON_RECONNECT_ERROR, onReconnectError);
      socket.io.off(SOCKET_IO_EVENT.ON_RECONNECT_FAILED, onReconnectFailed);
      socket.io.off(SOCKET_IO_EVENT.ON_RECONNECT, onReconnect);
      socket.off(SOCKET_CLIENT_EVENT.ON_CONNECT_ERROR, onConnectError);
      socket.off(SOCKET_CLIENT_EVENT.ON_CONNECT, onConnect);
      socket.off(SOCKET_CLIENT_EVENT.ON_DISCONNECT, onDisconnect);
      socket.off(SOCKET_EVENT.ON_AUTHENTICATED, onAuthenticated);
      socket.off(SOCKET_EVENT.ON_AUTHENTICATION_ERROR, onAuthenticationError);
      socket.off(SOCKET_EVENT.ON_CHAT_MESSAGE_SENT, onChatMessageSent);
      socket.off(SOCKET_EVENT.ON_VOID_GAME, onVoidGame);
      socket.off(SOCKET_EVENT.ON_ROUND_CREATED, onRoundCreated);
      socket.off(SOCKET_EVENT.ON_PLAYER_CONNECTED, onPlayerConnected);
      socket.off(SOCKET_EVENT.ON_RESULT_ARRIVED, onResultArrived);
      socket.off(SOCKET_EVENT.ON_ROUND_CHANGED, onRoundChanged);
      socket.off(SOCKET_EVENT.ON_SETTINGS_CHANGED, onSettingsChanged);
      socket.off(SOCKET_EVENT.ON_TABLE_STATE_CHANGE_PENDING, onTableStateChangePending);
      socket.off(SOCKET_EVENT.ON_TABLE_STATE_CHANGED, onTableStateChanged);
      socket.off(SOCKET_EVENT.ON_TIME_CHANGED, onTimeChanged);
      socket.off(SOCKET_EVENT.ON_TIMER_CHANGED, onTimerChanged);
      socket.off(SOCKET_EVENT.ON_TIMER_STOPPED, onTimerStopped);
      socket.off(SOCKET_EVENT.ON_WINNERS_ARRIVED, onWinnersArrived);
    };
  }, [
    publicPlayerId,
    dispatch,
    isGameInitialized,
    onAuthenticated,
    onAuthenticationError,
    onChatMessageSent,
    onConnect,
    onConnectError,
    onVoidGame,
    onDisconnect,
    onError,
    onRoundCreated,
    onPlayerConnected,
    onReconnect,
    onReconnectAttempt,
    onReconnectError,
    onReconnectFailed,
    onResultArrived,
    onRoundChanged,
    onSettingsChanged,
    onTableStateChanged,
    onTableStateChangePending,
    onTimeChanged,
    onTimerChanged,
    onTimerStopped,
    onWinnersArrived,
  ]);

  return null;
};

export default SocketHandler;
