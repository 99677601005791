import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '../common/Snackbar';
import { removeSnackbar } from '@/store/uiSlice';
import { useCallback } from 'react';

const SnackbarManager = () => {
  const snackbars = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.snackbars);
  const dispatch = useDispatch();

  const handleCloseSnackbar = useCallback(
    (id) => {
      dispatch(removeSnackbar({ id }));
    },
    [dispatch]
  );

  return (
    <>
      {snackbars.map((snackbar) => (
        <Snackbar key={snackbar.id} title={snackbar.title} onClose={() => handleCloseSnackbar(snackbar.id)}>
          {snackbar.content}
        </Snackbar>
      ))}
    </>
  );
};

export default SnackbarManager;
