import { BET_TYPE, RACE_TRACK_BET_TYPE } from '@/enums/layoutTable';
import ROUND_STATE from '@/enums/roundState';
import { createBetKey, getNeighbors, getRacetrackNamedBets } from '@/lib/rouletteService';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Dolly from './Dolly';
import RacetrackItem from './RacetrackItem';

const RacetrackVerticalItems = ({
  height,
  width,
  backgroundFilled,
  withFieldOpacity,
  orientation,
  displayChipsAndWinningNumber,
  onClick,
  onMouseEnter,
  onMouseLeave,
  racetrackHighlightedNumbers,
}) => {
  const radius = useMemo(() => width / 2, [width]);
  const straightItemWidth = useMemo(() => width / 4, [width]);
  const itemHeight = useMemo(() => (height - 2 * radius) / 13, [height, radius]);
  const radiusInner = useMemo(() => radius - straightItemWidth, [radius, straightItemWidth]);
  const chipSize = useMemo(() => Math.min(itemHeight * 0.6, straightItemWidth * 0.6), [itemHeight, straightItemWidth]);

  const numberOrder = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.numberOrder);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const winningNumber = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.winningNumber
  );
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);

  const numberRefs = useRef([]);
  const namedBetRefs = useRef({});

  const isHighlighted = useCallback(
    (number) => racetrackHighlightedNumbers?.includes(number),
    [racetrackHighlightedNumbers]
  );

  const getCenterCoordinates = useCallback((x, y, width, height) => {
    return {
      x: x + width / 2,
      y: y + height / 2,
    };
  }, []);

  const polarToCartesian = useCallback((centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }, []);

  const describeArcLine = useCallback(
    (x, y, radius, startAngle, endAngle) => {
      const start = polarToCartesian(x, y, radius, endAngle);
      const end = polarToCartesian(x, y, radius, startAngle);
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
      return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`;
    },
    [polarToCartesian]
  );

  const describeArc = useCallback(
    (x, y, radius, spread, startAngle, endAngle) => {
      const innerStart = polarToCartesian(x, y, radius, endAngle);
      const innerEnd = polarToCartesian(x, y, radius, startAngle);
      const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
      const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      return [
        `M ${outerStart.x} ${outerStart.y}`,
        `A ${radius + spread} ${radius + spread} 0 ${largeArcFlag} 0 ${outerEnd.x} ${outerEnd.y}`,
        `L ${innerEnd.x} ${innerEnd.y}`,
        `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${innerStart.x} ${innerStart.y}`,
        `L ${outerStart.x} ${outerStart.y} Z`,
      ].join(' ');
    },
    [polarToCartesian]
  );

  const getItemArcParams = useCallback(
    (x, y, steps, index, antiClockwise = false) => {
      const step = 180 / steps;
      const radiusInner = radius - straightItemWidth;
      const startAngle = antiClockwise ? 90 + 360 - (index + 1) * step : 90 + index * step;
      const endAngle = antiClockwise ? 90 + 360 - index * step : 90 + (index + 1) * step;
      return {
        centerCoordinates: polarToCartesian(
          x,
          y,
          radiusInner + straightItemWidth / 2,
          startAngle + (endAngle - startAngle) / 2
        ),
        description: describeArc(x, y, radiusInner, straightItemWidth, startAngle, endAngle),
      };
    },
    [describeArc, polarToCartesian, radius, straightItemWidth]
  );

  const bottomBendItems = useMemo(() => {
    const startItem = 35;
    const items = [startItem, ...getNeighbors(numberOrder, startItem, 4).right].map((number, index) => {
      const arcParams = getItemArcParams(width / 2, height - radius, 5, index);
      return {
        text: number,
        bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
        centerCoordinates: arcParams.centerCoordinates,
        pathDescr: arcParams.description,
      };
    });

    return items;
  }, [bets, getItemArcParams, height, numberOrder, radius, width]);

  const topBendItems = useMemo(() => {
    const startItem = 24;
    const items = [startItem, ...getNeighbors(numberOrder, startItem, 5).left.reverse()].map((number, index) => {
      const arcParams = getItemArcParams(width / 2, radius, 6, index, true);
      return {
        text: number,
        bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
        centerCoordinates: arcParams.centerCoordinates,
        pathDescr: arcParams.description,
      };
    });

    return items;
  }, [bets, getItemArcParams, numberOrder, radius, width]);

  const rightStraightItems = useMemo(() => {
    const firstItemIndex = numberOrder.indexOf(16);
    const numberItems = numberOrder.slice(firstItemIndex, firstItemIndex + 13);
    return numberItems.map((number, index) => ({
      text: number,
      bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
      centerCoordinates: getCenterCoordinates(
        width - straightItemWidth,
        radius + index * itemHeight,
        straightItemWidth,
        itemHeight
      ),
      pathDescr: `M ${width - straightItemWidth} ${
        radius + index * itemHeight
      } h ${straightItemWidth} v ${itemHeight} h ${-1 * straightItemWidth} z`,
    }));
  }, [bets, getCenterCoordinates, itemHeight, numberOrder, radius, straightItemWidth, width]);

  const leftStraightItems = useMemo(() => {
    const firstItemIndex = numberOrder.indexOf(11);
    const numberItems = numberOrder.slice(firstItemIndex - 12, firstItemIndex + 1).reverse();
    return numberItems.map((number, index) => ({
      text: number,
      bets: bets[createBetKey(BET_TYPE.STRAIGHT, number)],
      centerCoordinates: getCenterCoordinates(0, radius + index * itemHeight, straightItemWidth, itemHeight),
      pathDescr: `M 0 ${radius + index * itemHeight} h ${straightItemWidth} v ${itemHeight} h ${
        -1 * straightItemWidth
      } z`,
    }));
  }, [bets, getCenterCoordinates, itemHeight, numberOrder, radius, straightItemWidth]);

  const straightItems = useMemo(() => {
    return [...leftStraightItems, ...rightStraightItems, ...bottomBendItems, ...topBendItems];
  }, [leftStraightItems, bottomBendItems, topBendItems, rightStraightItems]);

  const namedBets = useMemo(() => {
    const items = [];

    items.push({
      text: 'SERIE 5/8',
      type: RACE_TRACK_BET_TYPE.TIER,
      centerCoordinates: {
        x: width / 2,
        y: (radius + 4.5 * itemHeight) / 2,
      },
      pathDescr: `M ${width - straightItemWidth} ${radius} v ${2 * itemHeight} L ${straightItemWidth} ${
        radius + 4 * itemHeight
      } v ${-4 * itemHeight}  ${describeArcLine(radius, radius, radiusInner, 270, 90)} `,
    });

    items.push({
      text: 'ORPHELINS',
      type: RACE_TRACK_BET_TYPE.ORPHELINS,
      centerCoordinates: {
        x: width / 2,
        y: items[0].centerCoordinates.x + 5 * itemHeight,
      },
      pathDescr: `M ${width - straightItemWidth} ${radius + 2 * itemHeight} L ${straightItemWidth} ${
        radius + 4 * itemHeight
      } v ${3 * itemHeight} h ${width - 2 * straightItemWidth} z `,
    });

    items.push({
      text: 'SERIE 0/2/3',
      type: RACE_TRACK_BET_TYPE.VOISINS,
      centerCoordinates: {
        x: width / 2,
        y: height - radius - 4 * itemHeight,
      },
      pathDescr: `${describeArcLine(width / 2, height - radius - itemHeight, radiusInner, 270, 90)} v ${
        -5 * itemHeight
      } h ${width - 2 * straightItemWidth} z `,
    });

    items.push({
      text: 'ZERO',
      type: RACE_TRACK_BET_TYPE.ZERO,
      centerCoordinates: {
        x: width / 2,
        y: height - (2 * radius + itemHeight) / 2,
      },
      pathDescr: `${describeArcLine(
        width / 2,
        height - radius - itemHeight,
        radiusInner,
        270,
        90
      )} v ${itemHeight} ${describeArcLine(width / 2, height - radius, radiusInner, 90, 270)} v ${-1 * itemHeight}`,
    });

    return items;
  }, [describeArcLine, height, itemHeight, radius, radiusInner, straightItemWidth, width]);

  const namedBetsValues = useCallback(
    (type) => {
      let bet = null;
      const notStraightNamedBetsKey = getRacetrackNamedBets(type)
        .filter((bet) => bet.betType !== BET_TYPE.STRAIGHT)
        .map(({ betType, numbers }) => createBetKey(betType, numbers));
      if (notStraightNamedBetsKey) {
        const everyBetsPlaced = notStraightNamedBetsKey.every((key) => Object.prototype.hasOwnProperty.call(bets, key));
        if (everyBetsPlaced) {
          bet = {
            value: _.sum(notStraightNamedBetsKey.map((key) => bets[key].value)),
          };
        }
      }

      return bet;
    },
    [bets]
  );

  return (
    <>
      {straightItems.map((item, index) => (
        <RacetrackItem
          key={`racetrack-item-${item.text}`}
          ref={(el) => (numberRefs.current[index] = el)}
          chipSize={chipSize}
          isHighlighted={isHighlighted(item.text)}
          backgroundFilled={backgroundFilled}
          withFieldOpacity={withFieldOpacity}
          orientation={orientation}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          onClick={(event) => onClick(event, RACE_TRACK_BET_TYPE.NUMBER, Number(item.text))}
          onMouseEnter={() => onMouseEnter(RACE_TRACK_BET_TYPE.NUMBER, Number(item.text), numberRefs.current[index])}
          onMouseLeave={onMouseLeave}
          {...item}
        />
      ))}
      {namedBets.map((item) => (
        <RacetrackItem
          key={`racetrack-item-${item.text}`}
          chipSize={chipSize}
          displayChipsAndWinningNumber={displayChipsAndWinningNumber}
          withFieldOpacity={withFieldOpacity}
          orientation={orientation}
          ref={(el) => (namedBetRefs.current[item.type] = el)}
          bets={namedBetsValues(item.type)}
          onClick={(event) => onClick(event, item.type)}
          onMouseEnter={() => onMouseEnter(item.type, null, namedBetRefs.current[item.type])}
          onMouseLeave={onMouseLeave}
          {...item}
        />
      ))}
      {displayChipsAndWinningNumber && roundState === ROUND_STATE.WINNING_NUMBER && winningNumber !== null && (
        <Dolly straightItems={straightItems} winningNumber={winningNumber} chipSize={chipSize} />
      )}
    </>
  );
};

export default RacetrackVerticalItems;
